export const metryvScoreConstants = {
  METRYV_SCORE_REQUEST: "METRYV_SCORE_REQUEST",
  METRYV_SCORE_SUCCESS: "METRYV_SCORE_SUCCESS",
  METRYV_SCORE_FAILURE: "METRYV_SCORE_FAILURE",
  METRYV_SCORE_CLEAR: "METRYV_SCORE_CLEAR",

  ORIGIN_SUMMARY_REQUEST: "ORIGIN_SUMMARY_REQUEST",
  ORIGIN_SUMMARY_SUCCESS: "ORIGIN_SUMMARY_SUCCESS",
  ORIGIN_SUMMARY_FAILURE: "ORIGIN_SUMMARY_FAILURE",
  ORIGIN_SUMMARY_CLEAR: "ORIGIN_SUMMARY_CLEAR",

  GET_MONTHLY_OVERALL_REQUEST: "GET_MONTHLY_OVERALL_REQUEST",
  GET_MONTHLY_OVERALL_SUCCESS: "GET_MONTHLY_OVERALL_SUCCESS",
  GET_MONTHLY_OVERALL_FAILURE: "GET_MONTHLY_OVERALL_FAILURE",
  GET_MONTHLY_OVERALL_CLEAR: "GET_MONTHLY_OVERALL_CLEAR",

  RANKING_REQUEST: "RANKING_REQUEST",
  RANKING_SUCCESS: "RANKING_SUCCESS",
  RANKING_FAILURE: "RANKING_FAILURE",
  RANKING_CLEAR: "RANKING_CLEAR",

  GET_MONTHLY_LAB_DATA_REQUEST: "GET_MONTHLY_LAB_DATA_REQUEST",
  GET_MONTHLY_LAB_DATA_SUCCESS: "GET_MONTHLY_LAB_DATA_SUCCESS",
  GET_MONTHLY_LAB_DATA_FAILURE: "GET_MONTHLY_LAB_DATA_FAILURE",
  GET_MONTHLY_LAB_DATA_CLEAR: "GET_MONTHLY_LAB_DATA_CLEAR",

  GET_UPTIME_DATA_REQUEST: "GET_UPTIME_DATA_REQUEST",
  GET_UPTIME_DATA_SUCCESS: "GET_UPTIME_DATA_SUCCESS",
  GET_UPTIME_DATA_FAILURE: "GET_UPTIME_DATA_FAILURE",
  GET_UPTIME_DATA_CLEAR: "GET_UPTIME_DATA_CLEAR",

  GET_W3_DATA_REQUEST: "GET_W3_DATA_REQUEST",
  GET_W3_DATA_SUCCESS: "GET_W3_DATA_SUCCESS",
  GET_W3_DATA_FAILURE: "GET_W3_DATA_FAILURE",
  GET_W3_DATA_CLEAR: "GET_W3_DATA_CLEAR",

  GET_SITES_STATUS_REQUEST: "GET_SITES_STATUS_REQUEST",
  GET_SITES_STATUS_SUCCESS: "GET_SITES_STATUS_SUCCESS",
  GET_SITES_STATUS_FAILURE: "GET_SITES_STATUS_FAILURE",
  GET_SITES_STATUS_CLEAR: "GET_SITES_STATUS_CLEAR",

  REMOVE_SITE_FROM_STATE: "REMOVE_SITE_FROM_STATE",
  GET_SITE_FROM_STATE: "GET_SITE_FROM_STATE",
  EDIT_SITE_FROM_STATE: "EDIT_SITE_FROM_STATE",
  REMOVE_COMPETITOR_SITE_FROM_STATE: "REMOVE_COMPETITOR_SITE_FROM_STATE",

  GET_COMPETITOR_SITES_STATUS_REQUEST: "GET_COMPETITOR_SITES_STATUS_REQUEST",
  GET_COMPETITOR_SITES_STATUS_SUCCESS: "GET_COMPETITOR_SITES_STATUS_SUCCESS",
  GET_COMPETITOR_SITES_STATUS_FAILURE: "GET_COMPETITOR_SITES_STATUS_FAILURE",
  GET_COMPETITOR_SITES_STATUS_CLEAR: "GET_COMPETITOR_SITES_STATUS_CLEAR",
};
