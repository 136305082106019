import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, FormSelect } from "shards-react";
import axios from "axios";
import { API_URL_PSI } from "../../constants";
import { PieChart } from "react-minimal-pie-chart";
import ReactTooltip from "react-tooltip";
import CircularProgress from "@mui/material/CircularProgress";

const TopDevices = ({ propertyId }) => {
  const [loading, setLoading] = useState(false);
  const [overall, setOverall] = useState([]);
  const [desktopData, setDesktopData] = useState([]);
  const [tabletData, setTabletData] = useState([]);
  const [mobileData, setMobileData] = useState([]);
  const [usersByDevice, setUsersByDevice] = useState("desktop");
  const [hovered, setHovered] = useState("");
  const [error, setError] = useState("");

  const getAnalyticsOverall = async () => {
    try {
      setError(true);
      const response = await axios.post(`${API_URL_PSI}/analytics-overall`, {
        propertyId: propertyId.toString(),
        dimension: "deviceCategory",
      });
      let mobile = [];
      let desktop = [];
      let tablet = [];
      setOverall(response.data);
      response.data.map((item) => {
        let { deviceCategory, activeUsers, newUsers, totalUsers } = item;
        if (deviceCategory === "mobile") {
          mobile.push(
            {
              title: "Active Users",
              value: +activeUsers,
              color: "#7ebafc",
            },
            {
              title: "New Users",
              value: +newUsers,
              color: "#b2d6fe",
            },
            {
              title: "Total Users",
              value: +totalUsers,
              color: "#1985fb",
            }
          );
        }
        if (deviceCategory === "desktop") {
          desktop.push(
            {
              title: "Active Users",
              value: +activeUsers,
              color: "#7ebafc",
            },
            {
              title: "New Users",
              value: +newUsers,
              color: "#b2d6fe",
            },
            {
              title: "Total Users",
              value: +totalUsers,
              color: "#1985fb",
            }
          );
        }
        if (deviceCategory === "tablet") {
          tablet.push(
            {
              title: "Active Users",
              value: +activeUsers,
              color: "#7ebafc",
            },
            {
              title: "New Users",
              value: +newUsers,
              color: "#b2d6fe",
            },
            {
              title: "Total Users",
              value: +totalUsers,
              color: "#1985fb",
            }
          );
        }
      });
      setDesktopData(desktop);
      setTabletData(tablet);
      setMobileData(mobile);
      setLoading(false);
    } catch (error) {
      console.log(error.response);
      setError(error.response.data);
      setOverall([]);
      setLoading(false);
    }
  };

  const handleTabChange = (e) => {
    setUsersByDevice(e.target.value);
  };

  useEffect(async () => {
    await getAnalyticsOverall();
  }, [propertyId]);

  return (
    <>
      <Card
        style={{ minHeight: overall.length ? "497px" : "", maxHeight: "497px" }}
      >
        <CardHeader className="border-bottom">
          <FormSelect className="overviewSelect" onChange={handleTabChange}>
            <option value={"desktop"}>Users By Desktop</option>
            <option value={"mobile"}>Users By Mobile</option>
            <option value={"tablet"}>Users By Tablet</option>
          </FormSelect>
        </CardHeader>
        {loading ? (
          <CircularProgress
            style={{
              margin: "auto",
              height: "140px",
              width: "140px",
              padding: "10px",
            }}
          />
        ) : overall.length ? (
          <CardBody>
            {usersByDevice === "desktop" ? (
              desktopData.length ? (
                <>
                  <PieChart
                    data-tip=""
                    animate={true}
                    animationDuration={500}
                    animationEasing="ease-out"
                    viewBoxSize={[120, 120]}
                    center={[60, 50]}
                    data={[...desktopData]}
                    // lineWidth={}
                    paddingAngle={5}
                  />
                  <ul className="usersByDeviceList">
                    <li>Total Users: {desktopData[2].value}</li>
                    <li>Active Users: {desktopData[0].value}</li>
                    <li>New Users: {desktopData[1].value}</li>
                  </ul>
                  <ReactTooltip>{hovered}</ReactTooltip>
                </>
              ) : (
                <p>{error ? error : "No Data"}</p>
              )
            ) : null}
            {usersByDevice === "mobile" ? (
              mobileData.length ? (
                <>
                  <PieChart
                    animate={true}
                    animationDuration={500}
                    animationEasing="ease-out"
                    data-tip=""
                    viewBoxSize={[120, 120]}
                    center={[60, 50]}
                    data={[...mobileData]}
                    paddingAngle={5}
                    onMouseOver={(e, segmentIndex) => {
                      setHovered(`${mobileData[segmentIndex].value}`);
                      console.log(`${mobileData[segmentIndex].value}`);
                    }}
                    onMouseOut={() => {
                      setHovered(``);
                    }}
                  />
                  <ReactTooltip>{hovered}</ReactTooltip>
                  <ul className="usersByDeviceList">
                    <li>Total Users: {mobileData[2].value} </li>
                    <li>Active Users: {mobileData[0].value} </li>
                    <li>New Users: {mobileData[1].value} </li>
                  </ul>
                </>
              ) : (
                <p>{error ? error : "No Data"}</p>
              )
            ) : null}
            {usersByDevice === "tablet" ? (
              tabletData.length ? (
                <>
                  <PieChart
                    data-tip=""
                    animate={true}
                    animationDuration={500}
                    animationEasing="ease-out"
                    viewBoxSize={[120, 120]}
                    center={[60, 50]}
                    data={[...tabletData]}
                    paddingAngle={5}
                  />
                  <ul className="usersByDeviceList">
                    <li>Total Users: {tabletData[2].value}</li>
                    <li>Active Users: {tabletData[0].value}</li>
                    <li>New Users: {tabletData[1].value}</li>
                  </ul>
                  <ReactTooltip>{hovered}</ReactTooltip>
                </>
              ) : (
                <p>{error ? error : "No Data"}</p>
              )
            ) : null}
          </CardBody>
        ) : (
          <CardBody>{error ? error : "No Data"}</CardBody>
        )}
      </Card>
      <ReactTooltip>{hovered}</ReactTooltip>
    </>
  );
};

export default TopDevices;
