import axios from "axios";
import { API_URL_PSI } from "../constants";

export function getScoreClass(score, type, device) {
  score = !score ? "" : parseFloat(score.replace("s", "").replace("ms", ""));
  let cssClass = "";
  if (device == "mobile") {
    switch (type) {
      case "FCP":
        if (score < 1.83) {
          cssClass = "good";
        } else if (score > 3.01) {
          cssClass = "bad";
        } else {
          cssClass = "average";
        }
        break;
      case "SI":
        if (score < 3.43) {
          cssClass = "good";
        } else if (score > 5.83) {
          cssClass = "bad";
        } else {
          cssClass = "average";
        }
        break;
      case "LCP":
        if (score < 2.53) {
          cssClass = "good";
        } else if (score > 4.01) {
          cssClass = "bad";
        } else {
          cssClass = "average";
        }
        break;

      case "TBT":
        if (score < 210) {
          cssClass = "good";
        } else if (score > 600) {
          cssClass = "bad";
        } else {
          cssClass = "average";
        }
        break;

      case "CLS":
        if (score < 0.11) {
          cssClass = "good";
        } else if (score > 0.25) {
          cssClass = "bad";
        } else {
          cssClass = "average";
        }
        break;

      default:
        break;
    }
  } else {
    switch (type) {
      case "FCP":
        if (score < 0.95) {
          cssClass = "good";
        } else if (score > 1.6) {
          cssClass = "bad";
        } else {
          cssClass = "average";
        }
        break;

      case "LCP":
        if (score < 1.22) {
          cssClass = "good";
        } else if (score > 2.41) {
          cssClass = "bad";
        } else {
          cssClass = "average";
        }
        break;
      case "SI":
        if (score < 1.33) {
          cssClass = "good";
        } else if (score > 2.31) {
          cssClass = "bad";
        } else {
          cssClass = "average";
        }
        break;

      case "TBT":
        if (score < 160) {
          cssClass = "good";
        } else if (score > 350) {
          cssClass = "bad";
        } else {
          cssClass = "average";
        }
        break;

      case "CLS":
        if (score < 0.11) {
          cssClass = "good";
        } else if (score > 0.25) {
          cssClass = "bad";
        } else {
          cssClass = "average";
        }
        break;

      default:
        break;
    }
  }
  return cssClass;
}

export function getSuggestionClass(time, device) {
  time = !time ? "" : parseFloat(time.replace("s", "").replace("ms", ""));
  let cssClass = "";
  if (device == "Mobile") {
    if (time >= 1) {
      cssClass = "bad";
    } else {
      cssClass = "average";
    }
  } else {
    if (time >= 0.7) {
      cssClass = "bad";
    } else {
      cssClass = "average";
    }
  }
  return cssClass;
}

export function getScoreColor(score) {
  let color = [];
  switch (score) {
    case "FAST":
      color["border"] = "rgb(23,198,113)";
      color["background"] = "rgba(23,198,113,0.1)";
      break;

    case "AVERAGE":
      color["border"] = "rgb(255,180,0)";
      color["background"] = "rgba(255,180,0,0.1)";
      break;

    default:
      color["border"] = "#EB0F00";
      color["background"] = "#ff5a4f";
      break;
  }
  return color;
}

export const fetchStatus = async (url) => {
  let data = "";
  await axios
    .get(
      `${API_URL_PSI}/status/`,
      { params: { url: url } },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.log(error.response);
      return false;
    });
  return data;
};

export function getStatus(score) {
  let status = "";
  let cssStyle = "";
  switch (score) {
    case "FAST":
      status = "passes";
      cssStyle = "score-text--good";
      break;

    case "AVERAGE":
      status = "barely passes";
      cssStyle = "score-text--average";
      break;

    case "SLOW":
      status = "does not pass";
      cssStyle = "score-text--bad";
      break;

    default:
      status = "no data enough";
      cssStyle = "";
      break;
  }
  return [status, cssStyle];
}

export function getOverallScoreColor(score) {
  let cssClass = "";
  if (score >= 90) {
    cssClass = " score-number--good";
  } else if (score >= 50 && score < 90) {
    cssClass = " score-number--average";
  } else {
    cssClass = " score-number--bad";
  }
  return cssClass;
}

export function getOverallScoreColorSiteDetails(score) {
  let cssClass = "";
  if (score >= 90) {
    cssClass = " ";
    // cssClass = " good";
  } else if (score >= 50 && score < 90) {
    cssClass = " average";
  } else {
    cssClass = " bad";
  }
  return cssClass;
}

export function getMetryVOverallScoreColorSiteDetails(score) {
  let cssClass = "";
  if (score >= 9) {
    cssClass = "--good";
  } else if (score >= 5 && score < 9) {
    cssClass = "--average";
  } else if (score == "?") {
    cssClass = "--null";
  } else {
    cssClass = "--bad";
  }
  return cssClass;
}

export const calculateDifference = (x, y) => {
  const getDifference = (x, y) => {
    return Math.abs(x - y).toFixed(2);
  };

  if (x > y) {
    return { value: true, differnce: +getDifference(x, y) };
  } else if (y > x) {
    return { value: false, differnce: +getDifference(x, y) };
  } else {
    return { value: "noDifference", differnce: +getDifference(x, y) };
  }
};

export function getCoreWebVitalsColor(score) {
  if (score == "FAST") return "rgb(23,198,113)";
  if (score == "AVERAGE") return "rgb(255,180,0)";
  return "#ff5a4f";
}
