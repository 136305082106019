import React from "react";
import { Row, Col } from "shards-react";
import SmallStats from "../common/SmallStats";
import CircularProgress from "@mui/material/CircularProgress";

const FieldData = ({
  hasFieldData,
  hasFID,
  fieldDataCategory,
  smallStats,
  smallStatsDesktop,
}) => {
  return (
    <>
      <Row>
        <h3 className="text-sm-left ml-3 heading-3">Field Data</h3>
      </Row>

      {/* Small Stats Blocks */}
      <Row>
        <h4 className="text-sm-left ml-3 heading-3">Mobile Devices</h4>
      </Row>
      <Row>
        {!hasFieldData ? (
          ""
        ) : (
          <span className="text-sm-left ml-3">
            Over the previous 28-day collection period, field data shows that
            this page{" "}
            <strong
              style={{
                color:
                  fieldDataCategory == "FAST"
                    ? "#018642"
                    : fieldDataCategory == "AVERAGE"
                    ? "#ffc107"
                    : "#EB0F00",
              }}
            >
              {fieldDataCategory == "FAST"
                ? "passes"
                : fieldDataCategory == "AVERAGE"
                ? "barely passes"
                : "does not pass"}
            </strong>{" "}
            the{" "}
            <a href="https://web.dev/vitals/" target="_blank" rel="noreferrer">
              Core Web Vitals
            </a>{" "}
            assessment.
          </span>
        )}
      </Row>
      <Row className="small-stats-mobile">
        {!hasFieldData ? (
          <p className="text-sm-left ml-3">
            The Chrome User Experience Report{" "}
            <a href="https://developers.google.com/speed/docs/insights/v5/about#faq">
              does not have sufficient real-world speed data
            </a>{" "}
            for this page.
          </p>
        ) : !smallStats ? (
          <CircularProgress
            style={{ margin: "2rem", height: "140px", width: "140px" }}
          />
        ) : (
          smallStats.slice(0, 3).map((stats, idx) => (
            <Col className="col-lg mb-4 mt-3" key={idx} {...stats.attrs}>
              <SmallStats
                id={`small-stats-${idx}`}
                variation="1"
                chartData={stats.datasets}
                chartLabels={stats.chartLabels}
                label={stats.label}
                labelSub={stats.labelSub}
                value={stats.value}
                percentage={stats.percentage}
                increase={stats.increase}
                decrease={stats.decrease}
              />
            </Col>
          ))
        )}
        {hasFID || !hasFieldData ? (
          ""
        ) : (
          <span className="hasFID text-sm-center">
            Insufficient First Input Delay (FID) data for this page, but this
            metric is optional for the Core Web Vitals assessment.
          </span>
        )}
      </Row>
      <Row className="small-stats-mobile">
        {smallStats.length > 3
          ? smallStats.slice(3, smallStats.length).map((stats, idx) => (
              <Col className="col-lg mb-4 mt-0" key={idx} {...stats.attrs}>
                <SmallStats
                  id={`small-stats-${idx}`}
                  variation="1"
                  chartData={stats.datasets}
                  chartLabels={stats.chartLabels}
                  label={stats.label}
                  labelSub={stats.labelSub}
                  value={stats.value}
                  percentage={stats.percentage}
                  increase={stats.increase}
                  decrease={stats.decrease}
                />
              </Col>
            ))
          : ""}
      </Row>

      <Row>
        <h4 className="text-sm-left ml-3 heading-3">Desktop Devices</h4>
      </Row>
      <Row>
        {!hasFieldData ? (
          ""
        ) : (
          <span className="text-sm-left ml-3">
            Over the previous 28-day collection period, field data shows that
            this page{" "}
            <strong
              style={{
                color:
                  fieldDataCategory == "FAST"
                    ? "#018642"
                    : fieldDataCategory == "AVERAGE"
                    ? "#ffc107"
                    : "#EB0F00",
              }}
            >
              {fieldDataCategory == "FAST"
                ? "passes"
                : fieldDataCategory == "AVERAGE"
                ? "barely passes"
                : "does not pass"}
            </strong>{" "}
            the{" "}
            <a href="https://web.dev/vitals/" target="_blank" rel="noreferrer">
              Core Web Vitals
            </a>{" "}
            assessment.
          </span>
        )}
      </Row>
      <Row className="small-stats-mobile">
        {!hasFieldData ? (
          <p className="text-sm-left ml-3">
            The Chrome User Experience Report{" "}
            <a href="https://developers.google.com/speed/docs/insights/v5/about#faq">
              does not have sufficient real-world speed data
            </a>{" "}
            for this page.
          </p>
        ) : !smallStatsDesktop ? (
          <CircularProgress
            style={{ margin: "2rem", height: "140px", width: "140px" }}
          />
        ) : (
          smallStatsDesktop.slice(0, 3).map((stats, idx) => (
            <Col className="col-lg mb-4 mt-3" key={idx} {...stats.attrs}>
              <SmallStats
                id={`small-stats-${idx}`}
                variation="1"
                chartData={stats.datasets}
                chartLabels={stats.chartLabels}
                label={stats.label}
                labelSub={stats.labelSub}
                value={stats.value}
                percentage={stats.percentage}
                increase={stats.increase}
                decrease={stats.decrease}
              />
            </Col>
          ))
        )}
        {hasFID || !hasFieldData ? (
          ""
        ) : (
          <span className="hasFID text-sm-center">
            Insufficient First Input Delay (FID) data for this page, but this
            metric is optional for the Core Web Vitals assessment.
          </span>
        )}
      </Row>
      <Row className="small-stats-mobile">
        {smallStatsDesktop.length > 3
          ? smallStatsDesktop
              .slice(3, smallStatsDesktop.length)
              .map((stats, idx) => (
                <Col className="col-lg mb-4 mt-0" key={idx} {...stats.attrs}>
                  <SmallStats
                    id={`small-stats-${idx}`}
                    variation="1"
                    chartData={stats.datasets}
                    chartLabels={stats.chartLabels}
                    label={stats.label}
                    labelSub={stats.labelSub}
                    value={stats.value}
                    percentage={stats.percentage}
                    increase={stats.increase}
                    decrease={stats.decrease}
                  />
                </Col>
              ))
          : ""}
      </Row>
    </>
  );
};

export default FieldData;
