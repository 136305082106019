import React from "react";
import WarningIcon from "@mui/icons-material/Warning";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const NotConfirmedNavbar = () => {
  return (
    <Tooltip
      title="Please confirm your email address"
      className="border-left"
      style={{ borderRadius: "0" }}
    >
      <IconButton>
        <WarningIcon color="warning" />
      </IconButton>
    </Tooltip>
  );
};

export default NotConfirmedNavbar;
