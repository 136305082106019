import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { API_URL_PSI } from "../constants";
import axios from "axios";

const SlackRedirect = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [error, setError] = useState(false);

  function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].substr(vars[i].indexOf("=") + 1, vars[i].length);
      var querys = vars[i].substr(0, vars[i].indexOf("="));
      if (querys == variable) {
        return pair;
      }
    }
    return false;
  }

  const code = getQueryVariable("code");

  const authenticateSlack = () => {
    axios
      .post(
        `${API_URL_PSI}/oauth-redirect`,
        {
          code: code,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        setAuthenticated(true);
      })
      .catch((error) => {
        console.log(error);
        setAuthenticated(false);
        setError(true);
      });
  };

  useEffect(() => {
    authenticateSlack();
  }, []);

  return (
    <div className="login-container">
      <h4>
        {authenticated && !error
          ? "Authentication is done!"
          : error
          ? "There was an issue authenticating your workspace, please try again later!"
          : "Adding our Slack Bot to your workspace ..."}
      </h4>
      <Link
        to={{
          pathname: "/notifications",
        }}
        className="btn btn-info mt-4"
      >
        Get back to Notifications page
      </Link>
    </div>
  );
};

export default SlackRedirect;
