import React, { useState, useEffect } from "react";
import { Row, Col } from "shards-react";
import SmallStats from "../common/SmallStats";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { API_URL_PSI } from "../../constants";
import { calculateDifference } from "../../helpers/scores";

const FieldDataComparison = ({
  smallStats,
  mainSitesmallStats,
  smallStatsDesktop,
  mainSitesmallStatsDesktop,
  loadingFieldDataGraph,
  mainSiteloadingFieldDataGraph,
  siteUrl,
  mainSiteUrl,
}) => {
  const [mainSitefieldDataCategory, setMainSiteFieldDataCategory] = useState(
    {}
  );
  const [mainSitehasFID, setMainSiteHasFID] = useState(false);
  const [mainSitehasFieldData, setMainSiteHasFieldData] = useState(false);

  const [fieldDataCategory, setFieldDataCategory] = useState({});
  const [hasFID, setHasFID] = useState(false);
  const [hasFieldData, setHasFieldData] = useState(false);
  const [comparedData, setComparedData] = useState([]);
  const [comparedDataDesktop, setComparedDataDesktop] = useState([]);

  const getLatestData = async (siteUrl) => {
    try {
      const response = await axios.get(
        `${API_URL_PSI}/scan/latest`,
        {
          params: {
            url: siteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      let data = response.data;
      setFieldDataCategory(data.mobile.fieldData.overall_category);
      setHasFID(data.mobile.fieldData.fid.score ? true : false);
      setHasFieldData(data.mobile.fieldData.overall_category ? true : false);
    } catch (error) {
      console.log(error);
    }
  };

  const getMainSiteLatestData = async (mainSiteUrl) => {
    try {
      const response = await axios.get(
        `${API_URL_PSI}/scan/latest`,
        {
          params: {
            url: mainSiteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setMainSiteFieldDataCategory(
        response.data.mobile.fieldData.overall_category
      );
      setMainSiteHasFID(
        response.data.mobile.fieldData.fid.score ? true : false
      );
      setMainSiteHasFieldData(
        response.data.mobile.fieldData.overall_category ? true : false
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(async () => {
    await getLatestData(siteUrl);
  }, [siteUrl]);

  useEffect(async () => {
    await getMainSiteLatestData(mainSiteUrl);
  }, [mainSiteUrl]);

  useEffect(() => {
    if (smallStats.length && mainSitesmallStats.length) {
      let dataToCompare = [];
      let comparedData = [];
      smallStats.forEach((smallItem, smallIndex) => {
        mainSitesmallStats.map((mainItem, mainIndex) => {
          if (smallIndex === mainIndex) {
            dataToCompare.push([
              +smallItem.value.split(" ")[0],
              +mainItem.value.split(" ")[0],
            ]);
          }
        });
      });
      dataToCompare.forEach((item) => {
        const difference = calculateDifference(item[0], item[1]);
        comparedData.push(difference);
      });
      setComparedData(comparedData);
    }
    if (smallStatsDesktop.length && mainSitesmallStatsDesktop.length) {
      let dataToCompareDesktop = [];
      let comparedDataDesktop = [];
      smallStatsDesktop.forEach((smallItem, smallIndex) => {
        mainSitesmallStatsDesktop.map((mainItem, mainIndex) => {
          if (smallIndex === mainIndex) {
            dataToCompareDesktop.push([
              +smallItem.value.split(" ")[0],
              +mainItem.value.split(" ")[0],
            ]);
          }
        });
      });
      dataToCompareDesktop.forEach((item) => {
        const differenceDesktop = calculateDifference(item[0], item[1]);
        comparedDataDesktop.push(differenceDesktop);
      });
      setComparedDataDesktop(comparedDataDesktop);
    }
  }, [
    smallStats,
    mainSitesmallStats,
    smallStatsDesktop,
    mainSitesmallStatsDesktop,
  ]);
  return (
    <>
      <Row>
        <h3 className="text-sm-left ml-3 heading-3">Field Data</h3>
      </Row>

      {/* Small Stats Blocks */}
      <Row>
        <h4 className="text-sm-left ml-3 heading-3">Mobile Devices</h4>
      </Row>
      <Row className="small-stats-mobile">
        <Col className="col-lg-5 col-md-5 col-sm-5 mb-4">
          <Row style={{ flexWrap: "nowrap" }}>
            {!hasFieldData && !mainSitehasFieldData ? (
              ""
            ) : (
              <>
                <span className="text-sm-left ml-3">
                  Over the previous 28-day collection period, field data shows
                  that this page{" "}
                  <strong
                    style={{
                      color:
                        fieldDataCategory == "FAST"
                          ? "#018642"
                          : fieldDataCategory == "AVERAGE"
                          ? "#ffc107"
                          : "#EB0F00",
                    }}
                  >
                    {fieldDataCategory == "FAST"
                      ? "passes"
                      : fieldDataCategory == "AVERAGE"
                      ? "barely passes"
                      : "does not pass"}
                  </strong>{" "}
                  the{" "}
                  <a
                    href="https://web.dev/vitals/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Core Web Vitals
                  </a>{" "}
                  assessment.
                </span>
              </>
            )}
          </Row>
          {loadingFieldDataGraph ? (
            <CircularProgress
              style={{ margin: "2rem", height: "140px", width: "140px" }}
            />
          ) : !hasFieldData || !smallStats.length ? (
            <p className="text-sm-left ml-3 mt-5">
              The Chrome User Experience Report{" "}
              <a href="https://developers.google.com/speed/docs/insights/v5/about#faq">
                does not have sufficient real-world speed data
              </a>{" "}
              for this page.
            </p>
          ) : (
            smallStats.map((stats, idx) => {
              return (
                <Col
                  className="col-lg-12 col-md-12 col-sm-12 mb-4 mt-3"
                  key={idx}
                  {...stats.attrs}
                >
                  <SmallStats
                    id={`small-stats-${idx}`}
                    variation="1"
                    chartData={stats.datasets}
                    chartLabels={stats.chartLabels}
                    label={stats.label}
                    labelSub={stats.labelSub}
                    value={stats.value}
                    percentage={stats.percentage}
                    increase={stats.increase}
                    decrease={stats.decrease}
                  />
                </Col>
              );
            })
          )}
          {hasFID || !hasFieldData ? (
            ""
          ) : (
            <span className="hasFID text-sm-center">
              Insufficient First Input Delay (FID) data for this page, but this
              metric is optional for the Core Web Vitals assessment.
            </span>
          )}
        </Col>
        <Col className="col-lg-2 col-md-2 col-sm-2 mb-4">
          {loadingFieldDataGraph || mainSiteloadingFieldDataGraph ? (
            <CircularProgress
              style={{ margin: "2rem", height: "140px", width: "140px" }}
            />
          ) : !hasFieldData ||
            !smallStats.length ||
            !mainSitehasFieldData ||
            !mainSitesmallStats.length ? (
            ""
          ) : (
            comparedData?.map((compare, index) => {
              let { value, differnce } = compare;
              return differnce === 0 ? (
                <Row key={index} className="my-5 pb-3">
                  <Col
                    className="col-lg-12 col-md-12 col-sm-12 my-5"
                    style={{ position: "relative" }}
                  >
                    <span
                      className={`stats-small__percentage stats-small__percentage--decrease`}
                      style={{
                        position: "absolute",
                        right: "40%",
                        transform: "scale(1.25)",
                      }}
                    ></span>
                    <span
                      style={{
                        position: "absolute",
                        right: "50%",
                        top: "-6px",
                      }}
                    >
                      <strong>Same</strong>
                    </span>
                  </Col>
                </Row>
              ) : (
                <Row key={index} className="my-5 pb-3">
                  <Col
                    className="col-lg-12 col-md-12 col-sm-12 my-5"
                    style={{ position: "relative" }}
                  >
                    <span
                      className={`stats-small__percentage stats-small__percentage--${
                        value ? "decrease" : "increase"
                      }`}
                      style={{
                        position: "absolute",
                        right: "40%",
                        transform: "scale(1.25)",
                      }}
                    ></span>
                    <span
                      style={{
                        position: "absolute",
                        right: "50%",
                        top: "-8px",
                      }}
                    >
                      <strong
                        style={{
                          color: value ? "#018642" : "#EB0F00",
                        }}
                      >
                        {value ? "Better" : "Worse"}
                      </strong>
                    </span>
                  </Col>
                </Row>
              );
            })
          )}
        </Col>
        <Col className="col-lg-5 col-md-5 col-sm-5 mb-4">
          <Row style={{ flexWrap: "nowrap" }}>
            {!hasFieldData || !mainSitehasFieldData ? (
              ""
            ) : (
              <>
                <span className="text-sm-left ml-3">
                  Over the previous 28-day collection period, field data shows
                  that this page{" "}
                  <strong
                    style={{
                      color:
                        mainSitefieldDataCategory == "FAST"
                          ? "#018642"
                          : mainSitefieldDataCategory == "AVERAGE"
                          ? "#ffc107"
                          : "#EB0F00",
                    }}
                  >
                    {mainSitefieldDataCategory == "FAST"
                      ? "passes"
                      : mainSitefieldDataCategory == "AVERAGE"
                      ? "barely passes"
                      : "does not pass"}
                  </strong>{" "}
                  the{" "}
                  <a
                    href="https://web.dev/vitals/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Core Web Vitals
                  </a>{" "}
                  assessment.
                </span>
              </>
            )}
          </Row>
          {mainSiteloadingFieldDataGraph ? (
            <CircularProgress
              style={{ margin: "2rem", height: "140px", width: "140px" }}
            />
          ) : !mainSitehasFieldData || !mainSitesmallStats.length ? (
            <p className="text-sm-left ml-3 mt-5">
              The Chrome User Experience Report{" "}
              <a href="https://developers.google.com/speed/docs/insights/v5/about#faq">
                does not have sufficient real-world speed data
              </a>{" "}
              for this page.
            </p>
          ) : (
            mainSitesmallStats.map((stats, idx) => {
              return (
                <Col
                  className="col-lg-12 col-md-12 col-sm-12 mb-4 mt-3"
                  key={idx}
                  {...stats.attrs}
                >
                  <SmallStats
                    id={`small-stats-${idx}`}
                    variation="1"
                    chartData={stats.datasets}
                    chartLabels={stats.chartLabels}
                    label={stats.label}
                    labelSub={stats.labelSub}
                    value={stats.value}
                    percentage={stats.percentage}
                    increase={stats.increase}
                    decrease={stats.decrease}
                  />
                </Col>
              );
            })
          )}
          {mainSitehasFID || !mainSitehasFieldData ? (
            ""
          ) : (
            <span className="hasFID text-sm-center">
              Insufficient First Input Delay (FID) data for this page, but this
              metric is optional for the Core Web Vitals assessment.
            </span>
          )}
        </Col>
      </Row>

      <Row>
        <h4 className="text-sm-left ml-3 heading-3">Desktop Devices</h4>
      </Row>
      <Row className="small-stats-mobile">
        <Col className="col-lg-5 col-md-5 col-sm-5 mb-4">
          <Row style={{ flexWrap: "nowrap" }}>
            {!hasFieldData && !mainSitehasFieldData ? (
              ""
            ) : (
              <>
                <span className="text-sm-left ml-3">
                  Over the previous 28-day collection period, field data shows
                  that this page{" "}
                  <strong
                    style={{
                      color:
                        fieldDataCategory == "FAST"
                          ? "#018642"
                          : fieldDataCategory == "AVERAGE"
                          ? "#ffc107"
                          : "#EB0F00",
                    }}
                  >
                    {fieldDataCategory == "FAST"
                      ? "passes"
                      : fieldDataCategory == "AVERAGE"
                      ? "barely passes"
                      : "does not pass"}
                  </strong>{" "}
                  the{" "}
                  <a
                    href="https://web.dev/vitals/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Core Web Vitals
                  </a>{" "}
                  assessment.
                </span>
              </>
            )}
          </Row>
          {loadingFieldDataGraph ? (
            <CircularProgress
              style={{ margin: "2rem", height: "140px", width: "140px" }}
            />
          ) : !hasFieldData || !smallStatsDesktop.length ? (
            <p className="text-sm-left ml-3 mt-5">
              The Chrome User Experience Report{" "}
              <a href="https://developers.google.com/speed/docs/insights/v5/about#faq">
                does not have sufficient real-world speed data
              </a>{" "}
              for this page.
            </p>
          ) : (
            smallStatsDesktop.map((stats, idx) => (
              <Col
                className="col-lg-12 col-md-12 col-sm-12 mb-4 mt-3"
                key={idx}
                {...stats.attrs}
              >
                <SmallStats
                  id={`small-stats-${idx}`}
                  variation="1"
                  chartData={stats.datasets}
                  chartLabels={stats.chartLabels}
                  label={stats.label}
                  labelSub={stats.labelSub}
                  value={stats.value}
                  percentage={stats.percentage}
                  increase={stats.increase}
                  decrease={stats.decrease}
                />
              </Col>
            ))
          )}
          {hasFID || !hasFieldData ? (
            ""
          ) : (
            <span className="hasFID text-sm-center">
              Insufficient First Input Delay (FID) data for this page, but this
              metric is optional for the Core Web Vitals assessment.
            </span>
          )}
        </Col>
        <Col className="col-lg-2 col-md-2 col-sm-2 mb-4">
          {loadingFieldDataGraph || mainSiteloadingFieldDataGraph ? (
            <CircularProgress
              style={{ margin: "2rem", height: "140px", width: "140px" }}
            />
          ) : !hasFieldData ||
            !smallStatsDesktop.length ||
            !mainSitehasFieldData ||
            !mainSitesmallStatsDesktop.length ? (
            ""
          ) : (
            comparedDataDesktop?.map((compare, index) => {
              let { value, differnce } = compare;
              return differnce === 0 ? (
                <Row className="my-5 pb-3">
                  <Col
                    className="col-lg-12 col-md-12 col-sm-12 my-5"
                    style={{ position: "relative" }}
                  >
                    <span
                      className={`stats-small__percentage stats-small__percentage--decrease`}
                      style={{
                        position: "absolute",
                        right: "40%",
                        transform: "scale(1.25)",
                      }}
                    ></span>
                    <span
                      style={{
                        position: "absolute",
                        right: "50%",
                        top: "-7px",
                      }}
                    >
                      <strong>Same</strong>
                    </span>
                  </Col>
                </Row>
              ) : (
                <Row key={index} className="my-5 pb-3">
                  <Col
                    className="col-lg-12 col-md-12 col-lg-12 my-5"
                    style={{ position: "relative" }}
                  >
                    <span
                      className={`stats-small__percentage stats-small__percentage--${
                        value ? "decrease" : "increase"
                      }`}
                      style={{
                        position: "absolute",
                        right: "40%",
                        transform: "scale(1.25)",
                      }}
                    ></span>
                    <span
                      style={{
                        position: "absolute",
                        right: "50%",
                        top: "-9px",
                      }}
                    >
                      <strong
                        style={{
                          color: value ? "#018642" : "#EB0F00",
                        }}
                      >
                        {value ? "Better" : "Worse"}
                      </strong>
                    </span>
                  </Col>
                </Row>
              );
            })
          )}
        </Col>
        <Col className="col-lg-5 col-md-5 col-sm-5 mb-4">
          <Row style={{ flexWrap: "nowrap" }}>
            {!hasFieldData || !mainSitehasFieldData ? (
              ""
            ) : (
              <>
                <span className="text-sm-left ml-3">
                  Over the previous 28-day collection period, field data shows
                  that this page{" "}
                  <strong
                    style={{
                      color:
                        mainSitefieldDataCategory == "FAST"
                          ? "#018642"
                          : mainSitefieldDataCategory == "AVERAGE"
                          ? "#ffc107"
                          : "#EB0F00",
                    }}
                  >
                    {mainSitefieldDataCategory == "FAST"
                      ? "passes"
                      : mainSitefieldDataCategory == "AVERAGE"
                      ? "barely passes"
                      : "does not pass"}
                  </strong>{" "}
                  the{" "}
                  <a
                    href="https://web.dev/vitals/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Core Web Vitals
                  </a>{" "}
                  assessment.
                </span>
              </>
            )}
          </Row>
          {mainSiteloadingFieldDataGraph ? (
            <CircularProgress
              style={{ margin: "2rem", height: "140px", width: "140px" }}
            />
          ) : !mainSitehasFieldData || !mainSitesmallStatsDesktop.length ? (
            <p className="text-sm-left ml-3 mt-5">
              The Chrome User Experience Report{" "}
              <a href="https://developers.google.com/speed/docs/insights/v5/about#faq">
                does not have sufficient real-world speed data
              </a>{" "}
              for this page.
            </p>
          ) : (
            mainSitesmallStatsDesktop.map((stats, idx) => (
              <Col
                className="col-lg-12 col-md-12 col-sm-12 mb-4 mt-3"
                key={idx}
                {...stats.attrs}
              >
                <SmallStats
                  id={`small-stats-${idx}`}
                  variation="1"
                  chartData={stats.datasets}
                  chartLabels={stats.chartLabels}
                  label={stats.label}
                  labelSub={stats.labelSub}
                  value={stats.value}
                  percentage={stats.percentage}
                  increase={stats.increase}
                  decrease={stats.decrease}
                />
              </Col>
            ))
          )}
          {mainSitehasFID || !mainSitehasFieldData ? (
            ""
          ) : (
            <span className="hasFID text-sm-center">
              Insufficient First Input Delay (FID) data for this page, but this
              metric is optional for the Core Web Vitals assessment.
            </span>
          )}
        </Col>
      </Row>
    </>
  );
};

export default FieldDataComparison;
