import React from "react";

const ScreenshotThumbnails = ({ screenshotThumbnails }) => {
  const screenshots =
    screenshotThumbnails[0] && screenshotThumbnails[0].details
      ? screenshotThumbnails[0].details.items
      : "";
  const alt =
    screenshotThumbnails[0] && screenshotThumbnails[0].title
      ? screenshotThumbnails[0].title
      : "";

  const styles = {
    display: "flex",
    alignItems: "center",
    margin: "2rem 0",
  };

  return (
    <div style={styles} className="d-none d-md-flex d-lg-flex">
      {!screenshots
        ? ""
        : screenshots.map((item, index) => (
            <img
              src={item.data}
              alt={`${alt} - ${index}`}
              key={item.timestamp}
              style={{ marginRight: "8px", width: "200px", height: "250px" }}
            />
          ))}
    </div>
  );
};

export default ScreenshotThumbnails;
