import React from "react";

const FinalScreenshot = ({ finalScreenshot, device }) => {
  const screenshot =
    finalScreenshot[0] && finalScreenshot[0].details
      ? finalScreenshot[0].details.data
      : "";
  const alt =
    finalScreenshot[0] && finalScreenshot[0].details
      ? finalScreenshot[0].title
      : "";
  return (
    <img src={screenshot} alt={alt} width={device === "mobile" ? 150 : 250} />
  );
};

export default FinalScreenshot;
