import React, { useState, useEffect } from "react";
import ReactCountryFlag from "react-country-flag";
import countries from "./countries";

const Table = ({ pingData, reportToView }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const reportDataToView = pingData.filter(
      (item) => item.date === reportToView
    );
    setData(reportDataToView[0].data);
  }, [pingData]);
  return (
    <table className="table mb-0">
      {data?.length ? (
        data?.map((item, index) => {
          let {
            country,
            ip_address,
            rtt: { min, avg, max },
            result: { ok, malformed, timeout },
          } = item;

          let countryCode = "";
          for (const [key, value] of Object.entries(countries)) {
            if (key === country.split(",")[0]) {
              countryCode = value.toLowerCase();
            }
          }

          return (
            <tbody key={index}>
              <tr>
                <th scope="col" style={{ borderWidth: "3px" }}>
                  Region
                </th>
                <td style={{ borderWidth: "3px" }}>
                  <ReactCountryFlag countryCode={countryCode} /> {country}
                </td>
              </tr>
              <tr>
                <th scope="col">ok / timeout / malformed</th>
                <td>
                  {ok && timeout && malformed === "No Data" ? (
                    <span style={{ marginleft: "20px", marginRight: "20px" }}>
                      No Data
                    </span>
                  ) : (
                    <>
                      <span
                        style={{
                          marginleft: "20px",
                          marginRight: "20px",
                          color: ok !== "4/4" ? "red" : "",
                        }}
                      >
                        {ok}
                      </span>{" "}
                      <span
                        style={{
                          marginleft: "20px",
                          marginRight: "20px",
                          color: timeout !== "0/4" ? "red" : "",
                        }}
                      >
                        {timeout}
                      </span>{" "}
                      <span
                        style={{
                          marginleft: "20px",
                          marginRight: "20px",
                          color: malformed !== "0/4" ? "red" : "",
                        }}
                      >
                        {malformed}
                      </span>
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <th scope="col">Result</th>
                <td style={ok !== "4/4" ? { color: "red" } : {}}>{ok}</td>
              </tr>
              <tr>
                <th scope="col">rtt min / avg / max</th>
                <td>
                  {min} / {avg} / {max}ms
                </td>
              </tr>
              <tr>
                <th scope="col">IP address</th>
                <td>{ip_address}</td>
              </tr>
            </tbody>
          );
        })
      ) : (
        <tbody>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      )}
    </table>
  );
};

export default Table;
