import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, FormSelect, Button } from "shards-react";
import axios from "axios";
import { API_URL_PSI } from "../constants";
import { getScoreColor } from "../helpers/scores";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import OverallScoreComparison from "../components/competitor-analysis/OverallScoreComparison";
import FieldDataComparison from "../components/competitor-analysis/FieldDataComparison";
import LabDataComparison from "../components/competitor-analysis/LabDataComparison";
import { useSelector, useDispatch } from "react-redux";
import UptimeOverviewComparison from "../components/competitor-analysis/UptimeOverviewComparison";
import W3ValidatorOverviewComparison from "../components/competitor-analysis/W3ValidatorOverviewComparison";
import PageSpeedScore from "../components/competitor-analysis/PageSpeedScore";
import TechStackComparison from "../components/competitor-analysis/TechStackComparison";
import { getData, getCompetitorSites } from "../state/actions/sites.actions";
import { useSnackbar } from "notistack";
import {
  getSitesStatusData,
  getCompetitorSitesStatus,
  getMetryVScoreData,
  getOriginSummaryData,
  getMonthlyOverallScoreData,
  getRankingData,
  getMonthlyLabData,
  getUptimeStatusData,
  getW3ValidatorData,
} from "../state/actions/psi.actions";
import { getPingData } from "../state/actions/ping.actions";

const SitesComparison = () => {
  const location = useLocation();
  const siteName = location.state.siteName;
  const siteUrl = location.state.siteUrl;

  // competitor site data
  const [smallStats, setSmallStats] = useState(false);
  const [smallStatsDesktop, setSmallStatsDesktop] = useState(false);
  const [loadingFieldDataGraph, setLoadingFieldDataGraph] = useState(true);

  //main site data
  const [mainSitesmallStats, setMainSiteSmallStats] = useState(false);
  const [mainSitesmallStatsDesktop, setMainSiteSmallStatsDesktop] = useState(
    false
  );
  const [
    mainSiteloadingFieldDataGraph,
    setMainSiteLoadingFieldDataGraph,
  ] = useState(true);

  const [mainSiteUrl, setMainSiteUrl] = useState(
    localStorage.getItem("comparisonUrl").replaceAll('"', "")
  );

  const dispatch = useDispatch();
  const sitesStatus = useSelector((state) => state.sitesStatus.sitesStatus);
  const sitesStatusLoading = useSelector((state) => state.sitesStatus?.loading);
  const user = useSelector((state) =>
    state.authentication.user
      ? state.authentication.user
      : state.registration.user
  );

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [setManualScanCounter] = useState(0);
  const [setLoadingOverallScore] = useState(true);
  const [setLoadingFieldData] = useState(true);
  const [setLoadingLabData] = useState(true);
  const [setLoadingSuggestions] = useState(true);
  const [setLoadingTechStack] = useState(true);

  const [setOverallScore] = useState({});
  const [setOpportunitiesMobile] = useState({});
  const [setDiagnosticsMobile] = useState({});
  const [setLcpElementMobile] = useState({});
  const [setClsElementsMobile] = useState({});
  const [setOpportunitiesDesktop] = useState({});
  const [setDiagnosticsDesktop] = useState({});
  const [setLcpElementDesktop] = useState({});
  const [setClsElementsDesktop] = useState({});
  const [setTechStack] = useState({});
  const [setFieldDataCategory] = useState({});
  const [setFieldDataCategoryDesktop] = useState({});
  const [setHasFID] = useState(false);
  const [setHasFieldData] = useState(false);
  const [setLabDataMobile] = useState([]);
  const [setLabDataDesktop] = useState([]);
  const [setFinalScreenshot] = useState("");
  const [setFinalScreenshotDesktop] = useState("");
  const [setScreenshotThumbnails] = useState("");
  const [setScreenshotThumbnailsDesktop] = useState("");

  const backdrop = (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  function relDiff(oldNumber, newNumber) {
    return Math.abs(((oldNumber - newNumber) / oldNumber) * 100);
  }

  async function manualPsiScan() {
    const scanningSnackbar = enqueueSnackbar("Scanning ...", {
      variant: "info",
      persist: true,
    });
    const email = localStorage.getItem("user");
    const request_body = {
      email: email,
      url: siteUrl,
    };
    await axios
      .post(`${API_URL_PSI}/scan/`, request_body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(() => {
        closeSnackbar(scanningSnackbar);
        enqueueSnackbar("Scan is done!", { variant: "success" });
        setManualScanCounter((data) => data + 1);
        dispatch(getMetryVScoreData(siteUrl));
        dispatch(getOriginSummaryData(siteUrl));
        dispatch(getMonthlyOverallScoreData(siteUrl));
        dispatch(getRankingData(siteUrl));
        dispatch(getMonthlyLabData(siteUrl));
        dispatch(getUptimeStatusData(siteUrl));
        dispatch(getW3ValidatorData(siteUrl));
        dispatch(getPingData(siteUrl));
        getLatestData();
        getFieldData();
        getSuggestionsData();
        getTechStack();
      })
      .catch((error) => {
        console.log(error.response);
        closeSnackbar(scanningSnackbar);
      });
  }

  async function getLatestData() {
    await axios
      .get(
        `${API_URL_PSI}/scan/latest`,
        {
          params: {
            url: siteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        let data = response.data;
        setOverallScore({
          Mobile: data.mobile.overallScore,
          Desktop: data.desktop.overallScore,
        });
        setFieldDataCategory(data.mobile.fieldData.overall_category);
        setFieldDataCategoryDesktop(data.desktop.fieldData.overall_category);
        setHasFID(data.mobile.fieldData.fid.score ? true : false);
        setHasFieldData(data.mobile.fieldData.overall_category ? true : false);
        setLabDataMobile([
          {
            FCP: data.mobile.labData.fcp,
            LCP: data.mobile.labData.lcp,
            SI: data.mobile.labData.si,
            CLS: data.mobile.labData.cls,
            TTI: data.mobile.labData.tti,
            TBT: data.mobile.labData.tbt,
          },
        ]);
        setLabDataDesktop([
          {
            FCP: data.desktop.labData.fcp,
            LCP: data.desktop.labData.lcp,
            SI: data.desktop.labData.si,
            CLS: data.desktop.labData.cls,
            TTI: data.desktop.labData.tti,
            TBT: data.desktop.labData.tbt,
          },
        ]);
        setLoadingOverallScore(false);
        setLoadingFieldData(false);
        setLoadingLabData(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  async function getSuggestionsData() {
    await axios
      .post(
        `${API_URL_PSI}/scan/suggestions`,
        {
          data: {
            url: siteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        let data = response.data;
        setOpportunitiesMobile(data.mobile?.opportunities?.value);
        setDiagnosticsMobile(data.mobile?.diagnostics?.value);
        setLcpElementMobile(data.mobile?.lcpElement?.value);
        setClsElementsMobile(data.mobile?.clsElements?.value);
        setFinalScreenshot(data.mobile?.finalScreenshot?.value);
        setScreenshotThumbnails(data.mobile?.screenshotThumbnails?.value);

        setOpportunitiesDesktop(data.desktop?.opportunities?.value);
        setDiagnosticsDesktop(data.desktop?.diagnostics?.value);
        setLcpElementDesktop(data.desktop?.lcpElement?.value);
        setClsElementsDesktop(data.desktop?.clsElements?.value);
        setFinalScreenshotDesktop(data.desktop?.finalScreenshot?.value);
        setScreenshotThumbnailsDesktop(
          data.desktop?.screenshotThumbnails?.value
        );
        setLoadingSuggestions(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  async function getTechStack() {
    await axios
      .post(
        `${API_URL_PSI}/scan/techstack`,
        {
          data: {
            url: siteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        let data = response.data;
        setTechStack(data);
        setLoadingTechStack(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  const setLatestData = (psiData) => {
    let lcpStats = [];
    let fidStats = [];
    let clsStats = [];

    let lcpStatsDesktop = [];
    let fidStatsDesktop = [];
    let clsStatsDesktop = [];

    psiData.forEach((element) => {
      lcpStats.push(
        parseFloat(
          typeof element.mobile.fieldData !== "undefined"
            ? element.mobile.fieldData.lcp.score
            : null
        )
      );
      fidStats.push(
        parseFloat(
          typeof element.mobile.fieldData !== "undefined"
            ? element.mobile.fieldData.fid.score
            : null
        )
      );
      clsStats.push(
        parseFloat(
          typeof element.mobile.fieldData !== "undefined"
            ? element.mobile.fieldData.cls.score
            : null
        )
      );

      lcpStatsDesktop.push(
        parseFloat(
          typeof element.desktop.fieldData !== "undefined"
            ? element.desktop.fieldData.lcp.score
            : null
        )
      );
      fidStatsDesktop.push(
        parseFloat(
          typeof element.desktop.fieldData !== "undefined"
            ? element.desktop.fieldData.fid.score
            : null
        )
      );
      clsStatsDesktop.push(
        parseFloat(
          typeof element.desktop.fieldData !== "undefined"
            ? element.desktop.fieldData.cls.score
            : null
        )
      );
    });

    setSmallStats([
      {
        label: "LCP",
        labelSub: "loading performance",
        value:
          psiData[psiData.length - 1].mobile.fieldData.lcp.score != null
            ? psiData[psiData.length - 1].mobile.fieldData.lcp.score + " s"
            : "-",
        percentage: relDiff(
          lcpStats[lcpStats.length - 2],
          lcpStats[lcpStats.length - 1]
        )
          ? relDiff(
              lcpStats[lcpStats.length - 2],
              lcpStats[lcpStats.length - 1]
            ).toFixed(2) + "%"
          : "0%",
        increase:
          lcpStats[lcpStats.length - 1] > lcpStats[lcpStats.length - 2]
            ? true
            : false,
        decrease:
          lcpStats[lcpStats.length - 1] < lcpStats[lcpStats.length - 2]
            ? true
            : false,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor:
              psiData[psiData.length - 1].desktop.fieldData.lcp.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].mobile.fieldData.lcp.category
                  )["background"]
                : "",
            borderColor:
              psiData[psiData.length - 1].desktop.fieldData.lcp.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].mobile.fieldData.lcp.category
                  )["border"]
                : "",
            data: [...lcpStats],
          },
        ],
      },
      {
        label: "FID",
        labelSub: "interactivity",
        value:
          psiData[psiData.length - 1].mobile.fieldData.fid.score != null
            ? psiData[psiData.length - 1].mobile.fieldData.fid.score + " ms"
            : "-",
        percentage: relDiff(
          fidStats[fidStats.length - 2],
          fidStats[fidStats.length - 1]
        )
          ? relDiff(
              fidStats[fidStats.length - 2],
              fidStats[fidStats.length - 1]
            ).toFixed(2) + "%"
          : "0%",
        increase:
          fidStats[fidStats.length - 1] > fidStats[fidStats.length - 2]
            ? true
            : false,
        decrease:
          fidStats[fidStats.length - 1] < fidStats[fidStats.length - 2]
            ? true
            : false,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor:
              psiData[psiData.length - 1].desktop.fieldData.fid.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].mobile.fieldData.fid.category
                  )["background"]
                : "",
            borderColor:
              psiData[psiData.length - 1].desktop.fieldData.fid.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].mobile.fieldData.fid.category
                  )["border"]
                : "",
            data: [...fidStats],
          },
        ],
      },
      {
        label: "CLS",
        labelSub: "visual stability",
        value:
          psiData[psiData.length - 1].mobile.fieldData.cls.score != null
            ? psiData[psiData.length - 1].mobile.fieldData.cls.score
            : "-",
        percentage: relDiff(
          clsStats[clsStats.length - 2],
          clsStats[clsStats.length - 1]
        )
          ? relDiff(
              clsStats[clsStats.length - 2],
              clsStats[clsStats.length - 1]
            ).toFixed(2) + "%"
          : "0%",
        increase:
          clsStats[clsStats.length - 1] > clsStats[clsStats.length - 2]
            ? true
            : false,
        decrease:
          clsStats[fidStats.length - 1] < clsStats[clsStats.length - 2]
            ? true
            : false,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "4", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor:
              psiData[psiData.length - 1].desktop.fieldData.cls.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].mobile.fieldData.cls.category
                  )["background"]
                : "",
            borderColor:
              psiData[psiData.length - 1].desktop.fieldData.cls.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].mobile.fieldData.cls.category
                  )["border"]
                : "",
            data: [...clsStats],
          },
        ],
      },
    ]);

    setSmallStatsDesktop([
      {
        label: "LCP",
        labelSub: "loading performance",
        value:
          psiData[psiData.length - 1].desktop.fieldData.lcp.score != null
            ? psiData[psiData.length - 1].desktop.fieldData.lcp.score + " s"
            : "-",
        percentage: relDiff(
          lcpStats[lcpStats.length - 2],
          lcpStats[lcpStats.length - 1]
        )
          ? relDiff(
              lcpStats[lcpStats.length - 2],
              lcpStats[lcpStats.length - 1]
            ).toFixed(2) + "%"
          : "0%",
        increase:
          lcpStats[lcpStats.length - 1] > lcpStats[lcpStats.length - 2]
            ? true
            : false,
        decrease:
          lcpStats[lcpStats.length - 1] < lcpStats[lcpStats.length - 2]
            ? true
            : false,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor:
              psiData[psiData.length - 1].desktop.fieldData.lcp.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].desktop.fieldData.lcp.category
                  )["background"]
                : "",
            borderColor:
              psiData[psiData.length - 1].desktop.fieldData.lcp.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].desktop.fieldData.lcp.category
                  )["border"]
                : "",
            data: [...lcpStats],
          },
        ],
      },
      {
        label: "FID",
        labelSub: "interactivity",
        value:
          psiData[psiData.length - 1].desktop.fieldData.fid.score != null
            ? psiData[psiData.length - 1].desktop.fieldData.fid.score + " ms"
            : "-",
        percentage: relDiff(
          fidStats[fidStats.length - 2],
          fidStats[fidStats.length - 1]
        )
          ? relDiff(
              fidStats[fidStats.length - 2],
              fidStats[fidStats.length - 1]
            ).toFixed(2) + "%"
          : "0%",
        increase:
          fidStats[fidStats.length - 1] > fidStats[fidStats.length - 2]
            ? true
            : false,
        decrease:
          fidStats[fidStats.length - 1] < fidStats[fidStats.length - 2]
            ? true
            : false,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor:
              psiData[psiData.length - 1].desktop.fieldData.fid.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].desktop.fieldData.fid.category
                  )["background"]
                : "",
            borderColor:
              psiData[psiData.length - 1].desktop.fieldData.fid.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].desktop.fieldData.fid.category
                  )["border"]
                : "",
            data: [...fidStats],
          },
        ],
      },
      {
        label: "CLS",
        labelSub: "visual stability",
        value:
          psiData[psiData.length - 1].desktop.fieldData.cls.score != null
            ? psiData[psiData.length - 1].desktop.fieldData.cls.score
            : "-",
        percentage: relDiff(
          clsStats[clsStats.length - 2],
          clsStats[clsStats.length - 1]
        )
          ? relDiff(
              clsStats[clsStats.length - 2],
              clsStats[clsStats.length - 1]
            ).toFixed(2) + "%"
          : "0%",
        increase:
          clsStats[clsStats.length - 1] > clsStats[clsStats.length - 2]
            ? true
            : false,
        decrease:
          clsStats[clsStats.length - 1] < clsStats[clsStats.length - 2]
            ? true
            : false,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "4", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor:
              psiData[psiData.length - 1].desktop.fieldData.lcp.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].desktop.fieldData.lcp.category
                  )["background"]
                : "",
            borderColor:
              psiData[psiData.length - 1].desktop.fieldData.lcp.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].desktop.fieldData.lcp.category
                  )["border"]
                : "",
            data: [...clsStats],
          },
        ],
      },
    ]);

    let smallStatsDesktopToCalculate = [];
    smallStatsDesktopToCalculate?.push(
      psiData[psiData.length - 1].desktop.fieldData.lcp.score
        ? Number(psiData[psiData.length - 1].desktop.fieldData.lcp.score)
        : null,
      psiData[psiData.length - 1].desktop.fieldData.fid.score
        ? Number(psiData[psiData.length - 1].desktop.fieldData.fid.score)
        : null,
      psiData[psiData.length - 1].desktop.fieldData.cls.score
        ? Number(psiData[psiData.length - 1].desktop.fieldData.cls.score)
        : null
    );
    let smallStatsMobileToCalculate = [];
    smallStatsMobileToCalculate?.push(
      psiData[psiData.length - 1].mobile.fieldData.lcp.score
        ? Number(psiData[psiData.length - 1].mobile.fieldData.lcp.score)
        : null,
      psiData[psiData.length - 1].mobile.fieldData.fid.score
        ? Number(psiData[psiData.length - 1].mobile.fieldData.fid.score)
        : null,
      psiData[psiData.length - 1].mobile.fieldData.cls.score
        ? Number(psiData[psiData.length - 1].mobile.fieldData.cls.score)
        : null
    );
  };

  const setMainSiteLatestData = (psiData) => {
    let lcpStats = [];
    let fidStats = [];
    let clsStats = [];

    let lcpStatsDesktop = [];
    let fidStatsDesktop = [];
    let clsStatsDesktop = [];

    psiData?.forEach((element) => {
      lcpStats.push(
        parseFloat(
          typeof element.mobile.fieldData !== "undefined"
            ? element.mobile.fieldData.lcp.score
            : null
        )
      );
      fidStats.push(
        parseFloat(
          typeof element.mobile.fieldData !== "undefined"
            ? element.mobile.fieldData.fid.score
            : null
        )
      );
      clsStats.push(
        parseFloat(
          typeof element.mobile.fieldData !== "undefined"
            ? element.mobile.fieldData.cls.score
            : null
        )
      );

      lcpStatsDesktop.push(
        parseFloat(
          typeof element.desktop.fieldData !== "undefined"
            ? element.desktop.fieldData.lcp.score
            : null
        )
      );
      fidStatsDesktop.push(
        parseFloat(
          typeof element.desktop.fieldData !== "undefined"
            ? element.desktop.fieldData.fid.score
            : null
        )
      );
      clsStatsDesktop.push(
        parseFloat(
          typeof element.desktop.fieldData !== "undefined"
            ? element.desktop.fieldData.cls.score
            : null
        )
      );
    });

    setMainSiteSmallStats([
      {
        label: "LCP",
        labelSub: "loading performance",
        value:
          psiData[psiData.length - 1].mobile.fieldData.lcp.score != null
            ? psiData[psiData.length - 1].mobile.fieldData.lcp.score + " s"
            : "-",
        percentage: relDiff(
          lcpStats[lcpStats.length - 2],
          lcpStats[lcpStats.length - 1]
        )
          ? relDiff(
              lcpStats[lcpStats.length - 2],
              lcpStats[lcpStats.length - 1]
            ).toFixed(2) + "%"
          : "0%",
        increase:
          lcpStats[lcpStats.length - 1] > lcpStats[lcpStats.length - 2]
            ? true
            : false,
        decrease:
          lcpStats[lcpStats.length - 1] < lcpStats[lcpStats.length - 2]
            ? true
            : false,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor:
              psiData[psiData.length - 1].desktop.fieldData.lcp.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].mobile.fieldData.lcp.category
                  )["background"]
                : "",
            borderColor:
              psiData[psiData.length - 1].desktop.fieldData.lcp.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].mobile.fieldData.lcp.category
                  )["border"]
                : "",
            data: [...lcpStats],
          },
        ],
      },
      {
        label: "FID",
        labelSub: "interactivity",
        value:
          psiData[psiData.length - 1].mobile.fieldData.fid.score != null
            ? psiData[psiData.length - 1].mobile.fieldData.fid.score + " ms"
            : "-",
        percentage: relDiff(
          fidStats[fidStats.length - 2],
          fidStats[fidStats.length - 1]
        )
          ? relDiff(
              fidStats[fidStats.length - 2],
              fidStats[fidStats.length - 1]
            ).toFixed(2) + "%"
          : "0%",
        increase:
          fidStats[fidStats.length - 1] > fidStats[fidStats.length - 2]
            ? true
            : false,
        decrease:
          fidStats[fidStats.length - 1] < fidStats[fidStats.length - 2]
            ? true
            : false,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor:
              psiData[psiData.length - 1].desktop.fieldData.fid.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].mobile.fieldData.fid.category
                  )["background"]
                : "",
            borderColor:
              psiData[psiData.length - 1].desktop.fieldData.fid.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].mobile.fieldData.fid.category
                  )["border"]
                : "",
            data: [...fidStats],
          },
        ],
      },
      {
        label: "CLS",
        labelSub: "visual stability",
        value:
          psiData[psiData.length - 1].mobile.fieldData.cls.score != null
            ? psiData[psiData.length - 1].mobile.fieldData.cls.score
            : "-",
        percentage: relDiff(
          clsStats[clsStats.length - 2],
          clsStats[clsStats.length - 1]
        )
          ? relDiff(
              clsStats[clsStats.length - 2],
              clsStats[clsStats.length - 1]
            ).toFixed(2) + "%"
          : "0%",
        increase:
          clsStats[clsStats.length - 1] > clsStats[clsStats.length - 2]
            ? true
            : false,
        decrease:
          clsStats[fidStats.length - 1] < clsStats[clsStats.length - 2]
            ? true
            : false,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "4", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor:
              psiData[psiData.length - 1].desktop.fieldData.cls.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].mobile.fieldData.cls.category
                  )["background"]
                : "",
            borderColor:
              psiData[psiData.length - 1].desktop.fieldData.cls.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].mobile.fieldData.cls.category
                  )["border"]
                : "",
            data: [...clsStats],
          },
        ],
      },
    ]);

    setMainSiteSmallStatsDesktop([
      {
        label: "LCP",
        labelSub: "loading performance",
        value:
          psiData[psiData.length - 1].desktop.fieldData.lcp.score != null
            ? psiData[psiData.length - 1].desktop.fieldData.lcp.score + " s"
            : "-",
        percentage: relDiff(
          lcpStats[lcpStats.length - 2],
          lcpStats[lcpStats.length - 1]
        )
          ? relDiff(
              lcpStats[lcpStats.length - 2],
              lcpStats[lcpStats.length - 1]
            ).toFixed(2) + "%"
          : "0%",
        increase:
          lcpStats[lcpStats.length - 1] > lcpStats[lcpStats.length - 2]
            ? true
            : false,
        decrease:
          lcpStats[lcpStats.length - 1] < lcpStats[lcpStats.length - 2]
            ? true
            : false,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor:
              psiData[psiData.length - 1].desktop.fieldData.lcp.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].desktop.fieldData.lcp.category
                  )["background"]
                : "",
            borderColor:
              psiData[psiData.length - 1].desktop.fieldData.lcp.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].desktop.fieldData.lcp.category
                  )["border"]
                : "",
            data: [...lcpStats],
          },
        ],
      },
      {
        label: "FID",
        labelSub: "interactivity",
        value:
          psiData[psiData.length - 1].desktop.fieldData.fid.score != null
            ? psiData[psiData.length - 1].desktop.fieldData.fid.score + " ms"
            : "-",
        percentage: relDiff(
          fidStats[fidStats.length - 2],
          fidStats[fidStats.length - 1]
        )
          ? relDiff(
              fidStats[fidStats.length - 2],
              fidStats[fidStats.length - 1]
            ).toFixed(2) + "%"
          : "0%",
        increase:
          fidStats[fidStats.length - 1] > fidStats[fidStats.length - 2]
            ? true
            : false,
        decrease:
          fidStats[fidStats.length - 1] < fidStats[fidStats.length - 2]
            ? true
            : false,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor:
              psiData[psiData.length - 1].desktop.fieldData.fid.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].desktop.fieldData.fid.category
                  )["background"]
                : "",
            borderColor:
              psiData[psiData.length - 1].desktop.fieldData.fid.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].desktop.fieldData.fid.category
                  )["border"]
                : "",
            data: [...fidStats],
          },
        ],
      },
      {
        label: "CLS",
        labelSub: "visual stability",
        value:
          psiData[psiData.length - 1].desktop.fieldData.cls.score != null
            ? psiData[psiData.length - 1].desktop.fieldData.cls.score
            : "-",
        percentage: relDiff(
          clsStats[clsStats.length - 2],
          clsStats[clsStats.length - 1]
        )
          ? relDiff(
              clsStats[clsStats.length - 2],
              clsStats[clsStats.length - 1]
            ).toFixed(2) + "%"
          : "0%",
        increase:
          clsStats[clsStats.length - 1] > clsStats[clsStats.length - 2]
            ? true
            : false,
        decrease:
          clsStats[clsStats.length - 1] < clsStats[clsStats.length - 2]
            ? true
            : false,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "4", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor:
              psiData[psiData.length - 1].desktop.fieldData.lcp.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].desktop.fieldData.lcp.category
                  )["background"]
                : "",
            borderColor:
              psiData[psiData.length - 1].desktop.fieldData.lcp.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].desktop.fieldData.lcp.category
                  )["border"]
                : "",
            data: [...clsStats],
          },
        ],
      },
    ]);

    let mainSiteSmallStatsDesktopToCalculate = [];
    mainSiteSmallStatsDesktopToCalculate?.push(
      psiData[psiData.length - 1].desktop.fieldData.lcp.score
        ? Number(psiData[psiData.length - 1].desktop.fieldData.lcp.score)
        : null,
      psiData[psiData.length - 1].desktop.fieldData.fid.score
        ? Number(psiData[psiData.length - 1].desktop.fieldData.fid.score)
        : null,
      psiData[psiData.length - 1].desktop.fieldData.cls.score
        ? Number(psiData[psiData.length - 1].desktop.fieldData.cls.score)
        : null
    );

    let mainSiteSmallStatsMobileToCalculate = [];
    mainSiteSmallStatsMobileToCalculate?.push(
      psiData[psiData.length - 1].mobile.fieldData.lcp.score
        ? Number(psiData[psiData.length - 1].mobile.fieldData.lcp.score)
        : null,
      psiData[psiData.length - 1].mobile.fieldData.fid.score
        ? Number(psiData[psiData.length - 1].mobile.fieldData.fid.score)
        : null,
      psiData[psiData.length - 1].mobile.fieldData.cls.score
        ? Number(psiData[psiData.length - 1].mobile.fieldData.cls.score)
        : null
    );
  };

  const getFieldData = async (siteUrl) => {
    await axios
      .get(
        `${API_URL_PSI}/scan/fieldData`,
        {
          params: {
            url: siteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        let data = response.data;
        setLatestData(data);
        setLoadingFieldDataGraph(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getMainSiteFieldData = async (mainSiteUrl) => {
    await axios
      .get(
        `${API_URL_PSI}/scan/fieldData`,
        {
          params: {
            url: mainSiteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        let data = response.data;
        setMainSiteLatestData(data);
        setMainSiteLoadingFieldDataGraph(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(async () => {
    await getFieldData(siteUrl);
  }, [siteUrl]);

  useEffect(async () => {
    await getMainSiteFieldData(mainSiteUrl);
  }, [mainSiteUrl]);

  useEffect(() => {
    if (!sitesStatus) {
      dispatch(getCompetitorSites(user.rootUser ? user.rootUser : user.email))
        .then((response) => {
          if (response.length) {
            let urls = [];
            response.map((item) => {
              let { url } = item;
              return urls.push(url);
            });
            dispatch(getCompetitorSitesStatus(urls));
          }
        })
        .catch((error) => {
          console.log(error);
        });
      dispatch(getData(user.rootUser ? user.rootUser : user.email))
        .then((response) => {
          if (response.length) {
            let urls = [];
            response.map((item) => {
              let { url } = item;
              return urls.push(url);
            });
            dispatch(getSitesStatusData(urls));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    const comparisionsiteoptionsarraylistuniqueclass = document.querySelectorAll(
      ".comparisionsiteoptionsarraylistuniqueclass"
    );
    comparisionsiteoptionsarraylistuniqueclass.forEach((item) => {
      if (localStorage.getItem("comparisonUrl")) {
        if (`"${item.value}"` === localStorage.getItem("comparisonUrl")) {
          item.selected = "selected";
        }
      }
    });
  }, []);

  return (
    <>
      {!sitesStatus || sitesStatusLoading ? (
        backdrop
      ) : (
        <Container fluid className="main-content-container px-4 my-2">
          <Row
            style={{
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
            }}
            className="py-2"
          >
            <h3 className="ml-3">{siteName}</h3>
            <FormSelect
              className="overviewSelect"
              id="feInputState"
              onChange={(e) => {
                setMainSiteUrl(e.target.value);
                localStorage.setItem(
                  "comparisonUrl",
                  JSON.stringify(e.target.value)
                );
              }}
              style={{ width: "40%" }}
            >
              {!sitesStatus.length
                ? ""
                : sitesStatus.map((site, idx) => (
                    <option
                      value={site.url}
                      key={idx}
                      className={"comparisionsiteoptionsarraylistuniqueclass"}
                    >
                      {site.siteName ? site.siteName : site.url}
                    </option>
                  ))}
            </FormSelect>
          </Row>
          <div>
            <Button
              size="md"
              theme="primary"
              style={{ position: "relative", left: "700px" }}
              onClick={manualPsiScan}
            >
              Run Scan Again
            </Button>
          </div>
          <hr />
          <main>
            <OverallScoreComparison
              siteUrl={siteUrl}
              siteName={siteName}
              mainSiteUrl={mainSiteUrl}
            />
            <hr />
            <PageSpeedScore
              siteUrl={siteUrl}
              mainSiteUrl={mainSiteUrl}
            ></PageSpeedScore>
            <hr />
            <FieldDataComparison
              smallStats={smallStats}
              smallStatsDesktop={smallStatsDesktop}
              mainSitesmallStats={mainSitesmallStats}
              mainSitesmallStatsDesktop={mainSitesmallStatsDesktop}
              loadingFieldDataGraph={loadingFieldDataGraph}
              mainSiteloadingFieldDataGraph={mainSiteloadingFieldDataGraph}
              siteUrl={siteUrl}
              mainSiteUrl={mainSiteUrl}
            />
            <hr />
            <LabDataComparison siteUrl={siteUrl} mainSiteUrl={mainSiteUrl} />
            <hr />
            <UptimeOverviewComparison
              siteUrl={siteUrl}
              mainSiteUrl={mainSiteUrl}
            />
            <hr />
            <W3ValidatorOverviewComparison
              siteUrl={siteUrl}
              mainSiteUrl={mainSiteUrl}
            />
            <hr />
            <TechStackComparison siteUrl={siteUrl} mainSiteUrl={mainSiteUrl} />
          </main>
        </Container>
      )}
    </>
  );
};
export default SitesComparison;
