import React, { useState, useEffect } from "react";
import {
  getOverallScoreColorSiteDetails,
  getMetryVOverallScoreColorSiteDetails,
} from "../../helpers/scores";
import CircularProgress from "@mui/material/CircularProgress";
import { Col, Row } from "shards-react";
import axios from "axios";
import { API_URL_PSI } from "../../constants";

const OverallScoreComparison = ({ siteUrl, siteName, mainSiteUrl }) => {
  const [assessment, setAssessment] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [mainSiteAssessment, setMainSiteAssessment] = useState({});
  const [mainSiteloading, setMainSiteLoading] = useState(false);
  const [mainSiteerror, setMainSiteError] = useState(false);

  const getAssessments = async (siteUrl) => {
    setError(false);
    setLoading(true);
    try {
      const response = await axios.post(
        `${API_URL_PSI}/scan/score`,
        {
          data: {
            url: siteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setAssessment(response.data);
      setLoading(false);
      setError(false);
    } catch (error) {
      console.log(error.response);
      setLoading(false);
      setError(true);
    }
  };

  const getMainSiteAssessments = async (mainSiteUrl) => {
    setMainSiteError(false);
    setMainSiteLoading(true);
    try {
      const response = await axios.post(
        `${API_URL_PSI}/scan/score`,
        {
          data: {
            url: mainSiteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setMainSiteAssessment(response.data);
      setMainSiteLoading(false);
      setMainSiteError(false);
    } catch (error) {
      console.log(error.response);
      setMainSiteLoading(false);
      setMainSiteError(true);
    }
  };

  const getMainSiteName = (mainSiteUrl) => {
    const domain = mainSiteUrl.match(/\/\/(?:www\.)?(.*?)\//)[1];
    const mainSiteName =
      domain.split(".")[0].charAt(0).toUpperCase() +
      domain.split(".")[0].slice(1);
    return mainSiteName;
  };

  useEffect(() => {
    getAssessments(siteUrl);
  }, [siteUrl]);

  useEffect(() => {
    getMainSiteAssessments(mainSiteUrl);
  }, [mainSiteUrl]);

  const scores = [
    {
      title: "Core Web Vitals Assessment - Mobile",
      score: assessment
        ? assessment.coreWebVitalsMobile === null
          ? "?"
          : parseFloat(assessment.coreWebVitalsMobile).toFixed(1)
        : 0,
    },
    {
      title: "Core Web Vitals Assessment - Desktop",
      score: assessment
        ? assessment.coreWebVitalsDesktop === null
          ? "?"
          : parseFloat(assessment.coreWebVitalsDesktop).toFixed(1)
        : 0,
    },
    {
      title: "Overall Score - Mobile",
      score:
        assessment && assessment.overallScoreMobile
          ? parseFloat(assessment.overallScoreMobile).toFixed(1)
          : 0,
    },
    {
      title: "Overall Score - Desktop",
      score:
        assessment && assessment.overallScoreDesktop
          ? parseFloat(assessment.overallScoreDesktop).toFixed(1)
          : 0,
    },
    {
      title: "Lab Data - Mobile",
      score:
        assessment && assessment.labDataMobile
          ? parseFloat(assessment.labDataMobile).toFixed(1)
          : 0,
    },
    {
      title: "Lab Data - Desktop",
      score:
        assessment && assessment.labDataDesktop
          ? parseFloat(assessment.labDataDesktop).toFixed(1)
          : 0,
    },
    {
      title: "Server Uptime",
      score:
        assessment && assessment.serverUptime
          ? parseFloat(assessment.serverUptime).toFixed(1)
          : 0,
    },
    {
      title: "W3 Validator Tool Assessment",
      score:
        assessment && assessment.w3Validator
          ? parseFloat(assessment.w3Validator).toFixed(1)
          : 0,
    },
  ];

  const mainSitescores = [
    {
      title: "Core Web Vitals Assessment - Mobile",
      score: mainSiteAssessment
        ? mainSiteAssessment.coreWebVitalsMobile === null
          ? "?"
          : parseFloat(mainSiteAssessment.coreWebVitalsMobile).toFixed(1)
        : 0,
    },
    {
      title: "Core Web Vitals Assessment - Desktop",
      score: mainSiteAssessment
        ? mainSiteAssessment.coreWebVitalsDesktop === null
          ? "?"
          : parseFloat(mainSiteAssessment.coreWebVitalsDesktop).toFixed(1)
        : 0,
    },
    {
      title: "Overall Score - Mobile",
      score:
        mainSiteAssessment && mainSiteAssessment.overallScoreMobile
          ? parseFloat(mainSiteAssessment.overallScoreMobile).toFixed(1)
          : 0,
    },
    {
      title: "Overall Score - Desktop",
      score:
        mainSiteAssessment && mainSiteAssessment.overallScoreDesktop
          ? parseFloat(mainSiteAssessment.overallScoreDesktop).toFixed(1)
          : 0,
    },
    {
      title: "Lab Data - Mobile",
      score:
        mainSiteAssessment && mainSiteAssessment.labDataMobile
          ? parseFloat(mainSiteAssessment.labDataMobile).toFixed(1)
          : 0,
    },
    {
      title: "Lab Data - Desktop",
      score:
        mainSiteAssessment && mainSiteAssessment.labDataDesktop
          ? parseFloat(mainSiteAssessment.labDataDesktop).toFixed(1)
          : 0,
    },
    {
      title: "Server Uptime",
      score:
        mainSiteAssessment && mainSiteAssessment.serverUptime
          ? parseFloat(mainSiteAssessment.serverUptime).toFixed(1)
          : 0,
    },
    {
      title: "W3 Validator Tool Assessment",
      score:
        mainSiteAssessment && mainSiteAssessment.w3Validator
          ? parseFloat(mainSiteAssessment.w3Validator).toFixed(1)
          : 0,
    },
  ];

  const getPercentage = (value) => {
    return value === "?" ? "0%" : (value * 100) / 10 + "%";
  };
  return (
    <>
      <Row style={{ marginRight: "0px", marginLeft: "0px" }}>
        <Col className="col-md-6 col-sm-6 mb-4" style={{ maxWidth: "45%" }}>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                maxWidth: "40%",
              }}
            >
              <CircularProgress
                style={{ margin: "2rem", height: "140px", width: "140px" }}
              />
              <div>Loading MetryV Score</div>
            </div>
          ) : error ? (
            "No sufficient data for calculating metryV score. Please wait for 24 hours to get some results!"
          ) : (
            <div className="metryV-score__container">
              <div
                className="metryV-score__container__items"
                style={{ width: "100%" }}
              >
                <div className="metryV-score__container__items__item">
                  <div className="metryV-score__container__items__item__top">
                    <span
                      className="metryV-score__container__items__item__top__title"
                      // style={{ fontSize: "1.5em" }}
                    >
                      {siteName} - MetryV Score
                    </span>
                    <span
                      className="metryV-score__container__items__item__top__score"
                      // style={{ fontSize: "1.5em" }}
                    >
                      {assessment.overall
                        ? Math.round(assessment.overall)
                        : "?"}
                    </span>
                  </div>
                  <div
                    className={
                      "metryV-score__container__items__item__progress metryV-score__container__items__item__progress" +
                      getOverallScoreColorSiteDetails(assessment.overall)
                    }
                    style={{
                      transform: "rotate(180deg)",
                    }}
                  >
                    <div
                      className={
                        "metryV-score__container__items__item__progress--rating metryV-score__container__items__item__progress--rating" +
                        getOverallScoreColorSiteDetails(assessment.overall)
                      }
                      style={{
                        width: getPercentage(assessment.overall / 10),
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Col>
        <Col className="col-md-6 col-sm-6 mb-4" style={{ maxWidth: "45%" }}>
          {mainSiteloading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <CircularProgress
                style={{ margin: "2rem", height: "140px", width: "140px" }}
              />
              <div>Loading MetryV Score</div>
            </div>
          ) : mainSiteerror ? (
            "No sufficient data for calculating metryV score. Please wait for 24 hours to get some results!"
          ) : (
            <div className="metryV-score__container">
              <div
                className="metryV-score__container__items"
                style={{ width: "100%" }}
              >
                <div className="metryV-score__container__items__item">
                  <div className="metryV-score__container__items__item__top">
                    <span
                      className="metryV-score__container__items__item__top__score"
                      // style={{ fontSize: "1.5em" }}
                    >
                      {mainSiteAssessment.overall
                        ? Math.round(mainSiteAssessment.overall)
                        : "?"}
                    </span>
                    <span
                      className="metryV-score__container__items__item__top__title"
                      // style={{ fontSize: "1.5em" }}
                    >
                      {getMainSiteName(mainSiteUrl)} - MetryV Score
                    </span>
                  </div>
                  <div
                    className={
                      "metryV-score__container__items__item__progress metryV-score__container__items__item__progress" +
                      getOverallScoreColorSiteDetails(
                        mainSiteAssessment.overall
                      )
                    }
                  >
                    <div
                      className={
                        "metryV-score__container__items__item__progress--rating metryV-score__container__items__item__progress--rating" +
                        getOverallScoreColorSiteDetails(
                          mainSiteAssessment.overall
                        )
                      }
                      style={{
                        width: getPercentage(mainSiteAssessment.overall / 10),
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="col-md-6 col-sm-6 mb-4" style={{ maxWidth: "45%" }}>
          <div className="metryV-score">
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <CircularProgress
                  style={{ margin: "2rem", height: "140px", width: "140px" }}
                />
                <div>Loading MetryV Score</div>
              </div>
            ) : error ? (
              "No sufficient data for calculating metryV score. Please wait for 24 hours to get some results!"
            ) : (
              <div className="metryV-score__container">
                <div
                  className="metryV-score__container__items"
                  style={{ width: "100%" }}
                >
                  {!scores || scores.length == 0
                    ? ""
                    : scores.map((item, index) => (
                        <div
                          className="metryV-score__container__items__item"
                          key={index}
                        >
                          <div className="metryV-score__container__items__item__top">
                            <span className="metryV-score__container__items__item__top__title">
                              {item.title}
                            </span>
                            <span className="metryV-score__container__items__item__top__score">
                              {item.score ? item.score : "?"}/10
                            </span>
                          </div>
                          <div
                            className={
                              "metryV-score__container__items__item__progress metryV-score__container__items__item__progress" +
                              getMetryVOverallScoreColorSiteDetails(item.score)
                            }
                            style={{
                              transform: "rotate(180deg)",
                            }}
                          >
                            <div
                              className={
                                "metryV-score__container__items__item__progress--rating metryV-score__container__items__item__progress--rating" +
                                getMetryVOverallScoreColorSiteDetails(
                                  item.score
                                )
                              }
                              style={{
                                width: getPercentage(item.score),
                              }}
                            ></div>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            )}
          </div>
        </Col>
        <Col className="col-md-6 col-sm-6 mb-4" style={{ maxWidth: "45%" }}>
          <div className="metryV-score">
            {mainSiteloading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <CircularProgress
                  style={{ margin: "2rem", height: "140px", width: "140px" }}
                />
                <div>Loading MetryV Score</div>
              </div>
            ) : mainSiteerror ? (
              "No sufficient data for calculating metryV score. Please wait for 24 hours to get some results!"
            ) : (
              <div className="metryV-score__container">
                <div
                  className="metryV-score__container__items"
                  style={{ width: "100%" }}
                >
                  {!mainSitescores || mainSitescores.length == 0
                    ? ""
                    : mainSitescores.map((item, index) => (
                        <div
                          className="metryV-score__container__items__item"
                          key={index}
                        >
                          <div className="metryV-score__container__items__item__top">
                            <span className="metryV-score__container__items__item__top__score">
                              {item.score ? item.score : "?"}/10
                            </span>
                            <span className="metryV-score__container__items__item__top__title">
                              {item.title}
                            </span>
                          </div>
                          <div
                            className={
                              "metryV-score__container__items__item__progress metryV-score__container__items__item__progress" +
                              getMetryVOverallScoreColorSiteDetails(item.score)
                            }
                          >
                            <div
                              className={
                                "metryV-score__container__items__item__progress--rating metryV-score__container__items__item__progress--rating" +
                                getMetryVOverallScoreColorSiteDetails(
                                  item.score
                                )
                              }
                              style={{ width: getPercentage(item.score) }}
                            ></div>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default OverallScoreComparison;
