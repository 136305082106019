import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PasswordStrengthBar from "react-password-strength-bar";
import logo from "../assets/images/logos/newLogo.svg";
import axios from "axios";
import { useSnackbar } from "notistack";
import { API_URL } from "../constants";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import matchIcon from "../assets/images/logos/matchIcon.svg";

function NewPassword() {
  const [user, setUser] = useState({
    password: "",
    password_confirmation: "",
  });
  const [visible, setVisible] = useState(false);
  const [icon] = useState(eyeOff);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [submitted, setSubmitted] = useState(false);
  const [, setDisabledBtn] = useState(true);

  function handleChange(e) {
    const { name, value } = e.target;
    setUser((inputs) => ({ ...inputs, [name]: value }));
  }

  const { userId } = useParams();

  async function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);

    return await axios
      .patch(`${API_URL}/users/${userId}`, {
        password: user.password,
      })
      .then((response) => {
        enqueueSnackbar(
          response.data.success
            ? response.data.success
            : "Reset password link sent successfully",
          { variant: "success" }
        );
        setTimeout(() => {
          closeSnackbar();
        }, 5000);
      })
      .catch((error) => {
        enqueueSnackbar(
          error.response?.data ? error.response?.data : "Something went wrong",
          {
            variant: "error",
          }
        );
        setTimeout(() => {
          closeSnackbar();
        }, 5000);
      });
  }

  return (
    <div className="login-container">
      <div className="d-table m-auto">
        <img
          id="main-logo"
          className="d-inline-block align-top"
          src={logo}
          alt="metryV Logo"
        />
      </div>
      <hr />
      <h2
        style={{
          textAlign: "center",
          fontSize: "40px",
          fontWeight: "600",
          color: "#3D506E",
        }}
        className="mt-2"
      >
        Set new password
      </h2>
      <form name="form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label style={{ marginTop: "20px", color: "#3D506E" }}>
            Password
          </label>
          <input
            type={visible ? "text" : "password"}
            name="password"
            value={user.password}
            onChange={handleChange}
            className={
              "form-control" +
              (submitted && !user.password ? " is-invalid" : "")
            }
            style={{
              borderRadius: "10px",
              fontSize: "15px",
              backgroundImage: "none",
              paddingRight: "0px",
            }}
          />
          <span
            onClick={() => setVisible(!visible)}
            style={{
              marginTop: "-30px",
              display: "flex",
              marginRight: "10px",
              float: "right",
              justifyContent: "end",
            }}
          >
            {!visible ? (
              <Icon
                icon={icon}
                style={{
                  width: "22px",
                  height: "20.02px",
                  marginRight: "10px",
                }}
              ></Icon>
            ) : (
              <Icon
                icon={eye}
                style={{
                  width: "22px",
                  height: "20.02px",
                  marginRight: "10px",
                }}
              ></Icon>
            )}
          </span>
          {submitted && !user.password && (
            <div className="invalid-feedback">Password is required</div>
          )}
          <PasswordStrengthBar
            password={user.password}
            onChangeScore={(score) => {
              if (score > 2) {
                setDisabledBtn(false);
              }
            }}
          />
          <div
            className="password-info"
            style={{ fontWeight: "400", marginTop: "-10px" }}
          >
            Password should contain at least 8 characters, one capital letter,
            one number and a special character.
          </div>
        </div>
        <div className="form-group">
          <label style={{ color: "#3D506E" }}>Confirm password</label>
          <input
            type={visible ? "text" : "password"}
            name="password_confirmation"
            value={user.password_confirmation}
            onChange={handleChange}
            className={
              "form-control" +
              (submitted && user.password_confirmation !== user.password
                ? " is-invalid"
                : "")
            }
            style={{
              borderRadius: "10px",
              fontSize: "15px",
              backgroundImage: "none",
              paddingRight: "2px",
            }}
          />
          <span
            onClick={() => setVisible(!visible)}
            style={{
              marginTop: "-30px",
              display: "flex",
              marginRight: "10px",
              float: "right",
              justifyContent: "end",
            }}
          >
            {!visible ? (
              <Icon
                icon={icon}
                style={{
                  width: "22px",
                  height: "20.02px",
                  marginRight: "10px",
                }}
              ></Icon>
            ) : (
              <Icon
                icon={eye}
                style={{
                  width: "22px",
                  height: "20.02px",
                  marginRight: "10px",
                }}
              ></Icon>
            )}
          </span>
          {submitted && user.password_confirmation !== user.password && (
            <div
              className="invalid-feedback"
              style={{
                fontWeight: "400",
                fontSize: "12px",
                width: "90%",
                marginTop: "10px",
              }}
            >
              <img
                src={matchIcon}
                alt="matchIcon"
                style={{
                  width: "18px",
                  height: "18px",
                  margin: "0 7.5px",
                  marginTop: "-2px",
                }}
              />
              Password don&apos;t match.
              <b style={{ fontWeight: "600" }}>Please try again!</b>
            </div>
          )}
        </div>
        <div className="form-group">
          <button
            className="form-control"
            style={{
              backgroundColor: "#0087F9",
              color: "white",
              borderRadius: "6px",
              fontSize: "14px",
              fontWeight: "600",
              marginTop: "25px",
            }}
          >
            Confirm
          </button>
        </div>
      </form>
    </div>
  );
}

export { NewPassword };
