import React from "react";
import { useLocation } from "react-router-dom";
import { Container, Row } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { checkRanking } from "../helpers/rankings";

const RankingDetails = () => {
  const location = useLocation();
  const siteName = location.state.siteName;
  const rankings = location.state.rankings.slice(
    1,
    location.state.rankings.length
  );

  return (
    <Container fluid className="main-content-container px-4 mb-5">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title={siteName}
          subtitle="Ranking Details"
          className="text-sm-left mb-3"
        />
      </Row>

      <Row className="ml-1 scrollable-table">
        <table className="table mb-0">
          <thead className="bg-light">
            <tr>
              <td>#</td>
              <td>Keyword</td>
              <td>Rank</td>
              <td>Volume</td>
              <td>Traffic %</td>
              <td># of Results</td>
            </tr>
          </thead>
          <tbody>
            {!rankings
              ? ""
              : rankings.map((item, index) => (
                  <tr key={index}>
                    <td>{item[0]}</td>
                    <td>{item[1]}</td>
                    <td>{checkRanking(item[2])}</td>
                    <td>{item[3]}</td>
                    <td>{item[4]}</td>
                    <td>{item[5]}</td>
                  </tr>
                ))}
          </tbody>
        </table>
      </Row>
    </Container>
  );
};

export default RankingDetails;
