import React from "react";
import { Row, Col } from "shards-react";
import { getScoreClass } from "../../helpers/scores";

const LabData = ({ labDataMobile, labDataDesktop }) => {
  return (
    <>
      <Row>
        <h3 className="text-sm-left ml-3 heading-3">Lab Data</h3>
      </Row>

      <Row>
        <Col className="col-12 col-lg-6 mb-4">
          <h4 className="text-sm-left heading-3">Mobile Devices</h4>
          {labDataMobile.map((metric, idx) => (
            <table className="table" key={idx}>
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    Metric
                  </th>
                  <th scope="col" className="border-0">
                    Score
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span
                      className={
                        "score score--" +
                        getScoreClass(metric.FCP, "FCP", "mobile")
                      }
                    ></span>
                    First Contentful Paint
                  </td>
                  <td
                    className={
                      "score-text--" +
                      getScoreClass(metric.FCP, "FCP", "mobile")
                    }
                  >
                    {metric.FCP}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      className={
                        "score score--" +
                        getScoreClass(metric.SI, "SI", "mobile")
                      }
                    ></span>
                    Speed Index
                  </td>
                  <td
                    className={
                      "score-text--" + getScoreClass(metric.SI, "SI", "mobile")
                    }
                  >
                    {metric.SI}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      className={
                        "score score--" +
                        getScoreClass(metric.LCP, "LCP", "mobile")
                      }
                    ></span>
                    Largest Contentful Paint
                  </td>
                  <td
                    className={
                      "score-text--" +
                      getScoreClass(metric.LCP, "LCP", "mobile")
                    }
                  >
                    {metric.LCP}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      className={
                        "score score--" +
                        getScoreClass(metric.TBT, "TBT", "mobile")
                      }
                    ></span>
                    Total Blocking TIme
                  </td>
                  <td
                    className={
                      "score-text--" +
                      getScoreClass(metric.TBT, "TBT", "mobile")
                    }
                  >
                    {metric.TBT}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      className={
                        "score score--" +
                        getScoreClass(metric.CLS, "CLS", "mobile")
                      }
                    ></span>
                    Cumulative Layout Shift
                  </td>
                  <td
                    className={
                      "score-text--" +
                      getScoreClass(metric.CLS, "CLS", "mobile")
                    }
                  >
                    {metric.CLS}
                  </td>
                </tr>
              </tbody>
            </table>
          ))}
        </Col>
        <Col className="col-12 col-lg-6 mb-4">
          <h4 className="text-sm-left heading-3">Desktop Devices</h4>
          {labDataDesktop.map((metric, idx) => (
            <table className="table" key={idx}>
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    Metric
                  </th>
                  <th scope="col" className="border-0">
                    Score
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span
                      className={
                        "score score--" +
                        getScoreClass(metric.FCP, "FCP", "desktop")
                      }
                    ></span>
                    First Contentful Paint
                  </td>
                  <td
                    className={
                      "score-text--" +
                      getScoreClass(metric.FCP, "FCP", "desktop")
                    }
                  >
                    {metric.FCP}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      className={
                        "score score--" +
                        getScoreClass(metric.LCP, "LCP", "desktop")
                      }
                    ></span>
                    Largest Contentful Paint
                  </td>
                  <td
                    className={
                      "score-text--" +
                      getScoreClass(metric.LCP, "LCP", "desktop")
                    }
                  >
                    {metric.LCP}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      className={
                        "score score--" +
                        getScoreClass(metric.SI, "SI", "desktop")
                      }
                    ></span>
                    Speed Index
                  </td>
                  <td
                    className={
                      "score-text--" + getScoreClass(metric.SI, "SI", "desktop")
                    }
                  >
                    {metric.SI}
                  </td>
                </tr>

                <tr>
                  <td>
                    <span
                      className={
                        "score score--" +
                        getScoreClass(metric.TBT, "TBT", "desktop")
                      }
                    ></span>
                    Total Blocking TIme
                  </td>
                  <td
                    className={
                      "score-text--" +
                      getScoreClass(metric.TBT, "TBT", "desktop")
                    }
                  >
                    {metric.TBT}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      className={
                        "score score--" +
                        getScoreClass(metric.CLS, "CLS", "desktop")
                      }
                    ></span>
                    Cumulative Layout Shift
                  </td>
                  <td
                    className={
                      "score-text--" +
                      getScoreClass(metric.CLS, "CLS", "desktop")
                    }
                  >
                    {metric.CLS}
                  </td>
                </tr>
              </tbody>
            </table>
          ))}
        </Col>
      </Row>
    </>
  );
};

export default LabData;
