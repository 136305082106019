import React, { useState, useEffect, useCallback, PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { Row, Col, Container } from "shards-react";
import "./ChartsTable.css";

const ChartsTable = (props) => {
  const [data, setData] = useState(props.reportToView);
  const [visibleBars] = useState({});
  const calculateBarSize = useCallback((data, yScale) => {
    const max = Math.max(...data);
    const barSize = yScale(max) * 0.7;
    return barSize;
  }, []);
  console.log("calculateBarSize", calculateBarSize);

  const avgLatencies = data.map((d) => {
    const avgLatency = d.data.reduce(
      (acc, obj) => acc + parseFloat(obj.latency),
      0
    );
    return avgLatency;
  });

  const times = data.map((d) => new Date(d.date).getHours());
  const mergedData = {};

  for (let i = 0; i < times.length; i++) {
    const date = times[i];
    const latency = avgLatencies[i];
    if (date in mergedData) {
      mergedData[date].push(latency);
    } else {
      mergedData[date] = [latency];
    }
  }
  const datArray = Object.keys(mergedData);

  const mergedArray = [];
  let chartData = [];
  for (const [date, latencies] of Object.entries(mergedData)) {
    const averageLatency =
      latencies.reduce((total, value) => total + value, 0) / latencies.length;
    const hourIndicator = date >= 12 ? "PM" : "AM";
    const allHours = Array.from({ length: 24 }, (_, index) => index);
    const missingHours = allHours.filter(
      (element) => !datArray.includes(element.toString())
    );

    const missingDataPoints = missingHours.map((date) => ({
      date,
      avgLatency: 0,
      hourIndicator: "",
    }));

    chartData = [...mergedArray, ...missingDataPoints];
    mergedArray.push({
      date: parseInt(date),
      avgLatency: averageLatency,
      hourIndicator: hourIndicator,
    });
    chartData = [...mergedArray, ...missingDataPoints];
  }
  chartData = chartData.sort((a, b) => a.date - b.date);

  useEffect(() => {
    const reportDataToView = props.pingData.find(
      (item) => item.date === props.reportToView
    );
    if (reportDataToView) {
      const newData = reportDataToView.data.map((item) => {
        const isVisible = visibleBars[item.country] !== undefined;
        return {
          ...item,
          isVisible,
        };
      });
      setData(newData);
    }
  }, [props.pingData, props.reportToView, visibleBars]);

  class CustomizedAxisTick extends PureComponent {
    render() {
      const { x, y, payload } = this.props;
      const date = payload.value;
      const hourIndicator = date >= 12 ? "pm" : "am";
      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={16}
            textAnchor="end"
            fill="#666"
            transform="rotate(-35)"
          >
            {payload.value} {hourIndicator}
          </text>
        </g>
      );
    }
  }

  const date = data[0].date.split("T")[0];
  const countries = data[0].data.map((country) => {
    return country.server;
  });

  return (
    <Container
      fluid
      className="main-content-container px-4"
      style={{ position: "relative", background: "white" }}
    >
      <div>
        <Row
          className="col-lg pl-0 pr-0 ml-0 mr-0"
          style={{ justifyContent: "center" }}
        >
          <Col>
            <div
              className="form-group"
              style={{
                display: "flex",
                width: "100%",
                marginRight: "500px",
              }}
            >
              <label
                style={{
                  color: "#3D506E",
                  fontWeight: "600",
                  fontSize: "20px",
                  marginTop: "30px",
                }}
              >
                Ping results on {date}
              </label>
            </div>
          </Col>
          <Col>
            <div
              className="form-group"
              style={{
                display: "flex",
              }}
            >
              <label
                style={{
                  color: "#3D506E",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginTop: "30px",
                }}
              >
                Filtered by Countries:{" "}
                <b style={{ color: "#0087F9" }}>
                  {" "}
                  {countries.map((country, index) => (
                    <div key={index}>* {country}</div>
                  ))}
                </b>
              </label>
            </div>
          </Col>
          <Col>
            <div
              className="form-group"
              style={{
                display: "flex",
              }}
            >
              <label
                style={{
                  color: "#3D506E",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginTop: "30px",
                }}
              >
                Collect results: <b style={{ color: "#0087F9" }}>Every hour</b>
              </label>
            </div>
          </Col>
        </Row>
      </div>
      <div
        className="d-flex justify-content-center"
        style={{ height: "500px", marginLeft: "-70px" }}
      >
        <LineChart
          width={1200}
          height={400}
          data={chartData}
          margin={{
            top: 100,
            right: 10,
            left: 50,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            type="number"
            height={60}
            angle={-90}
            domain={[0, 23]}
            tickCount={25}
            tick={<CustomizedAxisTick />}
            interval={0}
          />
          <YAxis
            dataKey="avgLatency"
            type="number"
            domain={[0, 30]}
            tickCount={7}
            interval={0}
          />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="avgLatency"
            stroke="#8884d8"
            strokeWidth={2}
            activeDot={{ r: 8 }}
          />
          {/* <Line type="monotone" dataKey="date" stroke="#82ca9d" /> */}
        </LineChart>
      </div>
    </Container>
  );
};

export default ChartsTable;
