import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "shards-react";
import SmallStats from "./SmallStats";
import { getScoreColor } from "../../helpers/scores";
import LabDataOverview from "../dashboard/LabDataOverview";
import OverallScoreOverview from "../dashboard/OverallScoreOverview";
import CheckRanking from "../dashboard/CheckRanking";
import UptimeOverview from "../dashboard/UptimeOverview";
import W3ValidatorOverview from "../dashboard/W3ValidatorOverview";
import MetryVScore from "../dashboard/MetryVScore";
import ScreenshotsAnalyze from "../dashboard/ScreenshotsAnalyze";
import { useSelector } from "react-redux";
import { FormControlLabel, Switch } from "@mui/material";
import { withStyles } from "@mui/styles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  checkedTrack: {
    backgroundColor: "#1976d2",
  },
}));

const SiteOverview = ({ siteName, siteUrl }) => {
  const [, setFieldDataCategory] = useState({});
  const [hasFCP, setHasFCP] = useState(false);
  const [hasOriginSummary, setHasOriginSummary] = useState(false);
  const [originSummaryCategory, setOriginSummaryCategory] = useState(false);
  const [smallStats, setSmallStats] = useState([]);
  const [device, setDevice] = useState(true);
  const handleToggleChange = (e) => {
    setDevice(e.target.checked);
  };

  const styles = {
    switchRoot: {
      "&$checked": {
        color: "#89CFF0",
      },
    },
    checked: {},
  };

  const classes = useStyles();

  const StyledSwitch = withStyles(styles)(Switch);

  function relDiff(oldNumber, newNumber) {
    return Math.abs(((oldNumber - newNumber) / oldNumber) * 100);
  }

  const originSummary = useSelector(
    (state) => state.originSummary.originSummary
  );
  const ranking = useSelector((state) => state.ranking.ranking);

  const setSummaryData = (originSummary) => {
    if (originSummary) {
      let lcpStats = [];
      let fcpStats = [];
      let clsStats = [];

      if (device) {
        setFieldDataCategory(originSummary?.mobile?.overall_category);
        setHasFCP(originSummary?.mobile?.fcp.score ? true : false);
        setHasOriginSummary(
          originSummary?.mobile?.overall_category ? true : false
        );
        setOriginSummaryCategory(originSummary?.mobile?.overall_category);

        setSmallStats([
          {
            label: "LCP",
            labelSub: "loading performance",
            value:
              originSummary.mobile.lcp.score != null
                ? originSummary.mobile.lcp.score + " s"
                : "-",
            percentage: relDiff(
              lcpStats[lcpStats.length - 2],
              lcpStats[lcpStats.length - 1]
            )
              ? relDiff(
                  lcpStats[lcpStats.length - 2],
                  lcpStats[lcpStats.length - 1]
                ).toFixed(2) + "%"
              : "0%",
            increase:
              lcpStats[lcpStats.length - 1] > lcpStats[lcpStats.length - 2]
                ? true
                : false,
            decrease:
              lcpStats[lcpStats.length - 1] < lcpStats[lcpStats.length - 2]
                ? true
                : false,
            chartLabels: [null, null, null, null, null, null, null],
            attrs: { md: "6", sm: "6" },
            datasets: [
              {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor:
                  originSummary.mobile.lcp.category != null
                    ? getScoreColor(originSummary.mobile.lcp.category)[
                        "background"
                      ]
                    : "",
                borderColor:
                  originSummary.mobile.lcp.category != null
                    ? getScoreColor(originSummary.mobile.lcp.category)["border"]
                    : "",
                data: [...lcpStats],
              },
            ],
          },
          {
            label: "FCP",
            labelSub: "interactivity",
            value:
              originSummary.mobile.fcp.score != null
                ? originSummary.mobile.fcp.score + " ms"
                : "-",
            percentage: relDiff(
              fcpStats[fcpStats.length - 2],
              fcpStats[fcpStats.length - 1]
            )
              ? relDiff(
                  fcpStats[fcpStats.length - 2],
                  fcpStats[fcpStats.length - 1]
                ).toFixed(2) + "%"
              : "0%",
            increase:
              fcpStats[fcpStats.length - 1] > fcpStats[fcpStats.length - 2]
                ? true
                : false,
            decrease:
              fcpStats[fcpStats.length - 1] < fcpStats[fcpStats.length - 2]
                ? true
                : false,
            chartLabels: [null, null, null, null, null, null, null],
            attrs: { md: "6", sm: "6" },
            datasets: [
              {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor:
                  originSummary.mobile.fcp.category != null
                    ? getScoreColor(originSummary.mobile.fcp.category)[
                        "background"
                      ]
                    : "",
                borderColor:
                  originSummary.mobile.fcp.category != null
                    ? getScoreColor(originSummary.mobile.fcp.category)["border"]
                    : "",
                data: [...fcpStats],
              },
            ],
          },
          {
            label: "CLS",
            labelSub: "visual stability",
            value:
              originSummary.mobile.cls.score != null
                ? originSummary.mobile.cls.score
                : "-",
            percentage: relDiff(
              clsStats[clsStats.length - 2],
              clsStats[clsStats.length - 1]
            )
              ? relDiff(
                  clsStats[clsStats.length - 2],
                  clsStats[clsStats.length - 1]
                ).toFixed(2) + "%"
              : "0%",
            increase:
              clsStats[clsStats.length - 1] > clsStats[clsStats.length - 2]
                ? true
                : false,
            decrease:
              clsStats[fcpStats.length - 1] < clsStats[clsStats.length - 2]
                ? true
                : false,
            chartLabels: [null, null, null, null, null, null, null],
            attrs: { md: "4", sm: "6" },
            datasets: [
              {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor:
                  originSummary.mobile.cls.category != null
                    ? getScoreColor(originSummary.mobile.cls.category)[
                        "background"
                      ]
                    : "",
                borderColor:
                  originSummary.mobile.cls.category != null
                    ? getScoreColor(originSummary.mobile.cls.category)["border"]
                    : "",
                data: [...clsStats],
              },
            ],
          },
        ]);
      } else {
        setFieldDataCategory(originSummary.desktop.overall_category);
        setHasFCP(originSummary.desktop.fcp.score ? true : false);
        setHasOriginSummary(
          originSummary.desktop.overall_category ? true : false
        );
        setOriginSummaryCategory(originSummary.desktop.overall_category);

        setSmallStats([
          {
            label: "LCP",
            labelSub: "loading performance",
            value:
              originSummary.desktop.lcp.score != null
                ? originSummary.desktop.lcp.score + " s"
                : "-",
            percentage: relDiff(
              lcpStats[lcpStats.length - 2],
              lcpStats[lcpStats.length - 1]
            )
              ? relDiff(
                  lcpStats[lcpStats.length - 2],
                  lcpStats[lcpStats.length - 1]
                ).toFixed(2) + "%"
              : "0%",
            increase:
              lcpStats[lcpStats.length - 1] > lcpStats[lcpStats.length - 2]
                ? true
                : false,
            decrease:
              lcpStats[lcpStats.length - 1] < lcpStats[lcpStats.length - 2]
                ? true
                : false,
            chartLabels: [null, null, null, null, null, null, null],
            attrs: { md: "6", sm: "6" },
            datasets: [
              {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor:
                  originSummary.desktop.lcp.category != null
                    ? getScoreColor(originSummary.desktop.lcp.category)[
                        "background"
                      ]
                    : "",
                borderColor:
                  originSummary.desktop.lcp.category != null
                    ? getScoreColor(originSummary.desktop.lcp.category)[
                        "border"
                      ]
                    : "",
                data: [...lcpStats],
              },
            ],
          },
          {
            label: "FCP",
            labelSub: "interactivity",
            value:
              originSummary.desktop.fcp.score != null
                ? originSummary.desktop.fcp.score + " ms"
                : "-",
            percentage: relDiff(
              fcpStats[fcpStats.length - 2],
              fcpStats[fcpStats.length - 1]
            )
              ? relDiff(
                  fcpStats[fcpStats.length - 2],
                  fcpStats[fcpStats.length - 1]
                ).toFixed(2) + "%"
              : "0%",
            increase:
              fcpStats[fcpStats.length - 1] > fcpStats[fcpStats.length - 2]
                ? true
                : false,
            decrease:
              fcpStats[fcpStats.length - 1] < fcpStats[fcpStats.length - 2]
                ? true
                : false,
            chartLabels: [null, null, null, null, null, null, null],
            attrs: { md: "6", sm: "6" },
            datasets: [
              {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor:
                  originSummary.desktop.fcp.category != null
                    ? getScoreColor(originSummary.desktop.fcp.category)[
                        "background"
                      ]
                    : "",
                borderColor:
                  originSummary.desktop.fcp.category != null
                    ? getScoreColor(originSummary.desktop.fcp.category)[
                        "border"
                      ]
                    : "",
                data: [...fcpStats],
              },
            ],
          },
          {
            label: "CLS",
            labelSub: "visual stability",
            value:
              originSummary.desktop.cls.score != null
                ? originSummary.desktop.cls.score
                : "-",
            percentage: relDiff(
              clsStats[clsStats.length - 2],
              clsStats[clsStats.length - 1]
            )
              ? relDiff(
                  clsStats[clsStats.length - 2],
                  clsStats[clsStats.length - 1]
                ).toFixed(2) + "%"
              : "0%",
            increase:
              clsStats[clsStats.length - 1] > clsStats[clsStats.length - 2]
                ? true
                : false,
            decrease:
              clsStats[fcpStats.length - 1] < clsStats[clsStats.length - 2]
                ? true
                : false,
            chartLabels: [null, null, null, null, null, null, null],
            attrs: { md: "4", sm: "6" },
            datasets: [
              {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor:
                  originSummary.desktop.cls.category != null
                    ? getScoreColor(originSummary.desktop.cls.category)[
                        "background"
                      ]
                    : "",
                borderColor:
                  originSummary.desktop.cls.category != null
                    ? getScoreColor(originSummary.desktop.cls.category)[
                        "border"
                      ]
                    : "",
                data: [...clsStats],
              },
            ],
          },
        ]);
      }
    }
  };

  useEffect(() => {
    if (originSummary) {
      setSummaryData(originSummary);
    }
  }, [device]);

  return (
    <Container fluid className="main-content-container mb-5">
      <main>
        <Row>
          <Col lg="12" md="12" sm="12" className="mb-4 mt-2">
            <MetryVScore siteName={siteName} siteUrl={siteUrl} />
          </Col>
        </Row>
        <Row>
          <h2 className="heading-dashboard ml-3 mt-2">
            Google&apos;s Core Web Vitals Assessment
          </h2>
          <Col className="mr-4 flex-right">
            <span
              style={{
                fontFamily: "Arial",
                fontWeight: "400",
                fontSize: "1rem",
                lineHeight: "1.5",
              }}
            >
              Desktop
            </span>
            <FormControlLabel
              style={{ margin: "0" }}
              control={
                <StyledSwitch
                  checked={device}
                  onChange={handleToggleChange}
                  style={{
                    color: "#fff",
                  }}
                  classes={{
                    track: classes.checkedTrack,
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={device ? "Mobile " : "Mobile"}
            />
          </Col>
        </Row>
        <Row>
          {!hasOriginSummary ? (
            ""
          ) : (
            <span
              className="text-sm-left ml-3"
              style={{
                fontSize: "14px",
                opacity: "0.8",
              }}
            >
              These data are collected from{" "}
              <a
                href="https://developers.google.com/web/tools/chrome-user-experience-report"
                target="_blank"
                rel="noreferrer"
              >
                Chrome User Experience Report
              </a>{" "}
              over the previous 28-day period, the aggregate experience of all
              pages served from this origin{" "}
              <strong
                style={{
                  color:
                    originSummaryCategory == "FAST"
                      ? "#018642"
                      : originSummaryCategory == "AVERAGE"
                      ? "#ffc107"
                      : "#EB0F00",
                }}
              >
                {originSummaryCategory == "FAST"
                  ? "passes"
                  : originSummaryCategory == "AVERAGE"
                  ? "barely passes"
                  : "does not pass"}
              </strong>{" "}
              the{" "}
              <a
                href="https://web.dev/vitals/"
                target="_blank"
                rel="noreferrer"
              >
                Core Web Vitals
              </a>{" "}
              assessment. To view suggestions tailored to each page, analyze
              individual page URLs.
            </span>
          )}
        </Row>
        <Row className="small-stats-mobile">
          {!hasOriginSummary ? (
            <p className="text-sm-left ml-3">
              The Chrome User Experience Report{" "}
              <a href="https://developers.google.com/speed/docs/insights/v5/about#faq">
                does not have sufficient real-world speed data
              </a>{" "}
              for this origin.
            </p>
          ) : (
            smallStats.map((stats, idx) => (
              <Col className="col-lg mb-4 mt-3" key={idx} {...stats.attrs}>
                <SmallStats
                  id={`small-stats-${idx}`}
                  variation="1"
                  chartData={stats.datasets}
                  chartLabels={stats.chartLabels}
                  label={stats.label}
                  labelSub={stats.labelSub}
                  value={stats.value}
                  percentage={stats.percentage}
                  increase={stats.increase}
                  decrease={stats.decrease}
                />
              </Col>
            ))
          )}
          {hasFCP || !hasOriginSummary ? (
            ""
          ) : (
            <span className="hasFCP text-sm-center">
              Insufficient First Input Delay (FCP) data for this page, but this
              metric is optional for the Core Web Vitals assessment.
            </span>
          )}
        </Row>

        <Row>
          <Col lg="8" md="12" sm="12" className="mb-4">
            <OverallScoreOverview siteName={siteName} siteUrl={siteUrl} />
          </Col>

          {/* Users by Device */}
          <Col lg="4" md="6" sm="12" className="mb-4">
            <CheckRanking
              siteName={siteName}
              siteUrl={siteUrl}
              rankings={ranking}
            />
          </Col>
        </Row>

        <Row>
          <Col className="col-lg-6 col-lg-6 mb-4">
            <LabDataOverview
              siteName={siteName}
              siteUrl={siteUrl}
              device={"Mobile"}
            />
          </Col>
          <Col className="col-lg-6 col-lg-6 mb-4">
            <LabDataOverview
              siteName={siteName}
              siteUrl={siteUrl}
              device={"Desktop"}
            />
          </Col>
        </Row>

        <Row>
          <Col className="col-lg-12 col-md-12 col-sm-12 mb-4">
            <UptimeOverview siteName={siteName} siteUrl={siteUrl} />
          </Col>
          <Col className="col-lg-12 col-md-12 col-sm-12 mb-4">
            <W3ValidatorOverview siteName={siteName} siteUrl={siteUrl} />
          </Col>
        </Row>

        <Row>
          <Col className="col-12">
            <ScreenshotsAnalyze
              siteName={
                siteName
                  ? siteName
                  : localStorage.getItem("tabUrl").replaceAll('"', "")
              }
              siteUrl={
                siteUrl
                  ? siteUrl
                  : localStorage.getItem("tabUrl").replaceAll('"', "")
              }
            />
          </Col>
        </Row>
      </main>
    </Container>
  );
};

export default SiteOverview;
