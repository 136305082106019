import React from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import { Card, CardHeader, CardFooter, CardBody } from "shards-react";

const ChatSection = ({
  sendMessage,
  messages,
  messageToSend,
  setMessageToSend,
  typing,
  socket,
  user,
}) => {
  return (
    <Card className="chat-window" style={{ height: "55vh" }}>
      <CardHeader className="py-3" style={{ backgroundColor: "#efefef" }}>
        <span style={{ fontWeight: "bold", color: "#514f4f" }}>
          Chat Support
        </span>
      </CardHeader>
      <hr style={{ margin: "0" }} />
      <CardBody className="chat-body pr-0 pl-3 pb-1">
        <ScrollToBottom className="message-container">
          {messages.length
            ? messages.map((item, index) => {
                let { content, to_email, date } = item;
                return (
                  <div
                    key={index}
                    className="message"
                    id={to_email ? "you" : "other"}
                  >
                    <div>
                      <div
                        className="message-content"
                        style={{
                          backgroundColor: to_email
                            ? "rgb(55, 107, 126)"
                            : "#f1f1f1",
                          color: to_email ? "white" : "#585858",
                        }}
                      >
                        <p>{content}</p>
                      </div>
                      <div className="message-meta">
                        <p>
                          {new Date(date).toLocaleDateString("en-UK", {
                            day: "numeric",
                            month: "long",
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </ScrollToBottom>
      </CardBody>
      {typing ? (
        <div className="message" id="you" style={{ marginLeft: "12px" }}>
          <div>
            <div className="message-meta">
              <p style={{ fontSize: "0.9em" }}>
                <i>typing...</i>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="message" id="you" style={{ marginLeft: "12px" }}>
          <div>
            <div className="message-meta">
              <p style={{ fontSize: "0.9em" }}>
                <i style={{ opacity: "0" }}>typing...</i>
              </p>
            </div>
          </div>
        </div>
      )}
      <hr style={{ margin: "0" }} />
      <CardFooter
        className="p-0"
        style={{ display: "flex", alignItems: "center", borderRadius: "0" }}
      >
        <input
          type="text"
          placeholder="Write your message here"
          value={messageToSend}
          onChange={(e) => {
            socket.emit("user_typing", {
              typing: e.target.value.length,
              from_user: user.userId,
            });
            setMessageToSend(e.target.value);
          }}
          onKeyPress={(e) => {
            e.key === "Enter" && sendMessage();
          }}
          className="focus-input"
          style={{
            width: "92.5%",
            border: "none",
            padding: "10px 10px 10px 20px",
          }}
        />
        <button
          onClick={sendMessage}
          style={{
            width: "7.5%",
            height: "100%",
            border: "0",
            cursor: "pointer",
            outline: "none",
            fontSize: "25px",
            backgroundColor: "rgb(55, 107, 126)",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            style={{
              fill: "white",
              width: "15px",
            }}
          >
            <path d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"></path>
          </svg>
        </button>
      </CardFooter>
    </Card>
  );
};

export default ChatSection;
