import React from "react";
import { Link } from "react-router-dom";
import { Card, CardHeader, CardBody, Button } from "shards-react";
import WarningIcon from "@mui/icons-material/Warning";
import GppBadIcon from "@mui/icons-material/GppBad";
import FmdBadIcon from "@mui/icons-material/FmdBad";

const MainSiteW3ValidatorOverviewStatusComparison = ({
  mainSiteUrl,
  newScan,
  data,
  comparedData,
}) => {
  const w3Status = data;

  return (
    <Card small className="h-100">
      <CardHeader
        className="border-bottom"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h6 className="d-inline-block m-0">W3 Validator Status</h6>
        <div>
          <Link
            to={{
              pathname: "/w3ValidationDetails",
              state: {
                siteName: mainSiteUrl,
                siteUrl: mainSiteUrl,
                data: w3Status.details,
              },
            }}
          >
            <Button size="sm" className="d-inline-block btn-white mr-2">
              View Full Report &rarr;
            </Button>
          </Link>
          <Button
            size="sm"
            className="d-inline-block btn-info mx-2 btn-small"
            onClick={() => {
              newScan(mainSiteUrl);
            }}
          >
            New Scan
          </Button>
        </div>
      </CardHeader>
      <CardBody className="pt-0">
        <div className="w3status">
          <div
            className="w3status__item arrow-position"
            style={{ paddingLeft: "2.5rem" }}
          >
            <GppBadIcon
              sx={{ color: "#EF4444", marginRight: "8px", fontSize: 30 }}
            />
            {comparedData.length ? (
              comparedData[0].differnce === 0 ? null : (
                <span
                  className={`stats-small__percentage stats-small__percentage--${
                    comparedData[0].value ? "decrease" : "increase"
                  } arrow-position-item-right-left`}
                ></span>
              )
            ) : null}
            Fatal Errors: {w3Status.fatalErrors ? w3Status.fatalErrors : 0}
          </div>
          <div
            className="w3status__item arrow-position"
            style={{ paddingLeft: "2.5rem" }}
          >
            <FmdBadIcon
              sx={{ color: "#EF4444", marginRight: "8px", fontSize: 30 }}
            />
            {comparedData.length ? (
              comparedData[1].differnce === 0 ? null : (
                <span
                  className={`stats-small__percentage stats-small__percentage--${
                    comparedData[1].value ? "decrease" : "increase"
                  } arrow-position-item-right-left`}
                ></span>
              )
            ) : null}
            Errors: {w3Status.errors ? w3Status.errors : 0}
          </div>
          <div
            className="w3status__item arrow-position"
            style={{ paddingLeft: "2.5rem" }}
          >
            <WarningIcon
              sx={{ color: "#ffc107", marginRight: "8px", fontSize: 30 }}
            />
            {comparedData.length ? (
              comparedData[2].differnce === 0 ? null : (
                <span
                  className={`stats-small__percentage stats-small__percentage--${
                    comparedData[2].value ? "decrease" : "increase"
                  } arrow-position-item-right-left`}
                ></span>
              )
            ) : null}
            Warnings: {w3Status.warnings ? w3Status.warnings : 0}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default MainSiteW3ValidatorOverviewStatusComparison;
