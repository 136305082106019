import React from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import CustomSuggestions from "../components/site-details/CustomSuggestions";
import { filterDescription, getLink } from "../helpers/psiData";
import SuggestionInfo from "../components/site-details/SuggestionDetails";

const SuggestionDetails = () => {
  const location = useLocation();
  const suggestion_type = location.state.suggestion_type;
  const suggestion_name = location.state.suggestion_name;
  const suggestion_description = location.state.suggestion_description;
  const suggestion_details = location.state.suggestion_details;
  const techStack = location.state.techStack;

  return (
    <Container fluid className="main-content-container px-4 mb-5">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title={suggestion_name}
          subtitle="Custom Suggestions"
          className="text-sm-left mb-3"
        />
      </Row>

      <Row className="ml-1">
        <h3 className="mt-4 mb-4">PageSpeed Insights Audit</h3>
        <Col sm={12} className="pl-0">
          <p style={{ margin: "0 0 1rem 0" }}>
            {filterDescription(suggestion_description)}
          </p>
          {getLink(suggestion_description)}
          {!suggestion_details ? (
            ""
          ) : (
            <SuggestionInfo
              details={suggestion_details}
              type={suggestion_type}
            />
          )}
        </Col>
      </Row>

      <Row className="ml-1">
        <CustomSuggestions
          suggestion_type={suggestion_type}
          techStack={techStack}
          suggestion_name={suggestion_name}
        />
      </Row>
    </Container>
  );
};

SuggestionDetails.propTypes = {
  /**
   * The small stats dataset.
   */
  smallStats: PropTypes.array,
};

SuggestionDetails.defaultProps = {
  smallStats: [
    {
      label: "LCP",
      value: "-",
      percentage: "0%",
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [4, 4, 3, 3, 3, 2, 1],
        },
      ],
    },
    {
      label: "FID",
      value: "-",
      percentage: "0%",
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(23,198,113,0.1)",
          borderColor: "rgb(23,198,113)",
          data: [4, 4, 3, 3, 3, 2, 1],
        },
      ],
    },
    {
      label: "CLS",
      value: "-",
      percentage: "0%",
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [2, 3, 3, 3, 4, 3, 3],
        },
      ],
    },
  ],
  smallStatsDesktop: [
    {
      label: "LCP",
      value: "-",
      percentage: "0%",
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [4, 4, 3, 3, 3, 2, 1],
        },
      ],
    },
    {
      label: "FCP",
      value: "-",
      percentage: "0%",
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(23,198,113,0.1)",
          borderColor: "rgb(23,198,113)",
          data: [4, 4, 3, 3, 3, 2, 1],
        },
      ],
    },
    {
      label: "CLS",
      value: "-",
      percentage: "0%",
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [2, 3, 3, 3, 4, 3, 3],
        },
      ],
    },
  ],
  labDataMobile: [
    {
      FCP: "0%",
      LCP: "0%",
      SI: "0%",
      CLS: "0%",
      TBT: "0%",
    },
  ],
  labDataDesktop: [
    {
      FCP: "0%",
      LCP: "0%",
      CLS: "0%",
      SI: "0%",
      TBT: "0%",
    },
  ],
  opportunitiesMobile: [
    {
      title: "-",
      description: "-",
    },
  ],
  diagnosticsMobile: [
    {
      title: "-",
      description: "-",
    },
  ],
  lcpElementMobile: [
    {
      title: "-",
      description: "-",
    },
  ],
  clsElementsMobile: [
    {
      title: "-",
      description: "-",
    },
  ],
  opportunitiesDesktop: [
    {
      title: "-",
      description: "-",
    },
  ],
  diagnosticsDesktop: [
    {
      title: "-",
      description: "-",
    },
  ],
  lcpElementDesktop: [
    {
      title: "-",
      description: "-",
    },
  ],
  clsElementsDesktop: [
    {
      title: "-",
      description: "-",
    },
  ],
};

export default SuggestionDetails;
