import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormSelect } from "shards-react";
import PasswordStrengthBar from "react-password-strength-bar";
import {
  register,
  registerGoogle,
  registerMicrosoft,
} from "../state/actions/user.actions";
import logo from "../assets/images/logos/newLogo.svg";
import { GoogleLogin } from "react-google-login";
import { Checkbox } from "@mui/material/";
import {
  GOOGLE_CLIENT_ID,
  MICROSOFT_CLIENT_ID,
  MICROSOFT_REDIRECT_URL,
} from "../constants";
import { msalConfig, loginRequest } from "../helpers/microsoft.config";
import { PublicClientApplication } from "@azure/msal-browser";
import googleIcon from "../assets/images/logos/google.svg";
import microsoft from "../assets/images/logos/microsoft.svg";
import matchIcon from "../assets/images/logos/matchIcon.svg";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import orHr from "../assets/images/logos/or.svg";
import { isMobile } from "react-device-detect";

function RegisterPage() {
  let history = useHistory();
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    username: "",
    password: "",
    password_confirmation: "",
    role: "",
    usePlan: "",
    terms: false,
  });
  const [visible, setVisible] = useState(false);
  const [icon] = useState(eyeOff);

  const [submitted, setSubmitted] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);

  const dispatch = useDispatch();

  const loading = useSelector((state) => state.registration.loading);
  const registered = useSelector((state) => state.registration.user);
  const error = useSelector((state) =>
    state.registration.error ? state.registration.error.errors[0] : null
  );

  // reset login status
  useEffect(() => {
    if (registered) {
      if (registered.suspendedAccount) history.push("/contactUs");
      if (!registered.suspendedAccount) history.push("/dashboard");
    }
  }, [registered]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((user) => ({ ...user, [name]: value }));
  };
  const handleCheckboxChange = (e) => {
    setUser((user) => ({ ...user, terms: e.target.checked }));
  };
  const handleCheckboxChangeLabel = () => {
    setUser((user) => ({ ...user, terms: !user.terms }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);

    if (
      user.firstName &&
      user.lastName &&
      user.username &&
      user.password &&
      user.role &&
      user.usePlan &&
      user.password_confirmation === user.password
    ) {
      dispatch(register(user));
    }
  };
  const handleRegisterGoogle = (googleData) => {
    dispatch(registerGoogle(googleData));
  };

  msalConfig.auth.clientId = MICROSOFT_CLIENT_ID;
  msalConfig.auth.redirectUri = MICROSOFT_REDIRECT_URL;
  const msalInstance = new PublicClientApplication(msalConfig);
  const msRegister = async (e) => {
    e.preventDefault();
    dispatch(registerMicrosoft(msalInstance, loginRequest));
  };

  return (
    <>
      {error && <div className={`alert alert-danger`}>{error}</div>}
      <div className="login-container">
        <div className="d-table m-auto">
          <img
            id="main-logo"
            className="d-inline-block align-top"
            src={logo}
            alt="metryV Logo"
          />
        </div>
        <hr />
        <h2
          style={{
            textAlign: "center",
            fontWeight: "600",
            fontSize: "40px",
            color: "#3D506E",
          }}
          className="mt-2"
        >
          Register
        </h2>
        <br />
        <form name="form">
          <div
            className="form-group"
            style={{
              display: "inline-block",
              width: "100%",
            }}
          >
            <button
              onClick={(e) => msRegister(e)}
              className="btn btn-outline-primary"
              style={{
                marginRight: "20px",
                weight: "800px",
                border: "1px solid #e1e5eb",
                backgroundColor: "#fff",
                color: "#3D506E",
                borderRadius: "10px",
                width: isMobile ? "93%" : "46%",
                fontSize: "14px",
                fontWeight: "600",
                marginBottom: isMobile ? "10px" : "0px",
              }}
            >
              <img
                src={microsoft}
                alt="microsoft"
                style={{
                  width: "20px",
                  height: "20px",
                  margin: "0 7.5px",
                }}
              />
              Continue with Microsoft
            </button>
            <GoogleLogin
              clientId={`${GOOGLE_CLIENT_ID}`}
              buttonText="Sign up with Google"
              onSuccess={handleRegisterGoogle}
              onFailure={handleRegisterGoogle}
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  className="btn btn-outline-danger"
                  style={{
                    border: "1px solid #e1e5eb",
                    backgroundColor: "#fff",
                    color: "#3D506E",
                    borderRadius: "10px",
                    width: isMobile ? "93%" : "46%",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  <img
                    src={googleIcon}
                    alt="google"
                    style={{ width: "20px", height: "20px", margin: "0 7.5px" }}
                  />
                  Continue with Google
                </button>
              )}
              cookiePolicy={"single_host_origin"}
            />
            <br />
          </div>
        </form>
        <img
          src={orHr}
          alt="google"
          style={{
            color: "#818EA3",
            marginBottom: "15px",
            width: "100%",
            height: "100%",
            fontWeight: "600",
            fontSize: "14px",
          }}
        />
        <form name="form">
          <div
            className="form-group"
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <label style={{ width: "52%", color: "#3D506E", fontSize: "15px" }}>
              First name
            </label>
            <label style={{ width: "30%", color: "#3D506E", fontSize: "15px" }}>
              Last name
            </label>
          </div>
        </form>
        <form name="form">
          <div
            className="form-group"
            style={{
              display: "flex",
              width: "95%",
              marginBottom: "4px",
            }}
          >
            <input
              type="text"
              name="firstName"
              value={user.firstName}
              onChange={handleChange}
              className={
                "form-control" +
                (submitted && !user.firstName ? " is-invalid" : "")
              }
              style={{
                borderRadius: "10px",
                marginRight: "20px",
                fontSize: "15px",
              }}
            />
            {submitted && !user.firstName && (
              <div
                className="invalid-feedback"
                style={{
                  paddingRight: "30px",
                  fontSize: "15px",
                  fontWeight: "400",
                }}
              >
                First Name is required
              </div>
            )}
            <input
              type="text"
              name="lastName"
              value={user.lastName}
              onChange={handleChange}
              className={
                "form-control" +
                (submitted && !user.lastName ? " is-invalid" : "")
              }
              style={{
                borderRadius: "10px",
                marginRight: "-20px",
                color: "#3D506E",
                fontSize: "15px",
              }}
            />
            {submitted && !user.lastName && (
              <div
                className="invalid-feedback"
                style={{
                  paddingLeft: "40px",
                  fontSize: "15px",
                  fontWeight: "400",
                }}
              >
                Last Name is required
              </div>
            )}
            <br />
          </div>
        </form>
        <form name="form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label style={{ color: "#3D506E", fontSize: "15px" }}>
              Email address
            </label>
            <input
              type="email"
              name="username"
              value={user.username}
              onChange={handleChange}
              className={
                "form-control" +
                (submitted && !user.username ? " is-invalid" : "")
              }
              style={{
                borderRadius: "10px",
                color: "#3D506E",
                backgroundImage: "none",
                paddingRight: "6px",
                fontSize: "15px",
              }}
            />
            {submitted && !user.username && (
              <div
                className="invalid-feedback"
                style={{
                  fontWeight: "400",
                  fontSize: "15px",
                }}
              >
                <img
                  src={matchIcon}
                  alt="matchIcon"
                  style={{
                    width: "18px",
                    height: "18px",
                    margin: "0 7.5px",
                    marginTop: "-2px",
                  }}
                />
                Email address is required
              </div>
            )}
          </div>
          <div className="form-group">
            <label style={{ color: "#3D506E", fontSize: "15px" }}>
              Password
            </label>
            <input
              type={visible ? "text" : "password"}
              name="password"
              value={user.password}
              onChange={handleChange}
              className={
                "form-control" +
                (submitted && !user.password ? " is-invalid" : "")
              }
              style={{
                borderRadius: "10px",
                display: "flex",
                color: "#3D506E",
                backgroundImage: "none",
                paddingRight: "6px",
                fontSize: "15px",
              }}
            />
            <span
              onClick={() => setVisible(!visible)}
              style={{
                marginTop: "-32px",
                display: "flex",
                marginRight: "5px",
                float: "right",
                justifyContent: "end",
              }}
            >
              {!visible ? (
                <Icon
                  icon={icon}
                  style={{
                    width: "22px",
                    height: "20.02px",
                    marginRight: "10px",
                  }}
                ></Icon>
              ) : (
                <Icon
                  icon={eye}
                  style={{
                    width: "22px",
                    height: "20.02px",
                    marginRight: "10px",
                  }}
                ></Icon>
              )}
            </span>
            {submitted && !user.password && (
              <div
                className="invalid-feedback"
                style={{
                  fontWeight: "400",
                  fontSize: "15px",
                  width: "91%",
                }}
              >
                <img
                  src={matchIcon}
                  alt="matchIcon"
                  style={{
                    width: "18px",
                    height: "18px",
                    margin: "0 7.5px",
                    marginTop: "-2px",
                  }}
                />
                Password is required
              </div>
            )}
            <PasswordStrengthBar
              password={user.password}
              onChangeScore={(score) => {
                if (score > 2) {
                  setDisabledBtn(false);
                }
              }}
            />
            <div
              className="password-info"
              style={{ fontWeight: "400", marginTop: "-5px" }}
            >
              Password should contain at least 8 characters, one capital letter,
              one number and a special character.
            </div>
          </div>
          <div className="form-group">
            <label style={{ color: "#3D506E", fontSize: "15px" }}>
              Confirm password
            </label>
            <input
              type={visible ? "text" : "password"}
              name="password_confirmation"
              value={user.password_confirmation}
              onChange={handleChange}
              className={
                "form-control" +
                (submitted && user.password_confirmation !== user.password
                  ? " is-invalid"
                  : "")
              }
              style={{
                borderRadius: "10px",
                color: "#3D506E",
                backgroundImage: "none",
                paddingRight: "6px",
                fontSize: "15px",
              }}
            />
            <span
              onClick={() => setVisible(!visible)}
              style={{
                marginTop: "-32px",
                display: "flex",
                float: "right",
                marginRight: "5px",
                justifyContent: "end",
              }}
            >
              {!visible ? (
                <Icon
                  icon={icon}
                  style={{
                    width: "22px",
                    height: "20.02px",
                    marginRight: "10px",
                  }}
                ></Icon>
              ) : (
                <Icon
                  icon={eye}
                  style={{
                    width: "22px",
                    height: "20.02px",
                    marginRight: "10px",
                  }}
                ></Icon>
              )}
            </span>
            {submitted && user.password_confirmation !== user.password && (
              <div
                className="invalid-feedback"
                style={{
                  fontWeight: "400",
                  fontSize: "12px",
                }}
              >
                <img
                  src={matchIcon}
                  alt="matchIcon"
                  style={{
                    width: "18px",
                    height: "18px",
                    margin: "0 7.5px",
                    marginTop: "-2px",
                  }}
                />
                Password don&apos;t match.{" "}
                <b style={{ fontWeight: "600" }}>Please try again!</b>
              </div>
            )}
          </div>
          <div className="form-group">
            <label style={{ color: "#3D506E", fontSize: "15px" }}>
              Your role on the team?
            </label>
            <FormSelect
              type="role"
              name="role"
              value={user.role}
              onChange={handleChange}
              className={
                "form-control" + (submitted && !user.role ? " is-invalid" : "")
              }
              style={{
                borderRadius: "10px",
                backgroundImage: "none",
                paddingRight: "6px",
                fontSize: "15px",
              }}
            >
              <option
                style={{
                  fontSize: "15px",
                  fontWeight: "400",
                  color: "#818EA3",
                }}
              >
                Choose here
              </option>
              <option>Developer</option>
              <option>Team Lead</option>
              <option>Product Owner</option>
              <option>Project Manager</option>
              <option>Tech Lead</option>
              <option>CEO</option>
              <option>Other</option>
            </FormSelect>
            {submitted && !user.role && (
              <div
                className="invalid-feedback"
                style={{
                  fontWeight: "400",
                  fontSize: "15px",
                }}
              >
                <img
                  src={matchIcon}
                  alt="matchIcon"
                  style={{
                    width: "18px",
                    height: "18px",
                    margin: "0 7.5px",
                    marginTop: "-2px",
                  }}
                />
                Role is required
              </div>
            )}
          </div>
          <div className="form-group">
            <label style={{ color: "#3D506E", fontSize: "15px" }}>
              What do you plan to use metryV for?
            </label>
            <FormSelect
              type="usePlan"
              name="usePlan"
              value={user.usePlan}
              onChange={handleChange}
              className={
                "form-control" +
                (submitted && !user.usePlan ? " is-invalid" : "")
              }
              style={{
                borderRadius: "10px",
                color: "#3D506E",
                backgroundImage: "none",
                paddingRight: "6px",
                fontSize: "15px",
              }}
            >
              <option
                style={{
                  fontSize: "15px",
                  fontWeight: "400",
                  color: "#818EA3",
                }}
              >
                Choose here
              </option>
              <option>Speed up my site</option>
              <option>Get my PageSpeed Insights score up</option>
              <option>Provide the best User Experience to my users</option>
              <option>Monitoring Web Vitals</option>
              <option>
                Get an overview of what I should be working on to improve Web
                Vitals
              </option>
              <option>Get help and suggestions</option>
              <option>Other</option>
            </FormSelect>
            {submitted && !user.usePlan && (
              <div
                className="invalid-feedback"
                style={{
                  fontWeight: "400",
                  fontSize: "15px",
                  width: "89%",
                }}
              >
                <img
                  src={matchIcon}
                  alt="matchIcon"
                  style={{
                    width: "18px",
                    height: "18px",
                    margin: "0 7.5px",
                    marginTop: "-2px",
                  }}
                />
                Use plan is required
              </div>
            )}
          </div>
          <div className="form-group">
            <span
              style={{ cursor: "pointer", userSelect: "none" }}
              onClick={handleCheckboxChangeLabel}
            >
              <Checkbox
                checked={user.terms}
                onChange={handleCheckboxChange}
                name="terms"
                size="small"
                style={{
                  padding: "0 5px 0 0",
                  border: "1px #818EA3",
                  weight: "24px",
                  height: "24px",
                  borderRadius: "6px",
                  boxSizing: "border-box",
                }}
              />
              <a
                style={{
                  fontWeight: "400",
                  color: "#3D506E",
                  fontSize: "15px",
                }}
              >
                {" "}
                I agree to the{" "}
              </a>
              <a
                href="https://metryv.com/terms-and-conditions/"
                style={{
                  textDecorationLine: "underline",
                  fontWeight: "600",
                  color: "#0087F9",
                }}
              >
                terms and conditions
              </a>
            </span>
          </div>
          <div className="form-group">
            <button
              className="form-control"
              style={{
                backgroundColor: "#0087F9",
                color: "white",
                borderRadius: "6px",
                fontSize: "14px",
                fontWeight: "600",
              }}
              disabled={disabledBtn}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}
              Register
            </button>
            <hr style={{ marginBottom: "5px" }} />
            <a
              style={{ fontWeight: "400", color: "#3D506E", fontSize: "15px" }}
            >
              Already have an account?
            </a>
            <Link
              to="/login"
              className="btn btn-link"
              style={{
                textDecorationLine: "underline",
                fontWeight: "600",
                fontSize: "15px",
                lineHeight: "20px",
                color: "#0087F9",
                paddingLeft: "5px",
                marginTop: "-5px",
              }}
            >
              Log in
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}

export { RegisterPage };
