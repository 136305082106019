import React, { useState, useEffect } from "react";
import { Row, Col } from "shards-react";
import { getOverallScoreColorSiteDetails } from "../../helpers/scores";
import FinalScreenshotComparison from "./FinalScreenshotComparison";
import MainFinalScreenshotComparison from "./MainFinalScreenshotComparison";
import { API_URL_PSI } from "../../constants";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";

const PageSpeedScore = ({ siteUrl, mainSiteUrl }) => {
  const [overallScore, setOverallScore] = useState([]);
  const [mainSiteOverallScore, setMainSiteOverallScore] = useState([]);
  const [finalScreenshot, setFinalScreenshot] = useState("");
  const [finalScreenshotDesktop, setFinalScreenshotDesktop] = useState("");
  const [mainSiteFinalScreenshot, setMainSiteFinalScreenshot] = useState("");
  const [
    mainSiteFinalScreenshotDesktop,
    setMainSiteFinalScreenshotDesktop,
  ] = useState("");

  const [overallScoreLoading, setOverallScoreLoading] = useState(false);
  const [
    mainSiteOverallScoreLoading,
    setMainSiteOverallScoreLoading,
  ] = useState(false);

  const [overallScoreError, setOverallScoreError] = useState(false);
  const [mainSiteOverallScoreError, setMainSiteOverallScoreError] = useState(
    false
  );

  const getLatestData = async (siteUrl) => {
    try {
      setOverallScoreError(false);
      setOverallScoreLoading(true);
      const response = await axios.get(
        `${API_URL_PSI}/scan/latest`,
        {
          params: {
            url: siteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      let data = response.data;
      const screenshot = await getScreenshots(siteUrl);
      if (screenshot) {
        setFinalScreenshot(screenshot.mobile.finalScreenshot);
        setFinalScreenshotDesktop(screenshot.desktop.finalScreenshot);
      }
      setOverallScore({
        Mobile: data.mobile.overallScore,
        Desktop: data.desktop.overallScore,
      });
      setOverallScoreLoading(false);
      setOverallScoreError(false);
    } catch (error) {
      setOverallScoreLoading(false);
      setOverallScoreError(true);
      console.log(error);
    }
  };

  const getMainSiteLatestData = async (mainSiteUrl) => {
    try {
      setMainSiteOverallScoreError(false);
      setMainSiteOverallScoreLoading(true);
      const response = await axios.get(
        `${API_URL_PSI}/scan/latest`,
        {
          params: {
            url: mainSiteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      let data = response.data;
      const screenshot = await getScreenshots(mainSiteUrl);
      if (screenshot) {
        setMainSiteFinalScreenshot(screenshot.mobile.finalScreenshot);
        setMainSiteFinalScreenshotDesktop(screenshot.desktop.finalScreenshot);
      }
      setMainSiteOverallScore({
        Mobile: data.mobile.overallScore,
        Desktop: data.desktop.overallScore,
      });
      setMainSiteOverallScoreError(false);
      setMainSiteOverallScoreLoading(false);
    } catch (error) {
      setMainSiteOverallScoreError(true);
      setMainSiteOverallScoreLoading(false);
      console.log(error);
    }
  };

  const getScreenshots = async (siteUrl) => {
    try {
      const response = await axios.post(
        `${API_URL_PSI}/scan/suggestions`,
        {
          data: {
            url: siteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      return error;
    }
  };

  useEffect(async () => {
    await getLatestData(siteUrl);
  }, [siteUrl]);

  useEffect(async () => {
    await getMainSiteLatestData(mainSiteUrl);
  }, [mainSiteUrl]);

  return (
    <>
      <Row>
        <h3 className="text-sm-left ml-3 heading-3 mb-4">Overall Score</h3>
      </Row>
      <hr />
      <Row>
        <Col
          className="col-lg-3 col-md-6 col-sm-6 mb-4"
          style={{ order: "1", marginLeft: "-50px", maxWidth: "20%" }}
        >
          <h4
            className="text-sm-left heading-3 mb-4"
            style={{ order: "1", marginLeft: "40px" }}
          >
            {siteUrl}
          </h4>
        </Col>
        <Col
          className="col-lg-3 col-md-6 col-sm-6 mb-4"
          style={{ order: "3", maxWidth: "20%" }}
        >
          <h4
            className="text-sm-left heading-3 mb-4"
            style={{ order: "3", marginLeft: "350px" }}
          >
            {mainSiteUrl}
          </h4>
        </Col>
      </Row>
      <Row>
        {overallScoreLoading ? (
          <Col className="col-lg-6 col-12 mb-4">
            <CircularProgress
              style={{ margin: "2rem", height: "140px", width: "140px" }}
            />
            <CircularProgress
              style={{ margin: "2rem", height: "140px", width: "140px" }}
            />
          </Col>
        ) : overallScoreError ? (
          <h4 className="text-center mt-3">No Data</h4>
        ) : (
          <>
            <Col
              className="col-lg-3 col-md-6 col-sm-6 pl-0 d-flex d-md-block mb-4 justify-content-center"
              style={{ order: "1", maxWidth: "20%", marginLeft: "30px" }}
            >
              <h4
                className="text-center mt-3"
                style={{ marginLeft: "-70px", marginBottom: "20px" }}
              >
                Mobile
              </h4>
              {!finalScreenshot ? (
                ""
              ) : (
                <FinalScreenshotComparison
                  finalScreenshot={finalScreenshot}
                  device="mobile"
                />
              )}
            </Col>
            <Col
              className="pl-0 d-flex d-md-block mb-4justify-content-center"
              style={{ order: "3", marginLeft: "350px" }}
            >
              <h4
                className="text-center mt-3"
                style={{ marginLeft: "-400px", marginBottom: "20px" }}
              >
                Mobile
              </h4>
              {!mainSiteFinalScreenshot ? (
                ""
              ) : (
                <MainFinalScreenshotComparison
                  finalScreenshot={mainSiteFinalScreenshot}
                  device="mobile"
                />
              )}
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Col
          className="col-lg-3 col-md-6 col-sm-6 mb-4"
          style={{ order: "1", maxWidth: "20%" }}
        >
          <div
            className={
              "c100 big center p" +
              Math.round(parseInt(overallScore.Mobile)) +
              getOverallScoreColorSiteDetails(
                Math.round(parseInt(overallScore.Mobile))
              )
            }
            style={{ fontSize: "170px" }}
          >
            <span>{Math.round(parseInt(overallScore.Mobile))}</span>
            <div className="slice">
              <div className="bar"></div>
              <div className="fill"></div>
            </div>
          </div>
        </Col>
        <Col
          className="col-lg-3 col-md-6 col-sm-6 mb-4"
          style={{
            order: "3",
            maxWidth: "20%",
            paddingLeft: "80px",
            marginLeft: "300px",
          }}
        >
          <div
            className={
              "c100 big center p" +
              Math.round(parseInt(mainSiteOverallScore.Mobile)) +
              getOverallScoreColorSiteDetails(
                Math.round(parseInt(mainSiteOverallScore.Mobile))
              )
            }
            style={{ fontSize: "170px" }}
          >
            <span>
              {!mainSiteOverallScore.Mobile
                ? ""
                : Math.round(parseInt(mainSiteOverallScore.Mobile))}
            </span>
            <div className="slice">
              <div className="bar"></div>
              <div className="fill"></div>
            </div>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: "30px" }}>
        {mainSiteOverallScoreLoading ? (
          <Col className="col-lg-6 col-12 mb-4">
            <CircularProgress
              style={{ margin: "2rem", height: "140px", width: "140px" }}
            />
            <CircularProgress
              style={{ margin: "2rem", height: "140px", width: "140px" }}
            />
          </Col>
        ) : mainSiteOverallScoreError ? (
          <h4 className="text-center mt-3">No Data</h4>
        ) : (
          <>
            <Col
              className="pl-0 d-flex d-md-block mb-4 justify-content-center"
              style={{ order: "1" }}
            >
              <h4
                className="text-center mt-3"
                style={{ marginLeft: "-360px", marginBottom: "20px" }}
              >
                Desktop
              </h4>
              {!finalScreenshotDesktop ? (
                ""
              ) : (
                <FinalScreenshotComparison
                  finalScreenshot={finalScreenshotDesktop}
                  device="desktop"
                />
              )}
            </Col>
            <Col
              className="col-md-6 col-sm-6 pl-0 d-flex d-md-block mb-4 ml-auto mr-auto justify-content-center"
              style={{ order: "3" }}
            >
              <h4
                className="text-center mt-3"
                style={{ marginLeft: "-360px", marginBottom: "20px" }}
              >
                Desktop
              </h4>
              {!mainSiteFinalScreenshotDesktop ? (
                ""
              ) : (
                <MainFinalScreenshotComparison
                  finalScreenshot={mainSiteFinalScreenshotDesktop}
                  device="Desktop"
                />
              )}
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Col
          className="col-lg-3 col-md-6 col-sm-6 mb-4"
          style={{ order: "1", maxWidth: "20%" }}
        >
          <div
            className={
              "c100 big center p" +
              Math.round(parseInt(overallScore.Desktop)) +
              getOverallScoreColorSiteDetails(
                Math.round(parseInt(overallScore.Desktop))
              )
            }
            style={{ fontSize: "170px" }}
          >
            <span>{Math.round(parseInt(overallScore.Desktop))}</span>
            <div className="slice">
              <div className="bar"></div>
              <div className="fill"></div>
            </div>
          </div>
        </Col>{" "}
        <Col
          className="col-lg-3 col-md-6 col-sm-6 mb-4"
          style={{
            order: "3",
            maxWidth: "20%",
            paddingLeft: "80px",
            marginLeft: "300px",
          }}
        >
          <div
            className={
              "c100 big center p" +
              Math.round(parseInt(mainSiteOverallScore.Desktop)) +
              getOverallScoreColorSiteDetails(
                Math.round(parseInt(mainSiteOverallScore.Desktop))
              )
            }
            style={{ fontSize: "170px" }}
          >
            <span>
              {!mainSiteOverallScore.Desktop
                ? ""
                : Math.round(parseInt(mainSiteOverallScore.Desktop))}
            </span>
            <div className="slice">
              <div className="bar"></div>
              <div className="fill"></div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PageSpeedScore;
