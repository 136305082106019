import React, { useState } from "react";
import { Card, CardHeader, CardBody, Button } from "shards-react";
import GppGoodIcon from "@mui/icons-material/GppGood";
import WarningIcon from "@mui/icons-material/Warning";
import GppBadIcon from "@mui/icons-material/GppBad";
import { useDispatch } from "react-redux";
import { getUptimeStatusData } from "../../state/actions/psi.actions";
import { API_URL_PSI } from "../../constants";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";

const UptimeOverviewGraph = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const newScan = async (siteUrl) => {
    setLoading(true);
    await axios
      .post(
        `${API_URL_PSI}/uptime`,
        {
          url: siteUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        dispatch(getUptimeStatusData(siteUrl));
        setLoading(false);
      })
      .catch((error) => {
        dispatch(getUptimeStatusData(siteUrl));
        setLoading(false);
        console.log(error);
      });
  };

  const getUptimePercentage = () => {
    let data = props.data;
    var count = 0;
    data.forEach((v) => v === 2 && count++);
    return (100 * count) / data.length;
  };

  const getUptimeStatus = () => {
    const percentage = getUptimePercentage();
    let status = "average";
    let message = "Partial Outages";
    if (percentage > 99) {
      message = "Operational";
      status = "good";
    } else if (percentage < 80) {
      message = "Major Outages";
      status = "bad";
    }
    return [status, message];
  };

  return (
    <Card small className="h-100">
      <CardHeader
        className="border-bottom"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h6 className="d-inline-block m-0">Web Uptime Overview</h6>

        <div
          className="uptime__status"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>
            {getUptimeStatus()[0] == "good" ? (
              <GppGoodIcon sx={{ color: "#10B981" }} />
            ) : getUptimeStatus()[0] == "bad" ? (
              <GppBadIcon sx={{ color: "#EF4444" }} />
            ) : (
              <WarningIcon sx={{ color: "#ffc107" }} />
            )}
            <span className={"uptime__status--" + getUptimeStatus()[0]}>
              {getUptimeStatus()[1]}
            </span>
          </div>
          <Button
            size="sm"
            className="d-inline-block btn-info ml-2 btn-small"
            onClick={() => {
              newScan(props.siteUrl);
            }}
          >
            New Scan
          </Button>
        </div>
      </CardHeader>
      <CardBody
        className="pt-0"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress
              style={{ margin: "2rem", height: "140px", width: "140px" }}
            />
            <div>Loading Uptime Status</div>
          </div>
        ) : (
          <>
            <div className="uptime">
              {props.data && props.data.length > 0
                ? props.data.map((item, index) =>
                    item == 2 ? (
                      <span key={index} className="uptime__up"></span>
                    ) : (
                      <span key={index} className="uptime__down"></span>
                    )
                  )
                : "Not enough data for the moment"}
            </div>
            <div className="uptime__legend">
              <div>{props.data.length} hours ago</div>
              <div>
                {getUptimePercentage() ? (
                  <span>{getUptimePercentage().toFixed(2)}% Uptime</span>
                ) : (
                  ""
                )}
              </div>
              <div>Last hour</div>
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default UptimeOverviewGraph;
