import React, { useState, useEffect } from "react";
import axios from "axios";
import { Checkbox, CircularProgress } from "@mui/material/";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import { getSitesStatusData } from "../../state/actions/psi.actions";
import { getData } from "../../state/actions/sites.actions";
import { API_URL_PSI, API_URL } from "../../constants";
import { Button } from "shards-react";

const PagesTable = ({ propertyId }) => {
  const [alreadyAddedPages, setAlreadyAddedPages] = useState([]);
  const [selectedPages, setSelectedPages] = useState([]);
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadmore, setLoadmore] = useState(10);
  const [totals, setTotals] = useState({
    conversions: 0,
    eventCount: 0,
    newUsers: 0,
    screenPageViews: 0,
    screenPageViewsPerSession: 0,
    totalRevenue: 0,
    users: 0,
    views: 0,
    averageSessionDuration: 0,
    bounceRate: 0,
  });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const currentPlan = useSelector((state) => state.plan.plan);
  const user = useSelector((state) =>
    state.authentication.user
      ? state.authentication.user
      : state.registration.user
  );
  const sites = useSelector((state) => state.sites.sites);

  const triggerPsiScan = async (pageUrl) => {
    const request_body = {
      email: user.rootUser ? user.rootUser : user.email,
      url: pageUrl,
    };
    await axios
      .post(`${API_URL_PSI}/scan/`, request_body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(() => {
        enqueueSnackbar(`Scan finished for: ${pageUrl}`, {
          variant: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        const errorSnackbar = enqueueSnackbar(`Scan failed for: ${pageUrl}`, {
          variant: "error",
        });
        setTimeout(() => {
          closeSnackbar(errorSnackbar);
        }, 5000);
      });
  };

  const addOtherPages = async () => {
    let pagesArray = [];
    sites?.forEach((site) => {
      if (site.url === localStorage.getItem("tabUrl").replaceAll("'", "")) {
        pagesArray.push(...site.pages);
      }
    });
    if (!currentPlan?.page_unlimited) {
      if (
        selectedPages.length + selectedPages.length <=
        currentPlan?.page_limit
      ) {
        await saveOtherPages();
      } else {
        const notAllowedToAddMorePagesSnackbar = enqueueSnackbar(
          `You're not allowed to add more than ${currentPlan?.page_limit} pages per site!`,
          {
            variant: "warning",
          }
        );
        setTimeout(() => {
          closeSnackbar(notAllowedToAddMorePagesSnackbar);
        }, 3500);
      }
    } else {
      await saveOtherPages();
    }
  };

  const triggerOtherPagesScan = async () => {
    if (selectedPages.length) {
      const parallelScans = 3;
      for (
        let i = 0;
        i < Math.ceil(selectedPages.length / parallelScans);
        i++
      ) {
        const pageUrls = selectedPages.slice(
          i * parallelScans,
          i * parallelScans + parallelScans
        );
        await Promise.all(
          pageUrls.map(async (pageUrl) => {
            const pagesSnackbar = enqueueSnackbar(
              `Scan triggered for: ${pageUrl}`,
              {
                variant: "info",
                persist: true,
              }
            );
            await triggerPsiScan(pageUrl);
            closeSnackbar(pagesSnackbar);
          })
        );
      }
    }
  };

  const saveOtherPages = async () => {
    const request_body = {
      email: user.rootUser ? user.rootUser : user.email,
      site: { url: localStorage.getItem("tabUrl").replaceAll('"', "") },
      pages: selectedPages,
    };
    try {
      await triggerOtherPagesScan();
      await axios.post(`${API_URL}/sites/addPages`, request_body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const pagesHasBeenAddedSuccessFullySnackbarGA = enqueueSnackbar(
        `Pages has been added successfully ${request_body.site.url}`,
        {
          variant: "success",
        }
      );
      setTimeout(() => {
        closeSnackbar(pagesHasBeenAddedSuccessFullySnackbarGA);
      }, 5000);
      dispatch(getData(user.rootUser ? user.rootUser : user.email))
        .then((response) => {
          if (response.length) {
            let urls = [];
            response.forEach((item) => {
              urls.push(item.url);
            });
            dispatch(getSitesStatusData(urls));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        alert(error.response.data);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
    }
  };

  const handleChangePages = (e) => {
    if (!e.target.checked) {
      setSelectedPages((data) => [
        ...data.filter((item) => item !== e.target.value),
      ]);
    } else {
      setSelectedPages([...selectedPages, e.target.value]);
    }
  };

  const getPages = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${API_URL_PSI}/analytics-overall-by-page`,
        {
          propertyId: propertyId.toString(),
        }
      );
      setLoading(false);
      setPages(data);

      let conversions = 0;
      let eventCount = 0;
      let newUsers = 0;
      let screenPageViews = 0;
      let screenPageViewsPerSession = 0;
      let totalRevenue = 0;
      let users = 0;
      let views = 0;
      let averageSessionDuration = 0;
      let bounceRate = 0;

      data.forEach((item) => {
        conversions += Number(item.conversions);
        eventCount += Number(item.eventCount);
        newUsers += Number(item.newUsers);
        screenPageViews += Number(item.screenPageViews);
        screenPageViewsPerSession += Number(item.screenPageViewsPerSession);
        totalRevenue += Number(item.totalRevenue);
        users += Number(item.users);
        views += Number(item.views);
        averageSessionDuration += Number(item.averageSessionDuration);
        bounceRate += Number(item.bounceRate);
      });

      setTotals({
        conversions,
        eventCount,
        newUsers,
        screenPageViews,
        screenPageViewsPerSession,
        totalRevenue,
        users,
        views,
        averageSessionDuration,
        bounceRate,
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(async () => {
    await getPages();
  }, [propertyId]);

  useEffect(() => {
    let pagesArray = [];
    for (const site of sites) {
      if (site.url === localStorage.getItem("tabUrl").replaceAll('"', "")) {
        pagesArray.push(...site.pages);
      }
    }
    setAlreadyAddedPages(pagesArray);
  }, []);

  return (
    <>
      <h2 className="heading-dashboard ml-3 my-3">Top Viewed Pages</h2>
      <table className="table mb-0">
        <thead className="bg-light">
          <tr>
            <th scope="col" className="border-0">
              #
            </th>
            <th scope="col" className="border-0"></th>
            <th scope="col" className="border-0">
              Page Title
            </th>
            <th scope="col" className="border-0" style={{ textAlign: "right" }}>
              Views
            </th>
            <th scope="col" className="border-0" style={{ textAlign: "right" }}>
              Total Users
            </th>
            <th scope="col" className="border-0" style={{ textAlign: "right" }}>
              New Users
            </th>
            <th scope="col" className="border-0" style={{ textAlign: "right" }}>
              Views per session
            </th>
            <th scope="col" className="border-0" style={{ textAlign: "right" }}>
              Average session duration
            </th>
            <th scope="col" className="border-0" style={{ textAlign: "right" }}>
              Bounce Rate
            </th>
            <th scope="col" className="border-0" style={{ textAlign: "right" }}>
              Event Count
            </th>
            <th scope="col" className="border-0" style={{ textAlign: "right" }}>
              Conversions
            </th>
            <th scope="col" className="border-0" style={{ textAlign: "right" }}>
              Total Revenue
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <th></th>
            <th>Totals</th>
            <th style={{ textAlign: "right" }}>{totals.views}</th>
            <th style={{ textAlign: "right" }}>{totals.users}</th>
            <th style={{ textAlign: "right" }}>{totals.newUsers}</th>
            <th style={{ textAlign: "right" }}>
              {Number(totals.screenPageViewsPerSession).toFixed(2)}
            </th>
            <th style={{ textAlign: "right" }}>
              {Math.floor(totals.averageSessionDuration / 60)}m{" "}
              {Number(
                (
                  totals.averageSessionDuration -
                  Math.floor(totals.averageSessionDuration / 60) * 60
                ).toFixed(2)
              ).toFixed(0).length < 2
                ? "0"
                : ""}
              {(
                totals.averageSessionDuration -
                Math.floor(totals.averageSessionDuration / 60) * 60
              ).toFixed(2)}
              s
            </th>
            <th style={{ textAlign: "right" }}>
              {((totals.bounceRate * 100) / pages.length).toFixed(2)}% (avg)
            </th>
            <th style={{ textAlign: "right" }}>{totals.eventCount}</th>
            <th style={{ textAlign: "right" }}>
              {Number(totals.conversions).toFixed(2)}
            </th>
            <th style={{ textAlign: "right" }}>
              &euro;{Number(totals.totalRevenue).toFixed(2)}
            </th>
          </tr>
          {loading ? (
            <CircularProgress
              style={{
                margin: "auto",
                height: "140px",
                width: "140px",
                padding: "10px",
              }}
            />
          ) : pages.length ? (
            pages.slice(0, loadmore).map((page, index) => {
              let {
                conversions,
                eventCount,
                pagePath,
                newUsers,
                screenPageViews,
                screenPageViewsPerSession,
                totalRevenue,
                users,
                views,
                averageSessionDuration,
                bounceRate,
              } = page;
              let minutes = Math.floor(averageSessionDuration / 60);
              let seconds = (averageSessionDuration - minutes * 60).toFixed(2);
              return (
                <tr key={index} className="fadeInEffect">
                  <td>{index + 1}</td>
                  <td>
                    {index === 0 ? (
                      <></>
                    ) : alreadyAddedPages.includes(
                        localStorage.getItem("tabUrl").replaceAll('"', "") +
                          pagePath.substring(1)
                      ) ? (
                      <Checkbox
                        size="small"
                        style={{ padding: "0 5px 0 0" }}
                        disabled={true}
                      />
                    ) : (
                      <Checkbox
                        size="small"
                        style={{ padding: "0 5px 0 0" }}
                        type="checkbox"
                        onChange={handleChangePages}
                        value={
                          localStorage.getItem("tabUrl").replaceAll('"', "") +
                          pagePath.substring(1)
                        }
                      />
                      // <input
                      // />
                    )}
                  </td>
                  <th style={{ minWidth: "314px" }}>{screenPageViews}</th>
                  <td style={{ textAlign: "right" }}>{views}</td>
                  <td style={{ textAlign: "right" }}>{users}</td>
                  <td style={{ textAlign: "right" }}>{newUsers}</td>
                  <td style={{ textAlign: "right" }}>
                    {Number(screenPageViewsPerSession).toFixed(2)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {minutes}m{" "}
                    {Number(seconds).toFixed(0).length < 2 ? "0" : ""}
                    {seconds}s
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {(bounceRate * 100).toFixed(2)}%
                  </td>
                  <td style={{ textAlign: "right" }}>{eventCount}</td>
                  <td style={{ textAlign: "right" }}>
                    {Number(conversions).toFixed(2)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    &euro;{Number(totalRevenue).toFixed(2)}
                  </td>
                </tr>
              );
            })
          ) : null}
        </tbody>
      </table>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <hr style={{ width: "2%" }} />
        <Button
          size="sm"
          theme="secondary"
          outline={true}
          disabled={
            selectedPages.length === 0
              ? true
              : selectedPages.length > currentPlan?.page_limit &&
                !currentPlan?.page_unlimited
              ? true
              : false
          }
          onClick={() => {
            addOtherPages();
          }}
        >
          Add Pages
        </Button>
        <hr style={{ width: "35%" }} />
        <span
          onClick={() =>
            setLoadmore((data) => {
              if (data === 10) {
                return pages.length;
              } else {
                return 10;
              }
            })
          }
          style={{
            width: "10%",
            cursor: "pointer",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {loadmore !== 10 ? "Show Less" : "Show More"}
        </span>
        <hr style={{ width: "43%" }} />
      </div>
    </>
  );
};

export default PagesTable;
