import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Description from "../components/web-vitals-support/Description";
import ChatSection from "../components/web-vitals-support/Chatbot/ChatSection";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { FormControlLabel, Switch } from "@mui/material";
import { getUserPlanData } from "../state/actions/user.actions";

const serviceMessages = [
  "Web Vitals Support",
  "Improve Web Vitals",
  "Tools to Measure Web Vitals",
  "Web Performance Optimization",
  "Pricing",
  "Supported Technologies",
  "Customer Support",
];

const WebVitalsSupport = ({ socket }) => {
  // const WebVitalsSupport = () => {
  const [messageToSend, setMessageToSend] = useState("");
  const [messages, setMessages] = useState([]);
  const [typing, setTyping] = useState(false);
  const [suggestions, setSuggestions] = useState(false);
  const dispatch = useDispatch();

  const user = useSelector((state) =>
    state.authentication.user
      ? state.authentication.user
      : state.registration.user
  );

  const sendMessage = async () => {
    try {
      if (messageToSend) {
        await axios.post(
          `${process.env.REACT_APP_BACKEND_CHAT_URL}/send-message`,
          {
            content: messageToSend,
            from_user: user.userId,
            from_email: user.email,
            read: false,
            date: new Date(),
          }
        );
        socket.emit("send_msg_to_admin", {
          content: messageToSend,
          from_user: user.userId,
          from_email: user.email,
          read: false,
          date: new Date(),
        });
        socket.emit("user_typing", {
          typing: 0,
          from_user: user.userId,
        });
        setMessages((prevData) => [
          ...prevData,
          {
            content: messageToSend,
            from_user: user.userId,
            from_email: user.email,
            read: false,
            date: new Date(),
          },
        ]);
        setMessageToSend("");
        setTyping(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sendMessageFromServices = async (message) => {
    console.log(message);
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_CHAT_URL}/send-message`,
        {
          content: message,
          from_user: user.userId,
          from_email: user.email,
          read: false,
          date: new Date(),
        }
      );
      socket.emit("send_msg_to_admin", {
        content: message,
        from_user: user.userId,
        from_email: user.email,
        read: false,
        date: new Date(),
      });
      setMessages((prevData) => [
        ...prevData,
        {
          content: message,
          from_user: user.userId,
          from_email: user.email,
          read: false,
          date: new Date(),
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_CHAT_URL}/get-messages-user/${user.userId}`
      );
      let prevData = response.data;
      setMessages(() => [...prevData]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    socket.on("recieve_msg_to_user", (data) => {
      setMessages((prevData) => [
        ...prevData,
        {
          content: data.content,
          to_user: data.to_user,
          to_email: data.to_email,
          read: false,
          date: data.date,
        },
      ]);
    });
    socket.on("typing", (data) => {
      setTyping(data.typing ? true : false);
    });
    return () => {
      socket.off("recieve_msg_to_user");
      socket.off("typing", () => {
        setTyping(false);
      });
      setMessages([]);
    };
  }, [socket]);

  useEffect(() => {
    if (user.userId) {
      fetchData();
      return () => {
        setMessages([]);
      };
    }
  }, [user]);

  useEffect(() => {
    dispatch(getUserPlanData(user.userId));
    setMessages([]);
    setTyping(false);
  }, []);

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Web Vitals Support"
          subtitle="metryv"
          md="12"
          className="ml-sm-auto mr-sm-auto"
        />
      </Row>
      <Row>
        <Col lg="4">
          <Description />
        </Col>
        <Col lg="8" className="pb-4">
          <Row className="col-lg-12 m-0 p-0">
            <ChatSection
              sendMessage={sendMessage}
              messages={messages}
              messageToSend={messageToSend}
              setMessageToSend={setMessageToSend}
              typing={typing}
              socket={socket}
              user={user}
            />
          </Row>
          <Row className="m-0 px-0 pt-3">
            <Col
              lg="12"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <FormControlLabel
                style={{ margin: "0" }}
                control={
                  <Switch
                    checked={suggestions}
                    onChange={() => setSuggestions((prevState) => !prevState)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Suggestions"
              />
            </Col>
          </Row>
          <Row className="m-0 px-0 py-3">
            {suggestions &&
              serviceMessages.map((item, index) => (
                <Card
                  key={index}
                  small
                  className="py-2 px-3 mx-2 my-2 service-chat"
                  onClick={(e) => {
                    sendMessageFromServices(e.target.innerText);
                  }}
                >
                  {item}
                </Card>
              ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
export default WebVitalsSupport;
