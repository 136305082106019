import React from "react";
import PropTypes from "prop-types";
import { Navbar } from "shards-react";

import { Dispatcher, Constants } from "../../../state/flux";
import { Link } from "react-router-dom";

import logo from "../../../assets/images/logos/logo.svg";
import icon from "../../../assets/images/logos/logo-icon.svg";

class SidebarMainNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
  }

  handleToggleSidebar() {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR,
    });
  }

  render() {
    const { hideLogoText } = this.props;
    return (
      <div className="main-navbar">
        <Navbar
          className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0"
          type="light"
          style={{ justifyContent: "center" }}
        >
          <Link
            to={this.props.suspendedAccount ? "/contactUs" : "/dashboard"}
            style={{ textDecoration: "none", lineHeight: "25px" }}
          >
            <div
              className="d-flex m-auto"
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <img
                id="main-logo"
                className="d-inline-block mr-2"
                style={{
                  width: "30px",
                  height: "30px",
                }}
                src={icon}
                alt="MetryV Icon"
              />
              {!hideLogoText && (
                <img
                  id="main-logo"
                  className="d-inline-block mt-1"
                  style={{ width: "30%" }}
                  src={logo}
                  alt="MetryV Logo"
                />
              )}
            </div>
          </Link>
          <a
            className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
            onClick={this.handleToggleSidebar}
          >
            <i className="material-icons">&#xE5C4;</i>
          </a>
        </Navbar>
      </div>
    );
  }
}

SidebarMainNavbar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool,
};

SidebarMainNavbar.defaultProps = {
  hideLogoText: false,
};

export default SidebarMainNavbar;
