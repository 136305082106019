import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import W3ValidatorOverviewStatus from "./W3ValidatorOverviewStatus";
import { Card, CardBody } from "shards-react";
import { useSelector } from "react-redux";

const W3ValidatorOverview = ({ siteName, siteUrl }) => {
  const [w3validatorstatus, setW3validatorstatus] = useState([]);

  const w3validator = useSelector((state) => state.w3Validator.w3Validator);
  const error = useSelector((state) => state.w3Validator.error);
  const loading = useSelector((state) => state.w3Validator.loading);

  useEffect(() => {
    if (w3validator) {
      setW3validatorstatus({
        fatalErrors: w3validator.fatalErrors,
        errors: w3validator.errors,
        warnings: w3validator.warnings,
        details: w3validator.details,
      });
    }
  }, [loading]);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress
            style={{ margin: "2rem", height: "140px", width: "140px" }}
          />
          <div>Loading W3 Validator Status</div>
        </div>
      ) : error ? (
        <Card small className="h-100">
          <CardBody>No sufficient data for w3 validator status!</CardBody>
        </Card>
      ) : (
        <W3ValidatorOverviewStatus
          data={w3validatorstatus}
          siteName={siteName}
          siteUrl={siteUrl}
        />
      )}
    </>
  );
};

export default W3ValidatorOverview;
