import React from "react";
import { Link } from "react-router-dom";

const ConfirmedSubUserEmailFailed = () => {
  return (
    <div className="login-container">
      <h4 style={{ color: "red" }}>
        Token has expired. Please try to verify email again!
      </h4>
      <Link
        to={{
          pathname: "/login",
        }}
        className="btn btn-info mt-4"
      >
        Go to login page
      </Link>
    </div>
  );
};

export { ConfirmedSubUserEmailFailed };
