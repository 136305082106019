export default function () {
  return [
    {
      title: "Contact Us",
      htmlBefore: '<i class="material-icons">email</i>',
      to: "/contactUs",
    },
    {
      title: "Billing",
      htmlBefore: '<i class="material-icons">email</i>',
      to: "/billing",
      disabled: true,
    },
  ];
}
