export const msalConfig = {
  auth: {
    // clientId: "acf0d271-4f34-47ca-891a-a7b78333de04",
    authority: "https://login.microsoftonline.com/common/", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    // redirectUri: "http://localhost:3000",
  },
  // cache: {
  //   cacheLocation: "sessionStorage", // This configures where your cache will be stored
  //   storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  // },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com",
};
