import React, { useState, useEffect } from "react";
import UptimeOverviewGraph from "./UptimeOverviewGraph";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { Card, CardBody } from "shards-react";

const UptimeOverview = ({ siteName, siteUrl }) => {
  const [uptime, setUptime] = useState([]);

  let uptimeData = [];

  const setData = (data) => {
    data.forEach((element) => {
      uptimeData.push(element.simple && element.simple.status ? 2 : 1);
    });

    setUptime(uptimeData);
  };

  const loading = useSelector((state) => state.upTimeData.loading);
  const upTimeData = useSelector((state) => state.upTimeData.upTimeData);
  const error = useSelector((state) => state.upTimeData.error);

  useEffect(() => {
    if (upTimeData) {
      setData(upTimeData);
    }
  }, [loading]);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress
            style={{ margin: "2rem", height: "140px", width: "140px" }}
          />
          <div>Loading Server Uptime Graph</div>
        </div>
      ) : error ? (
        <Card small className="h-100">
          <CardBody>No sufficient data for uptime status!</CardBody>
        </Card>
      ) : (
        <UptimeOverviewGraph
          data={uptime}
          siteName={siteName}
          siteUrl={siteUrl}
        />
      )}
    </>
  );
};

export default UptimeOverview;
