import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Container, Navbar } from "shards-react";
import NavbarNav from "./NavbarNav/NavbarNav";
import NavbarToggle from "./NavbarToggle";
import BackButton from "../../common/BackButton";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../state/actions/user.actions";
import {
  getNotifications,
  markAsReadFromState,
  markAllAsReadFromState,
} from "../../../state/actions/notifications.actions";

const MainNavbar = ({ stickyTop }) => {
  const classes = classNames(
    "main-navbar",
    "bg-white",
    stickyTop && "sticky-top"
  );
  const [more, setMore] = useState(4);
  const [onlyRead, setOnlyRead] = useState(false);
  const [unReadNotifications, setUnReadNotifications] = useState([]);

  const dispatch = useDispatch();

  const user = useSelector((state) =>
    state.authentication.user
      ? state.authentication.user
      : state.registration.user
  );
  const notifications = useSelector((state) =>
    state.notifications.notifications ? state.notifications.notifications : []
  );

  useEffect(() => {
    dispatch(getNotifications())
      .then((response) => {
        let unRead = [];
        response.forEach((item) => {
          if (!item.read) {
            unRead.push(item);
          }
        });
        setUnReadNotifications(unRead);
      })
      .catch((error) => {
        setUnReadNotifications([]);
        console.log(error);
      });
  }, []);

  const signout = () => {
    dispatch(logout());
  };

  const markAsRead = (id, idx) => {
    dispatch(markAsReadFromState(id, idx));
    setUnReadNotifications((data) => data.filter((item) => item._id !== id));
  };

  const markAllAsRead = () => {
    dispatch(markAllAsReadFromState());
    setUnReadNotifications((data) =>
      data.filter((item) => item.read !== item.read)
    );
  };

  return (
    <div className={classes}>
      <Container fluid className="p-0">
        <Navbar type="light" className="align-items-stretch flex-md-nowrap p-0">
          <NavbarToggle />
          <BackButton />
          <NavbarNav
            user={user}
            notifications={notifications}
            signout={signout}
            markAsRead={markAsRead}
            more={more}
            setMore={setMore}
            markAllAsRead={markAllAsRead}
            onlyRead={onlyRead}
            setOnlyRead={setOnlyRead}
            unReadNotifications={unReadNotifications}
          />
        </Navbar>
      </Container>
    </div>
  );
};

MainNavbar.propTypes = {
  /**
   * The layout type where the MainNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool,
};

MainNavbar.defaultProps = {
  stickyTop: true,
};

export default MainNavbar;
