import React, { useState, useEffect } from "react";
import { Row, Col, Container, FormSelect, Button } from "shards-react";
import { useSelector, useDispatch } from "react-redux";
import { getData } from "../state/actions/sites.actions";
import { getSitesStatusData } from "../state/actions/psi.actions";
import { getUserPlanData } from "../state/actions/user.actions";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import PageTitle from "../components/common/PageTitle";
import Analytics from "../components/dashboard/Analytics";
import TopCountries from "../components/dashboard/TopCountries";
import TopBrowser from "../components/dashboard/TopBrowser";
import TopDevices from "../components/dashboard/TopDevices";
import TopOperatingSystems from "../components/dashboard/TopOperatingSystems";
import MissingPropertyId from "../components/dashboard/MissingPropertyId";
import PagesTable from "../components/dashboard/PagesTable";
import {
  getMetryVScoreData,
  getOriginSummaryData,
  getMonthlyOverallScoreData,
  getRankingData,
  getMonthlyLabData,
  getUptimeStatusData,
  getW3ValidatorData,
} from "../state/actions/psi.actions";
import { getPingData } from "../state/actions/ping.actions";

const GoogleAnalysis = () => {
  const [propertyId, setPropertyId] = useState("");
  const sitesStatus = useSelector((state) => state.sitesStatus.sitesStatus);
  const sites = useSelector((state) => state.sites.sites);
  const user = useSelector((state) =>
    state.authentication.user
      ? state.authentication.user
      : state.registration.user
  );
  const loading = useSelector((state) => state.sitesStatus.loading);
  const dispatch = useDispatch();

  const handleTabChange = (e) => {
    localStorage.setItem("tabUrl", JSON.stringify(e.target.value));
    dispatch(getMetryVScoreData(e.target.value));
    dispatch(getOriginSummaryData(e.target.value));
    dispatch(getMonthlyOverallScoreData(e.target.value));
    dispatch(getRankingData(e.target.value));
    dispatch(getMonthlyLabData(e.target.value));
    dispatch(getUptimeStatusData(e.target.value));
    dispatch(getW3ValidatorData(e.target.value));
    dispatch(getPingData(e.target.value));
    const propertyIdToSet = sites.find((item) => item.url === e.target.value);
    if (propertyIdToSet.propertyId) {
      setPropertyId(propertyIdToSet.propertyId);
      localStorage.setItem("propertyId", propertyIdToSet.propertyId);
    } else {
      setPropertyId("");
      localStorage.setItem("propertyId", "");
    }
  };

  useEffect(() => {
    if (!sitesStatus) {
      dispatch(getData(user.rootUser ? user.rootUser : user.email))
        .then((response) => {
          if (response.length) {
            let urls = [];
            response.forEach((item) => {
              urls.push(item.url);
            });
            dispatch(getSitesStatusData(urls));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (!sites?.length) {
      dispatch(getData(user.rootUser ? user.rootUser : user.email))
        .then((response) => {
          if (response.length) {
            localStorage.setItem("tabUrl", JSON.stringify(response[0].url));
            localStorage.setItem(
              "comparisonUrl",
              JSON.stringify(response[0].url)
            );
            const propertyIdToSet = response.find(
              (item) =>
                item.url === localStorage.getItem("tabUrl").replaceAll('"', "")
            );
            if (propertyIdToSet.propertyId) {
              setPropertyId(propertyIdToSet.propertyId);
              localStorage.setItem("propertyId", propertyIdToSet.propertyId);
            } else {
              setPropertyId("");
              localStorage.setItem("propertyId", "");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      if (!localStorage.getItem("tabUrl")) {
        localStorage.setItem("tabUrl", JSON.stringify(sites[0].url));
        dispatch(getMetryVScoreData(sites[0].url));
        dispatch(getOriginSummaryData(sites[0].url));
        dispatch(getMonthlyOverallScoreData(sites[0].url));
        dispatch(getRankingData(sites[0].url));
        dispatch(getMonthlyLabData(sites[0].url));
        dispatch(getUptimeStatusData(sites[0].url));
        dispatch(getW3ValidatorData(sites[0].url));
        dispatch(getPingData(sites[0].url));
      }
      const propertyIdToSet = sites.find(
        (item) =>
          item.url === localStorage.getItem("tabUrl").replaceAll('"', "")
      );
      if (propertyIdToSet?.propertyId) {
        setPropertyId(propertyIdToSet.propertyId);
        localStorage.setItem("propertyId", propertyIdToSet.propertyId);
      } else {
        setPropertyId("");
        localStorage.setItem("propertyId", "");
      }
    }
    dispatch(getUserPlanData(user.userId));
    const optionsarraylistuniqueclass = document.querySelectorAll(
      ".optionsarraylistuniqueclassanalysis"
    );
    optionsarraylistuniqueclass.forEach((item) => {
      if (localStorage.getItem("tabUrl")) {
        if (`"${item.value}"` === localStorage.getItem("tabUrl")) {
          item.selected = "selected";
        }
      }
    });
  }, []);

  return (
    <Container
      fluid
      className="main-content-container px-4"
      style={{ position: "relative" }}
    >
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Google Analytics" className="text-sm-left" />
      </Row>
      {!sites?.length && !loading ? (
        <Row className="no-sites-yet">
          <Col className="col-12">
            <p>
              There are no sites yet added from you. Please go ahead and try it
              out!
            </p>
          </Col>
          <Col className="col-12">
            <Link
              to={{
                pathname: "/registered-sites",
              }}
            >
              <Button className="d-inline-block btn-primary">Add Site</Button>
            </Link>
          </Col>
        </Row>
      ) : (
        <>
          <Row className="col-lg mb-3 pl-0 pr-0 ml-0 mr-0">
            <FormSelect
              className="overviewSelect"
              id="feInputState"
              onChange={handleTabChange}
            >
              {loading ? (
                <CircularProgress />
              ) : sitesStatus ? (
                sitesStatus.map((site, idx) => (
                  <option
                    value={site.url}
                    key={idx}
                    className={"optionsarraylistuniqueclassanalysis"}
                  >
                    {site.siteName ? site.siteName : site.url}
                  </option>
                ))
              ) : null}
            </FormSelect>
          </Row>
          {propertyId ? (
            <>
              <Row className="col-lg-6 col-md-12 col-sm-12 py-3">
                <Col className="col-lg-3 col-md-3 col-sm-3 px-0">
                  <h5>Property id: {propertyId}</h5>
                </Col>
              </Row>
              <Row className="py-3">
                <Col className="col-lg-4 col-md-12 col-sm-12 mb-4">
                  <TopCountries propertyId={propertyId} />
                </Col>
                <Col className="col-lg-8 col-md-12 col-sm-12 mb-4">
                  <Analytics propertyId={propertyId} />
                </Col>
              </Row>
              <Row>
                <Col className="col-lg-5 col-md-12 col-sm-12 mb-4">
                  <TopBrowser propertyId={propertyId} />
                </Col>
                <Col className="col-lg-3 col-md-6 col-sm-6 mb-4">
                  <TopDevices propertyId={propertyId} />
                </Col>
                <Col className="col-lg-4 col-md-6 col-sm-6 mb-4">
                  <TopOperatingSystems propertyId={propertyId} />
                </Col>
              </Row>
              <Row>
                <Col className="col-lg-12 col-md-12 col-sm-12 mb-4">
                  <PagesTable propertyId={propertyId} />
                </Col>
              </Row>
            </>
          ) : (
            <Row className="px-3">
              <MissingPropertyId />
            </Row>
          )}
        </>
      )}
    </Container>
  );
};

export default GoogleAnalysis;
