import React, { useEffect, useState } from "react";
import OverallScoreOverviewGraphDetails from "./OverallScoreOverviewGraphDetails";
import CircularProgress from "@mui/material/CircularProgress";
import { Card, CardBody, Row } from "shards-react";
import { useSelector, useDispatch } from "react-redux";
import { getMonthlyOverallScoreData } from "../../state/actions/psi.actions";

const OverallScoreOverview = ({ siteName, siteUrl, manualScanCounter }) => {
  const [overallScores, setOverallScores] = useState({
    mobile: [],
    desktop: [],
    scanDate: [],
  });
  const loading = useSelector((state) => state.monthlyOverall.loading);
  const error = useSelector((state) => state.monthlyOverall.error);

  const dispatch = useDispatch();

  let mobileData = [];
  let desktopData = [];
  let scanDate = [];

  const setData = (data) => {
    data.forEach((element) => {
      mobileData.push(parseInt(element.mobile));
      desktopData.push(parseInt(element.desktop));
      scanDate.push(element.scanDate);
    });
    setOverallScores({
      mobile: mobileData,
      desktop: desktopData,
      scanDate: scanDate,
    });
  };

  useEffect(() => {
    dispatch(getMonthlyOverallScoreData(siteUrl))
      .then((response) => {
        setData(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [siteUrl, manualScanCounter]);

  return (
    <>
      <Row>
        <h3 className="text-sm-left ml-3 heading-3">Overall Score</h3>
      </Row>

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress
            style={{ margin: "2rem", height: "140px", width: "140px" }}
          />
          <div>Loading Overall Score Graph</div>
        </div>
      ) : error ? (
        <Card small className="h-100">
          <CardBody>No sufficient data for monthly graph!</CardBody>
        </Card>
      ) : (
        <OverallScoreOverviewGraphDetails
          data={overallScores}
          siteName={siteName}
          siteUrl={siteUrl}
        />
      )}
    </>
  );
};

export default OverallScoreOverview;
