import { userConstants } from "../../constants";

export function registration(state = {}, action) {
  switch (action.type) {
    case userConstants.REGISTER_REQUEST:
      return { loading: true };
    case userConstants.REGISTER_SUCCESS:
      return { loading: false, user: action.payload };
    case userConstants.REGISTER_FAILURE:
      return { loading: false, error: { ...action.payload.data } };
    case userConstants.REGISTER_CLEAR:
      return {};
    default:
      return state;
  }
}
