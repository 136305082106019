import { Button } from "react-bootstrap";
import React from "react";
import axios from "axios";
import { API_URL } from "../../constants";
import { isMobile } from "react-device-detect";

const SubUsersTable = ({ users, getUsers }) => {
  const removeSubUser = (id) => {
    // console.log(id);
    axios
      .delete(
        `${API_URL}/sub-users/delete/${localStorage.getItem("user")}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        getUsers();
      })
      .catch((error) => {
        console.log(error.response);
        getUsers();
      });
  };
  return (
    <>
      {isMobile ? (
        <table className="table mb-0">
          {users.length ? (
            users.map((item) => {
              let { id, firstName, lastName, email, confirmed, role } = item;
              return (
                <tbody key={id}>
                  <tr>
                    <th scope="col">First Name</th>
                    <td>{firstName}</td>
                  </tr>
                  <tr>
                    <th scope="col">Last Name</th>
                    <td>{lastName}</td>
                  </tr>
                  <tr>
                    <th scope="col">Email</th>
                    <td>{email}</td>
                  </tr>

                  <tr>
                    <th scope="col">Role</th>
                    <td>{role}</td>
                  </tr>
                  <tr>
                    <th scope="col">Confirmed</th>
                    <td>{confirmed ? "True" : "False"}</td>
                  </tr>
                  <tr>
                    <th scope="col"></th>
                    <td style={{ textAlign: "right" }}>
                      <Button
                        onClick={() => {
                          removeSubUser(id);
                        }}
                        variant="danger"
                      >
                        Remove Sub User
                      </Button>
                    </td>
                  </tr>
                </tbody>
              );
            })
          ) : (
            <tbody>
              <tr style={{ position: "relative" }}>
                {/* <th scope="col"></th> */}
                <td>No Sub Users</td>
              </tr>
            </tbody>
          )}
        </table>
      ) : (
        <table className="table mb-0">
          <thead className="bg-light">
            <tr>
              <th scope="col" className="border-0">
                First Name
              </th>
              <th scope="col" className="border-0">
                Last Name
              </th>
              <th scope="col" className="border-0">
                Email
              </th>
              <th scope="col" className="border-0">
                Role
              </th>
              <th scope="col" className="border-0">
                Confirmed
              </th>
              <th scope="col" className="border-0"></th>
            </tr>
          </thead>
          <tbody>
            {users.length ? (
              users.map((item) => {
                let { id, firstName, lastName, email, confirmed, role } = item;
                return (
                  <tr key={id} style={{ position: "relative" }}>
                    <td>{firstName}</td>
                    <td>{lastName}</td>
                    <td>{email}</td>
                    <td>{role}</td>
                    <td>{confirmed ? "True" : "False"}</td>
                    <td>
                      <Button
                        onClick={() => {
                          removeSubUser(id);
                        }}
                        variant="danger"
                      >
                        Remove Sub User
                      </Button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr style={{ position: "relative" }}>
                <td>No Sub Users</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </>
  );
};

export default SubUsersTable;
