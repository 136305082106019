import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "shards-react";
import { useSnackbar } from "notistack";
import axios from "axios";
import { API_URL } from "../../constants";

const RequestQouteModal = ({ open, setOpen }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const requestQuote = async () => {
    try {
      const qoute = await axios.post(
        `${API_URL}/qoute/request/${localStorage.getItem("id")}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (qoute) {
        const qouteSnackbar = enqueueSnackbar("Request made successfully!", {
          variant: "success",
        });
        setTimeout(() => {
          closeSnackbar(qouteSnackbar);
        }, 5000);
        setOpen(false);
      }
    } catch (error) {
      const qouteSnackbar = enqueueSnackbar(
        "Request was unsuccessfully, please try again later!",
        {
          variant: "danger",
        }
      );
      setTimeout(() => {
        closeSnackbar(qouteSnackbar);
      }, 5000);
      console.log(error);
    }
  };

  return (
    <Modal show={open} onHide={() => setOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Request Qoute</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form name="form">
          <div className="form-group"></div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button outline variant="secondary" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button
          outline
          variant="primary"
          onClick={() => {
            requestQuote();
            setOpen(false);
          }}
        >
          Request Qoute
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RequestQouteModal;
