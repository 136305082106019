import axios from "axios";
import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "shards-react";
import { API_URL } from "../../constants";
import { useDispatch } from "react-redux";
import { loginGoogle } from "../../state/actions/user.actions";

const MergeAccountsModal = ({
  setMergeAccountsModal,
  mergeAccountsModal: { open, email, googleId, googleData },
}) => {
  const dispatch = useDispatch();

  return (
    <Modal show={open} onHide={() => setMergeAccountsModal({ open: false })}>
      <Modal.Header closeButton>
        <Modal.Title>Merge Accounts</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Another metryv user was found with the same{" "}
        {googleId ? "gmail" : "microsoft"} address, you can merge these accounts
        into one. Click yes to merge these accounts. Click no if you want to
        login with email and password with your current metryv account.
      </Modal.Body>
      <Modal.Footer>
        <Button
          theme="danger"
          onClick={async () => {
            try {
              await axios.post(`${API_URL}/auth/merge`, {
                email,
                googleId,
                microsoftId: null,
              });
              setMergeAccountsModal({ open: false });
              if (googleId) {
                dispatch(loginGoogle(googleData));
              }
            } catch (error) {
              console.log(error);
            }
          }}
        >
          Yes
        </Button>
        <Button onClick={() => setMergeAccountsModal({ open: false })}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MergeAccountsModal;
