import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardHeader, CardBody, CardFooter } from "shards-react";
import { Link } from "react-router-dom";
import { checkRanking } from "../../helpers/rankings";

class CheckRanking extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { title, rankings } = this.props;
    if (rankings) {
      rankings = rankings.slice(1, 5);
    }
    return (
      <Card small className="h-100">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{title}</h6>
        </CardHeader>
        <CardBody className="d-flex py-0">
          <table className="table mb-0">
            <thead className="bg-light">
              <tr>
                <td>#</td>
                <td>Keyword</td>
                <td>Rank</td>
                <td>Traffic %</td>
              </tr>
            </thead>
            <tbody>
              {!rankings
                ? null
                : rankings.map((item, index) => (
                    <tr key={index}>
                      <td>{item[0]}</td>
                      <td>{item[1]}</td>
                      <td>{checkRanking(item[2], index)}</td>
                      <td>{item[4]}</td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </CardBody>
        <CardFooter className="border-top">
          <Row>
            <Col className="text-right view-report">
              <Link
                to={{
                  pathname: "/rankingDetails",
                  state: {
                    siteName: this.props.siteName,
                    rankings: this.props.rankings,
                  },
                }}
              >
                View full report &rarr;
              </Link>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    );
  }
}

CheckRanking.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The chart config object.
   */
  chartConfig: PropTypes.object,
  /**
   * The Chart.js options.
   */
  chartOptions: PropTypes.object,
  /**
   * The chart data.
   */
  chartData: PropTypes.object,
};

CheckRanking.defaultProps = {
  title: "Top traffic-driving keywords",
};

export default CheckRanking;
