import React from "react";

/* eslint-disable no-empty-character-class */
export function filterDescription(description) {
  description = description.replace(/([])|\[(.*?)\]\(.*?\)/gm, "");
  description = description.replace(". .", ".");
  return description;
}

export function getLink(description) {
  const regex = /(\(.*\))/gm;
  const string = description;
  const myMatch = string.match(regex);
  let link = myMatch ? myMatch[0].replace("(", "").replace(")", "") : "";

  if (link) {
    link = React.createElement(
      "a",
      {
        className: "btn btn-info mr-2",
        href: link,
        target: "_blank",
      },
      "Learn more"
    );
  }
  return link;
}
