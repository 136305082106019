import React, { useState } from "react";
import axios from "axios";
import { Button } from "shards-react";
import { Link } from "react-router-dom";
import { API_URL, API_URL_PSI } from "../../constants";
import { Modal } from "react-bootstrap";
import TablePages from "./TablePages";
import { useSnackbar } from "notistack";
import { getData } from "../../state/actions/sites.actions";
import { useDispatch, useSelector } from "react-redux";
import { getStatus, getOverallScoreColor } from "../../helpers/scores";
import DesktopMacIcon from "@mui/icons-material/DesktopMac";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import CircularProgress from "@mui/material/CircularProgress";
import { removeSiteFromState } from "../../state/actions/psi.actions";
import { getSiteFromState } from "../../state/actions/psi.actions";
import { editSiteFromState } from "../../state/actions/psi.actions";
import "../../assets/styles/dropdown.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { faEllipsisH, faCircle } from "@fortawesome/free-solid-svg-icons";

const Table = ({ loadingNewSite, handleFetchPages }) => {
  const sites = useSelector((state) =>
    state.sitesStatus.sitesStatus ? state.sitesStatus.sitesStatus : []
  );
  const page = useSelector((state) => state.sites.sites);
  const sitesArray = [];
  sites.forEach((item1) => {
    if (page) {
      const matchedItems = page.filter((item2) => item2.url === item1.siteName);
      matchedItems.forEach((matchedItem) => {
        sitesArray.push({
          ...item1,
          siteName: matchedItem.siteName,
          url: matchedItem.url,
          _id: matchedItem._id,
          propertyId: matchedItem.propertyId,
        });
      });
    } else {
      console.error("Page data is undefined or null.");
    }
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [siteUrlToBeDeleted, setSiteUrlToBeDeleted] = useState(false);
  const [siteUrlToBeEdited, setSiteUrlToBeEdited] = useState(false);
  const [siteInfos, setSiteInfos] = useState(null);
  const [selectedSite, setSelectedSite] = useState(false);
  const handleSiteClick = (site) => {
    setSelectedSite(site);
  };
  const handleShow = (siteUrl) => {
    setShow(true);
    setSiteUrlToBeDeleted(siteUrl.siteName);
  };
  const handleEdit = (site) => {
    setShowEdit(true);
    setSiteInfos(site);
    setSiteUrlToBeEdited(site.siteUrl);
  };
  const handleClose = () => setShow(false);
  const closeEditModal = () => setShowEdit(false);
  const [siteUrlToBeAdded, setSiteUrlToBeAdded] = useState(false);
  const [showPageModal, setShowPageModal] = useState(false);
  const handleAddPageModal = (siteUrl) => {
    setShowPageModal(true);
    setSiteUrlToBeAdded(siteUrl);
  };
  const handleClosePageModal = () => setShowPageModal(false);

  const [error] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [inputs, setInputs] = useState({
    pageUrl: "",
    siteNamee: "",
    propertyId: "",
  });
  const { pageUrl } = inputs;
  const user = useSelector((state) =>
    state.authentication.user
      ? state.authentication.user
      : state.registration.user
  );
  const currentPlan = useSelector((state) => state.plan.plan);
  const currentPlanError = useSelector((state) => state.plan.error);
  const currentPlanLoading = useSelector((state) => state.plan.loading);
  const sitesError = useSelector((state) => state.sitesStatus.error);
  const loading = useSelector((state) => state.sitesStatus.loading);
  const sitesloading = useSelector((state) => state.sites.loading);
  const newSite = useSelector((state) => state.newSite);
  const dispatch = useDispatch();

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  const handleDeffaultValues = (event) => {
    const { name, value } = event.target;
    setSiteInfos({
      ...siteInfos,
      [name]: value,
    });
  };

  const editSite = async (siteNamee, propertyId) => {
    const request_body = {
      email: user.rootUser ? user.rootUser : user.email,
      sites: [
        {
          url: siteUrlToBeEdited,
          siteNamee: siteNamee,
          propertyId: propertyId,
        },
      ],
    };

    if (siteUrlToBeEdited) {
      await axios
        .post(
          `${API_URL}/sites/${user.rootUser ? user.rootUser : user.email}/edit`,
          request_body,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then(() => {
          handleClose();
          dispatch(editSiteFromState(siteUrlToBeEdited));
          dispatch(getData(user.rootUser ? user.rootUser : user.email));
          const tabUrl = localStorage.getItem("tabUrl");
          if (tabUrl && tabUrl.replaceAll('"', "") === siteUrlToBeEdited) {
            localStorage.setItem("tabUrl", tabUrl);
          }
          const comparisonUrl = localStorage.getItem("comparisonUrl");
          if (
            comparisonUrl &&
            comparisonUrl.replaceAll('"', "") === siteUrlToBeEdited
          ) {
            localStorage.setItem("comparisonUrl", comparisonUrl);
          }
        })
        .catch(() => {
          setTimeout(() => {
            closeSnackbar();
          }, 5000);
        });
    }
  };

  const getSite = (siteNamee) => {
    const request_body = {
      email: user.rootUser ? user.rootUser : user.email,
      sites: [{ url: siteNamee }],
    };

    axios
      .post(
        `${API_URL}/sites/${user.rootUser ? user.rootUser : user.email}/get`,
        request_body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        handleClose();
        let dispatch = dispatch(getSiteFromState());
        dispatch(getData(user.rootUser ? user.rootUser : user.email));
      })
      .catch((error) => {
        console.log(error);
        setTimeout(() => {
          closeSnackbar();
        }, 5000);
      });
  };

  console.log("getSite", getSite());

  const removeSite = () => {
    const request_body = {
      email: user.rootUser ? user.rootUser : user.email,
      sites: [{ url: siteUrlToBeDeleted }],
    };

    if (siteUrlToBeDeleted) {
      axios
        .post(
          `${API_URL}/sites/${
            user.rootUser ? user.rootUser : user.email
          }/delete`,
          request_body,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then(() => {
          handleClose();
          dispatch(removeSiteFromState(siteUrlToBeDeleted));
          dispatch(getSiteFromState());
          dispatch(getData(user.rootUser ? user.rootUser : user.email));
          if (
            localStorage.getItem("tabUrl")?.replaceAll('"', "") ===
            siteUrlToBeDeleted
          ) {
            localStorage.removeItem("tabUrl");
          }
          if (
            localStorage.getItem("comparisonUrl")?.replaceAll('"', "") ===
            siteUrlToBeDeleted
          ) {
            localStorage.removeItem("comparisonUrl");
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(
            `Removing site (${siteUrlToBeDeleted}) failed, please try again later`,
            {
              variant: "error",
            }
          );
          setTimeout(() => {
            closeSnackbar();
          }, 5000);
        });
    }
  };

  const pageLimit = async (email) => {
    return await axios
      .post(
        `${API_URL}/sites/pages-limit/${email}`,
        {
          siteUrlToBeAdded,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  const addSinglePage = async () => {
    const returnedValue = await pageLimit(user.email);

    if (pageUrl) {
      if (returnedValue?.data?.success) {
        const request_body = {
          email: user.rootUser ? user.rootUser : user.email,
          site: siteUrlToBeAdded,
          page: pageUrl,
        };
        setSubmitted(true);
        axios
          .post(`${API_URL}/sites/addSinglePage`, request_body, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              dispatch(getData(user.rootUser ? user.rootUser : user.email));
              handleClosePageModal();
              enqueueSnackbar("Page has been added successfully", {
                variant: "success",
              });
              const scanningSnackbar = enqueueSnackbar(
                `Scanning ... ${pageUrl}`,
                {
                  variant: "info",
                  persist: true,
                }
              );
              enqueueSnackbar(
                "The Initial PageSpeed Insights Scan takes some time to finish, please be patient!",
                { variant: "warning" }
              );

              handleClose();

              const request_body = {
                email: user.rootUser ? user.rootUser : user.email,
                url: pageUrl,
              };
              axios
                .post(`${API_URL_PSI}/scan/`, request_body, {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                })
                .then((response) => {
                  if (response.status === 200) {
                    dispatch(
                      getData(user.rootUser ? user.rootUser : user.email)
                    );
                    closeSnackbar(scanningSnackbar);
                    enqueueSnackbar(
                      "Initial Scan has finished. Go and check the results!",
                      {
                        variant: "success",
                      }
                    );
                    setTimeout(() => {
                      closeSnackbar();
                    }, 5000);
                  }
                })
                .catch(() => {
                  enqueueSnackbar(
                    `Scan failed for page (${pageUrl}), please try again later`,
                    {
                      variant: "error",
                    }
                  );
                  setTimeout(() => {
                    closeSnackbar();
                  }, 5000);
                });
            }
          })
          .catch(() => {
            enqueueSnackbar(
              "Adding page to site failed, please try again later!",
              {
                variant: "error",
              }
            );
            setTimeout(() => {
              closeSnackbar();
            }, 5000);
          });
      } else {
        enqueueSnackbar(
          `You are not allowed to add more than ${
            currentPlanLoading
              ? ""
              : currentPlanError
              ? ""
              : currentPlan?.page_limit
          } pages per site!`,
          {
            variant: "warning",
          }
        );
        setTimeout(() => {
          closeSnackbar();
        }, 5000);
      }
    }
  };

  const deleteSiteModal = (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Site</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete this site from your list?
      </Modal.Body>
      <Modal.Footer>
        <Button
          theme="danger"
          onClick={() => {
            removeSite();
          }}
        >
          Yes
        </Button>
        <Button onClick={handleClose}>No</Button>
      </Modal.Footer>
    </Modal>
  );

  const editSiteModal = (
    <Modal show={showEdit} onHide={closeEditModal}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Site</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {siteInfos && (
          <div>
            <div className="form-group">
              <label>Site name</label>
              <input
                type="text"
                name="siteName"
                value={siteInfos.siteName}
                onChange={handleDeffaultValues}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Property id</label>
              <input
                type="text"
                name="propertyId"
                value={siteInfos.propertyId}
                onChange={handleDeffaultValues}
                className="form-control"
              />
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            editSite(siteInfos.siteName, siteInfos.propertyId);
            closeEditModal();
          }}
          style={{ float: "right" }}
        >
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const addSinglePageModal = (
    <Modal show={showPageModal} onHide={handleClosePageModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Page</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form name="form">
          <div className="form-group">
            <label>Page URL</label>
            <input
              type="url"
              name="pageUrl"
              value={pageUrl}
              onChange={handleChange}
              className={
                "form-control" + (submitted && !pageUrl ? " is-invalid" : "")
              }
            />
            {submitted && !pageUrl && (
              <div className="invalid-feedback">Page URL is required</div>
            )}
            {error && <div className="invalid-feedback">{error}</div>}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClosePageModal}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            addSinglePage();
          }}
        >
          Add Page
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const [pagesCollapsed, setPagesCollapsed] = useState(false);
  const handleCollapsePages = (url) => {
    setPagesCollapsed({ ...pagesCollapsed, [url]: !pagesCollapsed[url] });
  };

  function toggleDropdown(index) {
    const dropdownMenu = document.getElementById("dropdownMenu" + index);
    if (dropdownMenu) {
      dropdownMenu.classList.toggle("show");
    }
  }

  function formatListItemUrl(text) {
    let link = text;
    // text = text.length > 47 ? text.substring(0, 100) + "..." : text;
    text = React.createElement(
      "a",
      {
        href: link,
        target: "_blank",
      },
      text
    );
    return text;
  }

  function sitePageName(name) {
    return name.charAt(0).toUpperCase() + name.slice(1);
  }

  return (
    <table className="table mb-0">
      <thead className="bg-light">
        <tr>
          <th scope="col" className="border-0">
            #
          </th>
          <th scope="col" className="border-0">
            Name
          </th>
          <th scope="col" className="border-0">
            URL
          </th>
          <th scope="col" className="border-0">
            Web Vitals Status
          </th>
          <th scope="col" className="border-0">
            Overall Score
          </th>
          <th scope="col" className="border-0">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        {loading || sitesloading ? (
          <tr>
            <td>
              <CircularProgress
                style={{
                  width: "20px",
                  height: "20px",
                  position: "relative",
                  top: "6px",
                }}
              />
            </td>
          </tr>
        ) : !sites.length && !newSite.loading ? (
          <tr>
            <td colSpan="5">
              There are no sites yet added from you. Please go ahead and try it
              out!
            </td>
          </tr>
        ) : (
          sitesArray.map((site, index) => (
            <>
              <tr
                style={{ position: "relative" }}
                key={index}
                onClick={() => handleSiteClick(site)}
              >
                <td>{index + 1}</td>
                <td>{sitePageName(site.siteName)}</td>
                <td>{formatListItemUrl(site.url)}</td>
                <td>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "40%", marginRight: "1rem" }}>
                      <PhoneIphoneIcon fontSize="small" />
                      <span
                        className={
                          site.mobileStatus
                            ? getStatus(site.mobileStatus)[1]
                            : ""
                        }
                      >
                        {site && !sitesError ? (
                          getStatus(site.mobileStatus)[0]
                        ) : sitesError ? (
                          "-"
                        ) : (
                          <CircularProgress
                            style={{
                              width: "20px",
                              height: "20px",
                              position: "relative",
                              top: "6px",
                            }}
                          />
                        )}
                      </span>
                    </div>
                    <div style={{ width: "40%" }}>
                      <DesktopMacIcon fontSize="small" />{" "}
                      <span
                        className={site ? getStatus(site.desktopStatus)[1] : ""}
                      >
                        {site && !sitesError ? (
                          getStatus(site.desktopStatus)[0]
                        ) : sitesError ? (
                          "-"
                        ) : (
                          <CircularProgress
                            style={{
                              width: "20px",
                              height: "20px",
                              position: "relative",
                              top: "6px",
                            }}
                          />
                        )}
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "50%" }}>
                      <PhoneIphoneIcon fontSize="small" />
                      <span
                        className={
                          site ? getOverallScoreColor(site.mobileScore) : ""
                        }
                      >
                        {site && !sitesError ? (
                          parseInt(site.mobileScore)
                        ) : sitesError ? (
                          "-"
                        ) : (
                          <CircularProgress
                            style={{
                              width: "20px",
                              height: "20px",
                              position: "relative",
                              top: "6px",
                            }}
                          />
                        )}
                      </span>
                    </div>

                    <div style={{ width: "50%" }}>
                      <DesktopMacIcon fontSize="small" />{" "}
                      <span
                        className={
                          site ? getOverallScoreColor(site.desktopScore) : ""
                        }
                      >
                        {site && !sitesError ? (
                          parseInt(site.desktopScore)
                        ) : sitesError ? (
                          "-"
                        ) : (
                          <CircularProgress
                            style={{
                              width: "20px",
                              height: "20px",
                              position: "relative",
                              top: "6px",
                            }}
                          />
                        )}
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <Link
                    to={{
                      pathname: "/siteDetails",
                      state: {
                        siteName: site.siteName,
                        siteUrl: site.url,
                      },
                    }}
                  >
                    <Button
                      theme="info"
                      style={{ width: "102px", height: "36px" }}
                    >
                      View report
                    </Button>
                  </Link>
                  <div
                    className="dropdown"
                    style={{ paddingLeft: "8px", width: "10px" }}
                  >
                    <div
                      onClick={() => toggleDropdown(index)}
                      style={{
                        position: "relative",
                        display: "inline-block",
                        width: "24px",
                        height: "24px",
                        borderRadius: "50%",
                        backgroundColor: "#f2f2f2",
                        visibility: "visible",
                      }}
                    >
                      <FontAwesomeIcon icon={faCircle} color="#f2f2f2" />
                      <FontAwesomeIcon
                        icon={faEllipsisH}
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          color: "black",
                        }}
                      />
                    </div>
                    {selectedSite && (
                      <div
                        className="dropdown-menu"
                        id={`dropdownMenu${index}`}
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          className="dropdown-item"
                          onClick={() =>
                            handleAddPageModal(site.siteName, site.pageUrl)
                          }
                        >
                          Add Page
                        </div>
                        <div
                          className="dropdown-item"
                          onClick={() => handleFetchPages(site.pageUrl)}
                        >
                          Fetch Pages
                        </div>
                        <div
                          key={site.id}
                          className="dropdown-item"
                          onClick={() => {
                            handleEdit({
                              siteName: site.siteName,
                              siteUrl: site.url,
                              propertyId: site.propertyId,
                            });
                          }}
                        >
                          Edit Site
                        </div>
                        <div
                          className="dropdown-item"
                          onClick={() => handleShow({ siteName: site.url })}
                        >
                          Remove Site
                        </div>
                      </div>
                    )}
                  </div>
                  {!site.pages || site.pages.length <= 0 ? null : (
                    <span
                      className={`pages-list-expand ${
                        pagesCollapsed[site.siteName]
                          ? "pages-list-expand--collapsed"
                          : ""
                      }`}
                      onClick={() => handleCollapsePages(site.siteName)}
                    ></span>
                  )}
                </td>
              </tr>
              {page &&
                page.map((item) =>
                  item.url === site.siteName ? (
                    <TablePages
                      key={item.url}
                      pages={item.pages}
                      site={item.url}
                    />
                  ) : null
                )}
            </>
          ))
        )}
        {loadingNewSite.loading ? (
          <tr style={{ position: "relative" }}>
            <td>{sites.length + 1}</td>
            <td>{loadingNewSite.name}</td>
            <td>
              <a
                href={loadingNewSite.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {loadingNewSite.link}
              </a>
            </td>
            <td>
              <div style={{ display: "flex" }}>
                <div style={{ width: "40%", marginRight: "1rem" }}>
                  <PhoneIphoneIcon fontSize="small" />
                  <CircularProgress
                    style={{
                      width: "20px",
                      height: "20px",
                      position: "relative",
                      top: "6px",
                    }}
                  />
                </div>
                <div style={{ width: "40%" }}>
                  <DesktopMacIcon fontSize="small" />
                  <CircularProgress
                    style={{
                      width: "20px",
                      height: "20px",
                      position: "relative",
                      top: "6px",
                    }}
                  />
                </div>
              </div>
            </td>
            <td>
              <div style={{ display: "flex" }}>
                <div style={{ width: "50%" }}>
                  <PhoneIphoneIcon fontSize="small" />
                  <CircularProgress
                    style={{
                      width: "20px",
                      height: "20px",
                      position: "relative",
                      top: "6px",
                    }}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <DesktopMacIcon fontSize="small" />
                  <CircularProgress
                    style={{
                      width: "20px",
                      height: "20px",
                      position: "relative",
                      top: "6px",
                    }}
                  />
                </div>
              </div>
            </td>
          </tr>
        ) : null}
        {deleteSiteModal}
        {editSiteModal}
        {addSinglePageModal}
      </tbody>
    </table>
  );
};

export default Table;
