import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, FormSelect } from "shards-react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { API_URL_PSI } from "../../constants";

const TopBrowser = ({ propertyId }) => {
  const [loading, setLoading] = useState(false);
  const [overall, setOverall] = useState([]);
  const [error, setError] = useState("");
  const [usersByBrowser, setUsersByBrowser] = useState("totalUsers");
  const [headingName, setHeadingName] = useState("Total Users");

  const getAnalyticsOverall = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${API_URL_PSI}/analytics-overall`, {
        propertyId: propertyId.toString(),
        dimension: "browser",
      });
      setOverall(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error.response);
      setError(error.response.data);
      setLoading(false);
      setOverall([]);
    }
  };

  const handleTabChange = (e) => {
    setUsersByBrowser(e.target.value);
    if (e.target.value === "totalUsers") setHeadingName("Total Users");
    if (e.target.value === "newUsers") setHeadingName("New Users");
    if (e.target.value === "activeUsers") setHeadingName("Active Users");
  };

  // const getPercentage = (value) => {
  //   return value === false ? "0%" : (value * 100) / 10 / 10 + "%";
  // };

  useEffect(async () => {
    await getAnalyticsOverall();
  }, [propertyId]);

  return (
    <Card
      style={{ minHeight: overall.length ? "497px" : "", maxHeight: "497px" }}
    >
      <CardHeader className="border-bottom">
        <FormSelect className="overviewSelect" onChange={handleTabChange}>
          <option value={"totalUsers"}>Total Users By Browser</option>
          <option value={"newUsers"}>New Users By Browser</option>
          <option value={"activeUsers"}>Active Users By Browser</option>
        </FormSelect>
      </CardHeader>
      {loading ? (
        <CircularProgress
          style={{
            margin: "auto",
            height: "140px",
            width: "140px",
            padding: "10px",
          }}
        />
      ) : overall.length ? (
        <CardBody>
          <table className="table mb-0">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="border-0">
                  #
                </th>
                <th scope="col" className="border-0">
                  Name
                </th>
                {/* <th scope="col" className="border-0"></th>
                <th scope="col" className="border-0"></th>
                <th scope="col" className="border-0"></th> */}
                <th scope="col" className="border-0">
                  {headingName}
                </th>
              </tr>
            </thead>
            <tbody>
              {overall.slice(0, 6).map((item, index) => {
                let { browser } = item;
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{browser}</td>
                    {/* <td colSpan="3">
                      <div
                        className={
                          "metryV-score__container__items__item__progress metryV-score__container__items__item__progress--good"
                        }
                      >
                        <div
                          className={
                            "metryV-score__container__items__item__progress--rating metryV-score__container__items__item__progress--rating--good"
                          }
                          style={{
                            width: getPercentage(item[usersByBrowser]),
                          }}
                        ></div>
                      </div>
                    </td> */}
                    <td>{item[usersByBrowser]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
      ) : (
        <CardBody>{error ? error : "No Data"}</CardBody>
      )}
    </Card>
  );
};

export default TopBrowser;
