import React from "react";
import { DropdownItem } from "shards-react";
import { useSelector } from "react-redux";

const BillingDataTable = ({
  pagesLength,
  sitesLength,
  subUsersLength,
  competitorSitesLength,
}) => {
  const currentPlan = useSelector((state) => state.plan.plan);
  return (
    <table className="table mb-0">
      <thead className="bg-light">
        <tr>
          <th scope="col" className="border-0" style={{ fontWeight: "normal" }}>
            #
          </th>
          <th
            scope="col"
            className="border-0"
            style={{ textAlign: "right", fontWeight: "normal" }}
          >
            Amount of Features used in percentage
          </th>
          <th scope="col" className="border-0"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="col" className="border-0">
            Sites
            <DropdownItem divider />
          </th>
          <th scope="col" className="border-0" style={{ textAlign: "right" }}>
            {currentPlan?.site_unlimited
              ? "Unlimited"
              : (sitesLength / currentPlan?.site_limit) * 100 + "%"}
            <DropdownItem divider />
          </th>
        </tr>
        <tr>
          <th scope="col" className="border-0">
            Pages
            <DropdownItem divider />
          </th>
          <th scope="col" className="border-0" style={{ textAlign: "right" }}>
            {currentPlan?.page_unlimited
              ? "Unlimited"
              : (pagesLength / currentPlan?.page_limit) * 100 + "%"}
            <DropdownItem divider />
          </th>
        </tr>
        <tr>
          <th scope="col" className="border-0">
            Sub Users
            <DropdownItem divider />
          </th>
          <th scope="col" className="border-0" style={{ textAlign: "right" }}>
            {currentPlan?.sub_users_unlimited
              ? "Unlimited"
              : (subUsersLength / currentPlan?.sub_users_limit) * 100 + "%"}
            <DropdownItem divider />
          </th>
        </tr>
        <tr>
          <th scope="col" className="border-0">
            Competitor Sites
            <DropdownItem divider />
          </th>
          <th scope="col" className="border-0" style={{ textAlign: "right" }}>
            {currentPlan?.competitor_sites_unlimited
              ? "Unlimited"
              : (competitorSitesLength / currentPlan?.competitor_sites_limit) *
                  100 +
                "%"}
            <DropdownItem divider />
          </th>
        </tr>
      </tbody>
    </table>
  );
};

export default BillingDataTable;
