import React from "react";
import {
  getOverallScoreColorSiteDetails,
  getMetryVOverallScoreColorSiteDetails,
} from "../../helpers/scores";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const MetryVScore = ({ siteName, siteUrl }) => {
  const assessment = useSelector((state) => state.metryVScore.assessment);
  const loading = useSelector((state) => state.metryVScore.loading);
  const error = useSelector((state) => state.metryVScore.error);

  const scores = [
    {
      title: "Core Web Vitals Assessment - Mobile",
      score: assessment
        ? assessment.coreWebVitalsMobile === null
          ? "?"
          : parseFloat(assessment.coreWebVitalsMobile).toFixed(1)
        : 0,
    },
    {
      title: "Core Web Vitals Assessment - Desktop",
      score: assessment
        ? assessment.coreWebVitalsDesktop === null
          ? "?"
          : parseFloat(assessment.coreWebVitalsDesktop).toFixed(1)
        : 0,
    },
    {
      title: "Overall Score - Mobile",
      score:
        assessment && assessment.overallScoreMobile
          ? parseFloat(assessment.overallScoreMobile).toFixed(1)
          : 0,
    },
    {
      title: "Overall Score - Desktop",
      score:
        assessment && assessment.overallScoreDesktop
          ? parseFloat(assessment.overallScoreDesktop).toFixed(1)
          : 0,
    },
    {
      title: "Lab Data - Mobile",
      score:
        assessment && assessment.labDataMobile
          ? parseFloat(assessment.labDataMobile).toFixed(1)
          : 0,
    },
    {
      title: "Lab Data - Desktop",
      score:
        assessment && assessment.labDataDesktop
          ? parseFloat(assessment.labDataDesktop).toFixed(1)
          : 0,
    },
    {
      title: "Server Uptime",
      score:
        assessment && assessment.serverUptime
          ? parseFloat(assessment.serverUptime).toFixed(1)
          : 0,
    },
    {
      title: "W3 Validator Tool Assessment",
      score:
        assessment && assessment.w3Validator
          ? parseFloat(assessment.w3Validator).toFixed(1)
          : 0,
    },
  ];

  const getPercentage = (value) => {
    return value === "?" ? "0%" : (value * 100) / 10 + "%";
  };

  return (
    <>
      <h2 className="heading-dashboard">MetryV Assessment</h2>
      <span
        className="text-sm-left"
        style={{
          fontSize: "14px",
          opacity: "0.8",
        }}
      >
        These scores are calculated using our algorithm to determine your
        site&apos;s score for different Web Vitals across the web. Into our
        calculation we take into consideration most of the Google&apos;s real
        data and data collected from our daily scans on your site.
      </span>
      <div className="metryV-score">
        {loading && !error ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress
              style={{ margin: "2rem", height: "140px", width: "140px" }}
            />
            <div>Loading MetryV Score</div>
          </div>
        ) : error ? (
          "No sufficient data for calculating metryV score. Please wait for 24 hours to get some results!"
        ) : (
          <div className="metryV-score__container">
            <div className="metryV-score__container__items">
              {!scores || scores.length == 0
                ? ""
                : scores.map((item, index) => (
                    <div
                      className="metryV-score__container__items__item"
                      key={index}
                    >
                      <div className="metryV-score__container__items__item__top">
                        <span className="metryV-score__container__items__item__top__title">
                          {item.title}
                        </span>
                        <span className="metryV-score__container__items__item__top__score">
                          {item.score ? item.score : "?"}/10
                        </span>
                      </div>
                      <div
                        className={
                          "metryV-score__container__items__item__progress metryV-score__container__items__item__progress" +
                          getMetryVOverallScoreColorSiteDetails(item.score)
                        }
                      >
                        <div
                          className={
                            "metryV-score__container__items__item__progress--rating metryV-score__container__items__item__progress--rating" +
                            getMetryVOverallScoreColorSiteDetails(item.score)
                          }
                          style={{ width: getPercentage(item.score) }}
                        ></div>
                      </div>
                    </div>
                  ))}
            </div>
            <div className="metryV-score__container__overall">
              <div
                className={
                  "c100 big center p" +
                  Math.round(parseInt(assessment.overall)) +
                  getOverallScoreColorSiteDetails(
                    Math.round(parseInt(assessment.overall))
                  )
                }
                style={{ fontSize: "170px" }}
              >
                <span>{Math.round(assessment.overall)}</span>
                <div className="slice">
                  <div className="bar"></div>
                  <div className="fill"></div>
                </div>
              </div>
              <h4 className="text-sm-center mt-4">
                {assessment.overall_status}
              </h4>
              <div className="mt-4">
                {siteName ? (
                  <Link
                    to={{
                      pathname: `/siteDetails`,
                      hash: "#mobile-suggestions",
                      state: {
                        siteName: siteName,
                        siteUrl: siteUrl,
                      },
                    }}
                    className="btn btn-danger"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "200px",
                      margin: "auto",
                      fontSize: "1rem",
                    }}
                  >
                    How to fix
                  </Link>
                ) : (
                  <p>Site Name is not available</p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MetryVScore;
