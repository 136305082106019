import React from "react";
import { NavItem, NavLink, Badge, Collapse, DropdownItem } from "shards-react";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Switch } from "@mui/material";

export default class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };

    this.toggleNotifications = this.toggleNotifications.bind(this);
  }

  toggleNotifications() {
    this.setState({
      visible: !this.state.visible,
    });
  }

  render() {
    return this.props.onlyRead ? (
      <NavItem className="border-right border-left dropdown notifications link--cursor">
        <NavLink
          className="nav-link-icon text-center"
          onClick={this.toggleNotifications}
        >
          <div className="nav-link-icon__wrapper">
            <i className="material-icons">&#xE7F4;</i>
            <Badge pill theme="danger">
              {this.props?.unReadNotifications?.filter(
                (item) => item.read === false
              ).length <= 9
                ? this.props?.unReadNotifications?.filter(
                    (item) => item.read === false
                  ).length
                : "9+"}
            </Badge>
          </div>
        </NavLink>
        <Collapse
          open={this.state.visible}
          className="dropdown-menu dropdown-menu-small"
          style={{
            overflowY: "scroll",
            float: "left",
            maxHeight: "400px",
          }}
        >
          {this.props.notifications?.length ? (
            <DropdownItem
              className="notification__all text-right"
              style={{
                color: "#818ea3",
                cursor: "default",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ cursor: "pointer" }}>
                Only show unread
                <Switch
                  value={this.props.onlyRead}
                  onChange={() => this.props.setOnlyRead((data) => !data)}
                  size="small"
                />
              </span>
              <span
                className="underline"
                style={{ cursor: "pointer" }}
                onClick={() => this.props.markAllAsRead()}
              >
                Mark all as read
              </span>
            </DropdownItem>
          ) : null}
          {this.props.unReadNotifications?.length ? (
            this.props.unReadNotifications
              .sort((a, b) => {
                return b.time.localeCompare(a.time);
              })
              .slice(0, this.props.more)
              .map((item, index) => {
                let { _id, message, title, site, time, read } = item;
                return (
                  <DropdownItem
                    key={index}
                    onClick={
                      read
                        ? () => {
                            console.log("read");
                          }
                        : () => {
                            this.props.markAsRead(_id, index);
                          }
                    }
                    style={{
                      position: "relative",
                      backgroundColor: !read ? "rgb(244, 245, 247)" : null,
                    }}
                  >
                    <div className="notification__icon-wrapper">
                      <div className="notification__icon">
                        <i className="material-icons">&#xE6E1;</i>
                      </div>
                    </div>
                    <div className="notification__content">
                      <span className="notification__category">{title}</span>
                      <p className="notification__site">{site}</p>
                      <p style={{ width: "90%" }}>{message}</p>
                      <small className="text-muted">
                        {time.slice(11, 16).split(":").join(":")}
                      </small>
                    </div>
                    {read ? (
                      <RadioButtonUncheckedIcon
                        style={{
                          color: "#007bff",
                          position: "absolute",
                          right: "16px",
                          top: "0",
                          bottom: "0",
                          margin: "auto",
                        }}
                      />
                    ) : (
                      <RadioButtonCheckedIcon
                        style={{
                          color: "#007bff",
                          position: "absolute",
                          right: "16px",
                          top: "0",
                          bottom: "0",
                          margin: "auto",
                        }}
                      />
                    )}
                  </DropdownItem>
                );
              })
          ) : (
            <>
              <DropdownItem>
                <div className="notification__icon-wrapper">
                  <div className="notification__icon">
                    <i className="material-icons">&#xE6E1;</i>
                  </div>
                </div>
                <div className="notification__content">
                  <span className="notification__category">
                    Everything up to date
                  </span>
                  <p>You Have no Notifications</p>
                </div>
              </DropdownItem>
              <DropdownItem className="notification__all text-center">
                {" "}
              </DropdownItem>
            </>
          )}
          {this.props.unReadNotifications?.length >= 5 ? (
            <DropdownItem
              className="notification__all text-center"
              onClick={
                this.props.more === 4
                  ? () => {
                      this.props.setMore(this.props.unReadNotifications.length);
                    }
                  : () => {
                      this.props.setMore(4);
                    }
              }
            >
              {this.props.more === 4 ? "Load More..." : "Hide"}
            </DropdownItem>
          ) : this.props.unReadNotifications?.length < 5 &&
            this.props.unReadNotifications?.length >= 1 ? (
            <DropdownItem className="notification__all text-center">
              {" "}
            </DropdownItem>
          ) : null}
        </Collapse>
      </NavItem>
    ) : (
      <NavItem className="border-right border-left dropdown notifications link--cursor">
        <NavLink
          className="nav-link-icon text-center"
          onClick={this.toggleNotifications}
        >
          <div className="nav-link-icon__wrapper">
            <i className="material-icons">&#xE7F4;</i>
            <Badge pill theme="danger">
              {this.props?.unReadNotifications?.filter(
                (item) => item.read === false
              ).length <= 9
                ? this.props?.unReadNotifications?.filter(
                    (item) => item.read === false
                  ).length
                : "9+"}
            </Badge>
          </div>
        </NavLink>
        <Collapse
          open={this.state.visible}
          className="dropdown-menu dropdown-menu-small"
          style={{
            overflowY: "scroll",
            float: "left",
            maxHeight: "400px",
          }}
        >
          {this.props.notifications?.length ? (
            <DropdownItem
              className="notification__all text-right"
              style={{
                color: "#818ea3",
                cursor: "default",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ cursor: "pointer" }}>
                Only show unread
                <Switch
                  value={this.props.onlyRead}
                  onChange={() => this.props.setOnlyRead((data) => !data)}
                  size="small"
                />
              </span>
              <span
                className="underline"
                style={{ cursor: "pointer" }}
                onClick={() => this.props.markAllAsRead()}
              >
                Mark all as read
              </span>
            </DropdownItem>
          ) : null}
          {this.props.notifications?.length ? (
            this.props.notifications
              .sort((a, b) => {
                return b.time.localeCompare(a.time);
              })
              .slice(0, this.props.more)
              .map((item, index) => {
                let { _id, message, title, site, time, read } = item;
                return (
                  <DropdownItem
                    key={index}
                    onClick={
                      read
                        ? () => {
                            console.log("read");
                          }
                        : () => {
                            this.props.markAsRead(_id, index);
                          }
                    }
                    style={{
                      position: "relative",
                      backgroundColor: !read ? "rgb(244, 245, 247)" : null,
                    }}
                  >
                    <div className="notification__icon-wrapper">
                      <div className="notification__icon">
                        <i className="material-icons">&#xE6E1;</i>
                      </div>
                    </div>
                    <div className="notification__content">
                      <span className="notification__category">{title}</span>
                      <p className="notification__site">{site}</p>
                      <p style={{ width: "90%" }}>{message}</p>
                      <small className="text-muted">
                        {time.slice(11, 16).split(":").join(":")}
                      </small>
                    </div>
                    {read ? (
                      <RadioButtonUncheckedIcon
                        style={{
                          color: "#007bff",
                          position: "absolute",
                          right: "16px",
                          top: "0",
                          bottom: "0",
                          margin: "auto",
                        }}
                      />
                    ) : (
                      <RadioButtonCheckedIcon
                        style={{
                          color: "#007bff",
                          position: "absolute",
                          right: "16px",
                          top: "0",
                          bottom: "0",
                          margin: "auto",
                        }}
                      />
                    )}
                  </DropdownItem>
                );
              })
          ) : (
            <>
              <DropdownItem>
                <div className="notification__icon-wrapper">
                  <div className="notification__icon">
                    <i className="material-icons">&#xE6E1;</i>
                  </div>
                </div>
                <div className="notification__content">
                  <span className="notification__category">
                    Everything up to date
                  </span>
                  <p>You Have no Notifications</p>
                </div>
              </DropdownItem>
              <DropdownItem className="notification__all text-center">
                {" "}
              </DropdownItem>
            </>
          )}
          {this.props.notifications?.length >= 5 ? (
            <DropdownItem
              className="notification__all text-center"
              onClick={
                this.props.more === 4
                  ? () => {
                      this.props.setMore(this.props.notifications.length);
                    }
                  : () => {
                      this.props.setMore(4);
                    }
              }
            >
              {this.props.more === 4 ? "Load More..." : "Hide"}
            </DropdownItem>
          ) : this.props.notifications?.length < 5 &&
            this.props.notifications?.length >= 1 ? (
            <DropdownItem className="notification__all text-center">
              {" "}
            </DropdownItem>
          ) : null}
        </Collapse>
      </NavItem>
    );
  }
}
