import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "shards-react";
import NavbarSearch from "../components/layout/MainNavbar/NavbarSearch";
import PageTitle from "../components/common/PageTitle";
import Table from "../components/registered-sites/Table";
import { API_URL, API_URL_PSI } from "../constants";
import { Modal } from "react-bootstrap";
import CircularProgress from "@mui/material/CircularProgress";
import { useSnackbar } from "notistack";
import ReactPaginate from "react-paginate";
import { getData } from "../state/actions/sites.actions";
import { useDispatch, useSelector } from "react-redux";
import { getSitesStatusData } from "../state/actions/psi.actions";
import {
  loadingNewSite,
  loadingNewSiteDone,
} from "../state/actions/sites.actions";
import { getUserPlanData } from "../state/actions/user.actions";
import TicTacToe from "../components/tictactoe/TicTacToe";
import ChooseTipsOrTicTacToe from "../components/tictactoe/ChooseTipsOrTicTacToe";
import Stats from "../components/tictactoe/Stats";

const RegisteredSites = () => {
  const [crawledPages, setCrawledPages] = useState([]);
  const [selectedPages, setSelectedPages] = useState([]);
  const [justAddedSite, setJustAddedSite] = useState([]);
  const [finishedPsiScan, setFinishedPsiScan] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermCrawledPages, setSearchTermCrawledPages] = useState("");
  const [ticTacToeOpen, setTicTacToeOpen] = useState(false);
  const [statsOpen, setStatsOpen] = useState(false);
  const [statsOrTicTacToeOpen, setStatsOrTicTacToeOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [invalidURL, setInvalidURL] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [inputs, setInputs] = useState({
    siteName: "",
    siteUrl: "",
    propertyId: "",
  });
  const [pageNumber, setPageNumber] = useState(0);
  const [alreadyAddedPages, setAlreadyAddedPages] = useState([]);

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;

  let { siteName, siteUrl, propertyId } = inputs;
  const user = useSelector((state) =>
    state.authentication.user
      ? state.authentication.user
      : state.registration.user
  );
  const currentPlan = useSelector((state) => state.plan.plan);
  const sites = useSelector((state) => state.sites.sites);
  const sitesStatus = useSelector((state) => state.sitesStatus.sitesStatus);
  const loading = useSelector((state) => state.sitesStatus.loading);
  const newSite = useSelector((state) => state.newSite);

  const dispatch = useDispatch();
  // const history = useHistory();

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showPages, setShowPages] = useState(false);
  const handlePagesClose = () => setShowPages(false);
  const handlePagesShow = () => {
    setSelectedPages([]);
    setShowPages(true);
  };

  const pageCount = Math.ceil(crawledPages.length / usersPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const selectAllPages = (e) => {
    if (e.target.checked) {
      setSelectedPages(() => [...crawledPages]);
    } else {
      setSelectedPages([]);
    }
  };

  const siteLimit = async (email) => {
    return await axios
      .get(`${API_URL}/sites/sites-limit/${email}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  const addSite = async (e) => {
    e.target.innerText = "Adding...";
    e.target.disabled = true;
    siteUrl = isValidURL(siteUrl);
    setInputs("", "", "");
    if (siteUrl.length > 0) {
      setInvalidURL(false);
      let existingSites = [];
      sitesStatus?.forEach((item) => {
        existingSites.push(item.siteName);
      });
      let urlWithSlash = siteUrl;
      if (urlWithSlash.at(-1) !== "/") {
        urlWithSlash += "/";
      }
      if (
        !existingSites.includes(siteUrl) ||
        !existingSites.includes(urlWithSlash)
      ) {
        const returnedValue = await siteLimit(user.email);
        if (returnedValue?.data?.success) {
          setSubmitted(true);
          const scanningSnackbar = enqueueSnackbar(`Scanning ...`, {
            variant: "info",
            persist: true,
          });
          setStatsOrTicTacToeOpen(true);
          enqueueSnackbar(
            "The Initial PageSpeed Insights Scan takes some time to finish, please be patient!",
            { variant: "warning" }
          );
          handleClose();
          if (siteUrl) {
            await makePsiScan();
          }
          closeSnackbar(scanningSnackbar);
          dispatch(getData(user.rootUser ? user.rootUser : user.email))
            .then((response) => {
              let urls = [];
              response.forEach((item) => {
                urls.push(item.url);
              });
              dispatch(getSitesStatusData(urls));
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          e.target.innerText = "Add Site";
          e.target.disabled = false;
          const sitesLimitSnackbar = enqueueSnackbar(
            "You are not allowed to add more sites!",
            {
              variant: "warning",
            }
          );
          setTimeout(() => {
            closeSnackbar(sitesLimitSnackbar);
          }, 5000);
        }
      } else {
        e.target.innerText = "Add Site";
        e.target.disabled = false;
        const siteAlreadyExistsSnackbar = enqueueSnackbar(
          "Site already exists!",
          {
            variant: "warning",
          }
        );
        setTimeout(() => {
          closeSnackbar(siteAlreadyExistsSnackbar);
        }, 5000);
      }
    } else {
      e.target.innerText = "Add Site";
      e.target.disabled = false;
      setSubmitted(true);
      setInvalidURL(true);
    }
  };

  const addOtherPages = async () => {
    let pagesArray = [];
    sites?.forEach((site) => {
      if (site.url === justAddedSite[1]) {
        pagesArray.push(...site.pages);
      }
    });
    if (!currentPlan?.page_unlimited) {
      if (selectedPages.length + pagesArray.length <= currentPlan?.page_limit) {
        handlePagesClose();
        await saveOtherPages(justAddedSite[0], justAddedSite[1]);
      } else {
        const pageLimitSnackbar = enqueueSnackbar(
          `You're not allowed to add more than ${currentPlan?.page_limit} pages per site!`,
          {
            variant: "warning",
          }
        );
        setTimeout(() => {
          closeSnackbar(pageLimitSnackbar);
        }, 3500);
      }
    } else {
      handlePagesClose();
      await saveOtherPages(justAddedSite[0], justAddedSite[1]);
    }
  };

  const triggerOtherPagesScan = async () => {
    if (selectedPages.length) {
      const parallelScans = 3;
      for (
        let i = 0;
        i < Math.ceil(selectedPages.length / parallelScans);
        i++
      ) {
        const pageUrls = selectedPages.slice(
          i * parallelScans,
          i * parallelScans + parallelScans
        );
        await Promise.allSettled(
          pageUrls.map(async (pageUrl) => {
            const pagesSnackbar = enqueueSnackbar(
              `Scan triggered for: ${pageUrl}`,
              {
                variant: "info",
                persist: true,
              }
            );
            await triggerPsiScan(pageUrl);
            closeSnackbar(pagesSnackbar);
          })
        );
      }
    }
  };

  const saveOtherPages = async (siteName, siteUrl, propertyId) => {
    let request_body = {
      email: user.rootUser ? user.rootUser : user.email,
      site: {
        url: siteUrl,
        siteName: siteName ? siteName : siteUrl,
        propertyId: propertyId,
      },
      pages: selectedPages,
    };
    enqueueSnackbar(
      "The scan for the other pages will take some time to finish, please come back after a while and check the results!",
      { variant: "warning" }
    );
    await triggerOtherPagesScan();
    await axios
      .post(`${API_URL}/sites/addPages`, request_body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(() => {
        const pagesHasBeenAddedSuccessFullySnackbar = enqueueSnackbar(
          `Pages has been added successfully ${request_body.site.url}`,
          {
            variant: "success",
          }
        );
        dispatch(getData(user.rootUser ? user.rootUser : user.email))
          .then((response) => {
            let urls = [];
            response.forEach((item) => {
              urls.push(item.url);
            });
            dispatch(getSitesStatusData(urls));
          })
          .catch((error) => {
            console.log(error);
          });
        setTimeout(() => {
          closeSnackbar(pagesHasBeenAddedSuccessFullySnackbar);
        }, 5000);
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          alert(error.response.data);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  };

  const handleChangePages = (e) => {
    if (!e.target.checked) {
      setSelectedPages((data) => [
        ...data.filter((item) => item !== e.target.value),
      ]);
    } else {
      setSelectedPages([...selectedPages, e.target.value]);
    }
  };

  const isValidURL = (siteUrl) => {
    const regex = new RegExp(
      "(https?://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    if (siteUrl.match(regex)) {
      return siteUrl;
    } else {
      siteUrl = "https://www." + siteUrl;
      return siteUrl;
    }
  };

  const addNewSiteModal = (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Site</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form name="form">
          <div className="form-group">
            <label>Site URL</label>
            <input
              type="url"
              name="siteUrl"
              value={siteUrl}
              placeholder="Enter site url"
              onChange={handleChange}
              className={
                "form-control" + (submitted && invalidURL ? " is-invalid" : "")
              }
            />
            {submitted && invalidURL ? (
              <div className="invalid-feedback">
                Site URL is not valid, please include https in the URL!
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="form-group">
            <label>Site name</label>
            <input
              type="name"
              name="siteName"
              value={siteName}
              placeholder="Enter site name"
              onChange={handleChange}
              className={
                "form-control" + (submitted && invalidURL ? " is-invalid" : "")
              }
            />
          </div>
          <div className="form-group">
            <label>Property id</label>
            <input
              type="id"
              name="propertyId"
              value={propertyId}
              placeholder="Enter property id"
              onChange={handleChange}
              className={
                "form-control" + (submitted && invalidURL ? " is-invalid" : "")
              }
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={(e) => {
            addSite(e);
          }}
        >
          Add Site
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const formatListItemUrl = (text) => {
    let link = text;
    text = text.length > 47 ? text.substring(0, 100) + "..." : text;

    text = React.createElement(
      "a",
      {
        className: "pages-link",
        href: link,
        target: "_blank",
      },
      text
    );

    return text;
  };

  const listOfPages = (
    <Modal show={showPages} size="lg" onHide={handlePagesClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          Do you want to add these pages from that origin too?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="h5">
          Keep in mind that only the homepage is for free
        </div>
        {!currentPlan?.page_unlimited && crawledPages.length ? (
          <div className="h6 mb-4">
            You are allowed to add up to {currentPlan?.page_limit} pages per
            site
          </div>
        ) : (
          <div className="h6 mb-4">There are no fetched pages </div>
        )}
        {crawledPages.length ? (
          <div style={{ minHeight: "331px" }}>
            <>
              <Row style={{ margin: "20px 0" }}>
                <input
                  className="search-term-crawled-pages"
                  placeholder="Search for pages..."
                  onChange={(e) => setSearchTermCrawledPages(e.target.value)}
                />
              </Row>
              <Row className="form-checkboxes">
                <input
                  type="checkbox"
                  name="addAll"
                  onChange={(e) => {
                    selectAllPages(e);
                  }}
                />
                <label htmlFor={"addAll"}>Add all pages</label>
              </Row>
            </>
            {crawledPages
              .filter((element) =>
                element
                  .toLowerCase()
                  .includes(searchTermCrawledPages.toLowerCase())
              )
              .slice(pagesVisited, pagesVisited + usersPerPage)
              .map((element, index) => {
                return (
                  <Row key={index} className="form-checkboxes">
                    {index === 0 ? (
                      <></>
                    ) : alreadyAddedPages.includes(element) ? (
                      <input
                        type="checkbox"
                        name={"page_" + element}
                        value={element}
                        disabled={true}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        name={"page_" + element}
                        value={element}
                        checked={selectedPages.includes(element)}
                        onChange={handleChangePages}
                      />
                    )}

                    <label htmlFor={"page_" + element}>
                      {formatListItemUrl(element)}
                    </label>
                  </Row>
                );
              })}
            <Row style={{ justifyContent: "center" }}>
              <ReactPaginate
                previousLabel="Previous"
                nextLabel="Next"
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBtns"}
                previousLinkClassName={"previousBtn"}
                nextLinkClassName={"nextBtn"}
                activeClassName={"paginationActive"}
              />
            </Row>
          </div>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handlePagesClose}>
          Close
        </Button>
        <Button
          variant="primary"
          // onClick={() => {
          //   selectedPages.length > currentPlan?.page_limit &&
          //   !currentPlan?.page_unlimited
          //     ? history.push("/subscription-plans")
          //     : addOtherPages();
          // }}
          onClick={() => {
            addOtherPages();
          }}
          disabled={
            selectedPages.length > currentPlan?.page_limit &&
            !currentPlan?.page_unlimited
              ? true
              : false
          }
        >
          Add Pages
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const addData = async (siteName, siteUrl, propertyId) => {
    let request_body = {
      email: user.rootUser ? user.rootUser : user.email,
      sites: [{ url: siteUrl, siteName: siteName, propertyId: propertyId }],
    };
    await axios
      .post(`${API_URL}/sites`, request_body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(() => {
        dispatch(
          loadingNewSite({
            name: siteName,
            link: siteUrl,
            id: propertyId,
          })
        );
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          alert(error.response.data);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
    setTimeout(() => {
      handleClose();
    }, 11000);
  };

  async function makePsiScan() {
    let request_body = {
      email: user.rootUser ? user.rootUser : user.email,
      url: siteUrl,
    };
    dispatch(
      loadingNewSite({
        name: siteName,
        link: siteUrl,
        id: propertyId,
      })
    );

    await axios
      .post(`${API_URL_PSI}/scan/`, request_body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(async () => {
        addData(siteName, siteUrl, propertyId);
        setJustAddedSite([siteName, siteUrl]);
        const scanningOtherPagesSnackbar = enqueueSnackbar(
          "Fetching other pages that are on your website ...",
          {
            variant: "info",
            persist: true,
          }
        );
        const listOfPages = await fetchListOfPages();
        enqueueSnackbar(
          "Initial Scan has finished. Go and check the results!",
          {
            variant: "success",
          }
        );
        setCrawledPages(listOfPages);
        closeSnackbar(scanningOtherPagesSnackbar);
        handlePagesShow();
      })
      .catch((error) => {
        setStatsOrTicTacToeOpen(false);
        setTicTacToeOpen(false);
        setStatsOpen(false);

        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        const scanFailedSnackbar = enqueueSnackbar(
          `Scan failed (${siteUrl}), please try again later`,
          {
            variant: "error",
          }
        );
        dispatch(loadingNewSiteDone());
        setFinishedPsiScan(false);
        setTimeout(() => {
          closeSnackbar(scanFailedSnackbar);
        }, 5000);
      });
  }

  async function triggerPsiScan(pageUrl) {
    const request_body = {
      email: user.rootUser ? user.rootUser : user.email,
      url: pageUrl,
    };
    await axios
      .post(`${API_URL_PSI}/scan/`, request_body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(() => {
        const successSnackbar = enqueueSnackbar(
          `Scan finished for: ${pageUrl}`,
          {
            variant: "success",
          }
        );
        setTimeout(() => {
          closeSnackbar(successSnackbar);
        }, 5000);
      })
      .catch((error) => {
        console.log(error);
        const errorSnackbar = enqueueSnackbar(`Scan failed for: ${pageUrl}`, {
          variant: "error",
        });
        setTimeout(() => {
          closeSnackbar(errorSnackbar);
        }, 5000);
      });
  }

  async function fetchListOfPages() {
    let data = [];
    let request_body = {
      url: siteUrl,
    };
    await axios
      .post(`${API_URL}/sites/findpages`, request_body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setStatsOrTicTacToeOpen(false);
        setTicTacToeOpen(false);
        setStatsOpen(false);

        dispatch(loadingNewSiteDone());
        data = response.data;
      })
      .catch((error) => {
        setStatsOrTicTacToeOpen(false);
        setTicTacToeOpen(false);
        setStatsOpen(false);

        console.log(error);
        const fetchingPagesFailedSnackbar = enqueueSnackbar(
          `Fetching pages for site ${siteUrl} failed, please try again later`,
          {
            variant: "warning",
          }
        );
        setTimeout(() => {
          closeSnackbar(fetchingPagesFailedSnackbar);
        }, 5000);
      });
    return data;
  }

  async function handleFetchPages(url) {
    const scanningOtherPagesSnackbar = enqueueSnackbar(
      "Fetching other pages that are on your website ...",
      {
        variant: "info",
        persist: true,
      }
    );
    let data = [];
    const request_body = {
      url: url,
    };
    let pagesArray = [];
    if (sites?.length)
      for (const site of sites) {
        if (site.url === url) {
          pagesArray = site.pages;
        }
      }
    setAlreadyAddedPages(pagesArray);
    await axios
      .post(`${API_URL}/sites/findpages`, request_body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        handlePagesShow();
        data = response.data;
        setCrawledPages(data);
        setJustAddedSite([url, url]);
        closeSnackbar(scanningOtherPagesSnackbar);
      })
      .catch((error) => {
        console.log(error);
        const fetchingPagesSiteFailedSnackbar = enqueueSnackbar(
          "Fetching other pages that are on your website failed ...",
          {
            variant: "error",
          }
        );
        setTimeout(() => {
          closeSnackbar(fetchingPagesSiteFailedSnackbar);
        }, 5000);
      });
    return data;
  }

  useEffect(() => {
    if (!sitesStatus && !loading) {
      dispatch(getData(user.rootUser ? user.rootUser : user.email))
        .then((response) => {
          if (response.length) {
            let urls = [];
            response.forEach((item) => {
              urls.push(item.url);
            });
            dispatch(getSitesStatusData(urls));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    dispatch(getUserPlanData(user.userId));
  }, [finishedPsiScan]);

  return (
    <Container
      fluid
      className="main-content-container px-4"
      style={{ position: "relative" }}
    >
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Add New Site"
          subtitle="Registered Sites"
          className="text-sm-left"
        />
      </Row>
      <Row noGutters className="page-header pb-4">
        <Col
          className="mr-4 d-none d-md-flex d-lg-flex"
          style={{ width: "400px" }}
        >
          <NavbarSearch
            id="search"
            placeholder="Search"
            htmlFor="search"
            onChange={handleSearch}
            style={{
              height: "34px",
              background: "#FFFFFF",
              borderRadius: "6px",
            }}
          />
        </Col>
        <Col>
          <Button
            size="lg"
            onClick={handleShow}
            disabled={
              (newSite.loading ? true : false) ||
              (!currentPlan?.site_unlimited &&
              sites?.length >= currentPlan?.site_limit
                ? true
                : false)
            }
            style={{ float: "right", height: "40px", paddingTop: "6px" }}
          >
            Add Site
          </Button>
          {addNewSiteModal}
        </Col>
      </Row>
      {!currentPlan?.site_unlimited &&
      sites?.length >= currentPlan?.site_limit ? (
        <div className={`alert alert-warning`} style={{ borderRadius: "7px" }}>
          You&apos;re not allowed to add more than {currentPlan?.site_limit}{" "}
          sites
        </div>
      ) : null}
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Registered Sites</h6>
            </CardHeader>
            <CardBody className="p-0 pb-3 registered-sites">
              {loading ? (
                <CircularProgress />
              ) : (
                <Table
                  finishedPsiScan={finishedPsiScan}
                  loadingNewSite={newSite}
                  searchTerm={searchTerm}
                  handleFetchPages={handleFetchPages}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ChooseTipsOrTicTacToe
        open={statsOrTicTacToeOpen}
        setOpen={setStatsOrTicTacToeOpen}
        setStatsOpen={setStatsOpen}
        setTicTacToeOpen={setTicTacToeOpen}
      />
      <Stats open={statsOpen} setOpen={setStatsOpen} />
      <TicTacToe open={ticTacToeOpen} setOpen={setTicTacToeOpen} />
      {listOfPages}
    </Container>
  );
};

export default RegisteredSites;
