import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { registration } from "./registration.reducer";
import { users, plan } from "./users.reducer";
import { alert } from "./alert.reducer";
import {
  sites,
  newSite,
  competitorSites,
  newCompetitorSite,
} from "./sites.reducer";
import { newPingData, pingData } from "./ping.reducer";
import { notifications } from "./notifications.reducer";
import {
  originSummary,
  metryVScore,
  ranking,
  monthlyLabData,
  upTimeData,
  w3Validator,
  monthlyOverall,
  sitesStatus,
  competitorSitesStatus,
} from "./psi.reducer";

const rootReducer = combineReducers({
  authentication,
  registration,
  notifications,
  users,
  alert,
  sites,
  competitorSites,
  metryVScore,
  originSummary,
  monthlyOverall,
  ranking,
  monthlyLabData,
  upTimeData,
  w3Validator,
  sitesStatus,
  competitorSitesStatus,
  newSite,
  newCompetitorSite,
  plan,
  newPingData,
  pingData,
});

export default rootReducer;
