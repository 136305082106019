import React from "react";
import { useHistory, useLocation } from "react-router-dom";

const BackButton = () => {
  let history = useHistory();
  const location = useLocation();

  if (location.pathname !== "/dashboard") {
    return (
      <a
        className="nav-link nav-link-icon toggle-sidebar text-center back-button d-flex align-items-center"
        onClick={() => history.goBack()}
      >
        <i className="material-icons">&#xE5C4;</i>
        <span className="d-none d-md-block ml-2">Back</span>
      </a>
    );
  } else {
    return "";
  }
};

export default BackButton;
