import React, { useState, useEffect } from "react";
import UptimeOverviewGraphComparison from "./UptimeOverviewGraphComparison";
import MainSiteUptimeOverviewGraphComparison from "./MainSiteUptimeOverviewGraphComparison";
import CircularProgress from "@mui/material/CircularProgress";
import { Card, CardBody, Row, Col } from "shards-react";
import axios from "axios";
import { API_URL_PSI } from "../../constants";

const UptimeOverviewComparison = ({ siteUrl, mainSiteUrl }) => {
  const [uptime, setUptime] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [mainSiteUptime, setMainSiteUptime] = useState([]);
  const [mainSiteLoading, setMainSiteLoading] = useState(false);
  const [mainSiteError, setMainSiteError] = useState(false);

  const getUptimeData = async (siteUrl) => {
    try {
      setLoading(true);
      setError(false);
      let uptimeData = [];
      const response = await axios.get(
        `${API_URL_PSI}/uptime/monthly`,
        {
          params: {
            url: siteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      response.data.forEach((element) => {
        uptimeData.push(element.simple && element.simple.status ? 2 : 1);
      });
      setLoading(false);
      setError(false);
      setUptime(uptimeData);
    } catch (error) {
      setLoading(false);
      setError(true);
      console.log(error);
    }
  };

  const getMainSiteUptimeData = async (mainSiteUrl) => {
    try {
      setMainSiteLoading(true);
      setMainSiteError(false);
      let uptimeData = [];
      const response = await axios.get(
        `${API_URL_PSI}/uptime/monthly`,
        {
          params: {
            url: mainSiteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      response.data.forEach((element) => {
        uptimeData.push(element.simple && element.simple.status ? 2 : 1);
      });
      setMainSiteLoading(false);
      setMainSiteError(false);
      setMainSiteUptime(uptimeData);
    } catch (error) {
      setMainSiteLoading(false);
      setMainSiteError(true);
      console.log(error);
    }
  };

  const newScan = async (mainSiteUrl) => {
    try {
      setMainSiteLoading(true);
      setMainSiteError(false);
      await axios.post(
        `${API_URL_PSI}/uptime`,
        {
          url: mainSiteUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      await getMainSiteUptimeData(mainSiteUrl);
      setMainSiteLoading(false);
    } catch (error) {
      await getMainSiteUptimeData(mainSiteUrl);
      setMainSiteLoading(false);
      setMainSiteError(true);
      console.log(error);
    }
  };

  useEffect(async () => {
    await getUptimeData(siteUrl);
  }, [siteUrl]);

  useEffect(async () => {
    await getMainSiteUptimeData(mainSiteUrl);
  }, [mainSiteUrl]);

  return (
    <Row>
      <Col className="col-lg-6 col-md-12 col-sm-12 mb-4">
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress
              style={{ margin: "2rem", height: "140px", width: "140px" }}
            />
            <div>Loading Server Uptime Graph</div>
          </div>
        ) : error ? (
          <Card small className="h-100">
            <CardBody>No sufficient data for uptime status!</CardBody>
          </Card>
        ) : (
          <UptimeOverviewGraphComparison data={uptime} />
        )}
      </Col>
      <Col className="col-lg-6 col-md-12 col-sm-12 mb-4">
        {mainSiteLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress
              style={{ margin: "2rem", height: "140px", width: "140px" }}
            />
            <div>Loading Server Uptime Graph</div>
          </div>
        ) : mainSiteError ? (
          <Card small className="h-100">
            <CardBody>No sufficient data for uptime status!</CardBody>
          </Card>
        ) : (
          <MainSiteUptimeOverviewGraphComparison
            data={mainSiteUptime}
            newScan={newScan}
            mainSiteUrl={mainSiteUrl}
          />
        )}
      </Col>
    </Row>
  );
};

export default UptimeOverviewComparison;
