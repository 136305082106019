import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DesktopMacIcon from "@mui/icons-material/DesktopMac";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import { Button } from "shards-react";
import { getStatus, getOverallScoreColor } from "../../helpers/scores";
import axios from "axios";
import { API_URL, API_URL_PSI } from "../../constants";
import {
  loadingNewCompetitorSite,
  loadingNewCompetitorSiteDone,
} from "../../state/actions/sites.actions";
import { removeCompetitorSiteFromState } from "../../state/actions/psi.actions";
import { useSnackbar } from "notistack";
import { Modal } from "react-bootstrap";

const CompetitorSitesTable = ({ fetchCompetitorSites }) => {
  const [siteName, setSiteName] = useState("");
  const [siteLink, setSiteLink] = useState("");
  const [invalidURL, setInvalidURL] = useState(false);
  const [invalidSiteName, setInvalidSiteName] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [deleteSiteModalShow, setDeleteSiteModalShow] = useState({
    open: false,
    url: "",
  });

  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const mainSites = useSelector((state) =>
    state.sitesStatus.sitesStatus ? state.sitesStatus.sitesStatus : []
  );
  const competitorSitesStatus = useSelector((state) =>
    state.competitorSitesStatus.sitesStatus
      ? state.competitorSitesStatus.sitesStatus
      : []
  );
  const statusloading = useSelector(
    (state) => state.competitorSitesStatus.loading
  );
  const sitesError = useSelector((state) => state.competitorSitesStatus.error);

  const sitesloading = useSelector((state) => state.competitorSites.loading);
  const competitorSitesNames = useSelector(
    (state) => state.competitorSites.sites
  );
  const user = useSelector((state) =>
    state.authentication.user
      ? state.authentication.user
      : state.registration.user
  );
  const currentPlan = useSelector((state) => state.plan.plan);
  const currentPlanError = useSelector((state) => state.plan.error);
  const currentPlanLoading = useSelector((state) => state.plan.loading);

  const newCompetitorSite = useSelector((state) => state.newCompetitorSite);

  const regex = new RegExp(
    "(https?://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
  );

  function ensureHttps(url) {
    const fullUrlPattern = new RegExp("^(https?://)");
    const wwwPattern = new RegExp("^www.");
    if (!fullUrlPattern.test(url)) {
      if (!wwwPattern.test(url)) {
        url = "https://www." + url + "/";
      } else {
        url = "https://" + url + "/";
      }
    } else if (url.startsWith("http://")) {
      url.replace("http://", "https://");
    } else if (!url.startsWith("https://www.")) {
      url.replace("https://", "https://www.");
    }
    return url;
  }

  const formatListItemUrl = (text) => {
    let link = text;
    text = text.length > 47 ? text.substring(0, 100) + "..." : text;

    text = React.createElement(
      "a",
      {
        href: link,
        target: "_blank",
      },
      text
    );

    return text;
  };

  const competitorSiteLimit = async () => {
    try {
      const data = await axios.get(
        `${API_URL}/competitor/sites-limit/${user.email}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return data;
    } catch (error) {
      console.log(error.response);
      return error;
    }
  };

  const makePsiScan = async () => {
    const request_body = {
      email: user.rootUser ? user.rootUser : user.email,
      url: siteLink,
    };
    try {
      const scanningSnackbar = enqueueSnackbar("Scanning ...", {
        variant: "info",
        persist: true,
      });
      enqueueSnackbar(
        "The Initial PageSpeed Insights Scan takes some time to finish, please be patient!",
        { variant: "warning" }
      );
      const psiScan = await axios.post(`${API_URL_PSI}/scan/`, request_body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      closeSnackbar(scanningSnackbar);
      enqueueSnackbar("Initial Scan has finished. Go and check the results!", {
        variant: "success",
      });
      setTimeout(() => {
        closeSnackbar();
      }, 5000);
      return psiScan;
    } catch (error) {
      console.log(error.response);
      enqueueSnackbar(`Scan failed (${siteLink}), please try again later`, {
        variant: "error",
      });
      dispatch(loadingNewCompetitorSiteDone());
      setTimeout(() => {
        closeSnackbar();
      }, 5000);
      return error;
    }
  };

  const addNewCompetitorSite = async () => {
    const limit = await competitorSiteLimit();
    if (limit?.data?.success) {
      setSubmitted(true);
      if (siteName && Boolean(siteLink.match(regex))) {
        setInvalidURL(false);
        setInvalidSiteName(false);
        try {
          dispatch(loadingNewCompetitorSite({ siteName, siteLink }));
          const psiScan = await makePsiScan();
          if (psiScan) {
            await axios.post(`${API_URL}/competitor-sites`, {
              email: user.email,
              sites: [
                {
                  url: siteLink,
                  name: siteName,
                },
              ],
            });
            fetchCompetitorSites();
            dispatch(loadingNewCompetitorSiteDone());
            setSubmitted(false);
          } else {
            fetchCompetitorSites();
            dispatch(loadingNewCompetitorSiteDone());
            setSubmitted(false);
          }
        } catch (error) {
          console.log(error);
          setSubmitted(false);
          dispatch(loadingNewCompetitorSiteDone());
          fetchCompetitorSites();
        }
      } else if (siteName && !siteLink.match(regex)) {
        setInvalidURL(false);
        const newSiteLink = ensureHttps(siteLink);
        setSiteLink(newSiteLink);
        setInvalidSiteName(false);
      } else {
        setInvalidSiteName(true);
      }
    } else {
      if (limit?.data?.limit) {
        enqueueSnackbar(
          `You are not allowed to add more than ${limit?.data?.limit} competitor sites!`,
          {
            variant: "warning",
          }
        );
      } else {
        enqueueSnackbar("You are not allowed to add more competitor sites!", {
          variant: "warning",
        });
      }
      setTimeout(() => {
        closeSnackbar();
      }, 5000);
    }
  };

  const removeCompetitorSite = async (url) => {
    try {
      await axios.post(
        `${API_URL}/competitor-sites/${
          user.rootUser ? user.rootUser : user.email
        }/delete`,
        { data: { url: url } },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      dispatch(removeCompetitorSiteFromState(url));
    } catch (error) {
      console.log(error);
      enqueueSnackbar(`Removing site (${url}) failed, please try again later`, {
        variant: "error",
      });
      setTimeout(() => {
        closeSnackbar();
      }, 5000);
      fetchCompetitorSites();
    }
  };

  const deleteSiteModal = (
    <Modal show={deleteSiteModalShow.open}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Page</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete this site from your list?
      </Modal.Body>
      <Modal.Footer>
        <Button
          theme="danger"
          onClick={() => {
            removeCompetitorSite(deleteSiteModalShow.url);
            setDeleteSiteModalShow({ open: false, url: "" });
          }}
        >
          Yes
        </Button>
        <Button
          onClick={() => {
            setDeleteSiteModalShow({ open: false, url: "" });
          }}
        >
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <table className="table mb-0">
      <thead className="bg-light">
        <tr>
          <th scope="col" className="border-0">
            #
          </th>
          <th scope="col" className="border-0">
            Name
          </th>
          <th scope="col" className="border-0">
            URL
          </th>
          <th scope="col" className="border-0">
            Web Vitals Status
          </th>
          <th scope="col" className="border-0">
            Overall Score
          </th>
          <th scope="col" className="border-0"></th>
          <th scope="col" className="border-0"></th>
        </tr>
      </thead>
      <tbody>
        {statusloading || sitesloading ? (
          <tr>
            <td>
              <CircularProgress
                style={{
                  width: "20px",
                  height: "20px",
                  position: "relative",
                  top: "6px",
                }}
              />
            </td>
          </tr>
        ) : !mainSites?.length && !newCompetitorSite.loading ? (
          <tr>
            <td colSpan="2">
              There are no sites yet added from you. Please go ahead and try it
              out!
              <Link
                to={{
                  pathname: "/registered-sites",
                }}
              >
                <Button className="mx-4">Add Site</Button>
              </Link>
            </td>
            <td colSpan="5"></td>
          </tr>
        ) : !competitorSitesStatus?.length && !newCompetitorSite.loading ? (
          <tr>
            <td colSpan="7">
              There are no competitor sites yet added from you. Please go ahead
              and try it out!
            </td>
          </tr>
        ) : (
          competitorSitesStatus.map((site, index) => (
            <tr style={{ position: "relative" }} key={index}>
              <td>{index + 1}</td>
              <td>
                {competitorSitesNames.map((competitorSite) => {
                  if (competitorSite.url === site.siteName) {
                    return competitorSite.siteName;
                  }
                })}
              </td>
              <td>{formatListItemUrl(site.siteName)}</td>
              <td>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "40%", marginRight: "1rem" }}>
                    <PhoneIphoneIcon fontSize="small" />
                    <span
                      className={
                        site.mobileStatus ? getStatus(site.mobileStatus)[1] : ""
                      }
                    >
                      {site && !sitesError ? (
                        getStatus(site.mobileStatus)[0]
                      ) : sitesError ? (
                        "-"
                      ) : (
                        <CircularProgress
                          style={{
                            width: "20px",
                            height: "20px",
                            position: "relative",
                            top: "6px",
                          }}
                        />
                      )}
                    </span>
                  </div>
                  <div style={{ width: "40%" }}>
                    <DesktopMacIcon fontSize="small" />{" "}
                    <span
                      className={site ? getStatus(site.desktopStatus)[1] : ""}
                    >
                      {site && !sitesError ? (
                        getStatus(site.desktopStatus)[0]
                      ) : sitesError ? (
                        "-"
                      ) : (
                        <CircularProgress
                          style={{
                            width: "20px",
                            height: "20px",
                            position: "relative",
                            top: "6px",
                          }}
                        />
                      )}
                    </span>
                  </div>
                </div>
              </td>
              <td>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%" }}>
                    <PhoneIphoneIcon fontSize="small" />
                    <span
                      className={
                        site ? getOverallScoreColor(site.mobileScore) : ""
                      }
                    >
                      {site && !sitesError ? (
                        parseInt(site.mobileScore)
                      ) : sitesError ? (
                        "-"
                      ) : (
                        <CircularProgress
                          style={{
                            width: "20px",
                            height: "20px",
                            position: "relative",
                            top: "6px",
                          }}
                        />
                      )}
                    </span>
                  </div>

                  <div style={{ width: "50%" }}>
                    <DesktopMacIcon fontSize="small" />{" "}
                    <span
                      className={
                        site ? getOverallScoreColor(site.desktopScore) : ""
                      }
                    >
                      {site && !sitesError ? (
                        parseInt(site.desktopScore)
                      ) : sitesError ? (
                        "-"
                      ) : (
                        <CircularProgress
                          style={{
                            width: "20px",
                            height: "20px",
                            position: "relative",
                            top: "6px",
                          }}
                        />
                      )}
                    </span>
                  </div>
                </div>
              </td>
              <td colSpan="2">
                <Button
                  theme="danger"
                  style={{ float: "right" }}
                  onClick={() => {
                    setDeleteSiteModalShow({ open: true, url: site.siteName });
                  }}
                  className="mx-2"
                >
                  Remove Site
                </Button>
                <Link
                  to={{
                    pathname: "/comparisionDetails",
                    state: {
                      siteUrl: site.siteName,
                      siteName: competitorSitesNames.map((competitorSite) => {
                        if (competitorSite.url === site.siteName) {
                          return competitorSite.siteName;
                        }
                      }),
                    },
                  }}
                >
                  <Button theme="secondary" style={{ float: "right" }}>
                    View Report
                  </Button>
                </Link>
              </td>
            </tr>
          ))
        )}
        {newCompetitorSite.loading ? (
          <tr style={{ position: "relative" }}>
            <td>
              {competitorSitesStatus?.length + 1
                ? competitorSitesStatus?.length + 1
                : 1}
            </td>
            <td>{newCompetitorSite.name}</td>
            <td>{formatListItemUrl(newCompetitorSite.link)}</td>
            <td>
              <div style={{ display: "flex" }}>
                <div style={{ width: "40%", marginRight: "1rem" }}>
                  <PhoneIphoneIcon fontSize="small" />
                  <CircularProgress
                    style={{
                      width: "20px",
                      height: "20px",
                      position: "relative",
                      top: "6px",
                    }}
                  />
                </div>
                <div style={{ width: "40%" }}>
                  <DesktopMacIcon fontSize="small" />
                  <CircularProgress
                    style={{
                      width: "20px",
                      height: "20px",
                      position: "relative",
                      top: "6px",
                    }}
                  />
                </div>
              </div>
            </td>
            <td>
              <div style={{ display: "flex" }}>
                <div style={{ width: "50%" }}>
                  <PhoneIphoneIcon fontSize="small" />
                  <CircularProgress
                    style={{
                      width: "20px",
                      height: "20px",
                      position: "relative",
                      top: "6px",
                    }}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <DesktopMacIcon fontSize="small" />
                  <CircularProgress
                    style={{
                      width: "20px",
                      height: "20px",
                      position: "relative",
                      top: "6px",
                    }}
                  />
                </div>
              </div>
            </td>
            <td colSpan="2">
              <Button
                theme="danger"
                disabled
                className="mx-2"
                style={{ float: "right" }}
              >
                Remove Site
              </Button>
              <Button theme="secondary" disabled style={{ float: "right" }}>
                View Report
              </Button>
            </td>
          </tr>
        ) : null}
        {mainSites?.length ? (
          <tr style={{ position: "relative" }}>
            <td>
              {competitorSitesStatus?.length +
              1 +
              (newCompetitorSite.loading ? 1 : 0)
                ? competitorSitesStatus?.length +
                  1 +
                  (newCompetitorSite.loading ? 1 : 0)
                : newCompetitorSite.loading && !competitorSitesStatus?.length
                ? 2
                : 1}
            </td>
            <td>
              <input
                type="text"
                placeholder="Enter site name"
                value={siteName}
                onChange={(e) => {
                  setSiteName(e.target.value);
                }}
                name="siteName"
                className={`form-control ${
                  submitted && invalidSiteName ? " is-invalid" : ""
                }`}
                disabled={
                  currentPlanLoading
                    ? true
                    : currentPlanError
                    ? true
                    : !currentPlan.competitor_sites_unlimited &&
                      (newCompetitorSite.loading ||
                        competitorSitesNames?.length >=
                          currentPlan.competitor_sites_limit)
                }
              />
              {submitted && invalidSiteName ? (
                <div className="invalid-feedback">
                  Please fill the Site Name
                </div>
              ) : (
                ""
              )}
            </td>
            <td>
              <input
                type="text"
                placeholder="Enter site url"
                value={siteLink}
                onChange={(e) => {
                  setSiteLink(e.target.value);
                }}
                name="siteLink"
                className={`form-control ${
                  submitted && invalidURL ? " is-invalid" : ""
                }`}
                disabled={
                  currentPlanLoading
                    ? true
                    : currentPlanError
                    ? true
                    : !currentPlan.competitor_sites_unlimited &&
                      (newCompetitorSite.loading ||
                        competitorSitesNames?.length >=
                          currentPlan.competitor_sites_limit)
                }
              />
              {submitted && invalidURL ? (
                <div className="invalid-feedback">
                  Site URL is not valid, please include https in the URL!
                </div>
              ) : (
                ""
              )}
            </td>
            <td colSpan="4">
              <Button
                theme="info"
                onClick={addNewCompetitorSite}
                disabled={
                  currentPlanLoading
                    ? true
                    : currentPlanError
                    ? true
                    : !currentPlan.competitor_sites_unlimited &&
                      (newCompetitorSite.loading ||
                        competitorSitesNames?.length >=
                          currentPlan.competitor_sites_limit)
                }
              >
                Add Competitor Site
              </Button>
            </td>
          </tr>
        ) : null}
      </tbody>
      {deleteSiteModal}
    </table>
  );
};

export default CompetitorSitesTable;
