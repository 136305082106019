import {
  userConstants,
  metryvScoreConstants,
  sitesConstants,
  notificationsConstants,
} from "../../constants";
import { userService } from "../../state/services";
import { alertActions } from ".";
import axios from "axios";
import { API_URL } from "../../constants";
import { decodeToken } from "react-jwt";

export const userActions = {
  login,
  logout,
  register,
  update,
  forgotPassword,
};

export const login = (username, password) => async (dispatch) => {
  try {
    dispatch({ type: userConstants.LOGIN_REQUEST });
    const response = await axios.post(`${API_URL}/auth`, {
      email: username,
      password: password,
    });
    localStorage.setItem("token", response.data.accessToken);
    localStorage.setItem("user", username);
    localStorage.setItem("id", response.data.userId);
    dispatch({
      type: userConstants.LOGIN_SUCCESS,
      payload: decodeToken(response.data.accessToken),
    });
  } catch (error) {
    console.log(error.response);
    dispatch({ type: userConstants.LOGIN_FAILURE, payload: error.response });
  }
};

export const loginGoogle = (googleData) => async (dispatch) => {
  try {
    dispatch({ type: userConstants.LOGIN_REQUEST });
    const response = await axios.post(`${API_URL}/auth/google`, {
      googleId: googleData.googleId,
      email: googleData.profileObj.email,
    });
    if (!response.data.error) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("user", response.data.username);
      localStorage.setItem("id", response.data.userId);
      dispatch({
        type: userConstants.LOGIN_SUCCESS,
        payload: decodeToken(response.data.token),
      });
    } else {
      throw {
        response: {
          data: { errors: [response.data.message] },
        },
      };
    }
  } catch (error) {
    if (error.response) {
      dispatch({
        type: userConstants.LOGIN_FAILURE,
        payload: error.response,
      });
    }
    console.log(error);
  }
};

export const loginMicrosoft = (msalInstance, loginRequest) => async (
  dispatch
) => {
  try {
    dispatch({ type: userConstants.LOGIN_REQUEST });
    const response = await msalInstance.loginPopup(loginRequest);
    const data = {
      microsoftId: response.uniqueId,
      email: response.account.username?.toLowerCase(),
    };
    const loggedIn = await axios.post(`${API_URL}/auth/microsoft`, {
      data,
    });
    if (!loggedIn.data.error) {
      localStorage.setItem("token", loggedIn.data.token);
      localStorage.setItem("user", loggedIn.data.username);
      localStorage.setItem("id", loggedIn.data.userId);
      dispatch({
        type: userConstants.LOGIN_SUCCESS,
        payload: decodeToken(loggedIn.data.token),
      });
    } else {
      throw {
        response: {
          data: { errors: [loggedIn.data.message] },
        },
      };
    }
  } catch (error) {
    console.log(error);
    if (error.response) {
      dispatch({ type: userConstants.LOGIN_FAILURE, payload: error.response });
    }
  }
};

export const logout = () => (dispatch) => {
  localStorage.clear();
  sessionStorage.clear();
  dispatch({ type: userConstants.LOGOUT });
  dispatch({ type: userConstants.REGISTER_CLEAR });
  dispatch({ type: userConstants.PLAN_CLEAR });
  dispatch({ type: sitesConstants.GETALL_CLEAR });
  dispatch({ type: sitesConstants.COMPETITOR_SITES_GETALL_CLEAR });
  dispatch({ type: metryvScoreConstants.METRYV_SCORE_CLEAR });
  dispatch({ type: metryvScoreConstants.ORIGIN_SUMMARY_CLEAR });
  dispatch({ type: metryvScoreConstants.GET_MONTHLY_OVERALL_CLEAR });
  dispatch({ type: metryvScoreConstants.RANKING_CLEAR });
  dispatch({ type: metryvScoreConstants.GET_MONTHLY_LAB_DATA_CLEAR });
  dispatch({ type: metryvScoreConstants.GET_UPTIME_DATA_CLEAR });
  dispatch({ type: metryvScoreConstants.GET_W3_DATA_CLEAR });
  dispatch({ type: metryvScoreConstants.GET_SITES_STATUS_CLEAR });
  dispatch({ type: metryvScoreConstants.GET_COMPETITOR_SITES_STATUS_CLEAR });
  dispatch({ type: notificationsConstants.NOTIFICATIONS_CLEAR });
};

export const register = (user) => async (dispatch) => {
  try {
    dispatch({ type: userConstants.REGISTER_REQUEST });
    const response = await axios.post(`${API_URL}/users`, {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.username,
      password: user.password,
      role: user.role,
      usePlan: user.usePlan,
    });
    localStorage.setItem("token", response.data.accessToken);
    localStorage.setItem("user", response.data.email);
    localStorage.setItem("id", response.data.userId);
    dispatch({
      type: userConstants.REGISTER_SUCCESS,
      payload: decodeToken(response.data.accessToken),
    });
  } catch (error) {
    dispatch({
      type: userConstants.REGISTER_FAILURE,
      payload: error.response,
    });
    window.scrollTo(0, 0);
  }
};

export const registerGoogle = (googleData) => async (dispatch) => {
  try {
    dispatch({ type: userConstants.REGISTER_REQUEST });
    const response = await axios.post(`${API_URL}/auth/google-user/register`, {
      token: googleData.tokenId,
      googleId: googleData.googleId,
    });
    if (!response.data.error) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("user", response.data.username);
      localStorage.setItem("id", response.data.userId);
      dispatch({
        type: userConstants.REGISTER_SUCCESS,
        payload: decodeToken(response.data.token),
      });
    } else {
      throw {
        response: {
          data: { errors: [response.data.message] },
        },
      };
    }
  } catch (error) {
    if (error.response) {
      dispatch({
        type: userConstants.REGISTER_FAILURE,
        payload: error.response,
      });
    }
    window.scrollTo(0, 0);
  }
};

export const registerMicrosoft = (msalInstance, loginRequest) => async (
  dispatch
) => {
  dispatch({ type: userConstants.REGISTER_REQUEST });
  try {
    const response = await msalInstance.loginPopup(loginRequest);
    const data = {
      microsoftId: response.uniqueId,
      firstName: response.account.name.split(" ")[0],
      lastName: response.account.name.split(" ")[1],
      email: response.account.username.toLowerCase(),
    };
    const resgisteredUser = await axios.post(
      `${API_URL}/auth/microsoft-user/register`,
      {
        data,
      }
    );
    if (!resgisteredUser.data.error) {
      localStorage.setItem("token", resgisteredUser.data.token);
      localStorage.setItem("user", resgisteredUser.data.username);
      localStorage.setItem("id", resgisteredUser.data.userId);
      dispatch({
        type: userConstants.REGISTER_SUCCESS,
        payload: decodeToken(resgisteredUser.data.token),
      });
    } else {
      throw {
        response: {
          data: { errors: [resgisteredUser.data.message] },
        },
      };
    }
  } catch (error) {
    console.log(error);
    if (error.response) {
      dispatch({
        type: userConstants.REGISTER_FAILURE,
        payload: error.response,
      });
    }
    window.scrollTo(0, 0);
  }
};

export const getUserPlanData = (userId) => async (dispatch) => {
  try {
    dispatch({ type: userConstants.GET_USER_PLAN_REQUEST });
    const response = await axios.get(`${API_URL}/user-plan/${userId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    dispatch({
      type: userConstants.GET_USER_PLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    console.log(error.response);
    dispatch({
      type: userConstants.GET_USER_PLAN_FAILURE,
      payload: error,
    });
  }
};

function update(user, userId) {
  return (dispatch) => {
    dispatch(request(user));

    userService.update(user, userId).then(
      () => {
        dispatch(success());
        dispatch(alertActions.success("Password updated successfully"));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function forgotPassword(user) {
  return (dispatch) => {
    dispatch(request(user));
    userService.forgotPassword(user).then(
      () => {
        dispatch(success());
        dispatch(
          alertActions.success(
            "Please check your email for a link to reset your password"
          )
        );
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}
