export default function () {
  return [
    {
      title: "Overview",
      to: "/dashboard",
      htmlBefore: '<i class="material-icons">dashboard</i>',
      htmlAfter: "",
    },
    {
      title: "Registered Sites",
      htmlBefore: '<i class="material-icons">view_list</i>',
      to: "/registered-sites",
    },
    {
      title: "Competitor Analysis",
      htmlBefore: '<i class="material-icons">score</i>',
      to: "/competitor-analysis",
    },
    {
      title: "Google Analysis",
      htmlBefore: '<i class="material-icons">donut_small</i>',
      to: "/google-analysis",
    },
    {
      title: "Ping",
      htmlBefore: '<i class="material-icons">wifi_tethering</i>',
      to: "/ping",
    },
    {
      title: "Web Vitals Support",
      htmlBefore: '<i class="material-icons">support_agent</i>',
      to: "/web-vitals-support",
    },
    {
      title: "Contact Us",
      htmlBefore: '<i class="material-icons">email</i>',
      to: "/contactUs",
    },
    {
      title: "Billing",
      htmlBefore: '<i class="material-icons">receipt_long</i>',
      to: "/billing",
    },
  ];
}
