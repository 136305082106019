export const API_URL = `${process.env.REACT_APP_BACKEND_URL}`;
export const API_URL_PSI = `${process.env.REACT_APP_PSI_URL}`;
export const API_URL_PSI_TEST = `${process.env.REACT_APP_PSI_TEST_URL}`;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const MICROSOFT_CLIENT_ID = `${process.env.REACT_APP_MICROSOFT_CLIENT_ID}`;
export const MICROSOFT_REDIRECT_URL = `${process.env.REACT_APP_MICROSOFT_REDIRECT_URL}`;

export * from "./alert.constants";
export * from "./sites.constants";
export * from "./user.constants";
export * from "./actionTypes";
export * from "./notifications.constants";
export * from "./psi.constants";
export * from "./ping.constants";
