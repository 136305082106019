import { pingConstants } from "../../constants";

export const pingData = (
  state = {
    loading: false,
    pingData: [],
    error: false,
  },
  action
) => {
  switch (action.type) {
    case pingConstants.GET_SITE_PING_DATA_REQUEST:
      return { loading: true, pingData: [], error: false };
    case pingConstants.GET_SITE_PING_DATA_SUCCESS:
      return { loading: false, pingData: action.payload, error: false };
    case pingConstants.GET_SITE_PING_DATA_FAILURE:
      return { loading: false, error: true, pingData: [] };
    case pingConstants.GET_FILTERED_PING_DATA_REQUEST:
      return { loading: true, pingData: [], error: false };
    case pingConstants.GET_FILTERED_PING_DATA_SUCCESS:
      return { loading: false, pingData: action.payload, error: false };
    case pingConstants.GET_FILTERED_PING_DATA_FAILURE:
      return { loading: false, error: true, pingData: [] };
    case pingConstants.ADD_NEW_PING_DATA:
      return {
        loading: false,
        error: false,
        pingData: [action.payload, ...state.pingData],
      };
    case pingConstants.DELETE_SITE_PING_DATA:
      return {
        loading: false,
        pingData: state.pingData.filter((ping) => ping._id !== action.payload),
        error: false,
      };
    case pingConstants.GET_SITE_PING_DATA_CLEAR:
      return {};
    default:
      return state;
  }
};

export const newPingData = (
  state = {
    loading: false,
    date: "",
  },
  action
) => {
  switch (action.type) {
    case pingConstants.ADDING_NEW_PING_DATA:
      return {
        loading: true,
        date: new Date(),
      };
    case pingConstants.NEW_PING_DATA_CLEAR:
      return { loading: false, date: "" };
    default:
      return state;
  }
};
