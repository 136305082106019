export const sitesConstants = {
  GETALL_REQUEST: "SITES_GETALL_REQUEST",
  GETALL_SUCCESS: "SITES_GETALL_SUCCESS",
  GETALL_FAILURE: "SITES_GETALL_FAILURE",
  GETALL_CLEAR: "SITES_GETALL_CLEAR",

  ADDING_NEW_SITE: "ADDING_NEW_SITE",
  NEW_SITE_CLEAR: "NEW_SITE_CLEAR",

  COMPETITOR_SITES_GETALL_REQUEST: "COMPETITOR_SITES_GETALL_REQUEST",
  COMPETITOR_SITES_GETALL_SUCCESS: "COMPETITOR_SITES_GETALL_SUCCESS",
  COMPETITOR_SITES_GETALL_FAILURE: "COMPETITOR_SITES_GETALL_FAILURE",
  COMPETITOR_SITES_GETALL_CLEAR: "COMPETITOR_SITES_GETALL_CLEAR",

  ADDING_NEW_COMPETITOR_SITE: "ADDING_NEW_COMPETITOR_SITE",
  NEW_COMPETITOR_SITE_CLEAR: "NEW_COMPETITOR_SITE_CLEAR",

  REMOVE_COMPETITOR_SITE_NAME_FROM_STATE:
    "REMOVE_COMPETITOR_SITE_NAME_FROM_STATE",

  ADDING_NEW_PING_DATA: "ADDING_NEW_PING_DATA",
  NEW_PING_DATA_CLEAR: "NEW_PING_DATA_CLEAR",
};
