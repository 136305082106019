import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, FormSelect } from "shards-react";
import ReactTooltip from "react-tooltip";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { scaleLinear } from "d3-scale";
import { API_URL_PSI } from "../../constants";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";

const Analytics = ({ propertyId }) => {
  const [loading, setLoading] = useState(false);
  const [overall, setOverall] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const [newUsers, setNewUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState([]);
  const [usersByCountries, setUsersByCountries] = useState("totalUsers");
  const [tooltipContent, setTooltipContent] = useState("");
  const [usersByArray, setUsersByArray] = useState([]);
  const [error, setError] = useState("");
  const geoUrl =
    "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/v2/topojson-maps/world-110m.json";

  const colorScale = scaleLinear()
    .domain([0, Math.max(...usersByArray)])
    .range(["#90E0EF", "#03045E"]);

  const handleTabChange = (e) => {
    setUsersByCountries(e.target.value);
    if (e.target.value === "totalUsers") {
      setUsersByArray(totalUsers);
    } else if (e.target.value === "newUsers") {
      setUsersByArray(newUsers);
    } else {
      setUsersByArray(activeUsers);
    }
  };

  const getAnalyticsOverall = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${API_URL_PSI}/analytics-overall`, {
        propertyId: propertyId.toString(),
        dimension: "country",
      });
      let states = [];
      let activeusers = [];
      let newusers = [];
      let totalusers = [];
      let averagesessionduration = [];
      response.data.forEach((item) => {
        states.push(item.country);
        activeusers.push(item.activeUsers);
        newusers.push(item.newUsers);
        totalusers.push(item.totalUsers);
        averagesessionduration.push(item.averageSessionDuration);
      });
      setOverall(response.data);
      setActiveUsers(activeusers);
      setNewUsers(newusers);
      setTotalUsers(totalusers);
      setUsersByArray(totalusers);
      setLoading(false);
    } catch (error) {
      console.log(error.response);
      setError(error.response.data);
      setLoading(false);
      setOverall([]);
    }
  };

  useEffect(async () => {
    await getAnalyticsOverall();
  }, [propertyId]);

  return (
    <div>
      <Card
        style={{ minHeight: overall.length ? "681px" : "", maxHeight: "681px" }}
      >
        <CardHeader className="border-bottom">
          <FormSelect className="overviewSelect" onChange={handleTabChange}>
            <option value={"totalUsers"}>Total Users By Country</option>
            <option value={"newUsers"}>New Users By Country</option>
            <option value={"activeUsers"}>Active Users By Country</option>
          </FormSelect>
        </CardHeader>
        {loading ? (
          <CircularProgress
            style={{
              margin: "auto",
              height: "140px",
              width: "140px",
              padding: "10px",
            }}
          />
        ) : overall.length ? (
          <CardBody>
            <ComposableMap
              width={900}
              height={507}
              projectionConfig={{
                rotate: [-10, 0, 0],
                scale: 190,
              }}
              data-tip=""
            >
              <Geographies geography={geoUrl}>
                {({ geographies }) =>
                  geographies.map((geo) => {
                    const country = overall.find(
                      (item) =>
                        item.country === geo.properties.NAME ||
                        item.country === geo.properties.NAME_LONG ||
                        item.country === geo.properties.FORMAL_EN ||
                        item.country === geo.properties.FORMAL_EN
                    );

                    return (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        fill={
                          country
                            ? country[usersByCountries] !== "0"
                              ? colorScale(country[usersByCountries])
                              : "#dee0e5"
                            : "#dee0e5"
                        }
                        onMouseEnter={() => {
                          const { NAME } = geo.properties;
                          setTooltipContent(
                            `${NAME} - ${
                              country
                                ? country[usersByCountries]
                                  ? country[usersByCountries]
                                  : "0"
                                : "0"
                            }`
                          );
                        }}
                        onMouseLeave={() => {
                          setTooltipContent("");
                        }}
                        style={{
                          default: {
                            stroke: "#fff",
                            strokeWidth: 0.15,
                          },
                          hover: {
                            fill: "#a1a1a1",
                            outline: "none",
                          },
                        }}
                      />
                    );
                  })
                }
              </Geographies>
            </ComposableMap>
            <ReactTooltip>{tooltipContent}</ReactTooltip>
          </CardBody>
        ) : (
          <CardBody>
            <p>{error ? error : "No Data"}</p>
          </CardBody>
        )}
      </Card>
    </div>
  );
};

export default Analytics;
