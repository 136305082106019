import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

/* eslint-disable no-unused-vars */
function PrivateRoute({ component: Component, roles, ...rest }) {
  const user = useSelector((state) =>
    state.authentication.user
      ? state.authentication.user
      : state.registration.user
  );
  return (
    <Route
      {...rest}
      render={(props) =>
        user ? <Component {...props} /> : <Redirect to={"/login"} />
      }
    />
  );
}

export { PrivateRoute };
