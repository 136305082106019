// Layout Types
import DefaultLayout from "./layouts/Default";

// Route Views
import Dashboard from "./pages/Dashboard";
import RegisteredSites from "./pages/RegisteredSites";
import Notifications from "./pages/Notifications";
import WebVitalsSupport from "./pages/WebVitalsSupport";
import SiteDetails from "./pages/SiteDetails";
import RankingDetails from "./pages/RankingDetails";
import Errors from "./pages/Errors";
import ContactUs from "./pages/ContactUs";
import SuggestionDetails from "./pages/SuggestionDetails";
import W3ValidationDetails from "./pages/W3ValidationDetails";
import ViewAllNotifications from "./pages/ViewAllNotifications";
import SubUsersList from "./pages/SubUsersList";
import PricingPlans from "./pages/PricingPlans";
import CompetitorAnalysis from "./pages/CompetitorAnalysis";
import SitesComparison from "./pages/SitesComparison";
import AddPropertyId from "./pages/AddPropertyId";
import Billing from "./pages/Billing";
import GoogleAnalysis from "./pages/GoogleAnalysis";
import Ping from "./pages/Ping";

export default [
  {
    path: "/dashboard",
    layout: DefaultLayout,
    component: Dashboard,
  },
  {
    path: "/web-vitals-support",
    layout: DefaultLayout,
    component: WebVitalsSupport,
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors,
  },
  {
    path: "/registered-sites",
    layout: DefaultLayout,
    component: RegisteredSites,
  },
  {
    path: "/notifications",
    layout: DefaultLayout,
    component: Notifications,
  },
  {
    path: "/siteDetails",
    layout: DefaultLayout,
    component: SiteDetails,
  },
  {
    path: "/comparisionDetails",
    layout: DefaultLayout,
    component: SitesComparison,
  },
  {
    path: "/contactUs",
    layout: DefaultLayout,
    component: ContactUs,
  },
  {
    path: "/rankingDetails",
    layout: DefaultLayout,
    component: RankingDetails,
  },
  {
    path: "/suggestionDetails",
    layout: DefaultLayout,
    component: SuggestionDetails,
  },
  {
    path: "/w3ValidationDetails",
    layout: DefaultLayout,
    component: W3ValidationDetails,
  },
  {
    path: "/viewAllNotifications",
    layout: DefaultLayout,
    component: ViewAllNotifications,
  },
  {
    path: "/add-subusers",
    layout: DefaultLayout,
    component: SubUsersList,
  },
  {
    path: "/subscription-plans",
    layout: DefaultLayout,
    component: PricingPlans,
  },
  {
    path: "/competitor-analysis",
    layout: DefaultLayout,
    component: CompetitorAnalysis,
  },
  {
    path: "/google-analysis",
    layout: DefaultLayout,
    component: GoogleAnalysis,
  },
  {
    path: "/add-property-id",
    layout: DefaultLayout,
    component: AddPropertyId,
  },
  {
    path: "/billing",
    layout: DefaultLayout,
    component: Billing,
  },
  {
    path: "/ping",
    layout: DefaultLayout,
    component: Ping,
  },
];
