import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "shards-react";
import { deletePingData } from "../../state/actions/ping.actions";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

const DeleteDataModal = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return (
    <Modal show={open.open} onHide={() => setOpen({ open: false, data: "" })}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete these data</Modal.Body>
      <Modal.Footer>
        <Button
          theme="danger"
          onClick={async () => {
            await dispatch(deletePingData(open.data))
              .then(() => {
                const pingDataRemoved = enqueueSnackbar(
                  `Ping data removed successfully `,
                  {
                    variant: "success",
                  }
                );
                setTimeout(() => {
                  closeSnackbar(pingDataRemoved);
                }, 5000);
                setOpen({ open: false, data: "" });
              })
              .catch(() => {
                const deleteError = enqueueSnackbar(`Deletion Failed`, {
                  variant: "error",
                });
                setTimeout(() => {
                  closeSnackbar(deleteError);
                }, 5000);
                setOpen({ open: false, data: "" });
              });
          }}
        >
          Yes
        </Button>
        <Button onClick={() => setOpen({ open: false, data: "" })}>No</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteDataModal;
