export const notificationsConstants = {
  NOTIFICATIONS_GETALL_REQUEST: "NOTIFICATIONS_GETALL_REQUEST",
  NOTIFICATIONS_GETALL_SUCCESS: "NOTIFICATIONS_GETALL_SUCCESS",
  NOTIFICATIONS_GETALL_FAILURE: "NOTIFICATIONS_GETALL_FAILURE",
  NOTIFICATIONS_CLEAR: "NOTIFICATIONS_GETALL_CLEAR",

  NOTIFICATIONS_REMOVE_STATE: "NOTIFICATIONS_REMOVE_STATE",
  NOTIFICATIONS_MARK_AS_READ_STATE: "NOTIFICATIONS_MARK_AS_READ_STATE",
  NOTIFICATIONS_MARK_ALL_AS_READ_STATE: "NOTIFICATIONS_MARK_ALL_AS_READ_STATE",
};
