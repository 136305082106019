import React, { useState, useEffect } from "react";
import { calculateAITurn } from "./AI";
import { Modal } from "react-bootstrap";

const GAME_STATE = {
  PLAYER_TURN: "player_turn",
  AI_TURN: "ai_turn",
  PLAYER_WON: "player_won",
  AI_WON: "player_o_won",
  DRAW: "game_draw",
  ERROR: "game_error",
};

export const SPACE_STATE = {
  PLAYER: "player_filled",
  AI: "ai_filled",
  EMPTY: "empty_space",
};

export const GRID_LENGTH = 9;
const MAX_MOVES = 10;

const getGameStatus = (gameState) => {
  switch (gameState) {
    case GAME_STATE.PLAYER_TURN: {
      return "Your Turn";
    }
    case GAME_STATE.AI_TURN: {
      return "AI Turn";
    }
    case GAME_STATE.PLAYER_WON: {
      return "You Won!";
    }
    case GAME_STATE.AI_WON: {
      return "AI WON!";
    }
    case GAME_STATE.DRAW: {
      return "Draw";
    }
    case GAME_STATE.ERROR: {
      return "Error";
    }
    default: {
      return "Unknown game state " + gameState;
    }
  }
};

const getSquareSymbol = (spaceStatus) => {
  switch (spaceStatus) {
    case SPACE_STATE.PLAYER: {
      return "X";
    }
    case SPACE_STATE.AI: {
      return "O";
    }
    case SPACE_STATE.EMPTY: {
      return "";
    }
    default: {
      return "";
    }
  }
};

const createEmptyGrid = () => {
  return Array(GRID_LENGTH).fill(SPACE_STATE.EMPTY);
};

const getSpaceStateClass = (spaceState, gameState, winSpaces, spaceIndex) => {
  let space = "";

  if (spaceState === SPACE_STATE.AI) {
    space += "o-player";

    if (gameState === GAME_STATE.AI_WON && winSpaces.includes(spaceIndex)) {
      space += " o-winner";
    }
  }

  if (spaceState === SPACE_STATE.PLAYER) {
    space += "x-player";

    if (gameState === GAME_STATE.PLAYER_WON && winSpaces.includes(spaceIndex)) {
      space += " x-winner";
    }
  }

  return space;
};

const isDraw = (moveCount) => {
  return moveCount === MAX_MOVES;
};

const checkWinner = (grid, moveCount) => {
  const winnerSpaces = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];

  if (isDraw(moveCount)) {
    return {
      winner: GAME_STATE.DRAW,
      winSpaces: [],
    };
  }

  for (let i = 0; i < winnerSpaces.length; i++) {
    const [a, b, c] = winnerSpaces[i];

    if (
      grid[a] === SPACE_STATE.EMPTY &&
      grid[b] === SPACE_STATE.EMPTY &&
      grid[c] === SPACE_STATE.EMPTY
    ) {
      continue;
    }

    if (grid[a] && grid[a] === grid[b] && grid[a] === grid[c]) {
      let winner = null;

      if (grid[a] === SPACE_STATE.PLAYER) {
        winner = GAME_STATE.PLAYER_WON;
      } else {
        winner = GAME_STATE.AI_WON;
      }

      return {
        winner: winner,
        winSpaces: [a, b, c],
      };
    }
  }

  return null;
};

const TicTacToe = ({ open, setOpen }) => {
  // Grid State
  const [grid, setGrid] = useState(createEmptyGrid());
  // Count of all moves made
  const [moveCount, setMoveCount] = useState(0);
  // Spaces used to get a win
  const [winSpaces, setWinSpaces] = useState([]);
  // Current game state
  const [gameState, setGameState] = useState(GAME_STATE.PLAYER_TURN);

  // Whenever the game state changes
  // from player interaction,
  // we handle logic flow in
  // here.
  useEffect(() => {
    // Player took turn,
    // check if game still running.
    let winner = checkWinner(grid, moveCount);

    // If the player won, update state to reflect.
    if (winner) {
      setGameState(winner.winner);
      setWinSpaces(winner.winSpaces);
    }

    // Run AI turn
    if (gameState === GAME_STATE.AI_TURN && moveCount < 10) {
      const aiSpace = calculateAITurn(grid, moveCount);
      setMoveCount((oldMoves) => {
        return oldMoves + 1;
      });
      fillGridSpace(aiSpace, SPACE_STATE.AI);
      winner = checkWinner(grid, moveCount);
    }

    // If AI won, update state to reflect, else
    // go back to player turn.
    if (winner) {
      setGameState(winner.winner);
      setWinSpaces(winner.winSpaces);
    } else {
      setGameState(GAME_STATE.PLAYER_TURN);
    }
  }, [gameState, grid]);

  // Reset state to default values
  const reset = () => {
    setGrid(createEmptyGrid());
    setGameState(GAME_STATE.PLAYER_TURN);
    setMoveCount(0);
    setWinSpaces([]);
  };

  // Fill in a grid box with status
  const fillGridSpace = (gridIndex, spaceStatus) => {
    setGrid((oldGrid) => {
      oldGrid[gridIndex] = spaceStatus;
      return [...oldGrid];
    });
  };

  // Fill in the grid array with the player space state.
  const handlePlayerClick = (gridIndex) => {
    if (gameState !== GAME_STATE.PLAYER_TURN) {
      return;
    }

    if (grid[gridIndex] === SPACE_STATE.EMPTY) {
      fillGridSpace(gridIndex, SPACE_STATE.PLAYER);
      setGameState(GAME_STATE.AI_TURN);
      setMoveCount((oldMoves) => {
        return oldMoves + 1;
      });
    }
  };

  const Square = (props) => {
    return (
      <div
        className={
          "shadow-md" +
          getSpaceStateClass(
            grid[props.squareIndex],
            gameState,
            winSpaces,
            props.squareIndex
          )
        }
        style={{
          display: "flex",
          backgroundColor: "#ffffff",
          fontSize: " 4.5rem",
          lineHeight: "1",
          fontWeight: "300",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          width: "6rem",
          height: "6rem",
          borderRadius: "0.5rem",
          cursor: "default",
        }}
        onClick={() => {
          handlePlayerClick(props.squareIndex);
        }}
      >
        {getSquareSymbol(grid[props.squareIndex])}
      </div>
    );
  };

  return (
    <Modal show={open} onHide={() => setOpen(false)}>
      <Modal.Header
        closeButton
        style={{
          color: "#9CA3AF",
        }}
      >
        {getGameStatus(gameState)}
      </Modal.Header>
      <section
        className="game-board"
        style={{ paddingTop: "2.5rem", paddingBottom: "2.5rem" }}
      >
        <div style={{ maxWidth: "28rem", margin: "auto" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "32rem",
              gap: "1.25rem",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "1.25rem",
                justifyContent: "space-evenly",
              }}
            >
              <Square squareIndex={0} />
              <Square squareIndex={1} />
              <Square squareIndex={2} />
            </div>
            <div
              style={{
                display: "flex",
                gap: "1.25rem",
                justifyContent: "space-evenly",
              }}
            >
              <Square squareIndex={3} />
              <Square squareIndex={4} />
              <Square squareIndex={5} />
            </div>
            <div
              style={{
                display: "flex",
                gap: "1.25rem",
                justifyContent: "space-evenly",
              }}
            >
              <Square squareIndex={6} />
              <Square squareIndex={7} />
              <Square squareIndex={8} />
            </div>
          </div>

          <div className="text-center">
            <button
              style={{
                paddingTop: "0.5rem",
                paddingBottom: "0.5rem",
                marginTop: "2.5rem",
                backgroundColor: "#3B82F6",
                color: "#ffffff",
                fontWeight: "600",
                width: "100%",
                borderRadius: "0.375rem",
                boxShadow:
                  " 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                border: "none",
              }}
              onClick={() => {
                reset();
              }}
            >
              Reset
            </button>
            {/* <span className="block mt-5">
              Created by
              <a
                style={{
                  marginLeft: "0.25rem",
                  color: "#3B82F6",
                  textDecoration: "underline",
                }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.gregorygaines.com"
              >
                Gregory Gaines
              </a>
              .
              <br /> Check out the tutorial
              <a
                style={{
                  marginLeft: "0.25rem",
                  color: "#3B82F6",
                  textDecoration: "underline",
                }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.gregorygaines.com/blog/develop-unbeatable-tic-tac-toe-ai-react/"
              >
                here
              </a>
              .
            </span> */}
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default TicTacToe;
