import React, { useState, useEffect } from "react";
import LabDataOverviewGraph from "./LabDataOverviewGraph";
import CircularProgress from "@mui/material/CircularProgress";
import { Card, CardBody } from "shards-react";
import { useSelector } from "react-redux";

const LabDataOverview = ({ siteName, siteUrl, device }) => {
  const [monthlyData, setMonthlyData] = useState({
    fcp: [],
    lcp: [],
    si: [],
    tbt: [],
    cls: [],
    scanDates: [],
  });

  const monthlyLabData = useSelector(
    (state) => state.monthlyLabData.monthlyLabData
  );
  const error = useSelector((state) => state.monthlyLabData.error);
  const loading = useSelector((state) => state.monthlyLabData.loading);

  let fcpLabDataStats = [];
  let lcpLabDataStats = [];
  let siLabDataStats = [];
  let tbtLabDataStats = [];
  let clsLabDataStats = [];
  let scanDates = [];

  useEffect(() => {
    if (monthlyLabData) {
      if (device == "Mobile") {
        monthlyLabData.forEach((element) => {
          fcpLabDataStats.push(
            parseFloat(
              typeof element.mobile.labData !== "undefined"
                ? element.mobile.labData.fcp
                : null
            )
          );
          lcpLabDataStats.push(
            parseFloat(
              typeof element.mobile.labData !== "undefined"
                ? element.mobile.labData.lcp
                : null
            )
          );
          siLabDataStats.push(
            parseFloat(
              typeof element.mobile.labData !== "undefined"
                ? element.mobile.labData.si
                : null
            )
          );

          tbtLabDataStats.push(
            parseFloat(
              typeof element.mobile.labData !== "undefined"
                ? element.mobile.labData.tbt
                : null
            )
          );
          clsLabDataStats.push(
            parseFloat(
              typeof element.mobile.labData !== "undefined"
                ? element.mobile.labData.cls
                : null
            )
          );
        });
      } else {
        monthlyLabData.forEach((element) => {
          fcpLabDataStats.push(
            parseFloat(
              typeof element.desktop.labData !== "undefined"
                ? element.desktop.labData.fcp
                : null
            )
          );
          lcpLabDataStats.push(
            parseFloat(
              typeof element.desktop.labData !== "undefined"
                ? element.desktop.labData.lcp
                : null
            )
          );
          siLabDataStats.push(
            parseFloat(
              typeof element.desktop.labData !== "undefined"
                ? element.desktop.labData.si
                : null
            )
          );
          tbtLabDataStats.push(
            parseFloat(
              typeof element.desktop.labData !== "undefined"
                ? element.desktop.labData.tbt
                : null
            )
          );
          clsLabDataStats.push(
            parseFloat(
              typeof element.desktop.labData !== "undefined"
                ? element.desktop.labData.cls
                : null
            )
          );
        });
      }
      monthlyLabData.forEach((element) => {
        scanDates.push(element.scanDate);
      });
      setMonthlyData({
        fcp: fcpLabDataStats,
        lcp: lcpLabDataStats,
        si: siLabDataStats,
        tbt: tbtLabDataStats,
        cls: clsLabDataStats,
        scanDates: scanDates,
      });
    }
  }, [loading]);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress
            style={{ margin: "2rem", height: "140px", width: "140px" }}
          />
          <div>Loading Lab Data Graph</div>
        </div>
      ) : error ? (
        <Card small className="h-100">
          <CardBody>No sufficient data for monthly graph!</CardBody>
        </Card>
      ) : (
        <LabDataOverviewGraph
          data={monthlyData}
          siteName={siteName}
          siteUrl={siteUrl}
          device={device}
        />
      )}
    </>
  );
};

export default LabDataOverview;
