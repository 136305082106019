import React from "react";
import { Nav } from "shards-react";
import { useSelector } from "react-redux";

import Notifications from "./Notifications";
import UserActions from "./UserActions";
import NotConfirmedNavbar from "../../../common/NotConfirmedNavbar";
import UpgradePlan from "../../../common/UpgradePlan";
import SubscribeExpired from "../../../common/SubscribeExpired";

const NavbarNav = ({
  notifications,
  signout,
  markAsRead,
  more,
  setMore,
  user,
  markAllAsRead,
  onlyRead,
  setOnlyRead,
  unReadNotifications,
}) => {
  const currentPlan = useSelector((state) => state.plan.plan);
  const currentPlanError = useSelector((state) => state.plan.error);
  const currentPlanLoading = useSelector((state) => state.plan.loading);

  return (
    <Nav navbar className="flex-row ml-auto mr-0">
      {currentPlanLoading ? (
        <></>
      ) : currentPlanError ? (
        <></>
      ) : currentPlan?.name === "Trial" || currentPlan.name === "MetryV" ? (
        <></>
      ) : new Date(currentPlan?.next_payment_date) <= new Date() &&
        user.isRoot ? (
        <SubscribeExpired />
      ) : (
        <></>
      )}
      {currentPlanLoading ? (
        <></>
      ) : currentPlanError ? (
        <></>
      ) : currentPlan?.name === "Trial" && user.isRoot ? (
        <UpgradePlan />
      ) : (
        <></>
      )}
      {currentPlanLoading ? (
        <></>
      ) : currentPlanError ? (
        <></>
      ) : currentPlan?.confirmed ? (
        <></>
      ) : (
        <NotConfirmedNavbar />
      )}
      <Notifications
        notifications={notifications}
        markAsRead={markAsRead}
        more={more}
        setMore={setMore}
        markAllAsRead={markAllAsRead}
        onlyRead={onlyRead}
        setOnlyRead={setOnlyRead}
        unReadNotifications={unReadNotifications}
      />
      <UserActions
        user={user}
        signout={signout}
        suspendedAccount={user.suspendedAccount}
      />
    </Nav>
  );
};

export default NavbarNav;
