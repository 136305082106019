import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardHeader } from "shards-react";

const MissingPropertyId = () => {
  return (
    <Row>
      <Col className="col-lg-12 col-md-12 col-sm-12 my-4">
        <Card>
          <CardHeader className="border-bottom">
            <h5 className="d-inline-block m-0">Google Analytics</h5>
          </CardHeader>
          <CardBody style={{ display: "flex" }}>
            <span
              className="text-sm-left ml-3"
              style={{
                fontSize: "14px",
                opacity: "0.8",
              }}
            >
              Google Analytics is a web analytics service that provides
              statistics and basic analytical tools for search engine
              optimization (SEO) and marketing purposes. The service is part of
              the Google Marketing Platform and is available for free to anyone
              with a Google account.
              <Link
                to={{
                  pathname: "/add-property-id",
                }}
              >
                {" "}
                Add Property Id
              </Link>
            </span>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default MissingPropertyId;
