import { userConstants } from "../../constants";
import { decodeToken } from "react-jwt";

let user = decodeToken(localStorage.getItem("token"));
const initialState = user ? { loading: false, user: user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return { loading: true };
    case userConstants.LOGIN_SUCCESS:
      return { loading: false, user: action.payload };
    case userConstants.LOGIN_FAILURE:
      return { loading: false, error: { ...action.payload.data } };
    case userConstants.LOGOUT:
      return {};
    default:
      return state;
  }
}
