import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useSnackbar } from "notistack";
import axios from "axios";
import { API_URL } from "../../constants";

const CancelSubscription = ({ open, setOpen }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const cancelSubscriptionPlan = async () => {
    try {
      const { data } = await axios.post(
        `${API_URL}/subscription/cancel/${localStorage.getItem("id")}`,
        {
          userId: localStorage.getItem("id"),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const date = new Date(data);
      const dateSnack = enqueueSnackbar(
        `Your subscription will be cancelled on ${date
          .toString()
          .slice(0, 16)}`,
        {
          variant: "success",
        }
      );
      setTimeout(() => {
        closeSnackbar(dateSnack);
      }, 5000);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Something went wrong. Please try again later.", {
        variant: "error",
      });
    }
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="sm">
      <DialogTitle>Cancel Subscription</DialogTitle>
      <DialogContent>
        Are you sure you want to cancel your subscription?
      </DialogContent>
      <DialogContent>
        On canceling your subscription, you will be able to use current
        subscription until it expires, then your account will be suspended
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            cancelSubscriptionPlan();
          }}
        >
          Yes
        </Button>
        <Button
          onClick={() => {
            setOpen(false);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelSubscription;
