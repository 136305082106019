import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import W3ValidatorOverviewStatusComparison from "./W3ValidatorOverviewStatusComparison";
import MainSiteW3ValidatorOverviewStatusComparison from "./MainSiteW3ValidatorOverviewStatusComparison";
import { Card, CardBody, Row, Col } from "shards-react";
import axios from "axios";
import { API_URL_PSI } from "../../constants";
import { calculateDifference } from "../../helpers/scores";

const W3ValidatorOverviewComparison = ({ siteUrl, mainSiteUrl }) => {
  const [w3validatorstatus, setW3validatorstatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [mainSitew3validatorstatus, setMainSiteW3validatorstatus] = useState(
    []
  );
  const [mainSiteloading, setMainSiteLoading] = useState(false);
  const [mainSiteerror, setMainSiteError] = useState(false);

  const [comparedData, setComparedData] = useState([]);

  const getW3ValidatorData = async (siteUrl) => {
    try {
      setLoading(true);
      setError(false);
      const response = await axios.get(
        `${API_URL_PSI}/w3validator/latest`,
        {
          params: {
            url: siteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setW3validatorstatus({
        fatalErrors: response.data.fatalErrors,
        errors: response.data.errors,
        warnings: response.data.warnings,
        details: response.data.details,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
      console.log(error);
    }
  };

  const getMainSiteW3ValidatorData = async (mainSiteUrl) => {
    try {
      setMainSiteLoading(true);
      setMainSiteError(false);
      const response = await axios.get(
        `${API_URL_PSI}/w3validator/latest`,
        {
          params: {
            url: mainSiteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setMainSiteW3validatorstatus({
        fatalErrors: response.data.fatalErrors,
        errors: response.data.errors,
        warnings: response.data.warnings,
        details: response.data.details,
      });
      setMainSiteLoading(false);
    } catch (error) {
      setMainSiteLoading(false);
      setMainSiteError(true);
      console.log(error);
    }
  };

  const newScan = async (mainSiteUrl) => {
    try {
      setMainSiteLoading(true);
      setMainSiteError(false);
      await axios.get(
        `${API_URL_PSI}/w3validator`,
        {
          params: {
            url: mainSiteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      await getMainSiteW3ValidatorData(mainSiteUrl);
      setMainSiteLoading(false);
    } catch (error) {
      await getMainSiteW3ValidatorData(mainSiteUrl);
      setMainSiteLoading(false);
      setMainSiteError(true);
      console.log(error);
    }
  };

  useEffect(() => {
    getW3ValidatorData(siteUrl);
  }, [siteUrl]);

  useEffect(() => {
    getMainSiteW3ValidatorData(mainSiteUrl);
  }, [mainSiteUrl]);

  useEffect(() => {
    if (mainSitew3validatorstatus && w3validatorstatus) {
      let comparedData = [];
      let dataToCompare = [
        [
          w3validatorstatus.fatalErrors ? w3validatorstatus.fatalErrors : 0,
          mainSitew3validatorstatus.fatalErrors
            ? mainSitew3validatorstatus.fatalErrors
            : 0,
        ],
        [
          w3validatorstatus.errors ? w3validatorstatus.errors : 0,
          mainSitew3validatorstatus.errors
            ? mainSitew3validatorstatus.errors
            : 0,
        ],
        [
          w3validatorstatus.warnings ? w3validatorstatus.warnings : 0,
          mainSitew3validatorstatus.warnings
            ? mainSitew3validatorstatus.warnings
            : 0,
        ],
      ];
      dataToCompare.forEach((item) => {
        const difference = calculateDifference(item[0], item[1]);
        comparedData.push(difference);
      });
      setComparedData(comparedData);
    }
  }, [mainSitew3validatorstatus, w3validatorstatus]);

  return (
    <Row>
      <Col className="col-lg-6 col-md-6 col-sm-6 mb-4">
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress
              style={{ margin: "2rem", height: "140px", width: "140px" }}
            />
            <div>Loading W3 Validator Status</div>
          </div>
        ) : error || !w3validatorstatus ? (
          <Card small className="h-100">
            <CardBody>No sufficient data for w3 validator status!</CardBody>
          </Card>
        ) : (
          <W3ValidatorOverviewStatusComparison data={w3validatorstatus} />
        )}
      </Col>
      <Col className="col-lg-6 col-md-6 col-sm-6 mb-4">
        {mainSiteloading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress
              style={{ margin: "2rem", height: "140px", width: "140px" }}
            />
            <div>Loading W3 Validator Status</div>
          </div>
        ) : mainSiteerror || !mainSitew3validatorstatus ? (
          <Card small className="h-100">
            <CardBody>No sufficient data for w3 validator status!</CardBody>
          </Card>
        ) : (
          <MainSiteW3ValidatorOverviewStatusComparison
            mainSiteUrl={mainSiteUrl}
            newScan={newScan}
            data={mainSitew3validatorstatus}
            comparedData={comparedData}
          />
        )}
      </Col>
    </Row>
  );
};

export default W3ValidatorOverviewComparison;
