import React from "react";
import {
  Container,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  Row,
} from "shards-react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeNotificationFromState,
  markAsReadFromState,
} from "../state/actions/notifications.actions";

const ViewAllNotifications = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(
    (state) => state.notifications.notifications
  );

  const removeNotification = (id) => {
    dispatch(removeNotificationFromState(id));
  };

  const markAsRead = (id, idx) => {
    dispatch(markAsReadFromState(id, idx));
  };

  return (
    <Container
      fluid
      className="main-content-container px-4"
      style={{ marginTop: "20px" }}
    >
      <Row>
        {notifications && notifications.length === 0 ? (
          <Col lg="4">
            <Card small className="card-post mb-4">
              <CardBody>
                <h5 className="card-title">Everything up to date</h5>
                <p className="card-text text-muted">No notifications</p>
              </CardBody>
              <CardFooter className="border-top d-flex">
                <div className="my-auto ml-auto"></div>
              </CardFooter>
            </Card>
          </Col>
        ) : (
          notifications &&
          notifications
            .sort((a, b) => {
              return a.read - b.read;
            })
            .map((item, idx) => {
              let { message, time, title, _id, type, read } = item;
              return (
                <Col lg="4" key={_id}>
                  <Card small className="card-post mb-4">
                    <CardBody>
                      {/* {read ? null : (
                        <div className="suggestion-requests">
                          <div
                            className="suggestion-requests__item"
                            style={{ textAlign: "right" }}
                          >
                            <Badge theme="danger">!</Badge>
                          </div>
                        </div>
                      )} */}
                      <h5 className="card-title">{title}</h5>
                      <p className="card-text text-muted">{message}</p>
                      <div className="card-post__author d-flex">{type}</div>
                    </CardBody>
                    <CardFooter className="border-top d-flex">
                      <div className="card-post__author d-flex">
                        {time && time.slice(0, 10).split(":").join(":")}{" "}
                        {time && time.slice(11, 16).split(":").join(":")}
                      </div>
                      <div className="my-auto ml-auto">
                        <Button
                          theme="danger"
                          style={{ marginLeft: "1rem" }}
                          onClick={() => removeNotification(_id)}
                        >
                          Remove Notification
                        </Button>
                        {!read && (
                          <Button
                            theme="primary"
                            style={{ marginLeft: "1rem" }}
                            onClick={() => markAsRead(_id, idx)}
                          >
                            Mark As Read
                          </Button>
                        )}
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
              );
            })
        )}
      </Row>
    </Container>
  );
};

export default ViewAllNotifications;
