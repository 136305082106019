import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { API_URL } from "../../constants";
import { Button } from "shards-react";
import { FormSelect } from "shards-react";
const generator = require("generate-password");
import { isMobile } from "react-device-detect";

function AddSubUsers({
  setError,
  setSuccess,
  setSubmitted,
  getUsers,
  submitted,
}) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("Developer");
  const [subUser, setSubUser] = useState(true);
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const authentication = useSelector((state) =>
    state.authentication.user
      ? state.authentication.user
      : state.registration.user
  );
  const currentPlan = useSelector((state) => state?.plan?.plan);

  const clearFields = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setRole("Developer");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    setSubUser(true);
    setError("");
    setSuccess("");
    if (firstName && lastName && email.match(re) && role && subUser) {
      axios
        .post(
          `${API_URL}/subUser/${authentication.email}`,
          {
            firstName: firstName,
            lastName: lastName,
            email: email,
            plan: currentPlan.name,
            // password: "#MetryV.2@22",
            password: generator.generateMultiple(1, {
              length: 10,
              uppercase: true,
              numbers: true,
              symbols: true,
              lowercase: true,
              excludeSimilarCharacters: true,
            })[0],
            role: role,
            subUser: subUser,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then(() => {
          setSuccess("Registered Successfully");
          setTimeout(() => {
            setSuccess("");
          }, 3000);
          getUsers();
        })
        .catch((error) => {
          console.log(error.response);
          setError(
            error.response.data.errors[0]
              ? error.response.data.errors[0]
              : "Please fill in all required fields"
          );
          setTimeout(() => {
            setError("");
          }, 3000);
          window.scrollTo(0, 0);
          getUsers();
        });
    }
  };

  return (
    <>
      {isMobile ? (
        <table className="table mb-0">
          <tbody>
            <tr>
              <th scope="col">First Name</th>
              <td>
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  name="firstName"
                  className="form-control"
                />
              </td>
            </tr>
            <tr>
              <th scope="col">Last Name</th>
              <td>
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  name="lastName"
                  className="form-control"
                />
              </td>
            </tr>
            <tr>
              <th scope="col">Email</th>
              <td>
                <input
                  type="text"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  name="email"
                  className={`form-control ${
                    !email.match(re) && submitted ? " is invalid" : ""
                  }`}
                />
              </td>
            </tr>
            <tr>
              <th scope="col">Role</th>
              <td>
                <FormSelect
                  type="role"
                  name="role"
                  value={role}
                  onChange={(e) => {
                    setRole(e.target.value);
                  }}
                  className="form-control"
                >
                  <option>Developer</option>
                  <option>Team Lead</option>
                  <option>Product Owner</option>
                  <option>Project Manager</option>
                  <option>Tech Lead</option>
                  <option>CEO</option>
                  <option>Other</option>
                </FormSelect>
              </td>
            </tr>
            <tr>
              <th scope="col"></th>
              <td style={{ textAlign: "right" }}>
                <Button
                  theme="info"
                  style={{ marginLeft: "1rem" }}
                  onClick={clearFields}
                >
                  Clear Fields
                </Button>
                <Button
                  theme="primary"
                  style={{ marginLeft: "1rem" }}
                  onClick={handleSubmit}
                  disabled={!email.match(re)}
                >
                  Register User
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <table className="table mb-0">
          <thead className="bg-light">
            <tr>
              <th scope="col" className="border-0">
                First Name
              </th>
              <th scope="col" className="border-0">
                Last Name
              </th>
              <th scope="col" className="border-0">
                Email
              </th>
              <th scope="col" className="border-0">
                Role
              </th>
              <th scope="col" className="border-0"></th>
              <th scope="col" className="border-0"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  name="firstName"
                  className="form-control"
                />
              </td>
              <td>
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  name="lastName"
                  className="form-control"
                />
              </td>
              <td>
                <input
                  type="text"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  name="email"
                  className={`form-control ${
                    !email.match(re) && submitted ? " is invalid" : ""
                  }`}
                />
              </td>
              <td>
                <FormSelect
                  type="role"
                  name="role"
                  value={role}
                  onChange={(e) => {
                    setRole(e.target.value);
                  }}
                  className="form-control"
                >
                  <option>Developer</option>
                  <option>Team Lead</option>
                  <option>Product Owner</option>
                  <option>Project Manager</option>
                  <option>Tech Lead</option>
                  <option>CEO</option>
                  <option>Other</option>
                </FormSelect>
              </td>
              <td>
                <Button
                  theme="primary"
                  style={{ marginLeft: "1rem" }}
                  onClick={handleSubmit}
                  disabled={!email.match(re)}
                >
                  Register User
                </Button>
              </td>
              <td>
                <Button
                  theme="info"
                  style={{ marginLeft: "1rem" }}
                  onClick={clearFields}
                >
                  Clear Fields
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </>
  );
}

export default AddSubUsers;
