import React, { useState, useEffect } from "react";
import { Container, Row, Button } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { API_URL_PSI, API_URL } from "../constants";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import NotConfirmed from "../components/common/NotConfirmed";
import Options from "../components/notifications/Options";
import Settings from "../components/notifications/Settings";
import CircularProgress from "@mui/material/CircularProgress";
import { getUserPlanData } from "../state/actions/user.actions";

const Notifications = (site) => {
  const [emailNotifications, setEmailNotifications] = useState(true);
  const [, setSlackNotifications] = useState(false);
  const [discordNotifications, setDiscordNotifications] = useState(false);
  const [allNotifications, setAllNotifications] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [inputs, setInputs] = useState({
    webhook_url: "",
    toggle_notification: false,
  });
  console.log("site", site);
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state) =>
    state.authentication.user
      ? state.authentication.user
      : state.registration.user
  );
  const currentPlan = useSelector((state) => state.plan.plan);
  const dispatch = useDispatch();

  const handleEmailNotificationsChange = () => {
    if (currentPlan?.confirmed) {
      if (!allNotifications.email.toggle_notification) {
        handleShowEmailModal();
      } else {
        axios
          .patch(
            `${API_URL}/users/email/disable/${userEmail}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              enqueueSnackbar("Email notifications disabled", {
                variant: "error",
              });
              setEmailNotifications(false);
              getNotificationsData();
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    } else {
      enqueueSnackbar("Please confirm you email to enable this feature", {
        variant: "warning",
      });
    }
  };

  const handleSlackNotificationsChange = () => {
    if (currentPlan?.confirmed) {
      if (!allNotifications.slack.toggle_notification) {
        handleShowSlackModal();
      } else {
        axios
          .patch(
            `${API_URL}/users/slack/disable/${userEmail}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              enqueueSnackbar("Slack notifications disabled", {
                variant: "error",
              });
              setSlackNotifications(false);
              getNotificationsData();
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    } else {
      enqueueSnackbar("Please confirm you email to enable this feature", {
        variant: "warning",
      });
    }
  };

  const handleDiscordNotificationsChange = () => {
    if (currentPlan?.confirmed) {
      if (!allNotifications.discord.toggle_notification) {
        handleShowDiscordModal();
      } else {
        axios
          .patch(
            `${API_URL}/users/discord/disable/${userEmail}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              enqueueSnackbar("Discord notifications disabled", {
                variant: "error",
              });
              setDiscordNotifications(false);
              getNotificationsData();
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    } else {
      enqueueSnackbar("Please confirm you email to enable this feature", {
        variant: "warning",
      });
    }
  };

  const handleNotificationFrequency = (frequency) => {
    const request_body = {
      email: user,
      frequency: frequency,
    };

    axios
      .post(`${API_URL}/users/notifications/frequency`, request_body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(() => {
        enqueueSnackbar("Notification Frequency changed successfully", {
          variant: "success",
        });
      })
      .catch((error) => {
        console.log(error.response);
        enqueueSnackbar(
          "Your request couldn't be processed at the moment, please try again later!",
          { variant: "error" }
        );
      });
  };

  const userEmail = localStorage.getItem("user");

  const handleDiscordNotification = () => {
    if (currentPlan?.confirmed) {
      const request_body = {
        webhook_url: inputs.webhook_url,
        toggle_notification: discordNotifications,
      };
      setSubmitted(true);
      if (inputs.webhook_url) {
        axios
          .patch(`${API_URL}/users/discord/${userEmail}`, request_body, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              handleCloseDiscordModal();
              setDiscordNotifications(true);
              enqueueSnackbar("Discord notifications enabled", {
                variant: "success",
              });
              getNotificationsData();
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    } else {
      enqueueSnackbar("Please confirm you email to enable this feature", {
        variant: "warning",
      });
    }
  };

  const handleEmailNotification = () => {
    if (currentPlan?.confirmed) {
      const request_body = {
        userEmail: inputs.userEmail,
        toggle_notification: emailNotifications,
        userId: localStorage.getItem("id"),
      };
      setSubmitted(true);
      if (inputs.userEmail) {
        axios
          .patch(`${API_URL}/users/email/${userEmail}`, request_body, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              handleCloseEmailModal();
              setEmailNotifications(true);
              enqueueSnackbar("Email notifications enabled", {
                variant: "success",
              });
              getNotificationsData();
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    } else {
      enqueueSnackbar("Please confirm you email to enable this feature", {
        variant: "warning",
      });
    }
  };

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  const [showSlackModal, setShowSlackModal] = useState(false);
  const handleCloseSlackModal = () => setShowSlackModal(false);
  const handleShowSlackModal = () => setShowSlackModal(true);

  const handleSlackEmail = () => {
    if (currentPlan?.confirmed) {
      const request_body = {
        email: userEmail,
        toggle_notification: setSlackNotifications,
      };
      axios
        .post(
          `${API_URL_PSI}/notification/registerEmailForSlack`,
          request_body,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          let data = response.data;
          if (data) {
            window.open(
              "https://slack.com/oauth/v2/authorize?client_id=3073037549410.3066412386870&scope=channels:join,channels:read,chat:write,chat:write.customize,chat:write.public,incoming-webhook&user_scope=",
              "_blank"
            );
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    } else {
      enqueueSnackbar("Please confirm you email to enable this feature", {
        variant: "warning",
      });
    }
  };

  const slackModal = (
    <Modal show={showSlackModal} onHide={handleCloseSlackModal}>
      <Modal.Header closeButton>
        <Modal.Title>Slack Notifications</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Follow this link to provide us access to your Slack Workspace</p>
        <a onClick={handleSlackEmail} style={{ cursor: "pointer" }}>
          <img
            alt="Add to Slack"
            height="40"
            width="139"
            src="https://platform.slack-edge.com/img/add_to_slack.png"
            srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
          />
        </a>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseSlackModal}>
          Close
        </Button>
        <Button>Save</Button>
      </Modal.Footer>
    </Modal>
  );

  const [showDiscordModal, setShowDiscordModal] = useState(false);
  const handleCloseDiscordModal = () => setShowDiscordModal(false);
  const handleShowDiscordModal = () => setShowDiscordModal(true);
  const discordModal = (
    <Modal show={showDiscordModal} onHide={handleCloseDiscordModal}>
      <Modal.Header closeButton>
        <Modal.Title>Discord Notifications</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Please create a Webhook on your Discord server using{" "}
          <a
            href="https://support.discord.com/hc/en-us/articles/228383668-Intro-to-Webhooks"
            target="_blank"
            rel="noreferrer"
          >
            this guide
          </a>
          .{" "}
        </p>
        <p>Provide us that Webhook URL and we&apos;ll take care of the rest!</p>
        <form name="form">
          <div className="form-group">
            <label>Webhook URL</label>
            <input
              type="text"
              name="webhook_url"
              id="webhook_url"
              value={inputs.webhook_url}
              onChange={handleChange}
              className={
                "form-control" +
                (submitted && !inputs.webhook_url ? " is-invalid" : "")
              }
            />
            {submitted && !inputs.webhook_url && (
              <div className="invalid-feedback">Webhook URL is required</div>
            )}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseDiscordModal}>
          Close
        </Button>
        <Button onClick={handleDiscordNotification}>Save</Button>
      </Modal.Footer>
    </Modal>
  );

  const [showEmailModal, setShowEmailModal] = useState(false);
  const handleCloseEmailModal = () => setShowEmailModal(false);
  const handleShowEmailModal = () => setShowEmailModal(true);
  const emailModal = (
    <Modal show={showEmailModal} onHide={handleCloseEmailModal}>
      <Modal.Header closeButton>
        <Modal.Title>Email Notifications</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Email here to get email notification</p>
        <p>Provide us that email and we&apos;ll take care of the rest!</p>
        <form name="form">
          <div className="form-group">
            <label>Email</label>
            <input
              type="text"
              name="userEmail"
              id="userEmail"
              value={inputs.userEmail}
              onChange={handleChange}
              className={
                "form-control" +
                (submitted && !inputs.userEmail ? " is-invalid" : "")
              }
            />
            {submitted && !inputs.userEmail && (
              <div className="invalid-feedback">Email is required</div>
            )}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseEmailModal}>
          Close
        </Button>
        <Button onClick={handleEmailNotification}>Save</Button>
      </Modal.Footer>
    </Modal>
  );

  async function getNotificationsData() {
    await axios
      .get(`${API_URL}/users/notifications/${userEmail}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        const notifications = response.data;
        setAllNotifications(notifications);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  useEffect(() => {
    getNotificationsData();
    dispatch(getUserPlanData(user.userId));
  }, []);

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Notifications"
          subtitle="Notifications"
          className="text-sm-left"
        />
      </Row>

      {currentPlan?.confirmed ? null : <NotConfirmed />}
      {slackModal}
      {discordModal}
      {emailModal}

      {!allNotifications ? (
        <CircularProgress
          className="ml-1"
          style={{ marginBottom: "16.5rem" }}
          color="inherit"
        />
      ) : (
        <Settings
          allNotifications={allNotifications}
          handleEmailNotificationsChange={handleEmailNotificationsChange}
          handleSlackNotificationsChange={handleSlackNotificationsChange}
          handleDiscordNotificationsChange={handleDiscordNotificationsChange}
          handleNotificationFrequency={handleNotificationFrequency}
        />
      )}

      <Options notifications={allNotifications} />
    </Container>
  );
};

export default Notifications;
