import { API_URL } from "../../constants";
import axios from "axios";

export const userService = {
  login,
  logout,
  register,
  update,
  forgotPassword,
};

axios.interceptors.request.use(
  (config) => {
    const { origin } = new URL(config.url);
    const allowedOrigins = [API_URL];
    const token = localStorage.getItem("token");
    if (allowedOrigins.includes(origin)) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function login(username, password) {
  return axios
    .post(`${API_URL}/auth`, {
      email: username,
      password: password,
    })
    .then(handleResponse)
    .then((data) => {
      localStorage.setItem("token", data.data.accessToken);
      localStorage.setItem("user", username);
      localStorage.setItem("id", data.data.userId);
      // setJwt(data.accessToken);
      return data;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("token");
}

async function register(user) {
  return await axios
    .post(`${API_URL}/users`, {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.username,
      password: user.password,
      role: user.role,
      usePlan: user.usePlan,
      plan: user.plan,
    })
    .then(handleResponse)
    .then((data) => {
      localStorage.setItem("id", data.data.id);
    });
}

function update(user, userId) {
  return axios
    .patch(`${API_URL}/users/${userId}`, {
      password: user.password,
    })
    .then(handleResponseUpdate);
}

function forgotPassword(user) {
  return axios
    .post(`${API_URL}/users/forgot-password`, {
      email: user.email,
    })
    .then(handleResponseUpdate);
}

function handleResponse(response) {
  const data = response;
  if (response.status !== 201) {
    if (response.status === 401) {
      // auto logout if 401 response returned from api
      logout();
      // location.reload(true);
    }
    // const error = (data && data.message) || response.statusText;
    const error = data;
    return Promise.reject(error);
  }

  return data;
}

function handleResponseUpdate(response) {
  const data = response;
  if (response.status !== 200) {
    const error = data;
    return Promise.reject(error);
  }
  return data;
}
