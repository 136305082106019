import { userConstants } from "../../constants";

export const plan = (
  state = {
    loading: false,
    plan: {
      name: "",
      price_month: "",
      price_annual: "",
      is_paid: "",
      next_payment_date: "",
      last_payment_date: "",
      site_limit: "",
      site_unlimited: "",
      page_limit: "",
      page_unlimited: "",
      competitor_sites_limit: "",
      competitor_sites_unlimited: "",
      sub_users_limit: "",
      sub_users_unlimited: "",
      confirmed: "",
    },
    error: false,
  },
  action
) => {
  switch (action.type) {
    case userConstants.GET_USER_PLAN_REQUEST:
      return { loading: true, error: false };
    case userConstants.GET_USER_PLAN_SUCCESS:
      return {
        loading: false,
        plan: action.payload,
        error: false,
      };
    case userConstants.GET_USER_PLAN_FAILURE:
      return { loading: false, error: true };
    case userConstants.PLAN_CLEAR:
      return {
        loading: false,
        plan: {
          name: "",
          price_month: "",
          price_annual: "",
          is_paid: "",
          next_payment_date: "",
          last_payment_date: "",
          site_limit: "",
          site_unlimited: "",
          page_limit: "",
          page_unlimited: "",
          competitor_sites_limit: "",
          competitor_sites_unlimited: "",
          sub_users_limit: "",
          sub_users_unlimited: "",
          confirmed: "",
        },
        error: false,
      };
    default:
      return state;
  }
};

/* eslint-disable no-unused-vars */
export function users(state = {}, action) {
  switch (action.type) {
    case userConstants.GETALL_REQUEST:
      return {
        loading: true,
      };
    case userConstants.GETALL_SUCCESS:
      return {
        items: action.users,
      };
    case userConstants.GETALL_FAILURE:
      return {
        error: action.error,
      };
    case userConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map((user) =>
          user.id === action.id ? { ...user, deleting: true } : user
        ),
      };
    case userConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return {
        items: state.items.filter((user) => user.id !== action.id),
      };
    case userConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        items: state.items.map((user) => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }

          return user;
        }),
      };
    default:
      return state;
  }
}
