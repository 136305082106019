import React, { useEffect, useState } from "react";
import OverallScoreOverviewGraph from "./OverallScoreOverviewGraph";
import CircularProgress from "@mui/material/CircularProgress";
import { Card, CardBody } from "shards-react";
import { useSelector } from "react-redux";

const OverallScoreOverview = ({ siteName, siteUrl }) => {
  const [overallScores, setOverallScores] = useState({
    mobile: [],
    desktop: [],
    scanDate: [],
  });
  const monthlyOverall = useSelector(
    (state) => state.monthlyOverall.monthlyOverall
  );
  const loading = useSelector((state) => state.monthlyOverall.loading);
  const error = useSelector((state) => state.monthlyOverall.error);

  let mobileData = [];
  let desktopData = [];
  let scanDate = [];

  const setData = (data) => {
    data.forEach((element) => {
      mobileData.push(parseInt(element.mobile));
      desktopData.push(parseInt(element.desktop));
      scanDate.push(element.scanDate);
    });
    setOverallScores({
      mobile: mobileData,
      desktop: desktopData,
      scanDate: scanDate,
    });
  };

  useEffect(() => {
    if (monthlyOverall) {
      setData(monthlyOverall);
    }
  }, [loading]);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress
            style={{ margin: "2rem", height: "140px", width: "140px" }}
          />
          <div>Loading Overall Score Graph</div>
        </div>
      ) : error ? (
        <Card small className="h-100">
          <CardBody>No sufficient data for monthly graph!</CardBody>
        </Card>
      ) : (
        <OverallScoreOverviewGraph
          data={overallScores}
          siteName={siteName}
          siteUrl={siteUrl}
        />
      )}
    </>
  );
};

export default OverallScoreOverview;
