import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink,
} from "shards-react";
import avatar from "../../../../assets/images/avatars/avatar.png";

export default class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible,
    });
  }

  render() {
    const user = localStorage.getItem("user");
    return (
      <NavItem
        tag={Dropdown}
        caret
        toggle={this.toggleUserActions}
        className="link--cursor"
      >
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
          <img
            className="user-avatar rounded-circle mr-2"
            src={avatar}
            alt="User Avatar"
          />{" "}
          <span className="d-none d-md-inline-block">{user}</span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          {this.props.user.rootUser ? null : (
            <>
              {!this.props.suspendedAccount && (
                <>
                  <Link to="/add-subusers" className="link--nohover">
                    <DropdownItem>
                      <i className="material-icons">&#xE8B8;</i> Manage Users
                    </DropdownItem>
                  </Link>
                  <DropdownItem divider />
                </>
              )}
              <Link to="/subscription-plans" className="link--nohover">
                <DropdownItem>
                  <i className="material-icons">&#xE896;</i>
                  Subscription Plans
                </DropdownItem>
              </Link>
              <DropdownItem divider />
            </>
          )}
          <Link to="/login" className="link--nohover">
            <DropdownItem
              onClick={() => {
                this.props.signout();
              }}
              className="text-danger"
            >
              <i className="material-icons text-danger">&#xE879;</i> Logout
            </DropdownItem>
          </Link>
        </Collapse>
      </NavItem>
    );
  }
}
