export const userConstants = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",
  REGISTER_CLEAR: "USERS_REGISTER_CLEAR",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT: "USERS_LOGOUT",

  DELETE_REQUEST: "USERS_DELETE_REQUEST",
  DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
  DELETE_FAILURE: "USERS_DELETE_FAILURE",

  GET_USER_PLAN_REQUEST: "GET_USER_PLAN_REQUEST",
  GET_USER_PLAN_SUCCESS: "GET_USER_PLAN_SUCCESS",
  GET_USER_PLAN_FAILURE: "GET_USER_PLAN_FAILURE",
  PLAN_CLEAR: "PLAN_CLEAR",
};
