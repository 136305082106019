import React, { useState } from "react";
import { Card, CardHeader, CardBody, Button } from "shards-react";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import WarningIcon from "@mui/icons-material/Warning";
import GppBadIcon from "@mui/icons-material/GppBad";
import FmdBadIcon from "@mui/icons-material/FmdBad";
import { useDispatch } from "react-redux";
import { getW3ValidatorData } from "../../state/actions/psi.actions";
import { API_URL_PSI } from "../../constants";
import axios from "axios";

const W3ValidatorOverviewStatus = (props) => {
  const w3Status = props.data;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const newScan = async (siteUrl) => {
    setLoading(true);
    await axios
      .get(
        `${API_URL_PSI}/w3validator`,
        {
          params: {
            url: siteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        dispatch(getW3ValidatorData(siteUrl));
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        dispatch(getW3ValidatorData(siteUrl));
        setLoading(false);
      });
  };

  return (
    <Card small className="h-100">
      <CardHeader
        className="border-bottom"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h6 className="d-inline-block m-0">W3 Validator Status</h6>
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <Link
            to={{
              pathname: "/w3ValidationDetails",
              state: {
                siteName: props.siteName,
                siteUrl: props.siteUrl,
                data: w3Status.details,
              },
            }}
          >
            <Button
              size="sm"
              className="d-inline-block btn-white mr-2 btn-small"
            >
              View Full Report &rarr;
            </Button>
          </Link>
          <Button
            href="https://validator.w3.org/about.html"
            target="_blank"
            size="sm"
            className="d-inline-block btn-info btn-small"
          >
            Learn more
          </Button>
          <Button
            size="sm"
            className="d-inline-block btn-info ml-2 btn-small"
            onClick={() => {
              newScan(props.siteUrl);
            }}
          >
            New Scan
          </Button>
        </div>
      </CardHeader>
      <CardBody className="pt-0">
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress
              style={{ margin: "2rem", height: "140px", width: "140px" }}
            />
            <div>Loading W3 Validator Status</div>
          </div>
        ) : (
          <div className="w3status">
            <div className="w3status__item">
              <GppBadIcon
                sx={{ color: "#EF4444", marginRight: "8px", fontSize: 30 }}
              />
              Fatal Errors: {w3Status.fatalErrors ? w3Status.fatalErrors : 0}
            </div>
            <div className="w3status__item">
              <FmdBadIcon
                sx={{ color: "#EF4444", marginRight: "8px", fontSize: 30 }}
              />
              Errors: {w3Status.errors ? w3Status.errors : 0}
            </div>
            <div className="w3status__item">
              <WarningIcon
                sx={{ color: "#ffc107", marginRight: "8px", fontSize: 30 }}
              />
              Warnings: {w3Status.warnings ? w3Status.warnings : 0}
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default W3ValidatorOverviewStatus;
