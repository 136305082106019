import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, ListGroup, ListGroupItem, Row, Col, Button } from "shards-react";
import { Radio, RadioGroup } from "react-radio-group";
import axios from "axios";
import { API_URL } from "../../constants";
import { useSnackbar } from "notistack";

const ContactForm = () => {
  const [contactInfo, setContactInfo] = useState({
    subject: "",
    type: "",
    checkbox1: "",
    checkbox2: "",
    checkbox3: "",
    checkbox4: "",
    screenshot: "",
    description: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [imageName, setImageName] = useState("");
  const user = localStorage.getItem("user");
  const userId = localStorage.getItem("id");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleChange = (e) => {
    let { name, value } = e.target ? e.target : e;
    setContactInfo((contactInfo) => ({ ...contactInfo, [name]: value }));
    if (!name && !value) {
      name = e;
      value = e;
      setContactInfo((contactInfo) => ({
        ...contactInfo,
        ["type"]: value,
      }));
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const uploadFile = async (e) => {
    console.log(e.target.files[0]);
    if (
      e.target.files[0].type.includes("image/png") ||
      e.target.files[0].type.includes("image/jpeg")
    ) {
      try {
        setImageName(e.target.files[0].name);
        const image = await toBase64(e.target.files[0]);
        setContactInfo((contactInfo) => ({
          ...contactInfo,
          screenshot: e.target?.files?.length ? image : "",
        }));
      } catch (error) {
        console.log(error);
      }
    } else {
      enqueueSnackbar("Only JPEG and PNG formats are supported!", {
        variant: "warning",
      });
      setTimeout(() => {
        closeSnackbar();
      }, 4500);
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);

    if (contactInfo.subject && contactInfo.type && contactInfo.description) {
      const request_body = {
        email: user,
        contactInfo: contactInfo,
      };

      const base64String = request_body.contactInfo.screenshot;
      let stringLength;
      let sizeInBytes;
      let sizeInKb;
      let sizeInMb;

      if (base64String.includes("data:image/jpeg;base64")) {
        stringLength = base64String.length - "data:image/jpeg;base64,".length;

        sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;

        sizeInKb = sizeInBytes / 1000;
        sizeInMb = sizeInKb / 1000;
      } else {
        stringLength = base64String.length - "data:image/png;base64,".length;

        sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;

        sizeInKb = sizeInBytes / 1000;
        sizeInMb = sizeInKb / 1000;
      }

      if (sizeInMb <= 1) {
        await axios
          .post(`${API_URL}/contactUs/${userId}`, request_body, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then(() => {
            setSubmitted(false);
            enqueueSnackbar(
              "Your request has been sent successfully, we will reach out to you as soon as we can!",
              { variant: "success" }
            );
            setTimeout(() => {
              closeSnackbar();
            }, 4500);
          })
          .catch((error) => {
            console.log(error);
            setSubmitted(false);
            enqueueSnackbar(
              "Your request has been not sent successfully, we will reach out to you as soon as we can!",
              { variant: "error" }
            );
            setTimeout(() => {
              closeSnackbar();
            }, 4500);
          });
      } else {
        setSubmitted(false);
        enqueueSnackbar(
          "You cannot upload images more than 1MB, please upload another screenshot!",
          { variant: "warning" }
        );
        setTimeout(() => {
          closeSnackbar();
        }, 4500);
      }
    }
  }

  return (
    <Card small className="mb-4">
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <form name="form" onSubmit={handleSubmit}>
                <Row form>
                  <Col md="6" className="form-group">
                    <label htmlFor="subject">Subject</label>
                    <input
                      type="text"
                      id="subject"
                      name="subject"
                      placeholder="Subject"
                      value={contactInfo.subject}
                      onChange={handleChange}
                      className={
                        "form-control" +
                        (submitted && !contactInfo.subject ? " is-invalid" : "")
                      }
                    />
                    {submitted && !contactInfo.subject && (
                      <div className="invalid-feedback">
                        Subject is required
                      </div>
                    )}
                  </Col>
                </Row>
                <Row form>
                  <Col md="6" className="form-group">
                    <label htmlFor="type">Type</label>
                    <div>
                      <RadioGroup
                        id="type"
                        name="type"
                        onChange={(e) => handleChange(e)}
                        className={
                          "form-control" +
                          (submitted && !contactInfo.type ? " is-invalid" : "")
                        }
                      >
                        <div className="radio-button-background">
                          <Radio
                            value="Enterprise Plan"
                            className="radio-button"
                          />
                          Enterprise Plan
                        </div>
                        <div className="radio-button-background">
                          <Radio
                            value="Customer Support"
                            className="radio-button"
                          />
                          Customer Support
                        </div>
                        <div className="radio-button-background">
                          <Radio
                            value="Web Vitals Support"
                            className="radio-button"
                          />
                          Web Vitals Support
                        </div>
                        <div className="radio-button-background">
                          <Radio value="Feedback" className="radio-button" />
                          Feedback - We would really appreciate some feedback :)
                        </div>
                      </RadioGroup>
                      {submitted && !contactInfo.type && (
                        <div className="invalid-feedback">Type is required</div>
                      )}
                    </div>
                  </Col>
                  {contactInfo.type != "Web Vitals Support" ? (
                    ""
                  ) : (
                    <Col md="12" className="form-group">
                      <label htmlFor="requestDetails">
                        Request Details for Web Vitals Support
                      </label>

                      <Row className="form-checkboxes">
                        <input
                          type="checkbox"
                          id="checkbox1"
                          name="checkbox1"
                          value="Support regarding any questions you have about Web
                        Vitals for free"
                          onChange={handleChange}
                        />
                        <label htmlFor="checkbox1">
                          Support regarding any questions you have about Web
                          Vitals for free
                        </label>
                      </Row>

                      <Row className="form-checkboxes">
                        <input
                          type="checkbox"
                          id="checkbox2"
                          name="checkbox2"
                          value="Comprehensive testing of your pages through our internal
                      tools and provide you with the results"
                          onChange={handleChange}
                        />
                        <label htmlFor="checkbox2">
                          Comprehensive testing of your pages through our
                          internal tools and provide you with the results
                        </label>
                      </Row>

                      <Row className="form-checkboxes">
                        <input
                          type="checkbox"
                          id="checkbox3"
                          name="checkbox3"
                          value="Plugins or code related suggestions on how to fix your
                      issues depending on your technology stack"
                          onChange={handleChange}
                        />
                        <label htmlFor="checkbox3">
                          Plugins or code related suggestions on how to fix your
                          issues depending on your technology stack
                        </label>
                      </Row>

                      <Row className="form-checkboxes">
                        <input
                          type="checkbox"
                          id="checkbox4"
                          name="checkbox4"
                          value="Work ourselves together with your team to improve your
                      Web Vitals or Web Performance Optimization"
                          onChange={handleChange}
                        />
                        <label htmlFor="checkbox4">
                          Work ourselves together with your team to improve your
                          Web Vitals or Web Performance Optimization
                        </label>
                      </Row>
                    </Col>
                  )}
                </Row>
                <Row form>
                  <Col md="6" className="form-group">
                    <label htmlFor="screenshot">Upload Screenshot</label>
                    <div className="custom-file mb-3">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="screenshot"
                        name="screenshot"
                        // value={contactInfo.screenshot}
                        onChange={uploadFile}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="customFile2"
                      >
                        {imageName ? imageName : "Choose file..."}
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea
                      type="textarea"
                      id="description"
                      name="description"
                      rows="5"
                      value={contactInfo.description}
                      onChange={handleChange}
                      className={
                        "form-control" +
                        (submitted && !contactInfo.description
                          ? " is-invalid"
                          : "")
                      }
                    />
                    {submitted && !contactInfo.description && (
                      <div className="invalid-feedback">
                        Description is required
                      </div>
                    )}
                  </Col>
                </Row>
                <Button theme="accent" disabled={!!submitted}>
                  Send
                </Button>
              </form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};

ContactForm.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
};

ContactForm.defaultProps = {
  title: "Contact Form",
};

export default ContactForm;
