import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody } from "shards-react";
import Chart from "../../utils/chart";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

const CardStartDate = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & .date-inputs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    & input {
      margin: 0 10px;
      border: 1px solid #e1e5eb;
      padding: 0.4286rem 0.875rem;
      font-size: 0.6875rem;
      line-height: 1.5;
      border-radius: 0.25rem;
      color: #3d5170;
    }
  }
`;

const OverallScoreOverviewGraph = (props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    document.querySelector("#chartReport").innerHTML =
      '<canvas id="overallscorechart" height="120" style="max-width:100% !important"></canvas>';

    const ctx = document.getElementById("overallscorechart");

    const chartOptions = {
      ...{
        responsive: true,
        legend: {
          position: "top",
        },
        elements: {
          line: {
            // A higher value makes the line look skewed at this ratio.
            tension: 0.3,
          },
          point: {
            radius: 0,
          },
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                suggestedMax: 100,
                suggestedMin: 0,
                beginAtZero: true,
              },
            },
          ],
        },
        hover: {
          mode: "nearest",
          intersect: false,
        },
        tooltips: {
          custom: false,
          mode: "nearest",
          intersect: false,
        },
      },
      ...props.chartOptions,
    };

    let filtered = [];

    if (startDate || endDate) {
      props?.data?.scanDate?.forEach((item) => {
        if (startDate && endDate) {
          if (
            item.split("T")[0] >= `${startDate}` &&
            item.split("T")[0] <= `${endDate}`
          ) {
            filtered.push([
              `${item.slice(5, 16).split("-").join("/").split("T").join(" ")}`,
            ]);
          }
        }
        if (startDate && !endDate) {
          if (item.split("T")[0] >= `${startDate}`) {
            filtered.push([
              `${item.slice(5, 16).split("-").join("/").split("T").join(" ")}`,
            ]);
          }
        }
        if (!startDate && endDate) {
          if (item.split("T")[0] <= `${endDate}`) {
            filtered.push([
              `${item.slice(5, 16).split("-").join("/").split("T").join(" ")}`,
            ]);
          }
        }
      });
    }

    let scanDates = props.data.scanDate.map((item) => [
      new Date(item).toLocaleDateString("en-UK", {
        day: "numeric",
        month: "numeric",
      }) +
        " " +
        new Date(item)
          .toLocaleDateString("en-UK", {
            hour: "2-digit",
            minute: "2-digit",
          })
          .split(",")[1]
          .trim(),
    ]);

    let monthlyData = {
      labels:
        startDate || endDate
          ? filtered.length
            ? filtered.map((item) => [
                item[0].slice(0, 5),
                item[0].slice(6, 11),
              ])
            : ["No Data", "No Data"]
          : scanDates.map((item) => [
              item[0].slice(0, 5),
              item[0].slice(6, 11),
            ]),
      datasets: [
        {
          label: "Mobile",
          fill: "start",
          data: props.data ? props.data.mobile : [0],
          backgroundColor: "rgba(0,123,255,0.1)",
          borderColor: "rgba(0,123,255,1)",
          pointBackgroundColor: "#ffffff",
          pointHoverBackgroundColor: "rgb(0,123,255)",
          borderWidth: 1.5,
          pointRadius: 0,
          pointHoverRadius: 3,
        },
        {
          label: "Desktop",
          fill: "start",
          data: props.data ? props.data.desktop : [0],
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgba(255,65,105,1)",
          pointBackgroundColor: "#ffffff",
          pointHoverBackgroundColor: "rgba(255,65,105,1)",
          borderWidth: 1,
          pointRadius: 0,
          pointHoverRadius: 2,
          pointBorderColor: "rgba(255,65,105,1)",
        },
      ],
    };

    /* eslint-disable no-unused-vars */
    var myChart = new Chart(ctx, {
      type: "LineWithLine",
      data: monthlyData,
      options: chartOptions,
    });
  }, [props.data, startDate, endDate]);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  return (
    <Card
      small
      className="h-100"
      style={{
        maxWidth: isMobile ? "100%" : "75%",
      }}
    >
      <CardHeader
        className="border-bottom"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h6 className="d-inline-block m-0">Overall Score Overview</h6>
      </CardHeader>
      <CardBody className="pt-0">
        <CardStartDate>
          <div className="date-inputs">
            <div>
              <span>Start Date:</span>
              <input
                placeholder="Start Date"
                type={"date"}
                onChange={handleStartDateChange}
              />
            </div>
            <div>
              <span>End Date:</span>
              <input
                placeholder="End Date"
                type={"date"}
                onChange={handleEndDateChange}
              />
            </div>
          </div>
        </CardStartDate>
        <div id="chartReport">
          <canvas
            id="overallscorechart"
            height="40vh"
            style={{ maxWidth: "100% !important" }}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default OverallScoreOverviewGraph;
