import { pingConstants } from "../../constants";
import axios from "axios";

export const getPingData = (site, startDate, endDate) => async (dispatch) => {
  try {
    console.log("startDate", startDate);
    console.log("endDate", endDate);
    dispatch({ type: pingConstants.GET_SITE_PING_DATA_REQUEST });
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_PING_URL}/get-ping-data`,
      {
        site: site,
      }
    );
    dispatch({
      type: pingConstants.GET_SITE_PING_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: pingConstants.GET_SITE_PING_DATA_FAILURE,
    });
  }
};

export const getFilteredPingData = (startDate, endDate, site) => async (
  dispatch
) => {
  try {
    dispatch({ type: pingConstants.GET_FILTERED_PING_DATA_REQUEST });
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_PING_URL}/get-ping-data`,
      {
        startDate: startDate,
        endDate: endDate,
        site: site,
      }
    );
    dispatch({
      type: pingConstants.GET_FILTERED_PING_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: pingConstants.GET_FILTERED_PING_DATA_FAILURE,
    });
  }
};

export const addNewPingData = ({
  site,
  selectedCountries,
  startDate,
  endDate,
}) => async (dispatch) => {
  try {
    dispatch({ type: pingConstants.ADDING_NEW_PING_DATA });
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_PING_URL}/ping-server`,
      {
        site,
        selectedCountries,
        startDate,
        endDate,
      }
    );
    dispatch({ type: pingConstants.ADD_NEW_PING_DATA, payload: data });
    dispatch({ type: pingConstants.NEW_PING_DATA_CLEAR });
    return data;
  } catch (error) {
    dispatch({ type: pingConstants.NEW_PING_DATA_CLEAR });
    throw error;
  }
};

export const deletePingData = (date) => async (dispatch) => {
  try {
    const {
      data,
    } = await axios.post(
      `${process.env.REACT_APP_BACKEND_PING_URL}/delete-ping-data`,
      { site: localStorage.getItem("tabUrl").replaceAll('"', ""), date: date }
    );
    dispatch({
      type: pingConstants.DELETE_SITE_PING_DATA,
      payload: date,
    });
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
