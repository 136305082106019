import React, { useState, useEffect } from "react";
import { Container, Row, Col, FormSelect, Button } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import SiteOverview from "../components/common/SiteOverview";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getData, getCompetitorSites } from "../state/actions/sites.actions";
import {
  getMetryVScoreData,
  getOriginSummaryData,
  getMonthlyOverallScoreData,
  getRankingData,
  getMonthlyLabData,
  getUptimeStatusData,
  getW3ValidatorData,
  getSitesStatusData,
  getCompetitorSitesStatus,
} from "../state/actions/psi.actions";
import { getPingData } from "../state/actions/ping.actions";
import { getUserPlanData } from "../state/actions/user.actions";

const Dashboard = () => {
  const [currentTabName, setCurrentTabName] = useState("");
  const [currentTabUrl, setCurrentTabUrl] = useState("");
  const [currentPropertyId, setCurrentPropertyId] = useState("");

  const dispatch = useDispatch();

  const sites = useSelector((state) => state.sites.sites);
  const user = useSelector((state) =>
    state.authentication.user
      ? state.authentication.user
      : state.registration.user
  );
  const loading = useSelector((state) => state.sitesStatus.loading);
  const metryVScore = useSelector((state) =>
    state.metryVScore.assessment ? state.metryVScore.assessment : false
  );
  const loadingMetryV = useSelector((state) => state.metryVScore.loading);
  const sitesStatus = useSelector((state) => state.sitesStatus.sitesStatus);

  const handleTabChange = (e) => {
    setCurrentTabName(e.target[e.target.selectedIndex].text);
    setCurrentTabUrl(e.target.value);

    dispatch(getMetryVScoreData(e.target.value));
    dispatch(getOriginSummaryData(e.target.value));
    dispatch(getMonthlyOverallScoreData(e.target.value));
    dispatch(getRankingData(e.target.value));
    dispatch(getMonthlyLabData(e.target.value));
    dispatch(getUptimeStatusData(e.target.value));
    dispatch(getW3ValidatorData(e.target.value));
    dispatch(getPingData(e.target.value));

    localStorage.setItem("tabUrl", JSON.stringify(e.target.value));
  };

  const backdrop = (
    <CircularProgress className="ml-4" color="inherit" open={loading} />
  );

  useEffect(() => {
    dispatch(getUserPlanData(user.userId));
    const optionsarraylistuniqueclass = document.querySelectorAll(
      ".optionsarraylistuniqueclass"
    );
    optionsarraylistuniqueclass.forEach((item) => {
      if (localStorage.getItem("tabUrl")) {
        if (`"${item.value}"` === localStorage.getItem("tabUrl")) {
          item.selected = "selected";
        }
      }
    });
    if (!sites?.length || !metryVScore) {
      dispatch(getCompetitorSites(user.rootUser ? user.rootUser : user.email))
        .then((response) => {
          if (response.length) {
            let urls = [];
            response.map((item) => {
              let { url } = item;
              return urls.push(url);
            });
            dispatch(getCompetitorSitesStatus(urls));
          }
        })
        .catch((error) => {
          console.log(error);
        });
      dispatch(getData(user.rootUser ? user.rootUser : user.email))
        .then((response) => {
          if (response.length) {
            setCurrentTabName(
              response[0].siteName ? response[0].siteName : response[0].url
            );
            setCurrentTabUrl(response[0].url);
            localStorage.setItem("tabUrl", JSON.stringify(response[0].url));
            localStorage.setItem(
              "comparisonUrl",
              JSON.stringify(response[0].url)
            );
            dispatch(getMetryVScoreData(response[0].url));
            dispatch(getOriginSummaryData(response[0].url));
            dispatch(getMonthlyOverallScoreData(response[0].url));
            dispatch(getRankingData(response[0].url));
            dispatch(getMonthlyLabData(response[0].url));
            dispatch(getUptimeStatusData(response[0].url));
            dispatch(getW3ValidatorData(response[0].url));
            dispatch(getPingData(response[0].url));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      if (
        !localStorage.getItem("tabUrl") &&
        (sitesStatus?.length || sites?.length)
      ) {
        localStorage.setItem("tabUrl", JSON.stringify(sites[0].url));
        dispatch(getMetryVScoreData(sites[0].url));
        dispatch(getOriginSummaryData(sites[0].url));
        dispatch(getMonthlyOverallScoreData(sites[0].url));
        dispatch(getRankingData(sites[0].url));
        dispatch(getMonthlyLabData(sites[0].url));
        dispatch(getUptimeStatusData(sites[0].url));
        dispatch(getW3ValidatorData(sites[0].url));
        dispatch(getPingData(sites[0].url));
        setCurrentTabName(sites[0].url);
        setCurrentTabUrl(sites[0].url);
        if (localStorage.getItem("propertyId")) {
          setCurrentPropertyId(
            localStorage.getItem("propertyId").replaceAll('"', "")
          );
        }
      } else {
        setCurrentTabName(localStorage.getItem("tabUrl").replaceAll('"', ""));
        setCurrentTabUrl(localStorage.getItem("tabUrl").replaceAll('"', ""));
        if (localStorage.getItem("propertyId")) {
          setCurrentPropertyId(
            localStorage.getItem("propertyId").replaceAll('"', "")
          );
        }
      }
    }
    if (!sitesStatus) {
      dispatch(getCompetitorSites(user.rootUser ? user.rootUser : user.email))
        .then((response) => {
          if (response.length) {
            let urls = [];
            response.map((item) => {
              let { url } = item;
              return urls.push(url);
            });
            dispatch(getCompetitorSitesStatus(urls));
          }
        })
        .catch((error) => {
          console.log(error);
        });
      dispatch(getData(user.rootUser ? user.rootUser : user.email))
        .then((response) => {
          if (response.length) {
            let urls = [];
            response.map((item) => {
              let { url } = item;
              return urls.push(url);
            });
            dispatch(getSitesStatusData(urls));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (
      !localStorage.getItem("tabUrl") &&
      (sitesStatus?.length || sites?.length)
    ) {
      localStorage.setItem("tabUrl", JSON.stringify(sites[0].url));
    }
  }, []);

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Web Vitals Overview"
          subtitle="Dashboard"
          className="text-sm-left mb-3"
        />
      </Row>

      {!sitesStatus?.length ? (
        ""
      ) : (
        <Row className="col-lg mb-3 pl-0 pr-0 ml-0 mr-0">
          <FormSelect
            className="overviewSelect"
            id="feInputState"
            onChange={handleTabChange}
          >
            {!sitesStatus.length
              ? ""
              : sitesStatus.map((site, idx) => (
                  <option
                    value={site.url}
                    key={idx}
                    className={"optionsarraylistuniqueclass"}
                  >
                    {site.siteName ? site.siteName : site.url}
                  </option>
                ))}
          </FormSelect>
        </Row>
      )}
      <Row>
        {loading || loadingMetryV ? (
          backdrop
        ) : !sites?.length || !metryVScore ? (
          <Row className="no-sites-yet ml-1">
            <Col className="col-12">
              <p>
                There are no sites yet added from you. Please go ahead and try
                it out!
              </p>
            </Col>
            <Col className="col-12">
              <Link
                to={{
                  pathname: "/registered-sites",
                }}
              >
                <Button className="d-inline-block btn-primary">Add Site</Button>
              </Link>
            </Col>
          </Row>
        ) : (
          <SiteOverview
            siteName={currentTabName ? currentTabName : sites[0].url}
            siteUrl={currentTabUrl ? currentTabUrl : sites[0].url}
            propertyId={currentPropertyId}
          />
        )}
      </Row>
    </Container>
  );
};

export default Dashboard;
