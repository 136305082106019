import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Modal } from "react-bootstrap";
import { Button } from "shards-react";
import Chart from "../../utils/chart";
import fourtypercent from "../../assets/images/analytics/fourtypercent.png";

const Stats = ({ open, setOpen }) => {
  const LoadingTime = () => {
    return (
      <span
        style={{
          fontSize: "14px",
          textAlign: "center",
        }}
        className="fadeInEffect"
      >
        Loading time is a major contributing factor to page abandonment. The
        average user has no patience for a page that takes too long to load, and
        justifiably so.
      </span>
    );
  };

  const Observation = () => {
    return (
      <span
        style={{
          fontSize: "14px",
          textAlign: "center",
        }}
        className="fadeInEffect"
      >
        Observation: slower page response time results in an increase in page
        abandonment, as demonstrates in the following chart
      </span>
    );
  };

  const Canvas = () => {
    return (
      <div id="chartReportStats" className="fadeInEffect">
        <canvas
          id="overallscorechartstats"
          height="120"
          style={{ maxWidth: "100% !important" }}
        />
      </div>
    );
  };

  const ImageStats = () => (
    <img
      src={fourtypercent}
      className="fadeInEffect"
      style={{ width: "70%", margin: "auto" }}
    />
  );
  useEffect(() => {
    if (open) {
      ReactDOM.render(
        <LoadingTime />,
        document.getElementById("chartReportStatsBody")
      );

      const timer1 = setTimeout(() => {
        ReactDOM.render(
          <Observation />,
          document.getElementById("chartReportStatsBody")
        );
      }, 6500);

      const timer2 = setTimeout(() => {
        ReactDOM.render(
          <Canvas />,
          document.getElementById("chartReportStatsBody")
        );

        const ctx = document?.getElementById("overallscorechartstats");

        const chartOptions = {
          ...{
            responsive: true,
            legend: {
              position: "top",
            },
            elements: {
              line: {
                // A higher value makes the line look skewed at this ratio.
                tension: 0.3,
              },
              point: {
                radius: 0,
              },
            },
            scales: {
              xAxes: [
                {
                  scaleLabel: {
                    display: true,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    max: 50,
                    // Include a dollar sign in the ticks
                    callback: function (value) {
                      if (value % 25 === 0) {
                        return value + " %";
                      }
                    },
                  },
                  lable: "Loading",
                },
              ],
            },
            hover: {
              mode: "nearest",
              intersect: false,
            },
            tooltips: {
              custom: false,
              mode: "nearest",
              intersect: false,
            },
            animation: {
              duration: 4000,
            },
          },
        };

        let scanDates = [
          "0",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
        ];

        let monthlyData = {
          labels: scanDates.map((item) => [item]),
          datasets: [
            {
              label: "User Conversions",
              fill: "start",
              data: [
                "0",
                "6.25",
                "12.5",
                "18.75",
                "25",
                "28.125",
                "31.25",
                "34.375",
                "35.9375",
                "37.5",
                "39.0625",
              ],
              backgroundColor: "rgba(0,123,255,0.1)",
              borderColor: "rgba(0,123,255,1)",
              pointBackgroundColor: "#ffffff",
              pointHoverBackgroundColor: "rgb(0,123,255)",
              borderWidth: 1.5,
              pointRadius: 0,
              pointHoverRadius: 3,
            },
          ],
        };

        /* eslint-disable no-unused-vars */
        var myChart = new Chart(ctx, {
          type: "LineWithLine",
          data: monthlyData,
          options: chartOptions,
        });
      }, 13000);

      const timer3 = setTimeout(() => {
        ReactDOM.render(
          <ImageStats />,
          document.getElementById("chartReportStatsBody")
        );
      }, 19500);
      return () => {
        clearTimeout(timer1);
        clearTimeout(timer2);
        clearTimeout(timer3);
      };
    }
  }, [open]);

  return (
    <Modal show={open} onHide={() => setOpen(false)}>
      <Modal.Header closeButton>
        <h3>Statistics</h3>
      </Modal.Header>
      <Modal.Body
        id="chartReportStatsBody"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      ></Modal.Body>
      <Modal.Footer style={{ justifyContent: "flex-end" }}>
        <Button
          outline
          theme="danger"
          className="mx-2"
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Stats;
