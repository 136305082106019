import React, { useState } from "react";
import { Col, Button } from "shards-react";
import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import { API_URL } from "../../constants";

const InvoicesList = ({ invoices, user, downloading, setDownloading }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const downloadSingleInvoice = async (orderid, date) => {
    try {
      setDownloading(true);
      await fetch(
        `${API_URL}/invoices/download/${user.userId}/${orderid}/${
          date?.split("T")[0]
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/pdf, application/pdf, */*",
            "Content-Type": "application/pdf",
          },
        }
      )
        .then((res) => {
          return res.blob();
        })
        .then(async (data) => {
          setDownloading(false);
          const a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.download = `invoice-${new Date(date).toLocaleDateString("en-UK", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}-order_id-${orderid}.pdf`;
          a.click();
          await axios
            .delete(
              `${API_URL}/invoices/delete/${user.userId}/${orderid}/${
                date?.split("T")[0]
              }`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .catch((error) => {
              console.log(error);
              setDownloading(false);
            });
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {invoices
        ?.sort((a, b) => new Date(b.date) - new Date(a.date))
        .map((invoice, idx) => {
          let {
            orderid,
            amount,
            currency,
            annual,
            plan,
            periodic_payments_start_date,
            date,
            country,
          } = invoice;
          return (
            <Accordion
              key={idx}
              expanded={expanded === `panel${idx + 1}`}
              onChange={handleChange(`panel${idx + 1}`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${idx + 1}bh-content`}
                id={`panel${idx + 1}bh-header`}
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  {plan}
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  {new Date(date).toLocaleDateString("en-UK", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography style={{ display: "flex" }}>
                  <Col className="col-lg-6 col-md-6 col-sm-6">
                    <p className="my-1" style={{ color: "#5A6169" }}>
                      Order Id
                    </p>
                    <p className="my-1" style={{ color: "#5A6169" }}>
                      Plan
                    </p>
                    <p className="my-1" style={{ color: "#5A6169" }}>
                      Amount
                    </p>
                    <p className="my-1" style={{ color: "#5A6169" }}>
                      Currency
                    </p>
                    <p className="my-1" style={{ color: "#5A6169" }}>
                      Annual
                    </p>
                    <p className="my-1" style={{ color: "#5A6169" }}>
                      Start Date
                    </p>
                    <p className="my-1" style={{ color: "#5A6169" }}>
                      Date
                    </p>
                    <p className="my-1" style={{ color: "#5A6169" }}>
                      Country
                    </p>
                  </Col>
                  <Col className="col-lg-6 col-md-6 col-sm-6">
                    <p className="my-1" style={{ color: "#5A6169" }}>
                      {orderid}
                    </p>
                    <p className="my-1" style={{ color: "#5A6169" }}>
                      {plan}
                    </p>
                    <p className="my-1" style={{ color: "#5A6169" }}>
                      {amount}
                    </p>
                    <p className="my-1" style={{ color: "#5A6169" }}>
                      {currency}
                    </p>
                    <p className="my-1" style={{ color: "#5A6169" }}>
                      {annual ? "True" : "False"}
                    </p>
                    <p className="my-1" style={{ color: "#5A6169" }}>
                      {new Date(
                        periodic_payments_start_date
                      ).toLocaleDateString("en-UK", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </p>
                    <p className="my-1" style={{ color: "#5A6169" }}>
                      {new Date(date).toLocaleDateString("en-UK", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </p>
                    <p className="my-1" style={{ color: "#5A6169" }}>
                      {country}
                    </p>
                  </Col>
                </Typography>
                <div style={{ textAlign: "right" }}>
                  <Button
                    onClick={() => downloadSingleInvoice(orderid, date)}
                    outline
                    theme={downloading ? "secondary" : "info"}
                    disabled={downloading}
                  >
                    Download .pdf
                  </Button>
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
    </div>
  );
};

export default InvoicesList;
