import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getUserPlanData } from "../../state/actions/user.actions";
import axios from "axios";
import { API_URL } from "../../constants";

const checkout = ({ checkoutModal, setCheckoutModal }) => {
  const [annual, setAnnual] = useState("false");
  const [users, setUsers] = useState([]);
  const [pagesLength, setPagesLength] = useState(0);
  const [sitesLength, setSitesLength] = useState(0);
  const [usersLength, setUsersLength] = useState(0);
  const [link, setLink] = useState("");

  const user = useSelector((state) =>
    state.authentication.user
      ? state.authentication.user
      : state.registration.user
  );
  const sites = useSelector((state) => state.sites.sites);
  const currentPlan = useSelector((state) => state.plan.plan);

  const getUsers = async () => {
    await axios
      .get(`${API_URL}/sub-users/${localStorage.getItem("user")}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        const data = response.data.response.map((item) => {
          return { ...item };
        });
        setUsers(data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setAnnual(`${e.target.value}`);
    // setLink(
    //   `http://localhost/payment/redirect.php?name=${checkoutModal.clickedPlan}&user=${user.userId}&annual=${e.target.value}`
    // );
    setLink(
      `https://payment.metryv.com/redirect.php?name=${
        checkoutModal.clickedPlan
      }&user=${localStorage.getItem("id")}&annual=${e.target.value}`
    );
  };

  useEffect(() => {
    getUsers();
    let pages = [];
    if (sites?.length) {
      setSitesLength(sites.length);
      sites?.forEach((item) => {
        pages.push(item.pages.length);
      });
    }
    if (users?.length) {
      setUsersLength(users.length);
    }
    if (pages.length) {
      setPagesLength(Math.max(...pages));
    }
    setLink(
      `https://payment.metryv.com/redirect.php?name=${
        checkoutModal.clickedPlan
      }&user=${localStorage.getItem("id")}&annual=${annual}`
    );
    // setLink(
    //   `http://localhost/payment/redirect.php?name=${checkoutModal.clickedPlan}&user=${user.userId}&annual=${annual}`
    // );
  }, [checkoutModal.open]);

  return (
    <Dialog open={checkoutModal.open} fullWidth={true} maxWidth="sm">
      {currentPlan.name !== "Trial" ? (
        (!checkoutModal.site_unlimited &&
          sitesLength > checkoutModal.site_limit) ||
        (!checkoutModal.page_unlimited &&
          pagesLength > checkoutModal.page_limit) ||
        (!checkoutModal.subusers_unlimited &&
          usersLength > checkoutModal.subusers_limit) ? (
          <>
            <DialogTitle>Check Plan Features</DialogTitle>
            <DialogContent>
              <span>
                Please check this plan features and compare it with your current
                data
              </span>
            </DialogContent>
            <DialogContent>
              <table className="checkout-table">
                <tr>
                  <td>Current Added Sites</td>
                  <td>Selected Plan Sites Limit</td>
                </tr>
                <tr>
                  <td>{sites.length}</td>
                  <td>{checkoutModal.site_limit}</td>
                </tr>
                <tr>
                  <td>Current Pages per Site</td>
                  <td>Selected Plan Pages per Site Limit</td>
                </tr>
                <tr>
                  <td>{pagesLength}</td>
                  <td>{checkoutModal.page_limit}</td>
                </tr>
                <tr>
                  <td>Current Registered Users</td>
                  <td>Selected Plan Subusers Limit</td>
                </tr>
                <tr>
                  <td>{users.length}</td>
                  <td>{checkoutModal.subusers_limit}</td>
                </tr>
              </table>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setCheckoutModal((state) => ({
                    ...state,
                    open: !state.open,
                  }));
                }}
              >
                Close
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle>Payment Method</DialogTitle>
            <DialogContent style={{ padding: "0" }}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={annual}
                onChange={handleChange}
              >
                <FormControlLabel
                  value={"true"}
                  control={<Radio />}
                  label={`Annual ${checkoutModal.price_annual}€`}
                  style={{
                    backgroundColor: "#f5f6f8",
                    margin: "7.5px 0",
                    paddingLeft: "10px",
                  }}
                />
                <FormControlLabel
                  value={"false"}
                  control={<Radio />}
                  label={`Monthly ${checkoutModal.price_month}€`}
                  style={{
                    backgroundColor: "#f5f6f8",
                    margin: "7.5px 0",
                    paddingLeft: "10px",
                  }}
                />
              </RadioGroup>
            </DialogContent>
            <DialogContent
              style={{ textAlign: "right", padding: "0 10px 0 0" }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "1.25rem",
                }}
              >
                Total{" "}
                {annual === "true"
                  ? String(Number(checkoutModal.price_annual * 12).toFixed(2))
                  : checkoutModal.price_month}
                &euro;
              </span>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setCheckoutModal((state) => ({
                    ...state,
                    open: !state.open,
                  }));
                  dispatch(getUserPlanData(user.userId));
                }}
              >
                Close
              </Button>
              <Button>
                <a href={link} target="_blank" rel="noreferrer">
                  Proceed to checkout
                </a>
              </Button>
            </DialogActions>
          </>
        )
      ) : (
        <>
          <DialogTitle>Payment Method</DialogTitle>
          <DialogContent style={{ padding: "0" }}>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={annual}
              onChange={handleChange}
            >
              <FormControlLabel
                value={"true"}
                control={<Radio />}
                label={`Annual ${checkoutModal.price_annual}€`}
                style={{
                  backgroundColor: "#f5f6f8",
                  margin: "7.5px 0",
                  paddingLeft: "10px",
                }}
              />
              <FormControlLabel
                value={"false"}
                control={<Radio />}
                label={`Monthly ${checkoutModal.price_month}€`}
                style={{
                  backgroundColor: "#f5f6f8",
                  margin: "7.5px 0",
                  paddingLeft: "10px",
                }}
              />
            </RadioGroup>
          </DialogContent>
          <DialogContent style={{ textAlign: "right", padding: "0 10px 0 0" }}>
            <span
              style={{
                fontWeight: "bold",
                fontSize: "1.25rem",
              }}
            >
              Total{" "}
              {annual === "true"
                ? String(Number(checkoutModal.price_annual * 12).toFixed(2))
                : checkoutModal.price_month}
              &euro;
            </span>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setCheckoutModal((state) => ({ ...state, open: !state.open }));
                dispatch(getUserPlanData(user.userId));
              }}
            >
              Close
            </Button>
            <Button>
              <a href={link} target="_blank" rel="noreferrer">
                Proceed to checkout
              </a>
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default checkout;
