import { metryvScoreConstants, sitesConstants } from "../../constants";
import axios from "axios";
import { API_URL_PSI } from "../../constants";

export const getMetryVScoreData = (siteUrl) => (dispatch) => {
  dispatch({ type: metryvScoreConstants.METRYV_SCORE_REQUEST });
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${API_URL_PSI}/scan/score`,
        {
          data: {
            url: siteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: metryvScoreConstants.METRYV_SCORE_SUCCESS,
          payload: response.data,
        });
        resolve(response.data);
      })
      .catch((error) => {
        dispatch({
          type: metryvScoreConstants.METRYV_SCORE_FAILURE,
          payload: error.response,
        });
        reject(error.response);
      });
  });
};

export const getOriginSummaryData = (siteUrl) => (dispatch) => {
  dispatch({ type: metryvScoreConstants.ORIGIN_SUMMARY_REQUEST });
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${API_URL_PSI}/scan/originSummary`,
        {
          data: {
            url: siteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: metryvScoreConstants.ORIGIN_SUMMARY_SUCCESS,
          payload: response.data,
        });
        resolve(response.data);
      })
      .catch((error) => {
        dispatch({
          type: metryvScoreConstants.ORIGIN_SUMMARY_FAILURE,
          payload: error.response,
        });
        reject(error.response);
      });
  });
};

export const getMonthlyOverallScoreData = (siteUrl) => (dispatch) => {
  dispatch({ type: metryvScoreConstants.GET_MONTHLY_OVERALL_REQUEST });
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${API_URL_PSI}/scan/monthly/overall`,
        {
          params: {
            url: siteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
        dispatch({
          type: metryvScoreConstants.GET_MONTHLY_OVERALL_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        reject(error.response);
        dispatch({
          type: metryvScoreConstants.GET_MONTHLY_OVERALL_REQUEST,
          payload: error.response,
        });
      });
  });
};

export const getRankingData = (siteUrl) => (dispatch) => {
  dispatch({ type: metryvScoreConstants.RANKING_REQUEST });
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${API_URL_PSI}/scan/ranking`,
        {
          data: {
            url: siteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: metryvScoreConstants.RANKING_SUCCESS,
          payload: response.data,
        });
        resolve(response.data);
      })
      .catch((error) => {
        dispatch({
          type: metryvScoreConstants.RANKING_FAILURE,
          payload: error.response,
        });
        reject(error.response);
      });
  });
};

export const getMonthlyLabData = (siteUrl) => (dispatch) => {
  dispatch({
    type: metryvScoreConstants.GET_MONTHLY_LAB_DATA_REQUEST,
  });
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${API_URL_PSI}/scan/monthly/labData`,
        {
          params: {
            url: siteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
        dispatch({
          type: metryvScoreConstants.GET_MONTHLY_LAB_DATA_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        reject(error.response);
        dispatch({
          type: metryvScoreConstants.GET_MONTHLY_LAB_DATA_FAILURE,
          payload: error.response,
        });
      });
  });
};

export const getUptimeStatusData = (siteUrl) => (dispatch) => {
  dispatch({
    type: metryvScoreConstants.GET_UPTIME_DATA_REQUEST,
  });
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${API_URL_PSI}/uptime/monthly`,
        {
          params: {
            url: siteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
        dispatch({
          type: metryvScoreConstants.GET_UPTIME_DATA_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        reject(error.response);
        dispatch({
          type: metryvScoreConstants.GET_UPTIME_DATA_FAILURE,
          payload: error.response,
        });
      });
  });
};

export const getW3ValidatorData = (siteUrl) => (dispatch) => {
  dispatch({ type: metryvScoreConstants.GET_W3_DATA_REQUEST });
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${API_URL_PSI}/w3validator/latest`,
        {
          params: {
            url: siteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
        dispatch({
          type: metryvScoreConstants.GET_W3_DATA_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        reject(error.response);
        dispatch({
          type: metryvScoreConstants.GET_W3_DATA_FAILURE,
          payload: error.response,
        });
      });
  });
};

export const getSitesStatusData = (urls) => (dispatch) => {
  dispatch({ type: metryvScoreConstants.GET_SITES_STATUS_REQUEST });
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${API_URL_PSI}/status/all/`,
        { sites: urls },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: metryvScoreConstants.GET_SITES_STATUS_SUCCESS,
          payload: response.data,
        });
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
        dispatch({
          type: metryvScoreConstants.GET_SITES_STATUS_FAILURE,
          payload: error.response,
        });
      });
  });
};

export const getCompetitorSitesStatus = (urls) => (dispatch) => {
  dispatch({ type: metryvScoreConstants.GET_COMPETITOR_SITES_STATUS_REQUEST });
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${API_URL_PSI}/status/all/`,
        { sites: urls },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: metryvScoreConstants.GET_COMPETITOR_SITES_STATUS_SUCCESS,
          payload: response.data,
        });
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
        dispatch({
          type: metryvScoreConstants.GET_COMPETITOR_SITES_STATUS_FAILURE,
          payload: error.response,
        });
      });
  });
};

export const removeSiteFromState = (url) => (dispatch) => {
  dispatch({ type: metryvScoreConstants.REMOVE_SITE_FROM_STATE, payload: url });
};

export const getSiteFromState = (url) => (dispatch) => {
  dispatch({ type: metryvScoreConstants.GET_SITE_FROM_STATE, payload: url });
};

export const editSiteFromState = (url) => (dispatch) => {
  dispatch({ type: metryvScoreConstants.EDIT_SITE_FROM_STATE, payload: url });
};

export const removeCompetitorSiteFromState = (url) => (dispatch) => {
  dispatch({
    type: metryvScoreConstants.REMOVE_COMPETITOR_SITE_FROM_STATE,
    payload: url,
  });
  dispatch({
    type: sitesConstants.REMOVE_COMPETITOR_SITE_NAME_FROM_STATE,
    payload: url,
  });
};
