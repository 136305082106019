import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL_PSI } from "../../constants";
import CircularProgress from "@mui/material/CircularProgress";
import { Card, CardHeader, CardBody, Row, Col, Button } from "shards-react";
import GppGoodIcon from "@mui/icons-material/GppGood";
import WarningIcon from "@mui/icons-material/Warning";
import GppBadIcon from "@mui/icons-material/GppBad";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

const ScreenshotsAnalyze = ({ siteName, siteUrl }) => {
  const [compare, setCompare] = useState("");
  const [screenshots, setScreenshots] = useState([]);
  const [difference, setDifference] = useState("");
  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState(false);

  async function getCompareResult() {
    setLoading(true);
    setNoData(false);
    const request_body = {
      url: siteUrl,
    };
    await axios
      .post(`${API_URL_PSI}/screenshot/compare`, request_body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        let data = response.data;
        setCompare(data);
        setNoData(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        setNoData(true);
        setLoading(false);
      });
  }

  async function getScreenshots() {
    setNoData(false);
    setLoading(true);
    const request_body = {
      url: siteUrl,
    };
    await axios
      .post(`${API_URL_PSI}/screenshot/getScreenshots`, request_body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        let data = response.data;
        setScreenshots(data);
        setNoData(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        setNoData(true);
        setLoading(false);
      });
  }

  async function getDifference() {
    setNoData(false);
    setLoading(true);
    const request_body = {
      url: siteUrl,
    };
    await axios
      .post(`${API_URL_PSI}/screenshot/difference`, request_body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        let data = response.data;
        setNoData(false);
        setDifference(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        setNoData(true);
        setLoading(false);
      });
  }

  const getStatus = () => {
    let status = "average";
    let message = "No data yet";
    if (screenshots.length >= 2 && compare.compare === true) {
      message = "Site hasn't changed at all";
      status = "good";
    } else if (compare.compare === false) {
      message = "There are some significant differences";
      status = "bad";
    }
    return [status, message];
  };

  const newScan = async (siteUrl) => {
    try {
      setLoading(true);
      if (screenshots.length < 2) {
        await axios.post(
          `${API_URL_PSI}/screenshot/mobile`,
          { url: siteUrl },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        await axios.post(
          `${API_URL_PSI}/screenshot/mobile`,
          { url: siteUrl },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        getCompareResult();
        getScreenshots();
        getDifference();
        setLoading(false);
      } else {
        await axios.post(
          `${API_URL_PSI}/screenshot/mobile`,
          { url: siteUrl },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        getCompareResult();
        getScreenshots();
        getDifference();
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      getCompareResult();
      getScreenshots();
      getDifference();
      setLoading(false);
    }
  };

  useEffect(() => {
    getCompareResult();
    getScreenshots();
    getDifference();
  }, [siteName, siteUrl]);

  return (
    <Card small className="h-100 screenshots-analyze">
      <CardHeader
        className="border-bottom"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h6 className="d-inline-block m-0">Screenshot Analysis</h6>

        <div
          className="uptime__status"
          style={{ display: "flex", alignItems: "center" }}
        >
          {getStatus()[0] === "good" ? (
            <GppGoodIcon sx={{ color: "#10B981" }} />
          ) : getStatus()[0] === "bad" ? (
            <GppBadIcon sx={{ color: "#EF4444" }} />
          ) : (
            <WarningIcon sx={{ color: "#ffc107" }} />
          )}
          <span className={"uptime__status--" + getStatus()[0]}>
            {getStatus()[1]}
          </span>
          <Button
            size="sm"
            className="d-inline-block btn-info ml-2 btn-small"
            onClick={() => {
              newScan(siteUrl);
            }}
          >
            New Scan
          </Button>
        </div>
      </CardHeader>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress
            style={{ margin: "2rem", height: "140px", width: "140px" }}
          />
          <div>Loading Screenshot Analysis</div>
        </div>
      ) : noData ? (
        <CardBody className="pt-4 screenshots-analyze__body">
          Not enough data for the moment, please wait for some hours to get
          results from this feature
        </CardBody>
      ) : (
        <CardBody className="pt-0 screenshots-analyze__body">
          {screenshots.length < 2 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <CircularProgress
                style={{ margin: "2rem", height: "140px", width: "140px" }}
              />
              <div>Loading Screenshots Analyses</div>
            </div>
          ) : (
            <Row>
              <Col
                className="col-xs-12 col-lg-6"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  src={screenshots[0].mobile}
                  alt="old screenshot"
                  width={176}
                  height={410}
                />
                <h5 className="mt-2">old image</h5>
                <p className="mt-2">
                  {new Date(screenshots[0].date).toLocaleDateString("en-UK", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </p>
              </Col>
              <Col
                className="col-xs-12 col-lg-6"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  src={screenshots[1].mobile}
                  alt="new screenshot"
                  width={176}
                  height={410}
                />
                <h5 className="mt-2">new image</h5>
                <p className="mt-2">
                  {new Date(screenshots[1].date).toLocaleDateString("en-UK", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </p>
              </Col>
            </Row>
          )}
          {!difference.difference ? null : (
            <>
              <DoubleArrowIcon
                className="screenshots-analyze__arrow"
                style={{ margin: "auto 5%", height: "140px", width: "140px" }}
              />
              <Col className="col-xs-12 col-lg-6 screenshots-analyze__differences">
                <img
                  src={difference.difference}
                  alt="Differences Screenshot"
                  width={200}
                  height={453.33}
                />
                <h5 className="mt-2">difference</h5>
              </Col>
            </>
          )}
        </CardBody>
      )}
    </Card>
  );
};

export default ScreenshotsAnalyze;
