import React, { useState } from "react";
import axios from "axios";
import { API_URL } from "../constants";
import logo from "../assets/images/logos/logo-icon.svg";
import { useHistory } from "react-router-dom";

const SetSubUserPassword = () => {
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const history = useHistory();
  const regex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );

  function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].substr(vars[i].indexOf("=") + 1, vars[i].length);
      var querys = vars[i].substr(0, vars[i].indexOf("="));
      if (querys == variable) {
        return pair;
      }
    }
    return false;
  }

  const email = getQueryVariable("email");
  const confirmToken = getQueryVariable("confirmToken");

  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  const confirmEmail = () => {
    axios
      .post(`${API_URL}/users/confirm-email`, {
        email: email,
        confirmToken: confirmToken,
      })
      .then(() => {
        history.push("/confirmed-sub-user-email");
      })
      .catch((error) => {
        console.log(error.response);
        history.push("/confirmed-sub-user-email-failed");
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (password.match(regex)) {
      axios
        .post(`${API_URL}/sub-user/set-password`, {
          email: email,
          password: password,
        })
        .then(() => {
          setSubmitted(false);
          confirmEmail();
        })
        .catch((error) => {
          setSubmitted(false);
          history.push("/confirmed-sub-user-email-failed");
          console.log(error);
        });
    } else {
      setPassword("");
    }
  };

  return (
    <div className="login-container">
      <div className="d-table m-auto">
        <img
          id="main-logo"
          className="d-inline-block align-top"
          style={{ maxWidth: "50px" }}
          src={logo}
          alt="metryV Logo"
        />
      </div>
      <h2 style={{ textAlign: "center" }} className="mt-2">
        MetryV
      </h2>
      <form name="form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Set Password</label>
          <input
            type="password"
            name="password"
            value={password}
            onChange={handleChange}
            className={
              "form-control" +
              (submitted && Boolean(!password.match(regex))
                ? " is-invalid"
                : "")
            }
          />
          <br />
          <div className="feedback">
            Password must between 8 to 15 characters which contain at least one
            lowercase letter, one uppercase letter, one numeric digit, and one
            special character
          </div>
        </div>
        <div className="form-group">
          <button className="btn btn-primary" disabled={!password.match(regex)}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export { SetSubUserPassword };
