import React from "react";
import { Button } from "shards-react";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

const table = ({
  pingData,
  setReportToView,
  setClickReport,
  setOpenDeleteDataModal,
}) => {
  const loadingNewPingData = useSelector((state) => state.newPingData?.loading);
  const newPingDataDate = useSelector((state) => state.newPingData?.date);

  return (
    <table className="table mb-0">
      {loadingNewPingData && (
        <tr>
          <td>#</td>
          <td>
            {new Date(newPingDataDate).toLocaleDateString("en-UK", {
              day: "numeric",
              month: "long",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </td>
          <td>
            <CircularProgress
              style={{
                width: "20px",
                height: "20px",
                position: "relative",
                top: "6px",
              }}
            />
          </td>
          <td style={{ textAlign: "right" }}>
            <Button disabled theme="info" className="mr-4">
              View Full Report
            </Button>
            <Button disabled theme="danger" className="mr-4">
              Remove Data
            </Button>
          </td>
        </tr>
      )}
      {pingData?.length ? (
        pingData?.map((item, index) => {
          let { date, _id } = item;
          return (
            <tbody key={index}>
              <tr>
                <th scope="col" style={{ borderWidth: "3px" }}>
                  Date
                </th>
                <td style={{ borderWidth: "3px" }}>
                  {new Date(date).toLocaleDateString("en-UK", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </td>
              </tr>
              <tr>
                <th scope="col" style={{ textAlign: "left" }}>
                  latency - Overall PING Result
                </th>
              </tr>
              <tr>
                <th scope="col">Actions</th>
                <td style={{ textAlign: "left" }}>
                  <Button
                    theme="info"
                    className="m-2"
                    onClick={() => {
                      setReportToView(date), setClickReport(true);
                    }}
                  >
                    Full Report
                  </Button>
                  <Button
                    theme="danger"
                    className="m-2"
                    onClick={() =>
                      setOpenDeleteDataModal({ open: true, data: _id })
                    }
                  >
                    Remove Data
                  </Button>
                </td>
              </tr>
            </tbody>
          );
        })
      ) : (
        <tbody>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      )}
    </table>
  );
};

export default table;
