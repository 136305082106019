import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../constants";

function ConfirmEmail() {
  const [confirmedEmail, setConfirmedEmail] = useState(false);

  function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].substr(vars[i].indexOf("=") + 1, vars[i].length);
      var querys = vars[i].substr(0, vars[i].indexOf("="));
      if (querys == variable) {
        return pair;
      }
    }
    return false;
  }

  const email = getQueryVariable("email");
  const confirmToken = getQueryVariable("confirmToken");

  useEffect(() => {
    axios
      .post(
        `${API_URL}/users/confirm-email`,
        {
          email: email,
          confirmToken: confirmToken,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        setConfirmedEmail(true);
      })
      .catch((error) => {
        console.log(error);
        setConfirmedEmail(false);
      });
  }, []);

  return (
    <div className="login-container">
      {confirmedEmail ? (
        <h4 style={{ color: "green" }}>
          Your Email has been confirmed successfully!
        </h4>
      ) : (
        <h4 style={{ color: "red" }}>
          Token has expired. Please try to verify email again!
        </h4>
      )}
      <Link
        to={{
          pathname: "/login",
        }}
        className="btn btn-info mt-4"
      >
        Go to login page
      </Link>
    </div>
  );
}

export { ConfirmEmail };
