export const pingConstants = {
  GET_SITE_PING_DATA_REQUEST: "GET_SITE_PING_DATA_REQUEST",
  GET_SITE_PING_DATA_SUCCESS: "GET_SITE_PING_DATA_SUCCESS",
  GET_SITE_PING_DATA_FAILURE: "GET_SITE_PING_DATA_FAILURE",
  GET_SITE_PING_DATA_CLEAR: "GET_SITE_PING_DATA_CLEAR",

  GET_FILTERED_PING_DATA_REQUEST: "GET_FILTERED_PING_DATA_REQUEST",
  GET_FILTERED_PING_DATA_SUCCESS: "GET_FILTERED_PING_DATA_SUCCESS",
  GET_FILTERED_PING_DATA_FAILURE: "GET_FILTERED_PING_DATA_FAILURE",

  DELETE_SITE_PING_DATA: "DELETE_SITE_PING_DATA",
  ADD_NEW_PING_DATA: "ADD_NEW_PING_DATA",

  ADDING_NEW_PING_DATA: "ADDING_NEW_PING_DATA",
  NEW_PING_DATA_CLEAR: "NEW_PING_DATA_CLEAR",
};
