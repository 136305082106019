import { sitesConstants } from "../../constants";
import axios from "axios";
import { API_URL } from "../../constants";

export const getData = (email) => (dispatch) => {
  dispatch({ type: sitesConstants.GETALL_REQUEST });
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/sites/${email}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        resolve(response.data[0].sites);
        dispatch({
          type: sitesConstants.GETALL_SUCCESS,
          payload: response.data[0].sites,
        });
      })
      .catch((error) => {
        reject(error.response);
        dispatch({
          type: sitesConstants.GETALL_FAILURE,
          payload: error.response,
        });
      });
  });
};

export const getCompetitorSites = (email) => (dispatch) => {
  dispatch({ type: sitesConstants.COMPETITOR_SITES_GETALL_REQUEST });
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/competitor-sites/${email}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        resolve(response.data);
        dispatch({
          type: sitesConstants.COMPETITOR_SITES_GETALL_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error.response ? error.response : error);
        reject(error.response ? error.response : error);
        dispatch({
          type: sitesConstants.COMPETITOR_SITES_GETALL_FAILURE,
          payload: true,
        });
      });
  });
};

export const loadingNewSite = (data) => (dispatch) => {
  dispatch({ type: sitesConstants.ADDING_NEW_SITE, payload: data });
};
export const loadingNewSiteDone = () => (dispatch) => {
  dispatch({ type: sitesConstants.NEW_SITE_CLEAR });
};

export const loadingNewCompetitorSite = (data) => (dispatch) => {
  dispatch({ type: sitesConstants.ADDING_NEW_COMPETITOR_SITE, payload: data });
};
export const loadingNewCompetitorSiteDone = () => (dispatch) => {
  dispatch({ type: sitesConstants.NEW_COMPETITOR_SITE_CLEAR });
};
