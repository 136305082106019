import { metryvScoreConstants } from "../../constants";

export const metryVScore = (state = {}, action) => {
  switch (action.type) {
    case metryvScoreConstants.METRYV_SCORE_REQUEST:
      return { loading: true };
    case metryvScoreConstants.METRYV_SCORE_SUCCESS:
      return { loading: false, assessment: action.payload };
    case metryvScoreConstants.METRYV_SCORE_FAILURE:
      return { loading: false, error: action.payload };
    case metryvScoreConstants.METRYV_SCORE_CLEAR:
      return {};
    default:
      return state;
  }
};

export const originSummary = (state = {}, action) => {
  switch (action.type) {
    case metryvScoreConstants.ORIGIN_SUMMARY_REQUEST:
      return { loading: true };
    case metryvScoreConstants.ORIGIN_SUMMARY_SUCCESS:
      return { loading: false, originSummary: action.payload };
    case metryvScoreConstants.ORIGIN_SUMMARY_FAILURE:
      return { loading: false, error: action.payload };
    case metryvScoreConstants.ORIGIN_SUMMARY_CLEAR:
      return {};
    default:
      return state;
  }
};

export const monthlyOverall = (state = {}, action) => {
  switch (action.type) {
    case metryvScoreConstants.GET_MONTHLY_OVERALL_REQUEST:
      return { loading: true };
    case metryvScoreConstants.GET_MONTHLY_OVERALL_SUCCESS:
      return { loading: false, monthlyOverall: action.payload };
    case metryvScoreConstants.GET_MONTHLY_OVERALL_FAILURE:
      return { loading: false, error: action.payload };
    case metryvScoreConstants.GET_MONTHLY_OVERALL_CLEAR:
      return {};
    default:
      return state;
  }
};

export const ranking = (state = {}, action) => {
  switch (action.type) {
    case metryvScoreConstants.RANKING_REQUEST:
      return { loading: true };
    case metryvScoreConstants.RANKING_SUCCESS:
      return { loading: false, ranking: action.payload };
    case metryvScoreConstants.RANKING_FAILURE:
      return { loading: false, error: action.payload };
    case metryvScoreConstants.RANKING_CLEAR:
      return {};
    default:
      return state;
  }
};

export const monthlyLabData = (state = {}, action) => {
  switch (action.type) {
    case metryvScoreConstants.GET_MONTHLY_LAB_DATA_REQUEST:
      return { loading: true };
    case metryvScoreConstants.GET_MONTHLY_LAB_DATA_SUCCESS:
      return { loading: false, monthlyLabData: action.payload };
    case metryvScoreConstants.GET_MONTHLY_LAB_DATA_FAILURE:
      return { loading: false, error: action.payload };
    case metryvScoreConstants.GET_MONTHLY_LAB_DATA_CLEAR:
      return {};
    default:
      return state;
  }
};

export const upTimeData = (state = {}, action) => {
  switch (action.type) {
    case metryvScoreConstants.GET_UPTIME_DATA_REQUEST:
      return { loading: true };
    case metryvScoreConstants.GET_UPTIME_DATA_SUCCESS:
      return { loading: false, upTimeData: action.payload };
    case metryvScoreConstants.GET_UPTIME_DATA_FAILURE:
      return { loading: false, error: action.payload };
    case metryvScoreConstants.GET_UPTIME_DATA_CLEAR:
      return {};
    default:
      return state;
  }
};

export const w3Validator = (state = {}, action) => {
  switch (action.type) {
    case metryvScoreConstants.GET_W3_DATA_REQUEST:
      return { loading: true };
    case metryvScoreConstants.GET_W3_DATA_SUCCESS:
      return { loading: false, w3Validator: action.payload };
    case metryvScoreConstants.GET_W3_DATA_FAILURE:
      return { loading: false, error: action.payload };
    case metryvScoreConstants.GET_W3_DATA_CLEAR:
      return {};
    default:
      return state;
  }
};

export const sitesStatus = (state = {}, action) => {
  switch (action.type) {
    case metryvScoreConstants.GET_SITES_STATUS_REQUEST:
      return { loading: true };
    case metryvScoreConstants.GET_SITES_STATUS_SUCCESS:
      return { loading: false, sitesStatus: action.payload };
    case metryvScoreConstants.GET_SITES_STATUS_FAILURE:
      return { loading: false, error: action.payload };
    case metryvScoreConstants.REMOVE_SITE_FROM_STATE:
      return {
        ...state,
        sitesStatus: state.sitesStatus.filter(
          (item) => item.siteName !== action.payload
        ),
      };
    case metryvScoreConstants.GET_SITES_STATUS_CLEAR:
      return {};
    default:
      return state;
  }
};

export const competitorSitesStatus = (state = {}, action) => {
  switch (action.type) {
    case metryvScoreConstants.GET_COMPETITOR_SITES_STATUS_REQUEST:
      return { loading: true };
    case metryvScoreConstants.GET_COMPETITOR_SITES_STATUS_SUCCESS:
      return { loading: false, sitesStatus: action.payload };
    case metryvScoreConstants.GET_COMPETITOR_SITES_STATUS_FAILURE:
      return { loading: false, error: action.payload };
    case metryvScoreConstants.REMOVE_COMPETITOR_SITE_FROM_STATE:
      return {
        ...state,
        sitesStatus: state.sitesStatus.filter(
          (item) => item.siteName !== action.payload
        ),
      };
    case metryvScoreConstants.GET_COMPETITOR_SITES_STATUS_CLEAR:
      return {};
    default:
      return state;
  }
};
