import React from "react";

const SuggestionDetails = (props) => {
  const details = props.details;
  const type = props.type;
  const headings = details.headings;
  const items = details.items;

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function formatTime(timeInMilliseconds, decimals = 2) {
    if (timeInMilliseconds === 0) return "0 ms";

    return parseFloat(timeInMilliseconds.toFixed(decimals)) + " ms";
  }

  function msToTime(ms) {
    let seconds = (ms / 1000).toFixed(1);
    let minutes = (ms / (1000 * 60)).toFixed(1);
    let hours = (ms / (1000 * 60 * 60)).toFixed(1);
    let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
    if (seconds < 60) return seconds + " s";
    else if (minutes < 60) return minutes + " m";
    else if (hours < 24) return hours + " h";
    else return days + " Days";
  }

  function formatListItemUrl(text) {
    let link = text;
    text = text.length > 47 ? text.substring(0, 100) + "..." : text;

    text = React.createElement(
      "a",
      {
        className: "suggestions-link--sub",
        href: link,
        target: "_blank",
      },
      text
    );

    return text;
  }

  function createLinkFromUrlText(link) {
    link = React.createElement(
      "a",
      {
        className: "suggestions-link",
        href: link,
        target: "_blank",
      },
      link
    );

    return link;
  }

  function printImage(image) {
    var tmp = document.createElement("div");
    tmp.innerHTML = image;
    var src = tmp.querySelector("img")
      ? tmp.querySelector("img").getAttribute("src")
      : tmp.querySelector("div").getAttribute("src");

    if (src) {
      image = React.createElement("img", {
        className: "suggestions-image",
        src: src,
        width: 200,
        height: 100,
      });
    }

    return image;
  }

  function renderSwitch() {
    switch (type) {
      case "unused-css-rules":
        return (
          <table className="table mb-0 mt-4">
            <thead className="bg-light">
              <tr>
                {!headings || headings.length <= 0
                  ? ""
                  : headings.map((heading) => (
                      <th scope="col" className="border-0" key={heading.key}>
                        {heading.label}
                      </th>
                    ))}
              </tr>
            </thead>
            <tbody>
              {!items || items.length <= 0
                ? ""
                : items.map((item, index) => (
                    <tr key={index}>
                      {!headings || headings.length <= 0
                        ? ""
                        : headings.map((heading, index) => (
                            <td key={index}>
                              {heading.valueType == "bytes"
                                ? formatBytes(item[heading.key])
                                : createLinkFromUrlText(item[heading.key])}
                            </td>
                          ))}
                    </tr>
                  ))}
            </tbody>
          </table>
        );

      case "unused-javascript":
        return (
          <table className="table mb-0 mt-4">
            <thead className="bg-light">
              <tr>
                {!headings || headings.length <= 0
                  ? ""
                  : headings.map((heading) => (
                      <th scope="col" className="border-0" key={heading.key}>
                        {heading.label}
                      </th>
                    ))}
              </tr>
            </thead>
            <tbody>
              {!items || items.length <= 0
                ? ""
                : items.map((item, index) => (
                    <tr key={index}>
                      {!headings || headings.length <= 0
                        ? ""
                        : headings.map((heading, index) => (
                            <td key={index}>
                              {heading.valueType == "bytes"
                                ? formatBytes(item[heading.key])
                                : createLinkFromUrlText(item[heading.key])}
                            </td>
                          ))}
                    </tr>
                  ))}
            </tbody>
          </table>
        );

      case "mainthread-work-breakdown":
        return (
          <table className="table mb-0 mt-4">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="border-0">
                  Category
                </th>
                <th scope="col" className="border-0">
                  Time Spent
                </th>
              </tr>
            </thead>
            <tbody>
              {!items || items.length <= 0
                ? ""
                : items.map((item, index) => (
                    <tr key={index}>
                      <td>{item.groupLabel}</td>
                      <td>{formatTime(item.duration)}</td>
                    </tr>
                  ))}
            </tbody>
          </table>
        );

      case "bootup-time":
        return (
          <table className="table mb-0 mt-4">
            <thead className="bg-light">
              <tr>
                {!headings || headings.length <= 0
                  ? ""
                  : headings.map((heading) => (
                      <th scope="col" className="border-0" key={heading.key}>
                        {heading.text}
                      </th>
                    ))}
              </tr>
            </thead>
            <tbody>
              {!items || items.length <= 0
                ? ""
                : items.map((item, index) => (
                    <tr key={index}>
                      {!headings || headings.length <= 0
                        ? ""
                        : headings.map((heading, index) => (
                            <td key={index}>
                              {heading.key == "url"
                                ? formatListItemUrl(item[heading.key])
                                : formatTime(item[heading.key])}
                            </td>
                          ))}
                    </tr>
                  ))}
            </tbody>
          </table>
        );

      case "uses-long-cache-ttl":
        return (
          <table className="table mb-0 mt-4">
            <thead className="bg-light">
              <tr>
                <td>URL</td>
                <td>Cache TTL</td>
                <td>Transfer Size</td>
              </tr>
            </thead>
            <tbody>
              {!items || items.length <= 0
                ? ""
                : items.map((item, index) => (
                    <tr key={index}>
                      <td>{formatListItemUrl(item.url)}</td>
                      <td>{msToTime(item.cacheLifetimeMs)}</td>
                      <td>{formatBytes(item.totalBytes)}</td>
                    </tr>
                  ))}
            </tbody>
          </table>
        );

      case "largest-contentful-paint-element":
        return (
          <table className="table mb-0 mt-4">
            <thead className="bg-light">
              <tr>
                {!headings || headings.length <= 0
                  ? ""
                  : headings.map((heading) => (
                      <th scope="col" className="border-0" key={heading.key}>
                        {heading.text}
                      </th>
                    ))}
              </tr>
            </thead>
            <tbody>
              {!items || items.length <= 0 ? (
                ""
              ) : (
                <tr>
                  <td>
                    {items[0].node ? (
                      <>
                        <div className="mb-2">
                          {items[0].node.snippet &&
                          items[0].node.snippet.includes("img")
                            ? printImage(items[0].node.snippet)
                            : ""}
                        </div>
                        <h5>{items[0].node.nodeLabel}</h5>
                        <i>{items[0].node.snippet}</i>
                      </>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        );

      case "layout-shift-elements":
        return (
          <table className="table mb-0 mt-4">
            <thead className="bg-light">
              <tr>
                {!headings || headings.length <= 0
                  ? ""
                  : headings.map((heading) => (
                      <th scope="col" className="border-0" key={heading.key}>
                        {heading.text}
                      </th>
                    ))}
              </tr>
            </thead>
            <tbody>
              {!items || items.length <= 0
                ? ""
                : items.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <h5>{item.node.nodeLabel}</h5>
                        <i>{item.node.snippet}</i>
                      </td>
                      <td>{item.score.toFixed(3)}</td>
                    </tr>
                  ))}
            </tbody>
          </table>
        );

      case "render-blocking-resources":
        return (
          <table className="table mb-0 mt-4">
            <thead className="bg-light">
              <tr>
                {!headings || headings.length <= 0
                  ? ""
                  : headings.map((heading) => (
                      <th scope="col" className="border-0" key={heading.key}>
                        {heading.label}
                      </th>
                    ))}
              </tr>
            </thead>
            <tbody>
              {!items || items.length <= 0
                ? ""
                : items.map((item, index) => (
                    <tr key={index}>
                      <td>{createLinkFromUrlText(item.url)}</td>
                      <td>{formatBytes(item.totalBytes)}</td>
                      <td>{item.wastedMs} ms</td>
                    </tr>
                  ))}
            </tbody>
          </table>
        );

      case "total-byte-weight":
        return (
          <table className="table mb-0 mt-4">
            <thead className="bg-light">
              <tr>
                {!headings || headings.length <= 0
                  ? ""
                  : headings.map((heading) => (
                      <th scope="col" className="border-0" key={heading.key}>
                        {heading.text}
                      </th>
                    ))}
              </tr>
            </thead>
            <tbody>
              {!items || items.length <= 0
                ? ""
                : items.map((item, index) => (
                    <tr key={index}>
                      <td>{createLinkFromUrlText(item.url)}</td>
                      <td>{formatBytes(item.totalBytes)}</td>
                    </tr>
                  ))}
            </tbody>
          </table>
        );

      case "legacy-javascript":
        return (
          <table className="table mb-0 mt-4">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="border-0">
                  URL
                </th>
                <th scope="col" className="border-0">
                  Potential Savings
                </th>
              </tr>
            </thead>
            <tbody>
              {!items || items.length <= 0
                ? ""
                : items.map((item, index) => (
                    <tr key={index}>
                      <td>
                        {createLinkFromUrlText(item.url)}
                        <ul className="mt-4">
                          {item.subItems.items.map((subitem, indexx) => (
                            <div
                              key={indexx}
                              style={{
                                display: "flex",
                              }}
                            >
                              <span
                                style={{
                                  width: "50%",
                                }}
                              >
                                {formatListItemUrl(subitem.location.url)}
                              </span>
                              <span
                                style={{
                                  margin: "0 0 0 4rem",
                                }}
                              >
                                {subitem.signal}
                              </span>
                            </div>
                          ))}
                        </ul>
                      </td>
                      <td>{formatBytes(item.wastedBytes)}</td>
                    </tr>
                  ))}
            </tbody>
          </table>
        );

      case "uses-responsive-images":
      case "offscreen-images":
      case "modern-image-formats":
        return (
          <table className="table mb-0 mt-4">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="border-0">
                  Image
                </th>
                <th scope="col" className="border-0">
                  URL
                </th>
                <th scope="col" className="border-0">
                  Resource Size
                </th>
                <th scope="col" className="border-0">
                  Potential Savings
                </th>
              </tr>
            </thead>
            <tbody>
              {!items || items.length <= 0
                ? ""
                : items.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <img src={item.url} width="48" height="48" />
                      </td>
                      <td>{createLinkFromUrlText(item.url)}</td>
                      <td>{formatBytes(item.totalBytes)}</td>
                      <td>{formatBytes(item.wastedBytes)}</td>
                    </tr>
                  ))}
            </tbody>
          </table>
        );

      case "dom-size":
        return (
          <table className="table mb-0 mt-4">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="border-0">
                  Statistic
                </th>
                <th scope="col" className="border-0">
                  Element
                </th>
                <th scope="col" className="border-0">
                  Value
                </th>
              </tr>
            </thead>
            <tbody>
              {!items || items.length <= 0
                ? ""
                : items.map((item, index) => (
                    <tr key={index}>
                      <td>{item.statistic}</td>
                      <td>
                        <h5>{item.node ? item.node.nodeLabel : ""}</h5>
                        <i>{item.node ? item.node.selector : ""}</i>
                      </td>
                      <td>{item.value}</td>
                    </tr>
                  ))}
            </tbody>
          </table>
        );

      default:
        return (
          <table className="table mb-0 mt-4">
            <thead className="bg-light">
              <tr>
                {!headings || headings.length <= 0
                  ? ""
                  : headings.map((heading) => (
                      <th scope="col" className="border-0" key={heading.key}>
                        {heading.label}
                      </th>
                    ))}
              </tr>
            </thead>
            <tbody>
              {!items || items.length <= 0
                ? ""
                : items.map((item, index) => (
                    <tr key={index}>
                      {!headings || headings.length <= 0
                        ? ""
                        : headings.map((heading, index) => (
                            <td key={index}>
                              {item && heading.key !== "node"
                                ? item[heading.key]
                                : ""}
                            </td>
                          ))}
                    </tr>
                  ))}
            </tbody>
          </table>
        );
    }
  }
  return <>{renderSwitch()}</>;
};

export default SuggestionDetails;
