import React from "react";
import { isMobile } from "react-device-detect";
import { Row, Col } from "shards-react";

const TechStack = ({ techStack }) => {
  return (
    <>
      <Row>
        <h3 className="text-sm-left ml-3 mt-5 heading-3">Tech Stack</h3>
      </Row>

      <Row>
        <Col className="col-lg-12 col-md-12 col-sm-12 mb-4">
          <table className="table mb-0 mt-4">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="border-0">
                  Icon
                </th>
                <th scope="col" className="border-0">
                  Name
                </th>
                <th
                  scope="col"
                  className={`border-0 ${isMobile ? "none" : ""}`}
                >
                  Website
                </th>
                <th scope="col" className="border-0">
                  Category
                </th>
              </tr>
            </thead>
            <tbody>
              {!techStack
                ? ""
                : techStack.map((item, index) => (
                    <tr style={{ position: "relative" }} key={index}>
                      <td>
                        <img
                          src={item.icon}
                          alt={item.name + " Icon"}
                          height="20"
                        />
                      </td>
                      <td>{item.name}</td>
                      <td className={isMobile ? "none" : ""}>
                        <a href={item.website} target="_blank" rel="noreferrer">
                          {item.website}
                        </a>
                      </td>
                      <td>
                        {item.categories.map((element, index) => {
                          return index > 0 || item.categories.length === 1
                            ? Object.values(element)[0]
                            : Object.values(element)[0] + ", ";
                        })}
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </>
  );
};

export default TechStack;
