import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  Button,
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { PieChart } from "react-minimal-pie-chart";
import InvocesList from "../components/billings/InvoicesList";
import BillingDataTable from "../components/billings/BillingDataTable";
import { useSelector, useDispatch } from "react-redux";
import { getData, getCompetitorSites } from "../state/actions/sites.actions";
import axios from "axios";
import { API_URL } from "../constants";
import { getUserPlanData } from "../state/actions/user.actions";

const Billing = () => {
  const [subUsersLength, setSubUsersLength] = useState(0);
  const [sitesLength, setSitesLength] = useState(0);
  const [pagesLength, setPagesLength] = useState(0);
  const [competitorSitesLength, setCompetitorSitesLength] = useState(0);
  const [invoices, setInvoices] = useState([]);
  const [totalInvoicesPrice, setTotalInvoicesPrice] = useState(0);
  const [downloading, setDownloading] = useState(false);

  const dispatch = useDispatch();

  const user = useSelector((state) =>
    state.authentication.user
      ? state.authentication.user
      : state.registration.user
  );
  const sitesStatus = useSelector((state) => state.sitesStatus.sitesStatus);
  const sites = useSelector((state) => state.sites.sites);
  const loading = useSelector((state) => state.sitesStatus.loading);
  const competitorSites = useSelector((state) => state.competitorSites.sites);
  const currentPlan = useSelector((state) => state.plan.plan);

  const downloadAllInvoices = async () => {
    setDownloading(true);
    await fetch(`${API_URL}/invoices/download-all/${user.userId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/zip, application/zip, */*",
        "Content-Type": "application/zip",
      },
    })
      .then((res) => {
        console.log(res);
        return res.blob();
      })
      .then(async (data) => {
        setDownloading(false);
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = `invoices-user_id-${user.userId}.zip`;
        a.click();
        await axios
          .delete(`${API_URL}/invoices/delete-zip/${user.userId}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .catch((error) => {
            console.log(error);
            setDownloading(false);
          });
      });
  };

  const getInvoices = async () => {
    try {
      const { data } = await axios.get(`${API_URL}/invoices/${user.userId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setInvoices(data);
      if (data.length) {
        const sum = data?.reduce((accumulator, curValue) => {
          return accumulator + curValue.amount;
        }, 0);
        setTotalInvoicesPrice(sum);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubUsers = async () => {
    await axios
      .get(`${API_URL}/sub-users/${localStorage.getItem("user")}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        const data = response.data.response.map((item) => {
          return { ...item };
        });
        setSubUsersLength(data.length);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    if (!sitesStatus && !loading) {
      dispatch(getData(user.rootUser ? user.rootUser : user.email))
        .then((response) => {
          setSitesLength(response.length);
          setPagesLength(response.map((site) => site.pages));
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setSitesLength(sitesStatus.length);
      setPagesLength(sites.map((site) => site.pages));
    }
    if (!competitorSites && !loading) {
      dispatch(getCompetitorSites(user.rootUser ? user.rootUser : user.email))
        .then((response) => {
          setCompetitorSitesLength(response.length);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setCompetitorSitesLength(competitorSites.length);
    }
    dispatch(getUserPlanData(user.userId));
    getSubUsers();
    getInvoices();
  }, []);

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Billing"
          subtitle="billing"
          className="text-sm-left mb-3"
        />
      </Row>
      <Row>
        <Col className="col-lg-8 col-md-12 col-sm-12 my-2">
          <Card small className="mb-4">
            <CardHeader>
              <h4>Total Invoices</h4>
              <DropdownItem divider />
              <h6>
                Welcome to the MetryV billing console. Your total price of
                invoices apears below
              </h6>
            </CardHeader>
            <CardBody>
              <h1 className="pb-4">&euro;{totalInvoicesPrice.toFixed(2)}</h1>
              <h5>Alerts & Notifications</h5>
              <DropdownItem divider />
              <p className="my-1">
                The payment method associated with your account lets you receive
                notifications on {currentPlan?.notification}
              </p>
              <p className="my-1">
                Scheduled {currentPlan?.psiscan} on your sites
              </p>
              <p className="my-1">
                {currentPlan?.uptime?.charAt(0).toUpperCase() +
                  currentPlan?.uptime?.slice(1)}{" "}
                scans on uptime
              </p>
              <p className="my-1">
                Screenshot analysis {currentPlan?.screenshot} scans on server
                uptime
              </p>
            </CardBody>
          </Card>
          <Card small className="my-4">
            <CardHeader>
              <span
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h4 className="m-0">Invoices</h4>
                {invoices.length ? (
                  <Button
                    onClick={() => downloadAllInvoices()}
                    outline
                    theme={downloading ? "secondary" : "info"}
                    disabled={downloading}
                  >
                    Download all as .zip
                  </Button>
                ) : null}
              </span>
              <DropdownItem divider />
            </CardHeader>
            <CardBody className="p-0">
              <InvocesList
                invoices={invoices}
                user={user}
                downloading={downloading}
                setDownloading={setDownloading}
              />
            </CardBody>
          </Card>
        </Col>
        <Col className="col-lg-4 col-md-12 col-sm-12 my-2">
          <Card small>
            {/* <CardHeader></CardHeader> */}
            <CardBody>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <PieChart
                  data-tip=""
                  animate={true}
                  animationDuration={500}
                  animationEasing="ease-out"
                  viewBoxSize={[120, 120]}
                  center={[60, 60]}
                  data={[
                    {
                      title: "Sites",
                      value: sitesLength ? sitesLength : 0,
                      color: "#007bff",
                    },
                    {
                      title: "Pages",
                      value: pagesLength ? pagesLength.flat().length : 0,
                      color: "#17c671",
                    },
                    {
                      title: "Sub Users",
                      value: subUsersLength ? subUsersLength : 0,
                      color: "#ffb400",
                    },
                    {
                      title: "Competitor Sites",
                      value: competitorSitesLength ? competitorSitesLength : 0,
                      color: "#c4183c",
                    },
                  ]}
                  lineWidth={30}
                  paddingAngle={3}
                  style={{ width: "35%" }}
                />
                <ul className="usersByBillings">
                  <li>Sites: {sitesLength ? sitesLength : 0}</li>
                  <li>Pages: {pagesLength ? pagesLength.flat().length : 0}</li>
                  <li>Sub Users: {subUsersLength ? subUsersLength : 0}</li>
                  <li>
                    Competitor Sites:{" "}
                    {competitorSitesLength ? competitorSitesLength : 0}
                  </li>
                </ul>
              </div>
              <DropdownItem divider />
              <BillingDataTable
                pagesLength={pagesLength ? pagesLength.flat().length : 0}
                sitesLength={sitesLength ? sitesLength : 0}
                subUsersLength={subUsersLength ? subUsersLength : 0}
                competitorSitesLength={
                  competitorSitesLength ? competitorSitesLength : 0
                }
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Billing;
