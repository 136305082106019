import React, { useEffect, useState } from "react";
import { Button } from "shards-react";
import { Link } from "react-router-dom";
import DesktopMacIcon from "@mui/icons-material/DesktopMac";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import axios from "axios";
import { API_URL, API_URL_PSI } from "../../constants";
import { getStatus, getOverallScoreColor } from "../../helpers/scores";
import CircularProgress from "@mui/material/CircularProgress";
import { Modal } from "react-bootstrap";
import { getData } from "../../state/actions/sites.actions";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { faEllipsisH, faCircle } from "@fortawesome/free-solid-svg-icons";

const TablePages = ({ pages, site }) => {
  const [pagesStatus, setPagesStatus] = useState([]);

  const user = useSelector((state) =>
    state.authentication.user
      ? state.authentication.user
      : state.registration.user
  );
  const dispatch = useDispatch();

  function editPageName(name) {
    name = name.split("/");
    name = name[name.length - 2];
    name = name.replace(/-/g, " ");
    name = capitalizeFirstLetter(name);
    return name;
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  async function getPsiData(siteList) {
    if (siteList.length) {
      await axios
        .post(
          `${API_URL_PSI}/status/all/`,
          { sites: siteList },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          let sitesArr = response.data;
          setPagesStatus(sitesArr);
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  }

  const [show, setShow] = useState(false);
  const [pageToBeDeleted, setPageToBeDeleted] = useState(false);
  const handleShow = (siteUrl) => {
    setShow(true);
    setPageToBeDeleted(siteUrl);
  };
  const handleClose = () => setShow(false);

  const removeSite = () => {
    const request_body = {
      email: user.rootUser ? user.rootUser : user.email,
      site: site,
      page: pageToBeDeleted,
    };

    if (pageToBeDeleted) {
      axios
        .post(`${API_URL}/sites/deletePage`, request_body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then(() => {
          handleClose();
          dispatch(getData(user.rootUser ? user.rootUser : user.email));
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  };

  const deleteSiteModal = (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Page</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete this page from your list?
      </Modal.Body>
      <Modal.Footer>
        <Button
          theme="danger"
          onClick={() => {
            removeSite();
          }}
        >
          Yes
        </Button>
        <Button onClick={handleClose}>No</Button>
      </Modal.Footer>
    </Modal>
  );

  useEffect(() => {
    getPsiData(pages);
    // return () => {
    //   setPagesStatus([]);
    // };
  }, []);

  function formatListItemUrl(text) {
    let link = text;
    text = text.split("/");
    text = `.../${text[text.length - 2]}/${text[text.length - 1]}`;

    text = React.createElement(
      "a",
      {
        href: link,
        target: "_blank",
      },
      text
    );

    return text;
  }

  function toggleDropdown() {
    const dropdownMenu = document.getElementById("dropdownRemove");
    if (dropdownMenu) {
      dropdownMenu.classList.toggle("show");
    }
  }

  return (
    <>
      {!pages || pages.length <= 0
        ? null
        : pages.map((page, index) => (
            <tr
              className={`child-page child-page${
                index == pages.length - 1 ? "--last-item" : ""
              }`}
              key={index}
              style={{ background: "#f8f9fa" }}
            >
              <td>
                <div id="halfcross"></div>
              </td>
              <td>{editPageName(page)}</td>
              <td>{formatListItemUrl(page)}</td>
              <td>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "40%", marginRight: "1rem" }}>
                    <PhoneIphoneIcon fontSize="small" />
                    <span
                      className={
                        pagesStatus[index]
                          ? getStatus(pagesStatus[index].mobileStatus)[1]
                          : ""
                      }
                    >
                      {!pagesStatus.length ? (
                        <></>
                      ) : pagesStatus[index] ? (
                        getStatus(pagesStatus[index].mobileStatus)[0]
                      ) : (
                        <CircularProgress
                          style={{
                            width: "20px",
                            height: "20px",
                            position: "relative",
                            top: "6px",
                          }}
                        />
                      )}
                    </span>
                  </div>
                  <div style={{ width: "40%" }}>
                    <DesktopMacIcon fontSize="small" />{" "}
                    <span
                      className={
                        pagesStatus[index]
                          ? getStatus(pagesStatus[index].desktopStatus)[1]
                          : ""
                      }
                    >
                      {!pagesStatus.length ? (
                        <></>
                      ) : pagesStatus[index] ? (
                        getStatus(pagesStatus[index].desktopStatus)[0]
                      ) : (
                        <CircularProgress
                          style={{
                            width: "20px",
                            height: "20px",
                            position: "relative",
                            top: "6px",
                          }}
                        />
                      )}
                    </span>
                  </div>
                </div>
              </td>
              <td>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%" }}>
                    <PhoneIphoneIcon fontSize="small" />
                    <span
                      className={
                        pagesStatus[index]
                          ? getOverallScoreColor(pagesStatus[index].mobileScore)
                          : ""
                      }
                    >
                      {!pagesStatus.length ? (
                        <></>
                      ) : pagesStatus[index] ? (
                        parseInt(pagesStatus[index].mobileScore)
                      ) : (
                        <CircularProgress
                          style={{
                            width: "20px",
                            height: "20px",
                            position: "relative",
                            top: "6px",
                          }}
                        />
                      )}
                    </span>
                  </div>
                  <div style={{ width: "50%" }}>
                    <DesktopMacIcon fontSize="small" />{" "}
                    <span
                      className={
                        pagesStatus[index]
                          ? getOverallScoreColor(
                              pagesStatus[index].desktopScore
                            )
                          : ""
                      }
                    >
                      {!pagesStatus.length ? (
                        <></>
                      ) : pagesStatus[index] ? (
                        parseInt(pagesStatus[index].desktopScore)
                      ) : (
                        <CircularProgress
                          style={{
                            width: "20px",
                            height: "20px",
                            position: "relative",
                            top: "6px",
                          }}
                        />
                      )}
                    </span>
                  </div>
                </div>
              </td>
              <td>
                <Link
                  to={{
                    pathname: "/siteDetails",
                    state: {
                      siteName: editPageName(page),
                      siteUrl: page,
                    },
                  }}
                >
                  <Button theme="info">View report</Button>
                </Link>
                <div
                  className="dropdown"
                  style={{ paddingLeft: "6px", width: "10px" }}
                >
                  <div
                    onClick={toggleDropdown}
                    style={{
                      position: "relative",
                      display: "inline-block",
                      width: "24px",
                      height: "24px",
                      borderRadius: "50%",
                      backgroundColor: "#f2f2f2",
                    }}
                  >
                    <FontAwesomeIcon icon={faCircle} color="#f2f2f2" />
                    <FontAwesomeIcon
                      icon={faEllipsisH}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "black",
                      }}
                    />
                  </div>
                  <div className="dropdown-menu" id="dropdownRemove">
                    <div
                      className="dropdown-item"
                      onClick={() => handleShow(page)}
                    >
                      Remove page
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          ))}
      {deleteSiteModal}
    </>
  );
};

export default TablePages;
