import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "shards-react";

const ChooseTipsOrTicTacToe = ({
  open,
  setOpen,
  setStatsOpen,
  setTicTacToeOpen,
}) => {
  return (
    <Modal show={open}>
      <Modal.Footer style={{ justifyContent: "space-evenly" }}>
        <Button
          outline
          theme="info"
          className="mx-2"
          onClick={() => setStatsOpen(true)}
        >
          See Some Stats
        </Button>
        <Button
          outline
          theme="info"
          className="mx-2"
          onClick={() => setTicTacToeOpen(true)}
        >
          Play Tic Tac Toe
        </Button>
        <Button theme="danger" outline onClick={() => setOpen(false)}>
          X
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChooseTipsOrTicTacToe;
