import React from "react";
import { useLocation } from "react-router-dom";
import { Container, Row } from "shards-react";
import PageTitle from "../components/common/PageTitle";

const W3ValidationDetails = () => {
  const location = useLocation();
  const siteName = location.state.siteName;
  const details = location.state.data;

  const getStatus = (type) => {
    let status = "";
    if (type === "warning") {
      status = "average";
    } else if (type === "fatal") {
      status = "fatal";
    } else {
      status = "bad";
    }
    return status;
  };

  return (
    <Container fluid className="main-content-container px-4 mb-5">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title={siteName + " W3 Validation Errors"}
          subtitle="Ranking Details"
          className="text-sm-left mb-3"
          styles={{ width: "100% !important" }}
        />
      </Row>

      <Row className="ml-1 scrollable-table">
        <table className="table mb-0">
          <thead className="bg-light">
            <tr>
              <td>#</td>
              <td>Type</td>
              <td>Details</td>
            </tr>
          </thead>
          <tbody>
            {!details
              ? ""
              : details.map((item, index) => (
                  <tr key={index} className="w3errors">
                    <td>{index + 1}</td>
                    <td>
                      <span
                        className={
                          `w3errors__type w3errors__type--` +
                          getStatus(item.subType)
                        }
                      >
                        {item.subType === "fatal"
                          ? "Fatal Error"
                          : item.subType === "warning"
                          ? "Warning"
                          : "Error"}
                      </span>
                    </td>
                    <td className="w3errors__details">
                      <div className="w3errors__details__message">
                        {item.message}
                      </div>
                      <div className="w3errors__details__position">
                        From line {item.lastLine}, column {item.firstColumn}; to
                        line {item.lastLine}, column {item.lastColumn}
                      </div>
                      <div className="w3errors__details__extract">
                        {item.extract}
                      </div>
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </Row>
    </Container>
  );
};

export default W3ValidationDetails;
