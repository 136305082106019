import { sitesConstants } from "../../constants";

export const sites = (state = {}, action) => {
  switch (action.type) {
    case sitesConstants.GETALL_REQUEST:
      return { loading: true };
    case sitesConstants.GETALL_SUCCESS:
      return { loading: false, sites: action.payload };
    case sitesConstants.GETALL_FAILURE:
      return { loading: false, error: action.payload };
    case sitesConstants.GETALL_CLEAR:
      return {};
    default:
      return state;
  }
};

export const competitorSites = (state = {}, action) => {
  switch (action.type) {
    case sitesConstants.COMPETITOR_SITES_GETALL_REQUEST:
      return { loading: true };
    case sitesConstants.COMPETITOR_SITES_GETALL_SUCCESS:
      return { loading: false, sites: action.payload };
    case sitesConstants.COMPETITOR_SITES_GETALL_FAILURE:
      return { loading: false, error: action.payload };
    case sitesConstants.REMOVE_COMPETITOR_SITE_NAME_FROM_STATE:
      return {
        ...state,
        sites: state.sites.filter((item) => item.url !== action.payload),
      };
    case sitesConstants.COMPETITOR_SITES_GETALL_CLEAR:
      return {};
    default:
      return state;
  }
};

export const newSite = (
  state = {
    loading: false,
    name: "",
    link: "",
  },
  action
) => {
  switch (action.type) {
    case sitesConstants.ADDING_NEW_SITE:
      return {
        loading: true,
        name: action.payload.name ? action.payload.name : action.payload.link,
        link: action.payload.link,
      };
    case sitesConstants.NEW_SITE_CLEAR:
      return { loading: false, name: "", link: "" };
    default:
      return state;
  }
};

export const newCompetitorSite = (
  state = {
    loading: false,
    name: "",
    link: "",
  },
  action
) => {
  switch (action.type) {
    case sitesConstants.ADDING_NEW_COMPETITOR_SITE:
      return {
        loading: true,
        name: action.payload.siteName
          ? action.payload.siteName
          : action.payload.siteLink,
        link: action.payload.siteLink,
      };
    case sitesConstants.NEW_COMPETITOR_SITE_CLEAR:
      return { loading: false, name: "", link: "" };
    default:
      return state;
  }
};

export const newPingData = (
  state = {
    loading: false,
    date: "",
  },
  action
) => {
  switch (action.type) {
    case sitesConstants.ADDING_NEW_PING_DATA:
      return {
        loading: true,
        date: new Date(),
      };
    case sitesConstants.NEW_PING_DATA_CLEAR:
      return { loading: false, date: "" };
    default:
      return state;
  }
};
