import React from "react";

export function checkRanking(ranking) {
  let editedRanking = ranking.split(" ");
  if (editedRanking.length > 1) {
    let upRanking = React.createElement(
      "span",
      {
        className:
          editedRanking[0] > editedRanking[2]
            ? "icon-circle-arrow-up"
            : "icon-circle-arrow-down",
        key: { ranking },
      },
      editedRanking[2]
    );
    editedRanking = editedRanking[0] + upRanking;
    return [editedRanking[0], upRanking];
  }
  return ranking;
}
