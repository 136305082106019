import { EventEmitter } from "events";

import Dispatcher from "./dispatcher";
import Constants from "./constants";
import getSidebarNavItems from "../../constants/sidebar-nav-items";
import getSidebarSuspendedNavItems from "../../constants/sidebar-nav-item-suspended";
import getSidebarSubUserItems from "../../constants/sidebar-subuser-nav-items";

let _store = {
  menuVisible: false,
  navItems: getSidebarNavItems(),
  suspendedNavItems: getSidebarSuspendedNavItems(),
  subUserNavItems: getSidebarSubUserItems(),
};

class Store extends EventEmitter {
  constructor() {
    super();

    this.registerToActions = this.registerToActions.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);

    Dispatcher.register(this.registerToActions.bind(this));
  }

  registerToActions({ actionType }) {
    switch (actionType) {
      case Constants.TOGGLE_SIDEBAR:
        this.toggleSidebar();
        break;
      default:
    }
  }

  toggleSidebar() {
    _store.menuVisible = !_store.menuVisible;
    this.emit(Constants.CHANGE);
  }

  getMenuState() {
    return _store.menuVisible;
  }

  getSidebarItems() {
    return _store.navItems;
  }

  getSidebarSuspendedItems() {
    return _store.suspendedNavItems;
  }

  getSidebarSubUserItems() {
    return _store.subUserNavItems;
  }
  addChangeListener(callback) {
    this.on(Constants.CHANGE, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(Constants.CHANGE, callback);
  }
}

export default new Store();
