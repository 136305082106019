import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import logo from "../assets/images/logos/newLogo.svg";
import { API_URL } from "../constants";
import { useSnackbar } from "notistack";
import backIcon from "../assets/images/logos/backIcon.svg";

function ForgetPassword() {
  const [inputs, setInputs] = useState({
    email: "",
  });
  const [submitted] = useState(false);
  const { email } = inputs;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const user = {
      email: email,
    };
    await axios
      .post(`${API_URL}/users/forgot-password`, {
        email: user.email,
      })
      .then((response) => {
        enqueueSnackbar(
          response.data
            ? response.data
            : "Reset password link sent successfully",
          { variant: "success" }
        );
        setTimeout(() => {
          closeSnackbar();
        }, 5000);
      })
      .catch((error) => {
        enqueueSnackbar(
          error.response.data ? error.response.data : "Something went wrong",
          {
            variant: "error",
          }
        );
        setTimeout(() => {
          closeSnackbar();
        }, 5000);
      });
  }

  return (
    <div className="login-container">
      <div className="d-table m-auto">
        <img
          id="main-logo"
          className="d-inline-block align-top"
          src={logo}
          alt="metryV Logo"
        />
      </div>
      <hr />
      <h2
        style={{
          textAlign: "center",
          fontWeight: "600",
          fontSize: "40px",
          color: "#3D506E",
        }}
        className="mt-2"
      >
        Forgot Password
      </h2>
      <form name="form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label
            style={{ marginTop: "20px", color: "#3D506E", fontSize: "15px" }}
          >
            Enter email address
          </label>
          <input
            type="text"
            name="email"
            value={email}
            onChange={handleChange}
            className={
              "form-control" + (submitted && !email ? " is-invalid" : "")
            }
            style={{
              borderRadius: "10px",
              fontSize: "15px",
            }}
          />
          {submitted && !email && (
            <div className="invalid-feedback">Email is required</div>
          )}
        </div>
        <div className="form-group">
          <button
            className="form-control"
            style={{
              backgroundColor: "#0087F9",
              color: "white",
              borderRadius: "6px",
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            Submit
          </button>
        </div>
        <hr />
        <div
          className="form-group"
          style={{ textAlign: "center", color: "black" }}
        >
          <Link
            to="/"
            className="btn btn-link p-0"
            style={{ color: "#3D506E", fontWeight: "400", fontSize: "15px" }}
          >
            <img src={backIcon} alt="backIcon" style={{ margin: "0 7.5px" }} />
            Get back to log in
          </Link>
        </div>
      </form>
    </div>
  );
}

export { ForgetPassword };
