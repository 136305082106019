import React from "react";
import { Row } from "shards-react";
import { getSuggestionClass } from "../../helpers/scores";
import { filterDescription, getLink } from "../../helpers/psiData";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import SuggestionDetails from "./SuggestionDetails";
import { Link } from "react-router-dom";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  borderRadius: `10px`,
  marginTop: theme.spacing(1),
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  borderRadius: `10px`,
  backgroundColor: "#fff",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: "#0CCE6B",
    borderRadius: `10px`,
  },
  ".MuiSvgIcon-root": {
    fontSize: "1.1rem",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  ".MuiAccordionSummary-content p": {
    margin: "0",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const SuggestionsList = ({
  opportunities,
  diagnostics,
  lcpElement,
  clsElements,
  device,
  techStack,
}) => {
  function msToTime(ms) {
    let seconds = (ms / 1000).toFixed(1);
    let minutes = (ms / (1000 * 60)).toFixed(1);
    let hours = (ms / (1000 * 60 * 60)).toFixed(1);
    let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
    if (seconds < 60) return seconds + " s";
    else if (minutes < 60) return minutes + " m";
    else if (hours < 24) return hours + " h";
    else return days + " Days";
  }

  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Row>
        <h4 className="text-sm-left ml-3 heading-3">{device} Suggestions</h4>
      </Row>
      <Row>
        <p className="text-sm-left ml-3">
          These suggestions can help your page load faster and improve Web
          Vitals scores
        </p>
      </Row>
      <Row className="ml-1">
        {!opportunities
          ? `No ${device} Suggestions. Everything is good!`
          : opportunities.map((suggestion, idx) => (
              <Accordion
                key={idx}
                expanded={expanded === `panel${suggestion.id}`}
                onChange={handleChange(`panel${suggestion.id}`)}
                style={{ width: "100%" }}
              >
                <AccordionSummary
                  aria-controls={"panel" + suggestion.id + "d-content"}
                  id={"panel" + suggestion.id + "d-header"}
                >
                  <p>{suggestion.title}</p>
                  <div style={{ margin: "0 0 0 auto" }}>
                    <span
                      className={
                        "score score--" +
                        getSuggestionClass(
                          msToTime(suggestion.numericValue),
                          device
                        )
                      }
                    ></span>
                    <span
                      className={
                        "score-text--" +
                        getSuggestionClass(
                          msToTime(suggestion.numericValue),
                          device
                        )
                      }
                    >
                      {msToTime(suggestion.numericValue)}
                    </span>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <p style={{ margin: "0 0 1rem 0" }}>
                    {filterDescription(suggestion.description)}
                  </p>
                  <div className="mt-2">
                    {getLink(suggestion.description)}
                    <Link
                      to={{
                        pathname: "/suggestionDetails",
                        state: {
                          suggestion_type: suggestion.id,
                          suggestion_name: suggestion.title,
                          suggestion_description: suggestion.description,
                          suggestion_details: suggestion.details,
                          techStack: techStack,
                        },
                      }}
                      className="btn btn-danger"
                    >
                      How to fix
                    </Link>
                  </div>

                  {!suggestion.details ? (
                    ""
                  ) : (
                    <SuggestionDetails
                      details={suggestion.details}
                      type={suggestion.id}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
      </Row>

      <Row>
        <h4 className="text-sm-left ml-3 mt-4 heading-3">Diagnostics</h4>
      </Row>
      <Row>
        <p className="text-sm-left ml-3">
          More information about the performance of your application. These
          numbers don&apos;t directly affect the Performance score.
        </p>
      </Row>
      <Row className="ml-1">
        {!diagnostics
          ? `No ${device} Suggestions. Everything is good!`
          : diagnostics.map((suggestion, idx) => (
              <Accordion
                key={idx}
                expanded={expanded === `panel${suggestion.id}`}
                onChange={handleChange(`panel${suggestion.id}`)}
                style={{ width: "100%" }}
              >
                <AccordionSummary
                  aria-controls={"panel" + suggestion.id + "d-content"}
                  id={"panel" + suggestion.id + "d-header"}
                >
                  <p>{suggestion.title}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p style={{ margin: "0 0 1rem 0" }}>
                    {filterDescription(suggestion.description)}
                  </p>
                  <div className="mt-2">
                    {getLink(suggestion.description)}
                    <Link
                      to={{
                        pathname: "/suggestionDetails",
                        state: {
                          suggestion_type: suggestion.id,
                          suggestion_name: suggestion.title,
                          suggestion_description: suggestion.description,
                          suggestion_details: suggestion.details,
                          techStack: techStack,
                        },
                      }}
                      className="btn btn-danger"
                    >
                      How to fix
                    </Link>
                  </div>
                  {!suggestion.details ? (
                    ""
                  ) : (
                    <SuggestionDetails
                      details={suggestion.details}
                      type={suggestion.id}
                    />
                  )}
                  <hr />
                </AccordionDetails>
              </Accordion>
            ))}
      </Row>

      <Row>
        <h4 className="text-sm-left ml-3 mt-4 heading-3">LCP Element</h4>
      </Row>
      <Row className="ml-1">
        {!lcpElement
          ? `No ${device} Suggestions. Everything is good!`
          : lcpElement.map((suggestion, idx) => (
              <Accordion
                key={idx}
                expanded={expanded === `panel${suggestion.id}`}
                onChange={handleChange(`panel${suggestion.id}`)}
                style={{ width: "100%" }}
              >
                <AccordionSummary
                  aria-controls={"panel" + suggestion.id + "d-content"}
                  id={"panel" + suggestion.id + "d-header"}
                >
                  <p>{suggestion.title}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p style={{ margin: "0 0 1rem 0" }}>
                    {filterDescription(suggestion.description)}
                  </p>
                  {!suggestion.details ? (
                    ""
                  ) : (
                    <SuggestionDetails
                      details={suggestion.details}
                      type={suggestion.id}
                    />
                  )}
                  <div className="mt-2">
                    {getLink(suggestion.description)}
                    <Link
                      to={{
                        pathname: "/suggestionDetails",
                        state: {
                          suggestion_type: suggestion.id,
                          suggestion_name: suggestion.title,
                          suggestion_description: suggestion.description,
                          suggestion_details: suggestion.details,
                          techStack: techStack,
                        },
                      }}
                      className="btn btn-danger"
                    >
                      How to fix
                    </Link>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
      </Row>

      <Row>
        <h4 className="text-sm-left ml-3 mt-4 heading-3">CLS Elements</h4>
      </Row>
      <Row className="ml-1">
        {!clsElements
          ? `No ${device} Suggestions. Everything is good!`
          : clsElements.map((suggestion, idx) => (
              <Accordion
                key={idx}
                expanded={expanded === `panel${suggestion.id}`}
                onChange={handleChange(`panel${suggestion.id}`)}
                style={{ width: "100%" }}
              >
                <AccordionSummary
                  aria-controls={"panel" + suggestion.id + "d-content"}
                  id={"panel" + suggestion.id + "d-header"}
                >
                  <p>{suggestion.title}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p style={{ margin: "0 0 1rem 0" }}>
                    {filterDescription(suggestion.description)}
                  </p>
                  {!suggestion.details ? (
                    ""
                  ) : (
                    <SuggestionDetails
                      details={suggestion.details}
                      type={suggestion.id}
                    />
                  )}
                  <div className="mt-2">
                    {getLink(suggestion.description)}
                    <Link
                      to={{
                        pathname: "/suggestionDetails",
                        state: {
                          suggestion_type: suggestion.id,
                          suggestion_name: suggestion.title,
                          suggestion_description: suggestion.description,
                          suggestion_details: suggestion.details,
                          techStack: techStack,
                        },
                      }}
                      className="btn btn-danger"
                    >
                      How to fix
                    </Link>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
      </Row>
    </div>
  );
};

export default SuggestionsList;
