import { notificationsConstants } from "../../constants";
import axios from "axios";
import { API_URL } from "../../constants";

export const getNotifications = () => (dispatch) => {
  dispatch({ type: notificationsConstants.NOTIFICATIONS_GETALL_REQUEST });
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/notifications/dashboard`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        dispatch({
          type: notificationsConstants.NOTIFICATIONS_GETALL_SUCCESS,
          payload: response.data,
        });
        resolve(response.data);
      })
      .catch((error) => {
        dispatch({
          type: notificationsConstants.NOTIFICATIONS_GETALL_FAILURE,
          payload: error.response,
        });
        reject(error.response);
      });
  });
};

export const removeNotificationFromState = (id) => (dispatch) => {
  axios
    .delete(`${API_URL}/notifications/dashboard/delete/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then(() => {
      dispatch({
        type: notificationsConstants.NOTIFICATIONS_REMOVE_STATE,
        payload: id,
      });
    })
    .catch((error) => {
      console.log(error.response);
      dispatch(getNotifications());
    });
};

export const markAsReadFromState = (id, idx) => (dispatch) => {
  axios
    .post(
      `${API_URL}/notifications/dashboard/read/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then(() => {
      dispatch({
        type: notificationsConstants.NOTIFICATIONS_MARK_AS_READ_STATE,
        payload: { id, idx: idx.toString() },
      });
    })
    .catch((error) => {
      console.log(error.response);
      dispatch(getNotifications());
    });
};

export const markAllAsReadFromState = () => (dispatch) => {
  axios
    .post(
      `${API_URL}/notifications/dashboard/mark-all-as-read/`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then(() => {
      dispatch({
        type: notificationsConstants.NOTIFICATIONS_MARK_ALL_AS_READ_STATE,
      });
    })
    .catch((error) => {
      console.log(error.response);
      dispatch(getNotifications());
    });
};
