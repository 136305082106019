import React from "react";
import { Card, CardHeader, CardBody } from "shards-react";
import WarningIcon from "@mui/icons-material/Warning";
import GppBadIcon from "@mui/icons-material/GppBad";
import FmdBadIcon from "@mui/icons-material/FmdBad";

const W3ValidatorOverviewStatusComparison = ({ data }) => {
  const w3Status = data;

  return (
    <Card small className="h-100">
      <CardHeader
        className="border-bottom"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h6 className="d-inline-block m-0">W3 Validator Status</h6>
      </CardHeader>
      <CardBody className="pt-0">
        <div className="w3status">
          <div className="w3status__item">
            <GppBadIcon
              sx={{ color: "#EF4444", marginRight: "8px", fontSize: 30 }}
            />
            Fatal Errors: {w3Status.fatalErrors ? w3Status.fatalErrors : 0}
          </div>
          <div className="w3status__item">
            <FmdBadIcon
              sx={{ color: "#EF4444", marginRight: "8px", fontSize: 30 }}
            />
            Errors: {w3Status.errors ? w3Status.errors : 0}
          </div>
          <div className="w3status__item">
            <WarningIcon
              sx={{ color: "#ffc107", marginRight: "8px", fontSize: 30 }}
            />
            Warnings: {w3Status.warnings ? w3Status.warnings : 0}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default W3ValidatorOverviewStatusComparison;
