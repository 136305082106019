import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Button } from "shards-react";
import axios from "axios";
import PageTitle from "../components/common/PageTitle";
import { API_URL_PSI } from "../constants";
import { getScoreColor } from "../helpers/scores";
import SuggestionsList from "../components/site-details/SuggestionsList";
import { useSnackbar } from "notistack";
import LabDataOverviewDetails from "../components/site-details/LabDataOverviewDetails";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ScreenshotThumbnails from "../components/site-details/ScreenshotThumbnails";
import OverallScore from "../components/site-details/OverallScore";
import LabData from "../components/site-details/LabData";
import FieldData from "../components/site-details/FieldData";
import TechStack from "../components/site-details/TechStack";
import OverallScoreOverviewDetails from "../components/site-details/OverallScoreOverviewDetails";
import { FormControlLabel, Switch } from "@mui/material";
import { API_URL } from "../constants";
import download from "downloadjs";
import { useDispatch } from "react-redux";
import {
  getMetryVScoreData,
  getOriginSummaryData,
  getMonthlyOverallScoreData,
  getRankingData,
  getMonthlyLabData,
  getUptimeStatusData,
  getW3ValidatorData,
} from "../state/actions/psi.actions";
import { getPingData } from "../state/actions/ping.actions";
import { withStyles } from "@mui/styles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  checkedTrack: {
    backgroundColor: "#1976d6",
  },
}));

const SiteDetails = () => {
  const location = useLocation();
  const siteName = location?.state?.siteName;
  const siteUrl = location?.state?.siteUrl;

  const classes = useStyles();

  const [overallScore, setOverallScore] = useState({});
  const [opportunitiesMobile, setOpportunitiesMobile] = useState({});
  const [diagnosticsMobile, setDiagnosticsMobile] = useState({});
  const [lcpElementMobile, setLcpElementMobile] = useState({});
  const [clsElementsMobile, setClsElementsMobile] = useState({});
  const [opportunitiesDesktop, setOpportunitiesDesktop] = useState({});
  const [diagnosticsDesktop, setDiagnosticsDesktop] = useState({});
  const [lcpElementDesktop, setLcpElementDesktop] = useState({});
  const [clsElementsDesktop, setClsElementsDesktop] = useState({});
  const [techStack, setTechStack] = useState({});
  const [fieldDataCategory, setFieldDataCategory] = useState({});
  const [, setFieldDataCategoryDesktop] = useState({});
  const [hasFID, setHasFID] = useState(false);
  const [hasFieldData, setHasFieldData] = useState(false);
  const [labDataMobile, setLabDataMobile] = useState([]);
  const [labDataDesktop, setLabDataDesktop] = useState([]);
  const [smallStats, setSmallStats] = useState(false);
  const [smallStatsDesktop, setSmallStatsDesktop] = useState(false);
  const [finalScreenshot, setFinalScreenshot] = useState("");
  const [finalScreenshotDesktop, setFinalScreenshotDesktop] = useState("");
  const [screenshotThumbnails, setScreenshotThumbnails] = useState("");
  const [exporting, setExporting] = useState(false);
  const [
    screenshotThumbnailsDesktop,
    setScreenshotThumbnailsDesktop,
  ] = useState("");

  const [manualScanCounter, setManualScanCounter] = useState(0);
  const [device, setDevice] = useState(true); // True for mobile
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [loadingOverallScore, setLoadingOverallScore] = useState(true);
  const [loadingFieldData, setLoadingFieldData] = useState(true);
  const [loadingFieldDataGraph, setLoadingFieldDataGraph] = useState(true);
  const [loadingLabData, setLoadingLabData] = useState(true);
  const [loadingSuggestions, setLoadingSuggestions] = useState(true);
  const [loadingTechStack, setLoadingTechStack] = useState(true);

  const dispatch = useDispatch();

  const backdrop = (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  const handleToggleChange = (e) => {
    setDevice(e.target.checked);
  };

  function relDiff(oldNumber, newNumber) {
    return Math.abs(((oldNumber - newNumber) / oldNumber) * 100);
  }

  const styles = {
    switchRoot: {
      "&$checked": {
        color: "#89CFF0",
      },
    },
    checked: {},
  };

  const StyledSwitch = withStyles(styles)(Switch);

  const setLatestData = (psiData) => {
    let lcpStats = [];
    let fidStats = [];
    let clsStats = [];
    let fcpStats = [];
    let inpStats = [];
    let ttfbStats = [];

    let lcpStatsDesktop = [];
    let fidStatsDesktop = [];
    let clsStatsDesktop = [];
    let fcpStatsDesktop = [];
    let inpStatsDesktop = [];
    let ttfbStatsDesktop = [];

    psiData.forEach((element) => {
      lcpStats.push(
        parseFloat(
          typeof element.mobile.fieldData !== "undefined"
            ? element.mobile.fieldData.lcp.score
            : null
        )
      );
      fidStats.push(
        parseFloat(
          typeof element.mobile.fieldData !== "undefined"
            ? element.mobile.fieldData.fid.score
            : null
        )
      );
      clsStats.push(
        parseFloat(
          typeof element.mobile.fieldData !== "undefined"
            ? element.mobile.fieldData.cls.score
            : null
        )
      );

      fcpStats.push(
        parseFloat(
          typeof element.mobile.fieldData !== "undefined"
            ? element.mobile.fieldData.fcp?.score
            : null
        )
      );
      inpStats.push(
        parseFloat(
          typeof element.mobile.fieldData !== "undefined"
            ? element.mobile.fieldData.inp?.score
            : null
        )
      );
      ttfbStats.push(
        parseFloat(
          typeof element.mobile.fieldData !== "undefined"
            ? element.mobile.fieldData.ttfb?.score
            : null
        )
      );

      lcpStatsDesktop.push(
        parseFloat(
          typeof element.desktop.fieldData !== "undefined"
            ? element.desktop.fieldData.lcp.score
            : null
        )
      );
      fidStatsDesktop.push(
        parseFloat(
          typeof element.desktop.fieldData !== "undefined"
            ? element.desktop.fieldData.fid.score
            : null
        )
      );
      clsStatsDesktop.push(
        parseFloat(
          typeof element.desktop.fieldData !== "undefined"
            ? element.desktop.fieldData.cls.score
            : null
        )
      );

      fcpStatsDesktop.push(
        parseFloat(
          typeof element.desktop.fieldData !== "undefined"
            ? element.desktop.fieldData.fcp?.score
            : null
        )
      );
      inpStatsDesktop.push(
        parseFloat(
          typeof element.desktop.fieldData !== "undefined" &&
            element.desktop.fieldData.inp
            ? element.desktop.fieldData.inp?.score
            : null
        )
      );
      ttfbStatsDesktop.push(
        parseFloat(
          typeof element.desktop.fieldData !== "undefined"
            ? element.desktop.fieldData.ttfb?.score
            : null
        )
      );
    });

    setSmallStats([
      {
        label: "LCP",
        labelSub: "loading performance",
        value:
          psiData[psiData.length - 1].mobile.fieldData.lcp.score != null
            ? psiData[psiData.length - 1].mobile.fieldData.lcp.score + " s"
            : "-",
        percentage: relDiff(
          lcpStats[lcpStats.length - 2],
          lcpStats[lcpStats.length - 1]
        )
          ? relDiff(
              lcpStats[lcpStats.length - 2],
              lcpStats[lcpStats.length - 1]
            ).toFixed(2) + "%"
          : "0%",
        increase:
          lcpStats[lcpStats.length - 1] > lcpStats[lcpStats.length - 2]
            ? true
            : false,
        decrease:
          lcpStats[lcpStats.length - 1] < lcpStats[lcpStats.length - 2]
            ? true
            : false,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor:
              psiData[psiData.length - 1].desktop.fieldData.lcp.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].mobile.fieldData.lcp.category
                  )["background"]
                : "",
            borderColor:
              psiData[psiData.length - 1].desktop.fieldData.lcp.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].mobile.fieldData.lcp.category
                  )["border"]
                : "",
            data: [...lcpStats],
          },
        ],
      },
      {
        label: "FID",
        labelSub: "interactivity",
        value:
          psiData[psiData.length - 1].mobile.fieldData.fid.score != null
            ? psiData[psiData.length - 1].mobile.fieldData.fid.score + " ms"
            : "-",
        percentage: relDiff(
          fidStats[fidStats.length - 2],
          fidStats[fidStats.length - 1]
        )
          ? relDiff(
              fidStats[fidStats.length - 2],
              fidStats[fidStats.length - 1]
            ).toFixed(2) + "%"
          : "0%",
        increase:
          fidStats[fidStats.length - 1] > fidStats[fidStats.length - 2]
            ? true
            : false,
        decrease:
          fidStats[fidStats.length - 1] < fidStats[fidStats.length - 2]
            ? true
            : false,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor:
              psiData[psiData.length - 1].desktop.fieldData.fid.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].mobile.fieldData.fid.category
                  )["background"]
                : "",
            borderColor:
              psiData[psiData.length - 1].desktop.fieldData.fid.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].mobile.fieldData.fid.category
                  )["border"]
                : "",
            data: [...fidStats],
          },
        ],
      },
      {
        label: "CLS",
        labelSub: "visual stability",
        value:
          psiData[psiData.length - 1].mobile.fieldData.cls.score != null
            ? psiData[psiData.length - 1].mobile.fieldData.cls.score
            : "-",
        percentage: relDiff(
          clsStats[clsStats.length - 2],
          clsStats[clsStats.length - 1]
        )
          ? relDiff(
              clsStats[clsStats.length - 2],
              clsStats[clsStats.length - 1]
            ).toFixed(2) + "%"
          : "0%",
        increase:
          clsStats[clsStats.length - 1] > clsStats[clsStats.length - 2]
            ? true
            : false,
        decrease:
          clsStats[fidStats.length - 1] < clsStats[clsStats.length - 2]
            ? true
            : false,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "4", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor:
              psiData[psiData.length - 1].desktop.fieldData.cls.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].mobile.fieldData.cls.category
                  )["background"]
                : "",
            borderColor:
              psiData[psiData.length - 1].desktop.fieldData.cls.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].mobile.fieldData.cls.category
                  )["border"]
                : "",
            data: [...clsStats],
          },
        ],
      },
      {
        label: "FCP",
        labelSub: "first contentful paint",
        value:
          psiData[psiData.length - 1].mobile.fieldData.fcp?.score != null
            ? psiData[psiData.length - 1].mobile.fieldData.fcp?.score + " s"
            : "-",
        percentage: relDiff(
          fcpStats[fcpStats.length - 2],
          fcpStats[fcpStats.length - 1]
        )
          ? relDiff(
              fcpStats[fcpStats.length - 2],
              fcpStats[fcpStats.length - 1]
            ).toFixed(2) + "%"
          : "0%",
        increase:
          fcpStats[fcpStats.length - 1] > fcpStats[fcpStats.length - 2]
            ? true
            : false,
        decrease:
          fcpStats[fcpStats.length - 1] < fcpStats[fcpStats.length - 2]
            ? true
            : false,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor:
              psiData[psiData.length - 1].mobile.fieldData.fcp?.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].mobile.fieldData.fcp?.category
                  )["background"]
                : "",
            borderColor:
              psiData[psiData.length - 1].mobile.fieldData.fcp?.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].mobile.fieldData.fcp?.category
                  )["border"]
                : "",
            data: [...fcpStats],
          },
        ],
      },
      {
        label: "inp",
        labelSub: "interaction to next paint",
        value:
          psiData[psiData.length - 1].mobile.fieldData.inp?.score != null
            ? psiData[psiData.length - 1].mobile.fieldData.inp?.score + " ms"
            : "-",
        percentage: relDiff(
          inpStats[inpStats.length - 2],
          inpStats[inpStats.length - 1]
        )
          ? relDiff(
              inpStats[inpStats.length - 2],
              inpStats[inpStats.length - 1]
            ).toFixed(2) + "%"
          : "0%",
        increase:
          inpStats[inpStats.length - 1] > inpStats[inpStats.length - 2]
            ? true
            : false,
        decrease:
          inpStats[inpStats.length - 1] < inpStats[inpStats.length - 2]
            ? true
            : false,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor:
              psiData[psiData.length - 1].mobile.fieldData.inp?.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].mobile.fieldData.inp?.category
                  )["background"]
                : "",
            borderColor:
              psiData[psiData.length - 1].mobile.fieldData.inp?.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].mobile.fieldData.inp?.category
                  )["border"]
                : "",
            data: [...inpStats],
          },
        ],
      },
      {
        label: "ttfb",
        labelSub: "time to first byte",
        value:
          psiData[psiData.length - 1].mobile.fieldData.ttfb?.score != null
            ? psiData[psiData.length - 1].mobile.fieldData.ttfb?.score + " s"
            : "-",
        percentage: relDiff(
          ttfbStats[ttfbStats.length - 2],
          ttfbStats[ttfbStats.length - 1]
        )
          ? relDiff(
              ttfbStats[ttfbStats.length - 2],
              ttfbStats[ttfbStats.length - 1]
            ).toFixed(2) + "%"
          : "0%",
        increase:
          ttfbStats[ttfbStats.length - 1] > ttfbStats[ttfbStats.length - 2]
            ? true
            : false,
        decrease:
          ttfbStats[ttfbStats.length - 1] < ttfbStats[ttfbStats.length - 2]
            ? true
            : false,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor:
              psiData[psiData.length - 1].mobile.fieldData.ttfb?.category !=
              null
                ? getScoreColor(
                    psiData[psiData.length - 1].mobile.fieldData.ttfb?.category
                  )["background"]
                : "",
            borderColor:
              psiData[psiData.length - 1].mobile.fieldData.ttfb?.category !=
              null
                ? getScoreColor(
                    psiData[psiData.length - 1].mobile.fieldData.ttfb?.category
                  )["border"]
                : "",
            data: [...ttfbStats],
          },
        ],
      },
    ]);

    setSmallStatsDesktop([
      {
        label: "LCP",
        labelSub: "loading performance",
        value:
          psiData[psiData.length - 1].desktop.fieldData.lcp.score != null
            ? psiData[psiData.length - 1].desktop.fieldData.lcp.score + " s"
            : "-",
        percentage: relDiff(
          lcpStats[lcpStats.length - 2],
          lcpStats[lcpStats.length - 1]
        )
          ? relDiff(
              lcpStats[lcpStats.length - 2],
              lcpStats[lcpStats.length - 1]
            ).toFixed(2) + "%"
          : "0%",
        increase:
          lcpStats[lcpStats.length - 1] > lcpStats[lcpStats.length - 2]
            ? true
            : false,
        decrease:
          lcpStats[lcpStats.length - 1] < lcpStats[lcpStats.length - 2]
            ? true
            : false,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor:
              psiData[psiData.length - 1].desktop.fieldData.lcp.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].desktop.fieldData.lcp.category
                  )["background"]
                : "",
            borderColor:
              psiData[psiData.length - 1].desktop.fieldData.lcp.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].desktop.fieldData.lcp.category
                  )["border"]
                : "",
            data: [...lcpStats],
          },
        ],
      },
      {
        label: "FID",
        labelSub: "interactivity",
        value:
          psiData[psiData.length - 1].desktop.fieldData.fid.score != null
            ? psiData[psiData.length - 1].desktop.fieldData.fid.score + " ms"
            : "-",
        percentage: relDiff(
          fidStats[fidStats.length - 2],
          fidStats[fidStats.length - 1]
        )
          ? relDiff(
              fidStats[fidStats.length - 2],
              fidStats[fidStats.length - 1]
            ).toFixed(2) + "%"
          : "0%",
        increase:
          fidStats[fidStats.length - 1] > fidStats[fidStats.length - 2]
            ? true
            : false,
        decrease:
          fidStats[fidStats.length - 1] < fidStats[fidStats.length - 2]
            ? true
            : false,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor:
              psiData[psiData.length - 1].desktop.fieldData.fid.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].desktop.fieldData.fid.category
                  )["background"]
                : "",
            borderColor:
              psiData[psiData.length - 1].desktop.fieldData.fid.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].desktop.fieldData.fid.category
                  )["border"]
                : "",
            data: [...fidStats],
          },
        ],
      },
      {
        label: "CLS",
        labelSub: "visual stability",
        value:
          psiData[psiData.length - 1].desktop.fieldData.cls.score != null
            ? psiData[psiData.length - 1].desktop.fieldData.cls.score
            : "-",
        percentage: relDiff(
          clsStats[clsStats.length - 2],
          clsStats[clsStats.length - 1]
        )
          ? relDiff(
              clsStats[clsStats.length - 2],
              clsStats[clsStats.length - 1]
            ).toFixed(2) + "%"
          : "0%",
        increase:
          clsStats[clsStats.length - 1] > clsStats[clsStats.length - 2]
            ? true
            : false,
        decrease:
          clsStats[clsStats.length - 1] < clsStats[clsStats.length - 2]
            ? true
            : false,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "4", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor:
              psiData[psiData.length - 1].desktop.fieldData.lcp.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].desktop.fieldData.lcp.category
                  )["background"]
                : "",
            borderColor:
              psiData[psiData.length - 1].desktop.fieldData.lcp.category != null
                ? getScoreColor(
                    psiData[psiData.length - 1].desktop.fieldData.lcp.category
                  )["border"]
                : "",
            data: [...clsStats],
          },
        ],
      },
      {
        label: "FCP",
        labelSub: "first contentful paint",
        value:
          psiData[psiData.length - 1].desktop.fieldData.fcp?.score != null
            ? psiData[psiData.length - 1].desktop.fieldData.fcp?.score + " s"
            : "-",
        percentage: relDiff(
          fcpStats[fcpStats.length - 2],
          fcpStats[fcpStats.length - 1]
        )
          ? relDiff(
              fcpStats[fcpStats.length - 2],
              fcpStats[fcpStats.length - 1]
            ).toFixed(2) + "%"
          : "0%",
        increase:
          fcpStats[fcpStats.length - 1] > fcpStats[fcpStats.length - 2]
            ? true
            : false,
        decrease:
          fcpStats[fcpStats.length - 1] < fcpStats[fcpStats.length - 2]
            ? true
            : false,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor:
              psiData[psiData.length - 1].desktop.fieldData.fcp?.category !=
              null
                ? getScoreColor(
                    psiData[psiData.length - 1].desktop.fieldData.fcp?.category
                  )["background"]
                : "",
            borderColor:
              psiData[psiData.length - 1].desktop.fieldData.fcp?.category !=
              null
                ? getScoreColor(
                    psiData[psiData.length - 1].desktop.fieldData.fcp?.category
                  )["border"]
                : "",
            data: [...fcpStats],
          },
        ],
      },
      {
        label: "inp",
        labelSub: "interaction to next paint",
        value:
          psiData[psiData.length - 1].desktop.fieldData.inp?.score != null
            ? psiData[psiData.length - 1].desktop.fieldData.inp?.score + " ms"
            : "-",
        percentage: relDiff(
          inpStats[inpStats.length - 2],
          inpStats[inpStats.length - 1]
        )
          ? relDiff(
              inpStats[inpStats.length - 2],
              inpStats[inpStats.length - 1]
            ).toFixed(2) + "%"
          : "0%",
        increase:
          inpStats[inpStats.length - 1] > inpStats[inpStats.length - 2]
            ? true
            : false,
        decrease:
          inpStats[inpStats.length - 1] < inpStats[inpStats.length - 2]
            ? true
            : false,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor:
              psiData[psiData.length - 1].desktop.fieldData.inp?.category !=
              null
                ? getScoreColor(
                    psiData[psiData.length - 1].desktop.fieldData.inp?.category
                  )["background"]
                : "",
            borderColor:
              psiData[psiData.length - 1].desktop.fieldData.inp?.category !=
              null
                ? getScoreColor(
                    psiData[psiData.length - 1].desktop.fieldData.inp?.category
                  )["border"]
                : "",
            data: [...inpStats],
          },
        ],
      },
      {
        label: "ttfb",
        labelSub: "time to first byte",
        value:
          psiData[psiData.length - 1].desktop.fieldData.ttfb?.score != null
            ? psiData[psiData.length - 1].desktop.fieldData.ttfb?.score + " s"
            : "-",
        percentage: relDiff(
          ttfbStats[ttfbStats.length - 2],
          ttfbStats[ttfbStats.length - 1]
        )
          ? relDiff(
              ttfbStats[ttfbStats.length - 2],
              ttfbStats[ttfbStats.length - 1]
            ).toFixed(2) + "%"
          : "0%",
        increase:
          ttfbStats[ttfbStats.length - 1] > ttfbStats[ttfbStats.length - 2]
            ? true
            : false,
        decrease:
          ttfbStats[ttfbStats.length - 1] < ttfbStats[ttfbStats.length - 2]
            ? true
            : false,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor:
              psiData[psiData.length - 1].desktop.fieldData.ttfb?.category !=
              null
                ? getScoreColor(
                    psiData[psiData.length - 1].desktop.fieldData.ttfb?.category
                  )["background"]
                : "",
            borderColor:
              psiData[psiData.length - 1].desktop.fieldData.ttfb?.category !=
              null
                ? getScoreColor(
                    psiData[psiData.length - 1].desktop.fieldData.ttfb?.category
                  )["border"]
                : "",
            data: [...ttfbStats],
          },
        ],
      },
    ]);
  };

  async function manualPsiScan() {
    const scanningSnackbar = enqueueSnackbar("Scanning ...", {
      variant: "info",
      persist: true,
    });
    const email = localStorage.getItem("user");
    const request_body = {
      email: email,
      url: siteUrl,
    };
    await axios
      .post(`${API_URL_PSI}/scan/`, request_body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(() => {
        closeSnackbar(scanningSnackbar);
        enqueueSnackbar("Scan is done!", { variant: "success" });
        setManualScanCounter((data) => data + 1);
        dispatch(getMetryVScoreData(siteUrl));
        dispatch(getOriginSummaryData(siteUrl));
        dispatch(getMonthlyOverallScoreData(siteUrl));
        dispatch(getRankingData(siteUrl));
        dispatch(getMonthlyLabData(siteUrl));
        dispatch(getUptimeStatusData(siteUrl));
        dispatch(getW3ValidatorData(siteUrl));
        dispatch(getPingData(siteUrl));
        getLatestData();
        getFieldData();
        getSuggestionsData();
        getTechStack();
      })
      .catch((error) => {
        console.log(error.response);
        closeSnackbar(scanningSnackbar);
        enqueueSnackbar("Scan failed, please try again later!", {
          variant: "error",
        });
      });
  }

  async function getLatestData() {
    await axios
      .get(
        `${API_URL_PSI}/scan/latest`,
        {
          params: {
            url: siteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        let data = response.data;
        setOverallScore({
          Mobile: data.mobile.overallScore,
          Desktop: data.desktop.overallScore,
        });
        setFieldDataCategory(data.mobile.fieldData.overall_category);
        setFieldDataCategoryDesktop(data.desktop.fieldData.overall_category);
        setHasFID(data.mobile.fieldData.fid.score ? true : false);
        setHasFieldData(data.mobile.fieldData.overall_category ? true : false);
        setLabDataMobile([
          {
            FCP: data.mobile.labData.fcp,
            LCP: data.mobile.labData.lcp,
            SI: data.mobile.labData.si,
            CLS: data.mobile.labData.cls,
            TTI: data.mobile.labData.tti,
            TBT: data.mobile.labData.tbt,
          },
        ]);
        setLabDataDesktop([
          {
            FCP: data.desktop.labData.fcp,
            LCP: data.desktop.labData.lcp,
            SI: data.desktop.labData.si,
            CLS: data.desktop.labData.cls,
            TTI: data.desktop.labData.tti,
            TBT: data.desktop.labData.tbt,
          },
        ]);
        setLoadingOverallScore(false);
        setLoadingFieldData(false);
        setLoadingLabData(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  async function getFieldData() {
    await axios
      .get(
        `${API_URL_PSI}/scan/fieldData`,
        {
          params: {
            url: siteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        let data = response.data;
        setLatestData(data);
        setLoadingFieldDataGraph(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getSuggestionsData() {
    await axios
      .post(
        `${API_URL_PSI}/scan/suggestions`,
        {
          data: {
            url: siteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        let data = response.data;
        setOpportunitiesMobile(data.mobile?.opportunities?.value);
        setDiagnosticsMobile(data.mobile?.diagnostics?.value);
        setLcpElementMobile(data.mobile?.lcpElement?.value);
        setClsElementsMobile(data.mobile?.clsElements?.value);
        setFinalScreenshot(data.mobile?.finalScreenshot?.value);
        setScreenshotThumbnails(data.mobile?.screenshotThumbnails?.value);

        setOpportunitiesDesktop(data.desktop?.opportunities?.value);
        setDiagnosticsDesktop(data.desktop?.diagnostics?.value);
        setLcpElementDesktop(data.desktop?.lcpElement?.value);
        setClsElementsDesktop(data.desktop?.clsElements?.value);
        setFinalScreenshotDesktop(data.desktop?.finalScreenshot?.value);
        setScreenshotThumbnailsDesktop(
          data.desktop?.screenshotThumbnails?.value
        );
        setLoadingSuggestions(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  async function getTechStack() {
    await axios
      .post(
        `${API_URL_PSI}/scan/techstack`,
        {
          data: {
            url: siteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        let data = response.data;
        setTechStack(data);
        setLoadingTechStack(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const editPageName = (name) => {
    name = name.split(".");
    name = name[name.length - 2];
    if (name.includes("-")) {
      name = name.replace(/-/g, " ");
    }
    if (name.includes("https://")) {
      name = name.replace("https://", "");
    }
    if (name.includes("www")) {
      name = name.replace("www.", "");
    }
    name = capitalizeFirstLetter(name);
    return name;
  };

  const handleDownload = async () => {
    try {
      setExporting(true);
      const convertToCsv = await axios.post(
        `${API_URL}/convert-to-csv/${localStorage.getItem("user")}`,
        { siteUrl: siteUrl.toString() },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const downloadCsvFile = await fetch(
        `${API_URL}/download/${localStorage.getItem("user")}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            date: convertToCsv.data.date,
            userId: `${localStorage.getItem("id")}`,
          }),
        }
      );
      setExporting(false);
      if (downloadCsvFile) {
        const blob = await downloadCsvFile.blob();
        download(
          blob,
          `metryv-assessment-${editPageName(siteUrl)}-${
            new Date().toISOString().split("T")[0]
          }.csv`
        );
        await axios.post(
          `${API_URL}/delete-csv/${localStorage.getItem("user")}`,
          { date: convertToCsv.data.date, userId: localStorage.getItem("id") },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      }
    } catch (error) {
      setExporting(false);
      console.log(error.response);
    }
  };

  useEffect(() => {
    getLatestData();
    getFieldData();
    getSuggestionsData();
    getTechStack();
    scrollElement();
  }, []);

  async function scrollElement() {
    const hash = window.location.hash;
    if (hash) {
      const observer = new MutationObserver((mutationsList, observer) => {
        for (const mutation of mutationsList) {
          if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
            const mobileSuggestionsSection = document.querySelector(
              "#mobile-suggestions"
            );
            if (mobileSuggestionsSection) {
              setTimeout(() => {
                mobileSuggestionsSection.scrollIntoView({ behavior: "smooth" });
              }, 400);
              observer.disconnect();
              break;
            }
          }
        }
      });
      observer.observe(document.body, { subtree: true, childList: true });
    }
  }
  document.addEventListener("DOMContentLoaded", scrollElement);

  return (
    <Container
      fluid
      className="main-content-container px-4 mb-5"
      style={{ overflowX: "hidden" }}
    >
      <Row
        noGutters
        className="page-header py-4"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <PageTitle
          title={siteName ? siteName : siteUrl}
          subtitle="SiteDetails"
          className="text-sm-left mb-3"
        />
        <div>
          <Button
            size="md"
            className="d-inline-block btn-info mx-2"
            disabled={exporting ? true : false}
            onClick={() => handleDownload()}
          >
            Export
          </Button>
          <Button size="md" theme="primary" onClick={manualPsiScan}>
            Run Scan Again
          </Button>
        </div>
      </Row>

      <hr />

      {loadingOverallScore ||
      loadingFieldData ||
      loadingFieldDataGraph ||
      loadingLabData ||
      loadingSuggestions ||
      loadingTechStack ? (
        backdrop
      ) : (
        <main>
          <OverallScore
            overallScore={overallScore}
            finalScreenshot={finalScreenshot}
            finalScreenshotDesktop={finalScreenshotDesktop}
          />

          <hr />

          <FieldData
            smallStats={smallStats}
            smallStatsDesktop={smallStatsDesktop}
            hasFID={hasFID}
            hasFieldData={hasFieldData}
            fieldDataCategory={fieldDataCategory}
          />

          <hr />

          <OverallScoreOverviewDetails
            siteName={siteName}
            siteUrl={siteUrl}
            manualScanCounter={manualScanCounter}
          />

          <hr />

          <LabData
            labDataMobile={labDataMobile}
            labDataDesktop={labDataDesktop}
          />

          <Row>
            <Col className="col-12 col-lg-6 mb-4">
              <LabDataOverviewDetails
                siteName={siteName}
                siteUrl={siteUrl}
                manualScanCounter={manualScanCounter}
                device={"Mobile"}
              />
            </Col>
            <Col className="col-12 col-lg-6 mb-4">
              <LabDataOverviewDetails
                siteName={siteName}
                siteUrl={siteUrl}
                manualScanCounter={manualScanCounter}
                device={"Desktop"}
              />
            </Col>
          </Row>
          <div id="mobile-suggestions">
            <hr />
            <Row>
              <h2 className="heading-dashboard ml-3 mt-2">Loading process</h2>
              <Col className="flex-right">
                <span
                  style={{
                    fontFamily: "Arial",
                    fontWeight: "400",
                    fontSize: "1rem",
                    lineHeight: "1.5",
                  }}
                >
                  Mobile
                </span>
                <FormControlLabel
                  style={{ margin: "0" }}
                  control={
                    <StyledSwitch
                      checked={device}
                      onChange={handleToggleChange}
                      style={{
                        color: "#fff",
                      }}
                      classes={{
                        track: classes.checkedTrack,
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={device ? "Desktop " : "Desktop"}
                />
              </Col>
            </Row>
          </div>
          {!screenshotThumbnails ? (
            ""
          ) : (
            <ScreenshotThumbnails
              screenshotThumbnails={
                device ? screenshotThumbnailsDesktop : screenshotThumbnails
              }
            />
          )}
          <SuggestionsList
            siteName={siteName}
            opportunities={device ? opportunitiesMobile : opportunitiesDesktop}
            diagnostics={device ? diagnosticsMobile : diagnosticsDesktop}
            lcpElement={device ? lcpElementMobile : lcpElementDesktop}
            clsElements={device ? clsElementsMobile : clsElementsDesktop}
            device={device ? "Mobile " : "Desktop"}
            techStack={techStack}
          />
          <TechStack techStack={techStack} />
        </main>
      )}
    </Container>
  );
};

export default SiteDetails;
