import React, { useState } from "react";
import { Button, Container, Row, Col } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import axios from "axios";
import { API_URL } from "../constants";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getData } from "../state/actions/sites.actions";
import { useSnackbar } from "notistack";
import { Alert } from "@mui/material";

const AddPropertyId = () => {
  const [propertyId, setPropertyId] = useState("");
  const user = useSelector((state) =>
    state.authentication.user
      ? state.authentication.user
      : state.registration.user
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const addOrUpdatePropertyId = async () => {
    try {
      if (propertyId) {
        await axios.post(
          `${API_URL}/analytics/add-property-id/${localStorage.getItem(
            "user"
          )}`,
          {
            propertyIdToUpdate: propertyId,
            siteUrl: localStorage.getItem("tabUrl").replaceAll('"', ""),
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        dispatch(getData(user.rootUser ? user.rootUser : user.email))
          .then(() => {
            history.push("/google-analysis");
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        enqueueSnackbar("Please fill in property id", {
          variant: "warning",
        });
        setTimeout(() => {
          closeSnackbar();
        }, 5000);
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong, please try again later", {
        variant: "error",
      });
      setTimeout(() => {
        closeSnackbar();
      }, 5000);
      console.log(error);
    }
  };

  return (
    <Container style={{ minHeight: "87vh" }}>
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Add Property ID"
          subtitle="Dashboard"
          className="text-sm-left mb-3"
        />
      </Row>
      <Row>
        <Col className={"col-lg-8 col-md-8 col-sm-8"}>
          <input
            type="number"
            value={propertyId}
            onChange={(e) => {
              setPropertyId(e.target.value);
            }}
            name="propertyId"
            className={`form-control`}
            style={{ width: "400px" }}
          />
        </Col>
        <Col
          className="col-lg-4 col-md-4 col-sm-4"
          style={{ textAlign: "center" }}
        >
          <Button
            theme="info"
            style={{ margin: "0 5px", marginLeft: "-850px" }}
            onClick={addOrUpdatePropertyId}
          >
            Add Property Id
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="col-lg-6 col-md-6 col-sm-12">
          <ol className="property-id-lists">
            <li style={{ listStyleType: "none", marginLeft: "-20px" }}>
              <Alert severity="info">
                <span style={{ fontWeight: "bold" }}>Requirements</span>
                <br />
                You need to have a
                <span style={{ color: "#007bff", fontWeight: "bold" }}>
                  {" "}
                  Google Analytics 4 property{" "}
                </span>{" "}
                created for your site, as opposed to{" "}
                <span style={{ color: "#007bff", fontWeight: "bold" }}>
                  {" "}
                  Universal Analytics.
                </span>{" "}
                Universal Analytics is an older version of Google Analytics and
                is not supported by MetryV.
              </Alert>
            </li>
          </ol>
          <ol className="property-id-lists">
            <li>First go to your Google Analytics Dashboard</li>
            <li>
              Go to Admin Section on the bottom left corner of your Google
              Analytics dashboard. On this page you&apos;ll see on the center
              the Property Settings, go to that section and copy your{" "}
              <span style={{ color: "#007bff" }}>PROPERTY ID </span>
              to our input above.
            </li>
            <li>
              If you don&apos;t have a{" "}
              <span style={{ color: "#007bff" }}>
                Google Analytics 4 property{" "}
              </span>{" "}
              created for your site go on the Admin Page and follow up with the
              Setup Assistant to create this property.
            </li>
          </ol>
        </Col>
        <Col className="col-lg-6 col-md-6 col-sm-12">
          <ul className="property-id-lists" style={{ listStyleType: "none" }}>
            <li>
              <Alert severity="info">
                <span style={{ fontWeight: "bold" }}>Important</span>
                <br />
                In order to give us access to View your Google Analytics data,
                go to <span style={{ color: "#007bff" }}>Admin Section</span> on
                bottom left corner than on{" "}
                <span style={{ color: "#007bff" }}>
                  Account Settings &gt; Account Access Managment
                </span>{" "}
                and add this email{" "}
                <span style={{ color: "#007bff" }}>
                  starting-account-8oh68x47dcib@metryv-1664455436755.iam.gserviceaccount.com{" "}
                </span>
                as a <span style={{ color: "#007bff" }}>Viewer</span>
              </Alert>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default AddPropertyId;
