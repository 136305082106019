import React from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import { Store } from "../../../state/flux";

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props);

    let navItems;
    if (props.suspendedAccount) {
      navItems = Store.getSidebarSuspendedItems();
    } else if (!props.isRoot) {
      navItems = Store.getSidebarSubUserItems();
    } else {
      navItems = Store.getSidebarItems();
    }

    this.state = {
      navItems: navItems,
    };

    this.onChange = this.onChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: this.state.suspendedAccount
        ? Store.getSidebarSuspendedItems()
        : Store.getSidebarItems(),
    });
  }

  render() {
    const { navItems: items } = this.state;
    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {items.map((item, idx) => (
            <SidebarNavItem key={idx} item={item} />
          ))}
        </Nav>
      </div>
    );
  }
}

export default SidebarNavItems;
