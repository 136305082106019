import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import protectedRoutes from "./protectedRoutes";
import suspendedRoutes from "./suspendedRoutes";
import subUserRoutes from "./subUserRoutes";
import { history } from "./helpers";
import { alertActions } from "./state/actions";
import { PrivateRoute } from "./components/common/PrivateRoute";
import { LoginPage } from "./pages/LoginPage";
import { RegisterPage } from "./pages/RegisterPage";
import { ForgetPassword } from "./pages/ForgetPassword";
import { ConfirmEmail } from "./pages/ConfirmEmail";
import { NewPassword } from "./pages/NewPassword";
import { SetSubUserPassword } from "./pages/SetSubUserPassword";
import { ConfirmedSubUserEmail } from "./pages/ConfirmedSubUserEmail";
import { ConfirmedSubUserEmailFailed } from "./pages/ConfirmedSubUserEmailFailed";
import SiteDetails from "./pages/SiteDetails";
import { useSelector } from "react-redux";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/style.scss?v=1.0";

import { SnackbarProvider } from "notistack";

import ScrollToTop from "./components/common/ScrollToTop";
import SlackRedirect from "./pages/SlackRedirect";
import io from "socket.io-client";

const App = () => {
  const socket = io(process.env.REACT_APP_BACKEND_CHAT_URL.slice(0, -5));
  // HIDE CONSOLE ERRORS, WARNINGS and LOGS on PRODUCTION:
  if (process.env.REACT_APP_ENV === "production") {
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
  }

  const dispatch = useDispatch();
  const user = useSelector((state) =>
    state.authentication.user
      ? state?.authentication?.user
      : state?.registration?.user
  );

  useEffect(() => {
    /* eslint-disable no-unused-vars */
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
  }, []);

  useEffect(() => {
    if (user) {
      socket.emit("user_connect", {
        userId: user.userId,
        email: user.email,
        room: user.userId,
      });
    }
  }, [socket]);

  return (
    <SnackbarProvider maxSnack={3}>
      <Router history={history} basename={process.env.REACT_APP_BASENAME || ""}>
        <ScrollToTop />
        <Switch>
          {user?.suspendedAccount
            ? suspendedRoutes.map((route, index) => {
                return (
                  <PrivateRoute
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={(props) => {
                      return (
                        <route.layout {...props}>
                          <route.component {...props} />
                        </route.layout>
                      );
                    }}
                  />
                );
              })
            : user?.rootUser
            ? subUserRoutes.map((route, index) => {
                return (
                  <PrivateRoute
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={(props) => {
                      return (
                        <route.layout {...props}>
                          <route.component {...props} socket={socket} />
                          {/* <route.component {...props} /> */}
                        </route.layout>
                      );
                    }}
                  />
                );
              })
            : protectedRoutes.map((route, index) => {
                return (
                  <PrivateRoute
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={(props) => {
                      return (
                        <route.layout {...props}>
                          <route.component {...props} socket={socket} />
                          {/* <route.component {...props} /> */}
                        </route.layout>
                      );
                    }}
                  />
                );
              })}
          <Route path="/login" component={LoginPage} />
          <Route path="/register" component={RegisterPage} />
          <Route path="/forgot-password" component={ForgetPassword} />
          <Route path="/new-password/:userId" component={NewPassword} />
          <Route path="/confirm-email" component={ConfirmEmail} />
          <Route path="/siteDetails" component={SiteDetails} />
          <Route
            path="/confirmed-sub-user-email"
            component={ConfirmedSubUserEmail}
          />
          <Route
            path="/confirmed-sub-user-email-failed"
            component={ConfirmedSubUserEmailFailed}
          />
          <Route path="/sub-user/set-password" component={SetSubUserPassword} />
          <Route path="/oauth-redirect" component={SlackRedirect} />
          <Route path="/" component={LoginPage} />
          <Redirect from="*" to={user?.suspendedAccount ? "/contactUs" : "/"} />
        </Switch>
      </Router>
    </SnackbarProvider>
  );
};

export default App;
