import React from "react";

const NotConfirmed = () => {
  return (
    <div className="confirm-backdrop">
      <h3>Email Confirmation</h3>
      <p>You haven&apos;t confirmed you email yet</p>
    </div>
  );
};

export default NotConfirmed;
