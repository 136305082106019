import React from "react";
import { Button } from "shards-react";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { isMobile } from "react-device-detect";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import IconButton from "@mui/material/IconButton";

const UpgradePlan = () => {
  return (
    <div
      className={isMobile ? "border-left" : ""}
      style={{ borderRadius: "0", display: "flex", alignItems: "center" }}
    >
      <Link
        to={{
          pathname: "/subscription-plans",
        }}
      >
        {isMobile ? (
          <Tooltip title="Subscription Expired">
            <IconButton>
              <DoDisturbOnIcon color="warning" />
            </IconButton>
          </Tooltip>
        ) : (
          <Button className="d-inline-block mx-2 btn-ss">
            Subscription Expired
          </Button>
        )}
      </Link>
    </div>
  );
};

export default UpgradePlan;
