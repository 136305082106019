import React from "react";
import {
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
} from "shards-react";

const NavbarSearch = (props) => (
  <Form className="main-navbar__search w-100 h-100 d-none d-md-flex d-lg-flex">
    <InputGroup seamless>
      <InputGroupAddon type="prepend">
        <InputGroupText>
          <i className="material-icons">search</i>
        </InputGroupText>
      </InputGroupAddon>
      <FormInput
        className="navbar-search"
        placeholder={props.placeholder}
        onChange={props.onChange}
        id={props.id}
      />
    </InputGroup>
  </Form>
);

export default NavbarSearch;
