import React, { useEffect } from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import ContactForm from "../components/contact-us/ContactForm";
import { getUserPlanData } from "../state/actions/user.actions";
import { useDispatch, useSelector } from "react-redux";

const ContactUs = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) =>
    state.authentication.user
      ? state.authentication.user
      : state.registration.user
  );
  useEffect(() => {
    dispatch(getUserPlanData(user.userId));
  }, []);

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Contact Us"
          subtitle="metryv"
          md="12"
          className="ml-sm-auto mr-sm-auto"
        />
      </Row>
      <Row>
        <Col lg="12">
          <ContactForm />
        </Col>
      </Row>
    </Container>
  );
};

export default ContactUs;
