import React from "react";
import { Card, CardHeader, ListGroup, ListGroupItem } from "shards-react";

const Description = () => (
  <Card
    small
    className="mb-4 pt-3"
    style={{ height: "372px", borderRadius: "0px" }}
  >
    <CardHeader className="border-bottom text-center">
      <span className="text-muted d-block mb-2">
        What is Web Vitals Support?
      </span>
    </CardHeader>
    <ListGroup flush>
      <ListGroupItem className="p-4">
        <p>
          If you don&apos;t know how to deal with your bad Web Performance and
          Core Web Vitals, we can help you with that!
        </p>
        <span>
          Chat with our support and it can give you these information:
        </span>
        <ul>
          <li>an overview of what we can offer</li>
          <li>an estimate of how long it will take to fix them</li>
          <li>how much it will cost for you</li>
        </ul>
      </ListGroupItem>
    </ListGroup>
  </Card>
);

export default Description;
