import React from "react";
import { Button } from "shards-react";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

function groupItemsByDate(pingData) {
  const groupedData = {};

  if (!Array.isArray(pingData)) {
    return groupedData;
  }
  for (const items of pingData) {
    const iterableArray = Array.from(items);
    for (const item of iterableArray) {
      const dateObject = new Date(item.date);
      const date = dateObject
        .toLocaleDateString("en-UK", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
        .replace(/\//g, "-");
      if (groupedData[date]) {
        groupedData[date].push(item);
      } else {
        groupedData[date] = [item];
      }
    }
  }
  console.log("groupedData", groupedData);
  return groupedData;
}

const table = ({
  pingData,
  setReportToView,
  setClickReport,
  setOpenDeleteDataModal,
}) => {
  const loadingNewPingData = useSelector((state) => state.newPingData?.loading);
  const newPingDataDate = useSelector((state) => state.newPingData?.date);

  const groupedData = groupItemsByDate(pingData);
  let grData = Object.entries(groupedData);

  function parseDate(dateStr) {
    const [day, month, year] = dateStr.split("-").map(Number);
    return new Date(year, month - 1, day);
  }
  function convertDateFormat(dateStr) {
    const [day, month, year] = dateStr.split("-");
    return `${year}-${month}-${day}`;
  }
  let sortedItems = grData.sort((a, b) => parseDate(b[0]) - parseDate(a[0]));
  sortedItems = sortedItems.map((subArray) => {
    const [dateStr, arrayData] = subArray;
    const convertedDate = convertDateFormat(dateStr);
    return [convertedDate, arrayData];
  });

  return (
    <table className="table mb-0">
      <thead className="bg-light">
        <tr>
          <th scope="col" className="border-0">
            #
          </th>
          <th scope="col" className="border-0">
            Date
          </th>
          <th scope="col" className="border-0">
            Countries
          </th>
          <th scope="col" className="border-0">
            latency - Overall PING Result
          </th>
          <th scope="col" className="border-0" style={{ textAlign: "center" }}>
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        {loadingNewPingData && (
          <tr>
            <td>#</td>
            <td>
              {new Date(newPingDataDate).toLocaleDateString("en-UK", {
                day: "numeric",
                month: "long",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </td>
            <td>
              <CircularProgress
                style={{
                  width: "20px",
                  height: "20px",
                  position: "relative",
                  top: "6px",
                }}
              />
            </td>
            <td></td>
            <td style={{ textAlign: "right" }}>
              <Button disabled theme="info" className="mr-4">
                View Full Report
              </Button>
              <Button disabled theme="danger" className="mr-4">
                Remove Data
              </Button>
            </td>
          </tr>
        )}
        {pingData?.length ? (
          sortedItems.map((item) => {
            let date = item;
            let index = sortedItems.indexOf(item[0]);
            let avgLatency = 0;
            let country = [];
            for (const items of item[1]) {
              items.data.map((obj) => {
                return country.push(obj.server);
              });
            }
            country.filter((value, index) => country.indexOf(value) !== index);
            const countries = [...new Set(country)];
            const separatedCountries = countries.join(", ");

            for (const items of item[1]) {
              avgLatency = items.data.reduce(
                (acc, obj) => acc + parseFloat(obj.latency),
                0
              );
            }
            return (
              <tr key={index}>
                <td></td>
                <td>{date[0]}</td>
                <td>{separatedCountries}</td>
                <td>{avgLatency}</td>
                <td style={{ textAlign: "right" }}>
                  <Button
                    theme="info"
                    className="mr-4"
                    onClick={() => {
                      setReportToView(date[1]), setClickReport(true);
                    }}
                    style={{ background: "#0087F9" }}
                  >
                    View Full Report
                  </Button>
                  <Button
                    theme="danger"
                    className="mr-4"
                    onClick={() =>
                      setOpenDeleteDataModal({ open: true, data: date })
                    }
                  >
                    Remove Data
                  </Button>
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default table;
