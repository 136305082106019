import { notificationsConstants } from "../../constants";

export function notifications(state = {}, action) {
  switch (action.type) {
    case notificationsConstants.NOTIFICATIONS_GETALL_REQUEST:
      return { loading: true };
    case notificationsConstants.NOTIFICATIONS_GETALL_SUCCESS:
      return { loading: false, notifications: action.payload };
    case notificationsConstants.NOTIFICATIONS_GETALL_FAILURE:
      return { loading: false, error: action.payload };
    case notificationsConstants.NOTIFICATIONS_REMOVE_STATE:
      return {
        ...state,
        notifications: state.notifications.filter(
          (item) => item._id !== action.payload
        ),
      };
    case notificationsConstants.NOTIFICATIONS_MARK_AS_READ_STATE:
      return {
        ...state,
        notifications: state.notifications.map((item) =>
          item._id === action.payload.id ? { ...item, read: true } : item
        ),
      };
    case notificationsConstants.NOTIFICATIONS_MARK_ALL_AS_READ_STATE:
      return {
        ...state,
        notifications: state.notifications.map((item) => {
          return { ...item, read: true };
        }),
      };
    case notificationsConstants.NOTIFICATIONS_CLEAR:
      return {};
    default:
      return state;
  }
}
