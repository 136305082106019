import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  Badge,
} from "shards-react";
import axios from "axios";
import { API_URL } from "../constants";
import { useSelector, useDispatch } from "react-redux";
import Checkout from "../components/pricing-plans/checkout";
import CancelSubscription from "../components/pricing-plans/CancelSubscription";
import RequestQouteModal from "../components/pricing-plans/RequestQouteModal";
import { Alert, CircularProgress } from "@mui/material";
import { getUserPlanData } from "../state/actions/user.actions";
import { getData } from "../state/actions/sites.actions";
import paysera3 from "../assets/images/logos/trust-badge.png";

const PricingPlans = () => {
  const [plans, setPlans] = useState([]);
  const [error, setError] = useState(false);
  const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState(false);
  const [checkoutModal, setCheckoutModal] = useState({
    open: false,
    clickedPlan: "",
    price_month: "",
    price_annual: "",
    site_limit: "",
    site_unlimited: "",
    page_limit: "",
    page_unlimited: "",
    subusers_limit: "",
    subusers_unlimited: "",
    competitor_sites_limit: "",
    competitor_sites_unlimited: "",
  });
  const [requestQouteModal, setRequestQouteModal] = useState(false);
  const user = useSelector((state) =>
    state.authentication.user
      ? state.authentication.user
      : state.registration.user
  );
  const currentPlan = useSelector((state) => state.plan.plan);
  const currentPlanError = useSelector((state) => state.plan.error);
  const currentPlanLoading = useSelector((state) => state.plan.loading);
  const dispatch = useDispatch();

  const [planFeatures, setPlanFeatures] = useState({
    name: "Business",
    features: {
      sites: {
        pagesUnlimited: true,
        unlimited: false,
        pagesLimit: 5,
        limit: 10,
      },
      subusers: {
        unlimited: false,
        limit: 10,
      },
      scans: {
        notifications: "email,slack and discord",
        psiscan: "3 scans per day",
        screenshot: "every hour",
        uptime: "every hour",
      },
    },
  });
  const getPlansList = async () => {
    try {
      const data = await axios.get(`${API_URL}/plan/list`);
      let threePlansOnly = [];
      data.data.data.forEach((plan) => {
        if (
          plan.name === "Business" ||
          plan.name === "Team" ||
          plan.name === "Developer"
        ) {
          threePlansOnly.push(plan);
        }
      });
      threePlansOnly = threePlansOnly.reverse();
      setPlans(threePlansOnly);
      setError(false);
    } catch (error) {
      setError(true);
      console.log(error);
    }
  };

  useEffect(() => {
    user.isRoot ? getPlansList() : null;
    dispatch(getUserPlanData(user.userId));
    dispatch(getData(user.rootUser ? user.rootUser : user.email));
  }, []);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <>
      {currentPlanLoading ? (
        <Row>
          <Col
            className="col-lg-12 col-md-12 col-sm-12"
            style={{
              display: "flex",
              height: "100vh",
              width: "100vw",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              style={{
                width: "200px",
                height: "200px",
              }}
            />
          </Col>
        </Row>
      ) : currentPlanError ? (
        <Container fluid className="main-content-container px-4 pb-4">
          <div className="error">
            <div className="error__content">
              <h2>500</h2>
              <h3>Something went wrong!</h3>
              <p>There was a problem on our end. Please try again later.</p>
              <Button pill>&larr; Go Back</Button>
            </div>
          </div>
        </Container>
      ) : (
        <>
          {
            <>
              <Checkout
                checkoutModal={checkoutModal}
                setCheckoutModal={setCheckoutModal}
              />
              <CancelSubscription
                open={cancelSubscriptionModal}
                setOpen={setCancelSubscriptionModal}
              />
              <RequestQouteModal
                open={requestQouteModal}
                setOpen={setRequestQouteModal}
              />
            </>
          }
          {user.isRoot ? (
            <Container fluid className="main-content-container px-4">
              <Row style={{ justifyContent: "center" }}>
                <h1
                  className="mx-5 mt-5"
                  style={{ color: "#000000", position: "relative" }}
                >
                  MetryV Pricing
                  <img
                    src={paysera3}
                    style={{
                      width: isMobile ? "50px" : "70px",
                      position: "absolute",
                      right: "1",
                      top: "-10px",
                    }}
                    className={isMobile ? "" : "mx-4"}
                  />
                </h1>
              </Row>

              <Row style={{ justifyContent: "center" }}>
                <span
                  className="mb-5"
                  style={{ maxWidth: "40%", textAlign: "center" }}
                >
                  Find the best suited plan for your business
                </span>
              </Row>
              {currentPlan.name === "Trial" ||
              currentPlan.name === "MetryV" ? null : (
                <Row style={{ justifyContent: "center" }}>
                  <Col className="col-lg-9 col-md-12 col-sm-12 mb-4">
                    {new Date(currentPlan?.next_payment_date) > new Date() ? (
                      <Alert severity="info">
                        Important{" "}
                        <span style={{ fontWeight: "bold" }}>
                          NEXT PAYMENT DATE:{" "}
                          {new Date(
                            currentPlan?.next_payment_date
                          ).toLocaleDateString("en-UK", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}
                        </span>
                        <br />
                        If you choose to change your current plan, your old
                        subscription plan will be removed and your new plan will
                        start from today, meaning the next payment date will be
                        30 days or 12 months from today&apos;s date. <br />-
                        After your current subscription is expired, you will
                        have 7 days to resubscribe. In other cases your account
                        will be suspended
                      </Alert>
                    ) : null}
                    {new Date(currentPlan?.next_payment_date) <= new Date() ? (
                      <Alert severity="error" className="mt-4">
                        Your current subscription has expired, your{" "}
                        <span style={{ fontWeight: "bold" }}>
                          LAST PAYMENT DATE:{" "}
                          {new Date(
                            currentPlan?.last_payment_date
                          ).toLocaleDateString("en-UK", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}
                        </span>
                        <br />
                        You won&apos;t be able to use our services until further
                        payment!
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
              )}
              <Row style={{ justifyContent: "center" }}>
                {plans.length && !error ? (
                  plans.map((plan) => (
                    <Col lg="3" key={plan._id}>
                      <Card
                        small
                        className="card-post mb-4 onHoverCards"
                        style={{
                          border:
                            plan.name === currentPlan?.name
                              ? `2px solid #0087f9`
                              : `2px solid #fff`,
                          borderRadius: "13px",
                          position: "relative",
                          minHeight: "300px",
                        }}
                        onClick={() => {
                          setPlanFeatures(plan);
                        }}
                      >
                        {plan.name === currentPlan?.name ? (
                          <Badge
                            style={{
                              position: "absolute",
                              right: "-10px",
                              height: "50px",
                              top: "-10px",
                              fontSize: "40px",
                            }}
                          >
                            &#10004;
                          </Badge>
                        ) : null}
                        <CardBody>
                          <h4
                            className="card-title pb-2 cursor-pointer"
                            style={{ fontWeight: "700", color: "#090909" }}
                          >
                            {plan.name}
                          </h4>
                          <ul className="m-0 px-3">
                            <li
                              className="card-text my-2"
                              style={{ color: "#6E6E73" }}
                            >
                              {plan.features.sites.unlimited
                                ? `Add unlimited number of sites`
                                : plan.features.sites.limit > 1
                                ? `Add up to ${plan.features.sites.limit} sites`
                                : `Add up to ${plan.features.sites.limit} site`}
                            </li>
                            <li
                              className="card-text my-2"
                              style={{ color: "#6E6E73" }}
                            >
                              {plan.features.sites.pagesUnlimited
                                ? `Add unlimited number of pages per site`
                                : plan.features.sites.pagesLimit > 1
                                ? `Add up to ${plan.features.sites.pagesLimit} pages per site`
                                : `Add up to ${plan.features.sites.pagesLimit} page per site`}
                            </li>
                            <li
                              className="card-text my-2"
                              style={{ color: "#6E6E73" }}
                            >
                              {plan.features.subusers.unlimited
                                ? `Add unlimited number of users`
                                : plan.features.subusers.limit > 1
                                ? `Register up to ${plan.features.subusers.limit} users`
                                : `Register up to ${plan.features.subusers.limit} user`}
                            </li>
                          </ul>
                        </CardBody>
                        <CardFooter
                          className="d-flex"
                          style={{ justifyContent: "center" }}
                        >
                          <div className="my-auto" style={{ minWidth: "100%" }}>
                            <Button
                              size="lg"
                              theme="primary"
                              style={{
                                borderRadius: "10px",
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                cursor: "pointer",
                                padding: "0.51rem 1rem",
                              }}
                              onClick={() => {
                                setCheckoutModal({
                                  open: true,
                                  clickedPlan: plan.name,
                                  price_month: plan.priceMonth,
                                  price_annual: plan.priceAnnual,
                                  site_limit: plan.features.sites.limit,
                                  site_unlimited: plan.features.sites.unlimited,
                                  page_limit: plan.features.sites.pagesLimit,
                                  page_unlimited:
                                    plan.features.sites.pagesUnlimited,
                                  subusers_limit: plan.features.subusers.limit,
                                  subusers_unlimited:
                                    plan.features.subusers.unlimited,
                                  competitor_sites_limit:
                                    plan.features.competitor_sites.limit,
                                  competitor_sites_unlimited:
                                    plan.features.subusers.unlimited,
                                });
                                // checkPlanChangeWarning();
                              }}
                              disabled={
                                plan.name === currentPlan?.name
                                  ? !(
                                      new Date(
                                        currentPlan?.next_payment_date
                                      ) <= new Date()
                                    )
                                  : false
                              }
                            >
                              <span
                                style={{ width: "50%", fontWeight: "bold" }}
                              >
                                &euro;{`${plan.priceMonth}`} / mo
                              </span>
                              <span
                                style={{
                                  width: "40%",
                                  color: "#0371D0",
                                  backgroundColor: "white",
                                  borderRadius: "10px",
                                  fontSize: "1rem",
                                }}
                              >
                                Get now
                              </span>
                            </Button>
                          </div>
                        </CardFooter>
                      </Card>
                    </Col>
                  ))
                ) : (
                  <Col lg="9">
                    <Card
                      className="card-post mb-4"
                      style={{ borderRadius: "13px" }}
                    >
                      <CardBody
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <div>
                          <h4>
                            No pricing plans at the moment, please try again
                            later
                          </h4>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                )}
              </Row>
              <Row style={{ justifyContent: "center" }}>
                <Col lg="9">
                  <Card
                    className="card-post mb-4"
                    style={{ borderRadius: "13px" }}
                  >
                    <CardBody
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                      }}
                    >
                      <h2 style={{ color: "#0087F9" }}>{planFeatures.name}</h2>
                      <div>
                        <h5 style={{ color: "#0087F9" }}>Features</h5>
                        <ul
                          style={{
                            padding: "0",
                            listStyleType: "none",
                          }}
                        >
                          <li>
                            Scheduled {planFeatures.features.scans.psiscan} on
                            your sites
                          </li>
                          <li>
                            {capitalizeFirstLetter(
                              planFeatures.features.scans.screenshot
                            )}{" "}
                            scans on screenshot analysis
                          </li>
                          <li>
                            {capitalizeFirstLetter(
                              planFeatures.features.scans.uptime
                            )}{" "}
                            scans on server uptime
                          </li>
                          <li>
                            Get notified on{" "}
                            {planFeatures.features.scans.notifications}
                          </li>
                        </ul>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row style={{ justifyContent: "center" }}>
                <Col lg="9">
                  <Card
                    small
                    className="card-post mb-4 onHoverCards"
                    style={{ border: "2px solid #fff", borderRadius: "13px" }}
                  >
                    <CardBody>
                      <h4
                        className="card-title pb-2 cursor-pointer"
                        style={{ fontWeight: "700", color: "#090909" }}
                      >
                        Enterprise
                      </h4>
                      <p
                        className="card-text my-2"
                        style={{ color: "#6E6E73" }}
                      >
                        None of the above plans suit your business needs!
                      </p>
                      <p
                        className="card-text my-2"
                        style={{ color: "#6E6E73" }}
                      >
                        Contact us to get all of MetryV&apos;s core features
                        plus enterprise capabilities to suit your business and
                        workflow
                      </p>
                      <ul className="m-0 pt-2 px-3">
                        <li
                          className="card-text my-2"
                          style={{ color: "#6E6E73" }}
                        >
                          Get a Demo
                        </li>
                        <li
                          className="card-text my-2"
                          style={{ color: "#6E6E73" }}
                        >
                          Get Volume Pricing
                        </li>
                        <li
                          className="card-text my-2"
                          style={{ color: "#6E6E73" }}
                        >
                          Pay by Invoice
                        </li>
                        <li
                          className="card-text my-2"
                          style={{ color: "#6E6E73" }}
                        >
                          Learn about our Security & Compliance Policies
                        </li>
                      </ul>
                    </CardBody>
                    <CardFooter
                      className="d-flex"
                      style={{ justifyContent: "center" }}
                    >
                      <div className="mr-auto">
                        <Button
                          size="lg"
                          theme="primary"
                          style={{
                            borderRadius: "10px",
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                          onClick={() => setRequestQouteModal(true)}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Request a Quote
                          </span>
                        </Button>
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>

              <Row style={{ justifyContent: "center" }}>
                <Col lg="9">
                  <Card
                    small
                    className="card-post mb-4 onHoverCards"
                    style={{ border: "2px solid #fff", borderRadius: "13px" }}
                  >
                    <CardBody>
                      <h4
                        className="card-title pb-2 cursor-pointer"
                        style={{ fontWeight: "700", color: "#090909" }}
                      >
                        Terms
                      </h4>
                      <ul>
                        <li>
                          <a
                            href="https://metryv.com/privacy-policy/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Privacy Policy
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://metryv.com/return-and-refund-policy/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Return and Refund Policy
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://metryv.com/end-user-license-agreement/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            End-User License Agreement
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://metryv.com/terms-and-conditions/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Terms and Conditions
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://metryv.com/cookies-policy/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Cookies Policy
                          </a>
                        </li>
                      </ul>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {currentPlan.name === "Trial" ? null : currentPlan.to_suspend
                  ?.value ? (
                <Row style={{ justifyContent: "center" }}>
                  <Col
                    className="col-lg-9 col-md-9 col-sm-9 my-4"
                    style={{ fontWeight: "bold" }}
                  >
                    Account to be suspended on{" "}
                    {new Date(currentPlan.to_suspend?.date)
                      .toString()
                      .slice(0, 16)}
                  </Col>
                </Row>
              ) : (
                <Row style={{ justifyContent: "center" }}>
                  <Col
                    className="col-lg-9 col-md-9 col-sm-9 my-4"
                    style={{ fontWeight: "bold" }}
                  >
                    <Button
                      outline
                      theme="danger"
                      className="mr-4"
                      onClick={() => {
                        setCancelSubscriptionModal(true);
                      }}
                    >
                      Cancel Subscription
                    </Button>
                    Next Payment Date:{" "}
                    {new Date(
                      currentPlan?.next_payment_date
                    ).toLocaleDateString("en-UK", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </Col>
                </Row>
              )}
            </Container>
          ) : (
            <Container fluid className="main-content-container px-4 pb-4">
              <div className="error">
                <div className="error__content">
                  <h2>500</h2>
                  <h3>Something went wrong!</h3>
                  <p>There was a problem on our end. Please try again later.</p>
                </div>
              </div>
            </Container>
          )}
        </>
      )}
    </>
  );
};

export default PricingPlans;
