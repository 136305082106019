import React, { useState, useEffect } from "react";
import { Row, Col, Button, Card, CardHeader, CardBody } from "shards-react";
import axios from "axios";
import { API_URL } from "../../constants";
import { useSnackbar } from "notistack";

const Options = ({ notifications }) => {
  const [options, setOptions] = useState({
    checkbox1: "",
    checkbox2: "",
    checkbox3: "",
  });
  const user = localStorage.getItem("user");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleChange = (e) => {
    let { name, checked } = e.target ? e.target : e;
    setOptions((options) => ({ ...options, [name]: checked }));
  };
  async function handleSubmit(e) {
    e.preventDefault();

    if (options.checkbox1 || options.checkbox2 || options.checkbox3) {
      const request_body = {
        email: user,
        options: options,
      };

      await axios
        .post(`${API_URL}/users/notifications/options`, request_body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then(() => {
          enqueueSnackbar("Notification Options changed successfully", {
            variant: "success",
          });
          setTimeout(() => {
            closeSnackbar();
          }, 4500);
        })
        .catch((error) => {
          console.log(error.response);
          enqueueSnackbar(
            "Your request couldn't be processed at the moment, please try again later!",
            { variant: "error" }
          );
          setTimeout(() => {
            closeSnackbar();
          }, 4500);
        });
    }
  }

  useEffect(() => {
    if (notifications) {
      setOptions({
        checkbox1: notifications.options.good,
        checkbox2: notifications.options.average,
        checkbox3: notifications.options.bad,
      });
    }
  }, [notifications]);

  return (
    <Row>
      <Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">Notification Options</h6>
          </CardHeader>
          <CardBody className="p-3 registered-notifications">
            <form name="form" onSubmit={handleSubmit}>
              <label>
                Which types of notifications would you like to receive?
              </label>

              <Row className="form-checkboxes">
                <input
                  type="checkbox"
                  id="checkbox1"
                  name="checkbox1"
                  value="Good"
                  onChange={handleChange}
                  checked={options.checkbox1}
                />
                <label htmlFor="checkbox1">Good</label>
              </Row>

              <Row className="form-checkboxes">
                <input
                  type="checkbox"
                  id="checkbox2"
                  name="checkbox2"
                  value="Average"
                  onChange={handleChange}
                  checked={options.checkbox2}
                />
                <label htmlFor="checkbox2">Average</label>
              </Row>

              <Row className="form-checkboxes">
                <input
                  type="checkbox"
                  id="checkbox3"
                  name="checkbox3"
                  value="Bad"
                  onChange={handleChange}
                  checked={options.checkbox3}
                />
                <label htmlFor="checkbox3">Bad</label>
              </Row>
              <Button theme="accent" className="mt-3">
                Save
              </Button>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Options;
