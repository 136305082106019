import React, { useState, useEffect } from "react";
import axios from "axios";
import Table from "../components/sub-users/SubUsersTable";
import { Container, Row } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { API_URL } from "../constants";
import { useSelector } from "react-redux";
import AddSubUsers from "../components/sub-users/AddSubUsers";
import { useDispatch } from "react-redux";
import { getUserPlanData } from "../state/actions/user.actions";

const UsersList = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const dispatch = useDispatch();

  const user = useSelector((state) =>
    state.authentication.user
      ? state.authentication.user
      : state.registration.user
  );

  const getUsers = () => {
    axios
      .get(`${API_URL}/sub-users/${localStorage.getItem("user")}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        const data = response.data.response.map((item) => {
          return { ...item };
        });
        setUsers(data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    if (!user.rootUser) {
      getUsers();
    }
    dispatch(getUserPlanData(user.userId));
  }, []);

  return (
    <>
      {user.isRoot ? (
        <>
          {error && submitted && (
            <div className={`alert alert-danger`}>{error}</div>
          )}
          {success && submitted && (
            <div className={`alert alert-success`}>Registered Successfully</div>
          )}
          <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
              <PageTitle
                sm="4"
                title="Users List"
                subtitle="Manage Users"
                className="text-sm-left pb-4"
              />
              <Table users={users} getUsers={getUsers} />
            </Row>

            <Row noGutters className="page-header py-4 mb-4">
              <PageTitle
                sm="4"
                title="Add User"
                className="text-sm-left pb-4"
              />
              <AddSubUsers
                setError={setError}
                setSuccess={setSuccess}
                setSubmitted={setSubmitted}
                getUsers={getUsers}
                submitted={submitted}
              />
            </Row>
          </Container>{" "}
        </>
      ) : (
        <Container fluid className="main-content-container px-4 pb-4">
          <div className="error">
            <div className="error__content">
              <h2>500</h2>
              <h3>Something went wrong!</h3>
              <p>There was a problem on our end. Please try again later.</p>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export default UsersList;
