import React, { useEffect } from "react";
import { Container, Row, Col, CardHeader, CardBody, Card } from "shards-react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../components/competitor-analysis/CompetitorSitesTable";
import CircularProgress from "@mui/material/CircularProgress";
import { getCompetitorSites, getData } from "../state/actions/sites.actions";
import {
  getCompetitorSitesStatus,
  getSitesStatusData,
} from "../state/actions/psi.actions";
import { getUserPlanData } from "../state/actions/user.actions";

const CompetitorAnalysis = () => {
  const competitorSites = useSelector((state) => state.competitorSites.sites);
  const loading = useSelector((state) => state.competitorSites.loading);
  const user = useSelector((state) =>
    state.authentication.user
      ? state.authentication.user
      : state.registration.user
  );
  const currentPlan = useSelector((state) => state.plan.plan);
  const currentPlanError = useSelector((state) => state.plan.error);
  const currentPlanLoading = useSelector((state) => state.plan.loading);
  const sites = useSelector((state) => state.sites.sites);
  const sitesStatus = useSelector((state) => state.sitesStatus.sitesStatus);

  const dispatch = useDispatch();

  const fetchSites = () => {
    dispatch(getData(user.rootUser ? user.rootUser : user.email))
      .then((response) => {
        if (response.length) {
          let urls = [];
          response.map((item) => {
            let { url } = item;
            return urls.push(url);
          });
          dispatch(getSitesStatusData(urls));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchCompetitorSites = () => {
    dispatch(getCompetitorSites(user.rootUser ? user.rootUser : user.email))
      .then((response) => {
        if (response.length) {
          let urls = [];
          response.map((item) => {
            let { url } = item;
            return urls.push(url);
          });
          dispatch(getCompetitorSitesStatus(urls));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!competitorSites && !loading) {
      fetchCompetitorSites();
    }
    if (!sites?.length || !sitesStatus) {
      fetchSites();
    }
    dispatch(getUserPlanData(user.userId));
  }, []);

  return (
    <Container fluid className="main-content-container px-4">
      <Row
        style={{
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="py-5"
      >
        <h3>Competitor Analysis</h3>
        <p>Check your web vitals against other sites</p>
      </Row>
      {currentPlanLoading ? null : currentPlanError ? null : !currentPlan?.competitor_sites_unlimited ? (
        competitorSites?.length >= currentPlan?.competitor_sites_limit ? (
          <div
            className={`alert alert-warning`}
            style={{ borderRadius: "7px" }}
          >
            Limit reached! You&apos;re not allowed to add more than{" "}
            {currentPlanLoading
              ? null
              : currentPlanError
              ? null
              : currentPlan?.competitor_sites_limit}
            competitor sites
          </div>
        ) : null
      ) : null}
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Competitor Analysis</h6>
            </CardHeader>
            <CardBody className="p-0 pb-3 registered-sites">
              {loading ? (
                <CircularProgress />
              ) : (
                <Table fetchCompetitorSites={fetchCompetitorSites} />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CompetitorAnalysis;
