/* eslint-disable */
import axios from "axios";
import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "shards-react";
import { API_URL } from "../../constants";
import { useDispatch } from "react-redux";
import { loginMicrosoft } from "../../state/actions/user.actions";
import { msalConfig, loginRequest } from "../../helpers/microsoft.config";
import { PublicClientApplication } from "@azure/msal-browser";
import { MICROSOFT_CLIENT_ID, MICROSOFT_REDIRECT_URL } from "../../constants";

const MergeAccountsModalMs = ({
  setMergeAccountsModalMs,
  mergeAccountsModalMs: { open, email, microsoftId },
}) => {
  const dispatch = useDispatch();
  msalConfig.auth.clientId = MICROSOFT_CLIENT_ID;
  msalConfig.auth.redirectUri = MICROSOFT_REDIRECT_URL;
  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <Modal show={open} onHide={() => setMergeAccountsModalMs({ open: false })}>
      <Modal.Header closeButton>
        <Modal.Title>Merge Accounts</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Another metryv user was found with the same microsoft address, you can
        merge these accounts into one. Click yes to merge these accounts. Click
        no if you want to login with email and password with your current metryv
        account.
      </Modal.Body>
      <Modal.Footer>
        <Button
          theme="danger"
          onClick={async () => {
            try {
              let newmicrosoftId = microsoftId;
              await axios.post(`${API_URL}/auth/merge`, {
                email,
                newmicrosoftId: newmicrosoftId.toString(),
                googleId: null,
              });
              setMergeAccountsModalMs({ open: false });
              dispatch(loginMicrosoft(msalInstance, loginRequest));
            } catch (error) {
              console.log(error);
            }
          }}
        >
          Yes
        </Button>
        <Button onClick={() => setMergeAccountsModalMs({ open: false })}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MergeAccountsModalMs;
