import React, { useState, useEffect } from "react";
import LabDataOverviewGraphDetails from "./LabDataOverviewGraphDetails";
import CircularProgress from "@mui/material/CircularProgress";
import { Card, CardBody } from "shards-react";
import axios from "axios";
import { API_URL_PSI } from "../../constants";

const LabDataOverview = ({ siteName, siteUrl, device, manualScanCounter }) => {
  const [monthlyData, setMonthlyData] = useState({
    fcp: [],
    lcp: [],
    si: [],
    tbt: [],
    cls: [],
    scanDates: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  let fcpLabDataStats = [];
  let lcpLabDataStats = [];
  let siLabDataStats = [];
  let tbtLabDataStats = [];
  let clsLabDataStats = [];
  let scanDates = [];

  useEffect(async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${API_URL_PSI}/scan/monthly/labData`,
        {
          params: {
            url: siteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setLoading(false);
      setError(false);
      const monthlyLabData = response.data;
      if (monthlyLabData) {
        if (device == "Mobile") {
          monthlyLabData?.forEach((element) => {
            fcpLabDataStats.push(
              parseFloat(
                typeof element.mobile.labData !== "undefined"
                  ? element.mobile.labData.fcp
                  : null
              )
            );
            lcpLabDataStats.push(
              parseFloat(
                typeof element.mobile.labData !== "undefined"
                  ? element.mobile.labData.lcp
                  : null
              )
            );
            siLabDataStats.push(
              parseFloat(
                typeof element.mobile.labData !== "undefined"
                  ? element.mobile.labData.si
                  : null
              )
            );
            tbtLabDataStats.push(
              parseFloat(
                typeof element.mobile.labData !== "undefined"
                  ? element.mobile.labData.tbt
                  : null
              )
            );
            clsLabDataStats.push(
              parseFloat(
                typeof element.mobile.labData !== "undefined"
                  ? element.mobile.labData.cls
                  : null
              )
            );
          });
        } else {
          monthlyLabData.forEach((element) => {
            fcpLabDataStats.push(
              parseFloat(
                typeof element.desktop.labData !== "undefined"
                  ? element.desktop.labData.fcp
                  : null
              )
            );
            lcpLabDataStats.push(
              parseFloat(
                typeof element.desktop.labData !== "undefined"
                  ? element.desktop.labData.lcp
                  : null
              )
            );
            siLabDataStats.push(
              parseFloat(
                typeof element.desktop.labData !== "undefined"
                  ? element.desktop.labData.si
                  : null
              )
            );
            tbtLabDataStats.push(
              parseFloat(
                typeof element.desktop.labData !== "undefined"
                  ? element.desktop.labData.tbt
                  : null
              )
            );
            clsLabDataStats.push(
              parseFloat(
                typeof element.desktop.labData !== "undefined"
                  ? element.desktop.labData.cls
                  : null
              )
            );
          });
        }
        monthlyLabData.forEach((element) => {
          scanDates.push(element.scanDate);
        });
        setMonthlyData({
          fcp: fcpLabDataStats,
          lcp: lcpLabDataStats,
          si: siLabDataStats,
          tbt: tbtLabDataStats,
          cls: clsLabDataStats,
          scanDates: scanDates,
        });
      }
    } catch (error) {
      setLoading(false);
      setError(true);
      console.log(error);
    }
  }, [siteName, siteUrl, device, manualScanCounter]);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress
            style={{ margin: "2rem", height: "140px", width: "140px" }}
          />
          <div>Loading Lab Data Graph</div>
        </div>
      ) : error ? (
        <Card small className="h-100">
          <CardBody>No sufficient data for monthly graph!</CardBody>
        </Card>
      ) : (
        <LabDataOverviewGraphDetails
          data={monthlyData}
          siteName={siteName}
          siteUrl={siteUrl}
          device={device}
        />
      )}
    </>
  );
};

export default LabDataOverview;
