import React from "react";
import { Row, Col } from "shards-react";
import { getOverallScoreColorSiteDetails } from "../../helpers/scores";
import FinalScreenshot from "./FinalScreenshot";

const OverallScore = ({
  overallScore,
  finalScreenshot,
  finalScreenshotDesktop,
}) => {
  return (
    <>
      <Row>
        <h3 className="text-sm-left ml-3 heading-3 mb-4">Overall Score</h3>
      </Row>

      <Row>
        <Col className="col-12 col-lg-3 pl-0 d-flex mb-4 justify-content-center">
          {!finalScreenshot ? (
            ""
          ) : (
            <FinalScreenshot
              finalScreenshot={finalScreenshot}
              device="mobile"
            />
          )}
        </Col>
        <Col className="pl-0 d-flex mb-4 justify-content-center">
          {!finalScreenshotDesktop ? (
            ""
          ) : (
            <FinalScreenshot
              finalScreenshot={finalScreenshotDesktop}
              device="desktop"
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col className="col-lg-3 col-12 mb-4">
          <div
            className={
              "c100 big center p" +
              Math.round(parseInt(overallScore.Mobile)) +
              getOverallScoreColorSiteDetails(
                Math.round(parseInt(overallScore.Mobile))
              )
            }
            style={{ fontSize: "170px" }}
          >
            <span>
              {!overallScore.Mobile
                ? ""
                : Math.round(parseInt(overallScore.Mobile))}
            </span>
            <div className="slice">
              <div className="bar"></div>
              <div className="fill"></div>
            </div>
          </div>
          <h4 className="text-center mt-3">Mobile</h4>
        </Col>

        <Col className="mb-4">
          <div
            className={
              "c100 big center p" +
              Math.round(parseInt(overallScore.Desktop)) +
              getOverallScoreColorSiteDetails(
                Math.round(parseInt(overallScore.Desktop))
              )
            }
            style={{ fontSize: "170px" }}
          >
            <span>
              {!overallScore.Desktop
                ? ""
                : Math.round(parseInt(overallScore.Desktop))}
            </span>
            <div className="slice">
              <div className="bar"></div>
              <div className="fill"></div>
            </div>
          </div>
          <h4 className="text-center mt-3">Desktop</h4>
        </Col>
      </Row>
    </>
  );
};

export default OverallScore;
