import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";

const loggerMiddleware = createLogger();

export const store = createStore(
  rootReducer,
  process.env.REACT_APP_ENV === "production" ||
    process.env.REACT_APP_ENV === "development"
    ? applyMiddleware(thunkMiddleware, loggerMiddleware)
    : composeWithDevTools(applyMiddleware(thunkMiddleware, loggerMiddleware))
);
