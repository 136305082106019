import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo from "../assets/images/logos/newLogo.svg";
import {
  login,
  loginGoogle,
  loginMicrosoft,
} from "../state/actions/user.actions";
import { GoogleLogin } from "react-google-login";
import {
  GOOGLE_CLIENT_ID,
  MICROSOFT_CLIENT_ID,
  MICROSOFT_REDIRECT_URL,
} from "../constants";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "../helpers/microsoft.config";
import googleIcon from "../assets/images/logos/google.svg";
import microsoft from "../assets/images/logos/microsoft.svg";
import orHr from "../assets/images/logos/or.svg";
import axios from "axios";
import { API_URL } from "../constants";
import MergeAccountsModal from "../components/login/MergeAccountsModal";
import MergeAccountsModalMs from "../components/login/MergeAccountsModalMs";
import { decodeToken } from "react-jwt";
import { userConstants } from "../constants";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import matchIcon from "../assets/images/logos/matchIcon.svg";
import { isMobile } from "react-device-detect";

const LoginPage = () => {
  const history = useHistory();

  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });
  const [visible, setVisible] = useState(false);
  const [icon] = useState(eyeOff);

  const [submitted, setSubmitted] = useState(false);
  const [mergeAccountsModal, setMergeAccountsModal] = useState({
    open: false,
  });
  const [mergeAccountsModalMs, setMergeAccountsModalMs] = useState({
    open: false,
  });
  const [errorFromAuth, setErrorFromAuth] = useState("");
  const { username, password } = inputs;
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.authentication.loading);
  const user = useSelector((state) =>
    state.authentication.user
      ? state?.authentication?.user
      : state?.registration?.user
  );
  const error = useSelector((state) =>
    state.authentication.error ? state.authentication.error.errors[0] : null
  );

  // reset login status
  useEffect(() => {
    if (user) {
      if (user.suspendedAccount) history.push("/contactUs");
      if (!user.suspendedAccount) history.push("/dashboard");
    }
  }, [user]);

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    if (username && password) {
      dispatch(login(username, password));
    }
  }

  const handleLogin = async (googleData) => {
    try {
      setErrorFromAuth("");
      const { data } = await axios.post(`${API_URL}/auth/check`, {
        email: googleData.profileObj.email,
      });
      setMergeAccountsModal({
        open: data,
        email: googleData.profileObj.email,
        googleId: googleData.googleId,
        googleData,
      });
      if (!data) {
        setMergeAccountsModal({
          open: data,
        });
        dispatch(loginGoogle(googleData));
      }
    } catch (error) {
      setMergeAccountsModal({
        open: false,
      });
      setErrorFromAuth(error?.response?.data?.message);
      console.log(error.response);
    }
  };

  msalConfig.auth.clientId = MICROSOFT_CLIENT_ID;
  msalConfig.auth.redirectUri = MICROSOFT_REDIRECT_URL;
  const msalInstance = new PublicClientApplication(msalConfig);
  const msLogin = async (e) => {
    e.preventDefault();
    try {
      setErrorFromAuth("");
      const response = await msalInstance.loginPopup(loginRequest);
      const msdata = {
        microsoftId: response.uniqueId,
        email: response.account.username?.toLowerCase(),
      };
      const { data } = await axios.post(`${API_URL}/auth/check`, {
        ...msdata,
      });
      if (data === false) {
        setMergeAccountsModalMs({
          open: data,
        });
        dispatch(loginMicrosoft(msalInstance, loginRequest));
      } else if (data === true) {
        setMergeAccountsModalMs({
          open: data,
          email: msdata.email,
          microsoftId: msdata.microsoftId,
        });
      } else {
        localStorage.setItem("token", data.token);
        localStorage.setItem("user", data.username);
        localStorage.setItem("id", data.userId);
        dispatch({
          type: userConstants.LOGIN_SUCCESS,
          payload: decodeToken(data.token),
        });
      }
    } catch (error) {
      setMergeAccountsModalMs({
        open: false,
      });
      dispatch({
        type: userConstants.LOGIN_FAILURE,
        payload: error.response,
      });
      setErrorFromAuth(error?.response?.data?.message);
      console.log(error.response);
    }
  };

  return (
    <>
      {error || errorFromAuth ? (
        <div className={`alert alert-danger`}>
          {error ? error : errorFromAuth}
        </div>
      ) : null}
      {
        <MergeAccountsModal
          setMergeAccountsModal={setMergeAccountsModal}
          mergeAccountsModal={mergeAccountsModal}
        />
      }
      {
        <MergeAccountsModalMs
          setMergeAccountsModalMs={setMergeAccountsModalMs}
          mergeAccountsModalMs={mergeAccountsModalMs}
        />
      }
      <div className="login-container">
        <div className="d-table m-auto">
          <img
            id="main-logo"
            className="d-inline-block align-top"
            src={logo}
            alt="metryV Logo"
          />
        </div>
        <hr />

        <h1
          style={{
            textAlign: "center",
            color: "#3D506E",
            fontWeight: "600",
            fontSize: "40px",
            lineHeight: "53px",
          }}
          className="mt-2 mb-4"
        >
          Log in
        </h1>

        <form name="form">
          <div
            className="form-group"
            style={{
              display: "inline-block",
              width: "100%",
            }}
          >
            <button
              onClick={(e) => msLogin(e)}
              className="btn btn-outline-primary"
              style={{
                marginRight: "20px",
                weight: "800px",
                border: "1px solid #CACEDB",
                color: "#3D506E",
                backgroundColor: "#fff",
                borderRadius: "10px",
                width: isMobile ? "93%" : "46%",
                marginBottom: isMobile ? "10px" : "0px",
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              <img
                src={microsoft}
                alt="microsoft"
                style={{ width: "20px", height: "20px", margin: "0 7.5px" }}
              />
              Continue with Microsoft
            </button>
            <GoogleLogin
              clientId={`${GOOGLE_CLIENT_ID}`}
              buttonText="Continue with Google"
              onSuccess={handleLogin}
              onFailure={handleLogin}
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  className="btn btn-outline-danger"
                  style={{
                    border: "1px solid #CACEDB",
                    backgroundColor: "#fff",
                    color: "#3D506E",
                    borderRadius: "10px",
                    width: isMobile ? "93%" : "46%",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  <img
                    src={googleIcon}
                    alt="google"
                    style={{ width: "20px", height: "20px", margin: "0 7.5px" }}
                  />
                  Continue with Google
                </button>
              )}
              cookiePolicy={"single_host_origin"}
            />
            <br />
          </div>
        </form>
        <img
          src={orHr}
          alt="google"
          style={{
            color: "#818EA3",
            marginBottom: "15px",
            width: "100%",
            height: "100%",
            fontWeight: "600",
            fontSize: "14px",
          }}
        />

        <form name="form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label style={{ color: "#3D506E", fontSize: "15px" }}>
              Email address
            </label>
            <input
              type="text"
              name="username"
              value={username}
              onChange={handleChange}
              className={
                "form-control" + (submitted && !username ? " is-invalid" : "")
              }
              style={{
                borderRadius: "10px",
                fontSize: "15px",
                backgroundImage: "none",
                paddingRight: "8px",
              }}
            />
            {submitted && !username && (
              <div
                className="invalid-feedback"
                style={{
                  fontWeight: "400",
                  fontSize: "15px",
                  width: "91%",
                }}
              >
                <img
                  src={matchIcon}
                  alt="matchIcon"
                  style={{
                    width: "18px",
                    height: "18px",
                    margin: "0 7.5px",
                    marginTop: "-2px",
                  }}
                />
                Email address is required
              </div>
            )}
          </div>
          <div className="form-group mb-0">
            <label style={{ color: "#3D506E", fontSize: "15px" }}>
              Password
            </label>
            <input
              type={visible ? "text" : "password"}
              name="password"
              value={password}
              onChange={handleChange}
              className={
                "form-control" + (submitted && !password ? " is-invalid" : "")
              }
              style={{
                borderRadius: "10px",
                display: "flex",
                fontSize: "15px",
                backgroundImage: "none",
                paddingRight: "8px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            />
            <span
              onClick={() => setVisible(!visible)}
              style={{
                marginTop: "-32px",
                display: "flex",
                marginRight: "5px",
                float: "right",
                justifyContent: "end",
              }}
            >
              {!visible ? (
                <Icon
                  icon={icon}
                  style={{
                    width: "20.17px !important",
                    height: "18.36px !important",
                    marginRight: "10px",
                  }}
                ></Icon>
              ) : (
                <Icon
                  icon={eye}
                  style={{
                    width: "20.17px !important",
                    height: "18.36px !important",
                    marginRight: "10px",
                  }}
                ></Icon>
              )}
            </span>
            {submitted && !password && (
              <div
                className="invalid-feedback"
                style={{
                  fontWeight: "400",
                  fontSize: "15px",
                  width: "91%",
                }}
              >
                <img
                  src={matchIcon}
                  alt="matchIcon"
                  style={{
                    width: "20.17px",
                    height: "18.36px",
                    margin: "0 7.5px",
                    marginTop: "-2px",
                  }}
                />
                Password is required
              </div>
            )}
          </div>
          <div className="form-group">
            <Link
              to="/forgot-password"
              className="btn btn-link"
              style={{
                textDecorationLine: "underline",
                display: "flex",
                alignItems: "center",
                width: "29%",
                position: "absolute",
                justifyContent: "end",
                color: "#0087F9",
                fontWeight: "400",
                fontSize: "15px",
              }}
            >
              Forgot password?
            </Link>
          </div>
          <div className="form-group">
            <button
              className="form-control"
              style={{
                backgroundColor: "#0087F9",
                color: "white",
                fontWeight: "600",
                fontSize: "14px",
                borderRadius: "6px",
                marginTop: "50px",
              }}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}
              Log in
            </button>
          </div>
          <hr style={{ marginBottom: "5px" }} />
          <div
            className="form-group"
            style={{
              fontWeight: "400",
              color: "#3D506E",
              fontSize: "15px",
            }}
          >
            Don&apos;t have an account?
            <Link
              to="/register"
              className="btn btn-link"
              style={{
                textDecorationLine: "underline",
                fontWeight: "600",
                color: "#0087F9",
                fontSize: "15px",
                paddingLeft: "5px",
                marginTop: "-5px",
              }}
            >
              Register now
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

export { LoginPage };
