import React, { useState, useEffect } from "react";
import { Row, Col } from "shards-react";
import axios from "axios";
import TechStack from "../site-details/TechStack";
import { API_URL_PSI } from "../../constants";

const TechStackComparison = ({ siteUrl, mainSiteUrl }) => {
  const [techStack, setTechStack] = useState({});
  const [techStackMain, setTechStackMain] = useState({});
  const [loadingtechStack, setLoadingTechStack] = useState(true);
  const [loadingtechStackMain, setLoadingTechStackMain] = useState(true);

  async function getTechStack(siteUrl, mainSiteUrl) {
    await axios
      .post(
        `${API_URL_PSI}/scan/techstack`,
        {
          data: {
            url: siteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        let data = response.data;
        setTechStack(data);
        setLoadingTechStack(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
    await axios
      .post(
        `${API_URL_PSI}/scan/techstack`,
        {
          data: {
            url: mainSiteUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        let data = response.data;
        setTechStackMain(data);
        setLoadingTechStackMain(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  useEffect(() => {
    getTechStack(siteUrl, mainSiteUrl);
  }, [mainSiteUrl]);

  return (
    <Row className="mb-4">
      <Col className="col-lg-6 col-md-12 col-sm-12">
        {loadingtechStack ? "" : <TechStack techStack={techStack} />}
      </Col>
      <Col className="col-lg-6 col-md-12 col-sm-12">
        {loadingtechStackMain ? "" : <TechStack techStack={techStackMain} />}
      </Col>
    </Row>
  );
};

export default TechStackComparison;
