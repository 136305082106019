// Layout Types
import DefaultLayout from "./layouts/Default";

// Route Views
import ContactUs from "./pages/ContactUs";
import PricingPlans from "./pages/PricingPlans";
import Billing from "./pages/Billing";

export default [
  {
    path: "/contactUs",
    layout: DefaultLayout,
    component: ContactUs,
  },
  {
    path: "/subscription-plans",
    layout: DefaultLayout,
    component: PricingPlans,
  },
  {
    path: "/billing",
    layout: DefaultLayout,
    component: Billing,
  },
];
