import React, { useEffect, useState } from "react";
import { API_URL_PSI } from "../../constants";
import axios from "axios";
import { Row, Col, Button } from "shards-react";
import { Markup } from "interweave";
import { useSnackbar } from "notistack";

const CustomSuggestions = ({ suggestion_type, techStack, suggestion_name }) => {
  const [suggestion, setSuggestion] = useState({});
  const [generalDisable, setGeneralDisable] = useState(false);
  const [wordpressDisable, setWordpressDisable] = useState(false);
  const [isWordpress, setIsWordpress] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function getSuggestion() {
      await axios
        .get(`${API_URL_PSI}/suggestions/${suggestion_type}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          let data = {
            id: response.data[0] ? response.data[0].id : false,
            general: response.data[0] ? response.data[0].general : false,
            wordpress: response.data[0] ? response.data[0].wordpress : false,
          };
          techStack.forEach((technology) => {
            if (technology.name == "WordPress") {
              setIsWordpress(true);
            }
          });
          setSuggestion({ data });
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
    getSuggestion();
  }, []);

  const requestSuggestion = async (e) => {
    const request_body = {
      id: suggestion.data.id,
      suggestion_type: suggestion_type,
      type: e.target.value,
      suggestion_name,
    };
    await axios
      .post(`${API_URL_PSI}/suggestions/request`, request_body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(() => {
        enqueueSnackbar("Your request has been submitted successfully!", {
          variant: "success",
        });
        if (e.target.value == "wordpress") {
          setWordpressDisable(true);
        } else {
          setGeneralDisable(true);
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(
          "There was an error making your request at the moment",
          {
            variant: "error",
          }
        );
      });
  };

  return (
    <div>
      <Row className="ml-1">
        <h3 className="mt-4 mb-4">General Suggestions</h3>
        <Col sm={12} className="pl-0">
          {suggestion.data && suggestion.data.general ? (
            <Markup
              content={suggestion.data.general.replace(
                /<p><br[/]?><[/]?p>/g,
                ""
              )}
            />
          ) : (
            <div>
              <p>
                There are no General Suggestions for this issue at the moment
              </p>
              <Button
                outline
                theme="danger"
                value="general"
                onClick={requestSuggestion}
                disabled={generalDisable ? "true" : ""}
              >
                Request a suggestion
              </Button>
            </div>
          )}
        </Col>
      </Row>

      {!isWordpress ? (
        ""
      ) : (
        <Row className="ml-1">
          <h3 className="mt-4 mb-4">Wordpress Suggestions</h3>
          <Col sm={12} className="pl-0">
            {suggestion.data && suggestion.data.wordpress ? (
              <Markup
                content={suggestion.data.wordpress.replace(
                  /<p><br[/]?><[/]?p>/g,
                  ""
                )}
              />
            ) : (
              <div>
                <p>
                  There are no Wordpress Suggestions for this issue at the
                  moment
                </p>
                <Button
                  outline
                  theme="danger"
                  value="wordpress"
                  onClick={requestSuggestion}
                  disabled={wordpressDisable ? "true" : ""}
                >
                  Request a suggestion
                </Button>
              </div>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default CustomSuggestions;
